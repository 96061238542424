

// Package Dir: commerce/cart/product/relation_product
// FileName: RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.cart.product.relation_product.RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest
// Super types: java.lang.Object

export class RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_cart_product_entity_id = null;
        /**
         *
         * JVM type: kotlin.collections.List<kotlin.String>
         * @type {array<string>}
         */
        this.commerce_sale_product_release_item_entity_ids = null;

    }
}