

// Package Dir: release/collection
// FileName: PutReleaseCollectionPreviewRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.release.collection.PutReleaseCollectionPreviewRPCRequest
// Super types: java.lang.Object

export class PutReleaseCollectionPreviewRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.entity_id = null;

    }
}