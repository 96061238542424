import {GetCommerceSaleProductReleaseCollectionRPCResponse} from "../../commerce/sale/product/release/collection/GetCommerceSaleProductReleaseCollectionRPCResponse";

// Package Dir: release/collection
// FileName: GetReleaseCollectionRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.release.collection.GetReleaseCollectionRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.release.collection.ReleaseCollectionRPCBody, com.loops4.controller.loops4rpc.model.release.collection.ReleaseCollectionRPCRelation, java.lang.Object

export class GetReleaseCollectionRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_state = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_type = null;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.sale.product.release.collection.GetCommerceSaleProductReleaseCollectionRPCResponse?
         * @type {GetCommerceSaleProductReleaseCollectionRPCResponse}
         */
        this.product = new GetCommerceSaleProductReleaseCollectionRPCResponse();

    }
}