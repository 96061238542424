import React, {Component} from 'react';
import {Route, Router, Switch, Redirect} from "react-router-dom";
import {RouteDictionary} from "./route/RouteDictionary";
import SignIn from "./route/sign/SignIn";
import SignUp from "./route/sign/SignUp";
import Library from "./route/library/Library";
import {routeHistory} from "./route/RouteHistory";
import DimExposure from "./screen/layout/DimExposure";
import NotFound from "./route/error/NotFound";
import Store from "./route/store/Store";
import StoreReleaseFormByLibraryCollection
    from "./route/store/release_form/by_library_collection/StoreReleaseFormByLibraryCollection";
import ReleaseCollection from "./route/release/collection/ReleaseCollection";
import ReleaseItem from "./route/release/item/ReleaseItem";
import PublishReleaseCollection from "./route/publish/collection/PublishReleaseCollection";
import PublishReleaseCollectionSignIn from "./route/publish/collection/PublishReleaseCollectionSignIn";
import {signedAccountUserValidator} from "./route/security_router/SignedAccountUserRouter";
import {signedAccountSubscriberValidator} from "./route/security_router/SignedAccountSubscriberRouter";
import SignedAccountRouter from "./route/security_router/SignedAccountRouter";
import AdminSignIn from "./route/admin/sign/AdminSignIn";
import AdminPortal from "./route/admin/portal/AdminPortal";
import {signedAccountAdminValidator} from "./route/security_router/SignedAccountAdminRouter";

class App extends Component {
    render() {
        return (
            <Router history={routeHistory}>
                <DimExposure/>
                <Switch>
                    {/* Admin */}
                    <Route path={RouteDictionary.adminSignIn} component={AdminSignIn}/>
                    <SignedAccountRouter path={RouteDictionary.adminPortal_OrderDetail} component={AdminPortal} signingValidator={signedAccountAdminValidator} />
                    <SignedAccountRouter path={RouteDictionary.adminPortal} component={AdminPortal} signingValidator={signedAccountAdminValidator} />

                    {/* Authentication */}
                    <Route path={RouteDictionary.signIn} component={SignIn}/>
                    <Route path={RouteDictionary.signUp} component={SignUp}/>

                    {/* Library */}
                    <SignedAccountRouter path={RouteDictionary.item} component={Library} signingValidator={signedAccountUserValidator} />
                    <SignedAccountRouter path={RouteDictionary.collection} component={Library} signingValidator={signedAccountUserValidator} />
                    <SignedAccountRouter path={RouteDictionary.library} component={Library} signingValidator={signedAccountUserValidator} />
                    <SignedAccountRouter path={RouteDictionary.storeReleaseFormByLibraryCollection} component={StoreReleaseFormByLibraryCollection} signingValidator={signedAccountUserValidator} />
                    <SignedAccountRouter path={RouteDictionary.releaseItem} component={ReleaseItem} signingValidator={signedAccountUserValidator} />
                    <SignedAccountRouter path={RouteDictionary.orderDetailInReleaseCollection} component={ReleaseCollection} signingValidator={signedAccountUserValidator} />
                    <SignedAccountRouter path={RouteDictionary.releaseCollection} component={ReleaseCollection} signingValidator={signedAccountUserValidator} />
                    <SignedAccountRouter path={RouteDictionary.store} component={Store} signingValidator={signedAccountUserValidator} />

                    {/* Subscriber */}
                    <Route path={RouteDictionary.subscriberSignIn} component={PublishReleaseCollectionSignIn}/>
                    <SignedAccountRouter path={RouteDictionary.orderInPublishReleaseCollection} component={PublishReleaseCollection} signingValidator={signedAccountSubscriberValidator}/>
                    <SignedAccountRouter path={RouteDictionary.publishReleaseCollection} component={PublishReleaseCollection} signingValidator={signedAccountSubscriberValidator}/>

                    {/* Index */}
                    <Route exact path={RouteDictionary.index} >
                        <Redirect to={RouteDictionary.library} />
                    </Route>

                    {/* NotFound must be set in last line */}
                    <Route path={RouteDictionary.notFound} component={NotFound}/>
                </Switch>
            </Router>
        );
    }
}

export default App;
