

// Package Dir: commerce/order/common
// FileName: OrderBillPriceRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillPriceRPCBody
// Super types: java.lang.Object

export class OrderBillPriceRPCBody {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.price_currency = null;
        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.product_price_amount = 0;
        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.refund_price_amount = 0;
        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.shipping_price_amount = 0;
        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.tax_price_amount = 0;
        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.total_price_amount = 0;

    }
}