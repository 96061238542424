// source: account/AccountService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_Resource_pb = require('../common/Resource_pb.js');
goog.object.extend(proto, common_Resource_pb);
var master_language_LanguageMaster_pb = require('../master/language/LanguageMaster_pb.js');
goog.object.extend(proto, master_language_LanguageMaster_pb);
goog.exportSymbol('proto.account.GetAccountAdminResponse', null, global);
goog.exportSymbol('proto.account.GetAccountSubscriberResponse', null, global);
goog.exportSymbol('proto.account.GetAccountUserResponse', null, global);
goog.exportSymbol('proto.account.PutAccountUserRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetAccountSubscriberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.GetAccountSubscriberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetAccountSubscriberResponse.displayName = 'proto.account.GetAccountSubscriberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetAccountAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.GetAccountAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetAccountAdminResponse.displayName = 'proto.account.GetAccountAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetAccountUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.GetAccountUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetAccountUserResponse.displayName = 'proto.account.GetAccountUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.PutAccountUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.PutAccountUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.PutAccountUserRequest.displayName = 'proto.account.PutAccountUserRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetAccountSubscriberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetAccountSubscriberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetAccountSubscriberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountSubscriberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateCreated: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateUpdated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    language: jspb.Message.getFieldWithDefault(msg, 6, 0),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    accessKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
    commerceCartProductEntityId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetAccountSubscriberResponse}
 */
proto.account.GetAccountSubscriberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetAccountSubscriberResponse;
  return proto.account.GetAccountSubscriberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetAccountSubscriberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetAccountSubscriberResponse}
 */
proto.account.GetAccountSubscriberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateUpdated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {!proto.master.LanguageMaster} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessKey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommerceCartProductEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetAccountSubscriberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetAccountSubscriberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetAccountSubscriberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountSubscriberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateCreated();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateUpdated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccessKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCommerceCartProductEntityId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_created = 2;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getDateCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setDateCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_updated = 3;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getDateUpdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setDateUpdated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional master.LanguageMaster language = 6;
 * @return {!proto.master.LanguageMaster}
 */
proto.account.GetAccountSubscriberResponse.prototype.getLanguage = function() {
  return /** @type {!proto.master.LanguageMaster} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.master.LanguageMaster} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string access_key = 8;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string commerce_cart_product_entity_id = 9;
 * @return {string}
 */
proto.account.GetAccountSubscriberResponse.prototype.getCommerceCartProductEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountSubscriberResponse} returns this
 */
proto.account.GetAccountSubscriberResponse.prototype.setCommerceCartProductEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetAccountAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetAccountAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetAccountAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateCreated: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateUpdated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    language: jspb.Message.getFieldWithDefault(msg, 6, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetAccountAdminResponse}
 */
proto.account.GetAccountAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetAccountAdminResponse;
  return proto.account.GetAccountAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetAccountAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetAccountAdminResponse}
 */
proto.account.GetAccountAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateUpdated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {!proto.master.LanguageMaster} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetAccountAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetAccountAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetAccountAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateCreated();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateUpdated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.account.GetAccountAdminResponse.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountAdminResponse} returns this
 */
proto.account.GetAccountAdminResponse.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_created = 2;
 * @return {string}
 */
proto.account.GetAccountAdminResponse.prototype.getDateCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountAdminResponse} returns this
 */
proto.account.GetAccountAdminResponse.prototype.setDateCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_updated = 3;
 * @return {string}
 */
proto.account.GetAccountAdminResponse.prototype.getDateUpdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountAdminResponse} returns this
 */
proto.account.GetAccountAdminResponse.prototype.setDateUpdated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.account.GetAccountAdminResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountAdminResponse} returns this
 */
proto.account.GetAccountAdminResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.account.GetAccountAdminResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountAdminResponse} returns this
 */
proto.account.GetAccountAdminResponse.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional master.LanguageMaster language = 6;
 * @return {!proto.master.LanguageMaster}
 */
proto.account.GetAccountAdminResponse.prototype.getLanguage = function() {
  return /** @type {!proto.master.LanguageMaster} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.master.LanguageMaster} value
 * @return {!proto.account.GetAccountAdminResponse} returns this
 */
proto.account.GetAccountAdminResponse.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string account_id = 7;
 * @return {string}
 */
proto.account.GetAccountAdminResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountAdminResponse} returns this
 */
proto.account.GetAccountAdminResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetAccountUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetAccountUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetAccountUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateCreated: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateUpdated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    language: jspb.Message.getFieldWithDefault(msg, 6, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    libraryBoxEntityId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    releaseStoreEntityId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    commerceCartProductEntityId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetAccountUserResponse}
 */
proto.account.GetAccountUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetAccountUserResponse;
  return proto.account.GetAccountUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetAccountUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetAccountUserResponse}
 */
proto.account.GetAccountUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateUpdated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {!proto.master.LanguageMaster} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLibraryBoxEntityId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseStoreEntityId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommerceCartProductEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetAccountUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetAccountUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetAccountUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateCreated();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateUpdated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLibraryBoxEntityId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReleaseStoreEntityId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCommerceCartProductEntityId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_created = 2;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getDateCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setDateCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_updated = 3;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getDateUpdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setDateUpdated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional master.LanguageMaster language = 6;
 * @return {!proto.master.LanguageMaster}
 */
proto.account.GetAccountUserResponse.prototype.getLanguage = function() {
  return /** @type {!proto.master.LanguageMaster} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.master.LanguageMaster} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string account_id = 7;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string library_box_entity_id = 8;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getLibraryBoxEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setLibraryBoxEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string release_store_entity_id = 9;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getReleaseStoreEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setReleaseStoreEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string commerce_cart_product_entity_id = 10;
 * @return {string}
 */
proto.account.GetAccountUserResponse.prototype.getCommerceCartProductEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetAccountUserResponse} returns this
 */
proto.account.GetAccountUserResponse.prototype.setCommerceCartProductEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.PutAccountUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.PutAccountUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.PutAccountUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.PutAccountUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountPassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.PutAccountUserRequest}
 */
proto.account.PutAccountUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.PutAccountUserRequest;
  return proto.account.PutAccountUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.PutAccountUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.PutAccountUserRequest}
 */
proto.account.PutAccountUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {!proto.master.LanguageMaster} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.PutAccountUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.PutAccountUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.PutAccountUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.PutAccountUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.account.PutAccountUserRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.PutAccountUserRequest} returns this
 */
proto.account.PutAccountUserRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_password = 2;
 * @return {string}
 */
proto.account.PutAccountUserRequest.prototype.getAccountPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.PutAccountUserRequest} returns this
 */
proto.account.PutAccountUserRequest.prototype.setAccountPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.account.PutAccountUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.PutAccountUserRequest} returns this
 */
proto.account.PutAccountUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.account.PutAccountUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.PutAccountUserRequest} returns this
 */
proto.account.PutAccountUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional master.LanguageMaster language = 5;
 * @return {!proto.master.LanguageMaster}
 */
proto.account.PutAccountUserRequest.prototype.getLanguage = function() {
  return /** @type {!proto.master.LanguageMaster} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.master.LanguageMaster} value
 * @return {!proto.account.PutAccountUserRequest} returns this
 */
proto.account.PutAccountUserRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


goog.object.extend(exports, proto.account);
