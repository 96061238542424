import * as $ from 'jquery'

export const requestAjax = (url, method, data, headers = {},
                            callback = (data, textStatus, jqXHR) => {},
                            failback = (jqXHR, textStatus, errorThrown) => {},
                            additionalAjaxSetting = {}
) => {

    /**
     * @type {JQueryAjaxSettings}
     */
    let requestDefine = {};
    requestDefine.url = url;
    requestDefine.method = method;
    requestDefine.contentType = "application/x-www-form-urlencoded; charset=UTF-8";
    requestDefine.data = {};
    requestDefine.processData = true;
    requestDefine.async = true;

    if (method !== "GET") {
        requestDefine.contentType = "application/json";
    }

    if (method !== "DELETE") {
        requestDefine.dataType = "json";
    }

    if (data && data !== "undefined") {
        requestDefine.data = (method.toUpperCase() === "GET" ? (Object.keys(data).length > 0 ? data : null) : JSON.stringify(data));
    }

    requestDefine.headers = headers

    requestDefine.success = callback;
    requestDefine.error = failback;

    let requestSettings = Object.assign(requestDefine, additionalAjaxSetting);


    let requestedAjax = $.ajax(requestSettings);
    return requestedAjax
}

export const downloadBlobAjax = (url, method, data, authUserType = null,
                                 callback = (downloadingBinary, textStatus, jqXHR) => {},
                                 failback = (jqXHR, textStatus, errorThrown) => {},
                                 progressHandler = (progres) => {
                                 }
) => {

    /**
     * @type {AjaxRequestingSetting}
     */
    const ajaxRequestingSetting = {};
    ajaxRequestingSetting.accepts = 'application/octet-stream';
    ajaxRequestingSetting.contentType = 'application/json';
    ajaxRequestingSetting.dataType = 'binary';
    ajaxRequestingSetting.xhrFields = {responseType: 'blob'};
    ajaxRequestingSetting.cache = true;
    ajaxRequestingSetting.processData = false;
    // ajaxRequestingSetting.contentType = false;
    ajaxRequestingSetting.xhr = () => {
        let myXhr = $.ajaxSettings.xhr();
        myXhr.addEventListener('progress', progressHandler, false); // for handling the progress of the upload
        return myXhr;
    };

    return requestAjax(
        url,
        method,
        data,
        authUserType,
        callback,
        failback,
        ajaxRequestingSetting
    )
}

export const downloadFileAjax = (url, method, data, authUserType = null,
                                 callback = (downloadingBinary, textStatus, jqXHR) => {},
                                 failback = (jqXHR, textStatus, errorThrown) => {}
) => {
    /**
     * @type {AjaxRequestingSetting}
     */
    const ajaxRequestingSetting = {};
    ajaxRequestingSetting.accepts = 'application/octet-stream';
    ajaxRequestingSetting.contentType = 'application/json';
    ajaxRequestingSetting.cache = true;

    return requestAjax(
        url,
        method,
        data,
        authUserType,
        callback,
        failback,
        ajaxRequestingSetting
    )
}
