

// Package Dir: release/item
// FileName: ReleaseItemExifRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.release.item.ReleaseItemExifRPCBody
// Super types: java.lang.Object

export class ReleaseItemExifRPCBody {
    constructor() {

        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.aperture = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.create_date = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.date_original = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.focal_length = null;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.height = 0;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.iso = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.latitude = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.longitude = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.modify_date = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.shutterSpeed = null;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.width = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.component10 = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.component11 = 0;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.component2 = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.component3 = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.component4 = null;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.component5 = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.component6 = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.component7 = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.component8 = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.component9 = null;

    }
}