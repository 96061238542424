import * as React from "react";
import {RouteUtility} from "../../../../route/RouteUtility";
import {Link} from "react-router-dom";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../../route/RouteDictionary";
import * as PropTypes from "prop-types";
import DomIdConstant from "../../../../constant/DomIdConstant";

export default class ReleaseCollectionNavigator extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        storeId: PropTypes.string,
        accessTokenModel: PropTypes.object,
        releaseCollectionModel: PropTypes.object,
    }

    static viewMode = {
        browsing: 'browsing',
        subscriber: 'subscriber',
        orderHistory: 'orderHistory',
        orderDetail: 'orderDetail'
    }


    REFS_SET = {
    };

    iconTdStyle = {
        padding:6,paddingTop:10,paddingBottom:10
    };
    labelTdStyle = {
        width:'100%'
    };

    inActiveStyle = {
        border:0, width:'100%',background:'inherit'
    };
    activeStyle = {
        border:0, width:'100%',backgroundColor:'#24272d'
    };



    genTr(id, iconClass, label, style = {}){
        return (
            <div className="flexItem00"
                 id={id}
                 style={{width:'100%'}}
            >
                <div className="flexContainerColumn flexItemAlignCenterCenter"
                     // style={Object.assign({width: '100%', padding: 8}, (activeButton === activeConst ? this.activeStyle : this.inActiveStyle), customStyle)}
                     style={Object.assign({width: '100%', padding: 8}, style)}
                >
                    <div className="flexItem00">
                        {/*<i className={`icon large ${iconClass} ${(activeButton === activeConst ? 'white' : null)}`} />*/}
                        <i className={`icon large ${iconClass}`} />
                    </div>
                    <div className="flexItem11" style={{fontSize: 10}}>
                        <div style={this.labelTdStyle}>
                            {label}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    genSubtreeElement(title){
        return (
            <div className="flexContainerRow" style={{padding: 10, minHeight: 35}} >
                <div className="flexItem00" style={{marginRight: 5, marginLeft: 10}}><i className="ui icon minus small" /> </div>
                <div className="flexItem11">{title}</div>
            </div>
        )
    }


    render() {
        return (
            <div className="flexContainerColumn "
                 style={{backgroundColor:'#2b333d', color:'white', transition:'all 0.1s ease-in'}}
            >
                <div className="flexItem00">
                    <div className="flexContainerColumn flexItemAlignStartCenter">
                        <Link to={RouteUtility.getPathname(RouteDictionary.releaseCollection, {
                            [RoutePathParam.storeId]: this.props.storeId,
                            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId
                        },{
                            [RouteQueryParam.view_mode]: ReleaseCollectionNavigator.viewMode.browsing,
                        })}>
                            {this.genTr(DomIdConstant.DomIdReleaseCollectionNavigator.browsingButton, 'green', 'Browsing', {color:'#21ba45'})}
                        </Link>

                        <div className="ui fitted divider" />

                        <Link to={RouteUtility.getPathname(RouteDictionary.releaseCollection, {
                            [RoutePathParam.storeId]: this.props.storeId,
                            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId
                        },{
                            [RouteQueryParam.view_mode]: ReleaseCollectionNavigator.viewMode.subscriber,
                        })}>
                            {this.genTr(DomIdConstant.DomIdReleaseCollectionNavigator.subscriberButton,'disk outline grey', 'Subscriber')}
                        </Link>

                        <div className="ui fitted divider" />

                        <Link to={RouteUtility.getPathname(RouteDictionary.releaseCollection, {
                            [RoutePathParam.storeId]: this.props.storeId,
                            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId
                        },{
                            [RouteQueryParam.view_mode]: ReleaseCollectionNavigator.viewMode.orderHistory,
                        })}>
                            {this.genTr(DomIdConstant.DomIdReleaseCollectionNavigator.orderButton,'disk outline grey', 'Order')}
                        </Link>

                        <div className="ui fitted divider" />
                    </div>
                </div>
                <div className="flexItem11 flexItemVerticallyDynamic">
                </div>
            </div>

        );
  }
}