import {createStore, applyMiddleware, compose} from "redux";
import { composeWithDevTools } from 'remote-redux-devtools';

import reducer from "../reducer/ReduxReducer";
import {RouterInjector} from "../middleware/router/RouteInjector";

//for Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWares = [
    RouterInjector
]
export const ReduxMainStore = createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(...middleWares)
    )
)