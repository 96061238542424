import * as React from "react";
import * as PropTypes from "prop-types";
import BoxAdderComponent from "./adder_sub/BoxAdderComponent";
import CollectionAdderComponent from "./adder_sub/CollectionAdderComponent";

export const AdderTabType = {
    box:"box",
    collection:"collection",
    photo:"photo"
};

class LibraryAdderComponentState {
    tabType = AdderTabType.box
}

export default class LibraryAdderComponent extends React.Component {

    REFS_SET = {
    };

    static propTypes = {
        parentBoxId: PropTypes.string,
        loadTheModel: PropTypes.func,
        adderTabType: PropTypes.string,
    }

    elasticFiltersUIDs = 100;


    constructor(props, context) {
        super(props, context);
        this.state = new LibraryAdderComponentState();
    }

    updateTabType(tabType){
        this.setState((prevs, props)=> {
            prevs.tabType = tabType;
            return prevs;
        });
    }

    getTab(tabType){
        switch (tabType){
            case AdderTabType.box:
                return <BoxAdderComponent parentBoxId={this.props.parentBoxId}
                                          loaderAction={this.props.loadTheModel.bind(this)} />
            case AdderTabType.collection:
                return <CollectionAdderComponent parentBoxId={this.props.parentBoxId}
                                                 loaderAction={this.props.loadTheModel.bind(this)} />
            case AdderTabType.photo:
                break;
            default: throw Error("Unexcepted tab type::" + tabType)
        }
    }

    genTabMenuElement(tabName, tabType){
        let isActive = this.state.tabType === tabType;

        let classProps = "item " + (isActive ? "active" : null);
        return <div className={classProps} onClick={this.updateTabType.bind(this, tabType)}>{tabName}</div>
    }

    render() {

        return(
            <div className="ui segment" style={{color:'black', textAlign:'left'}}>

                <h4 style={{textAlign:'left'}}>
                    PATH
                </h4>

                <div className="ui divider"></div>


                <div className="ui top attached tabular menu">
                    {this.genTabMenuElement("Box", AdderTabType.box)}
                    {this.genTabMenuElement("Collection", AdderTabType.collection)}
                    {this.genTabMenuElement("Photo", AdderTabType.photo)}
                </div>

                {/*<div class="ui bottom attached tab segment">*/}
                <div class="">
                    <div style={{height: 10}}></div>
                    {this.getTab(this.state.tabType)}
                </div>

            </div>
        )
    }
}
