import React from 'react';
import '../../../App.css';
import {connect} from "react-redux";
import {RouteListenerComponent} from "../../RouteListener";
import LoadingComponent from "../../../ui_template/LoadingComponent";
import {clearFullSizeDIM, setFullSizeDIM} from "../../../state/redux/action/UIControlAction";
import {issueAccessToken} from "../../../api/loops4/AuthAPI";
import {AuthUserType} from "../../../constant/APIConstant";
import {AccessTokenModel} from "../../../model/auth/AccessTokenModel";
import {EmptyRequest} from "../../../api/loops4/model/general/EmptyRequest";
import {GET_ACCOUNT_INFORMATION_BY_AUTH_SESSION__V1_0} from "../../../api/loops4/caller/loops4RpcCaller";
import {getAuthorizationHeader} from "../../../api/loops4/Loops4RPCAPI";
import {clearAccessTokenForAdmin, setAccessTokenForAdmin} from "../../../state/redux/action/AuthAction";
import {clearAccountAdminInfo, setAccountAdminInfo} from "../../../state/redux/action/UserAction";
import {pushHistory} from "../../RouteHistory";
import {RouteDictionary} from "../../RouteDictionary";
import LanguagePack from "../../../language/LanguagePack";
import SignConstraintPack from "../../../constraints/SignConstraintPack";
import DomIdConstant from "../../../constant/DomIdConstant";
import SignLangPack from "../../../language/SignLangPack";
import {GetAccountInformationByAuthSessionRPCRequest} from "../../../api/loops4/model/account/user/GetAccountInformationByAuthSessionRPCRequest";
import ServiceInfoConstant from "../../../constant/ServiceInfoConstant";
import {APPLICATION_VERSION} from "../../../constant/ApplicationVersionConstant";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
    };
};

class AdminSignIn extends RouteListenerComponent {

    REFS_SET = {
        signForm_accountId: 'signForm_accountId',
        signForm_accountPassword: 'signForm_accountPassword',
    }

    signIn(e){
        e.preventDefault();

        setFullSizeDIM(<LoadingComponent loaderFlag={true} />)

        let accountId = this.refs[this.REFS_SET.signForm_accountId]["value"];
        let accountPassword = this.refs[this.REFS_SET.signForm_accountPassword]["value"];

        let accessToken;

        issueAccessToken({
            accountId,
            accountPassword,
            AuthUserType: AuthUserType.admin,
            },
        )
            .then(async (response) => {
                accessToken = new AccessTokenModel({
                    accessToken: response['access_token'],
                    lifeSeconds: response['life_seconds'],
                    refreshToken: response['refresh_token'],
                    createdAt: response['created_at'],
                    scope: response['scope'],
                    tokenType: response['token_type']
                })


                const param = new GetAccountInformationByAuthSessionRPCRequest()
                param.account_id = accountId
                const adminInfoResult = await GET_ACCOUNT_INFORMATION_BY_AUTH_SESSION__V1_0({
                    headers: getAuthorizationHeader(accessToken),
                    paramBody: param
                })

                if(adminInfoResult.isError()){
                    throw Error(`failed to get admin information, Please try it again or inquiry to administrator, Error Code: ${adminInfoResult.getErrorMessage()}`)
                } else {
                    return adminInfoResult.model
                }
            }).then( (accountAdminModel) => {
                setAccessTokenForAdmin(accessToken)
                setAccountAdminInfo(accountAdminModel)
                pushHistory(RouteDictionary.adminPortal)
            })
            .catch(e => {
                clearAccessTokenForAdmin()
                clearAccountAdminInfo()
                alert(`failed to sign in, please check your account e-mail and secretKey, Error:: ${e.message}`)
            })
            .finally(_ => {
                clearFullSizeDIM();
            })
    }


  render() {
    const langType = this.props.language

    return (
        <div style={{width:'100%', height:'100%', backgroundColor: '#EFEFEF',
            backgroundImage: `url('${process.env.PUBLIC_URL}/assets/img/backgrounds/trimed2_1080.jpg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: '70% 0px'
        }} >
            <div style={{verticalAlign:'middle'}}>
                <div className="ui container" style={{position:'relative', zIndex:100, paddingTop:'20%', paddingBottom:'20%'}}>

                    <div style={{width:390}}>
                        <div>
                            <h2>
                                {ServiceInfoConstant.serviceName}<br/>
                            </h2>
                            <h4 style={{marginTop:0, color: '#CCC'}}>
                                v{APPLICATION_VERSION}
                            </h4>
                        </div>

                        <div className="ui hidden divider"></div>

                        <form className="ui form" onSubmit={this.signIn.bind(this)}>
                            <div className="field">
                                <label>
                                    {LanguagePack.extract(langType, SignLangPack.fields.email)}
                                </label>
                                <div className="ui left icon input">
                                    <input type="email"
                                           placeholder="email@domain.com"
                                           autoFocus={true}
                                           id={DomIdConstant.DomIdSignIn.accountId}
                                           ref={this.REFS_SET.signForm_accountId}
                                           minLength={SignConstraintPack.email.minLength}
                                           maxLength={SignConstraintPack.email.maxLength}
                                    />
                                    <i className="mail icon" />
                                </div>
                            </div>
                            <div className="field">
                                <label>
                                    {LanguagePack.extract(langType, SignLangPack.fields.password)}
                                </label>
                                <div className="ui left icon input">
                                    <input type="password"
                                           id={DomIdConstant.DomIdSignIn.accountPassword}
                                           ref={this.REFS_SET.signForm_accountPassword}
                                           minLength={SignConstraintPack.password.minLength}
                                           maxLength={SignConstraintPack.password.maxLength}
                                    />
                                    <i className="lock icon" />
                                </div>
                            </div>
                            <button className="ui blue submit green button"
                                    id={DomIdConstant.DomIdSignIn.submitButton}
                            >
                                {LanguagePack.extract(langType, SignLangPack.buttons.signIn)}
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div style={{position:'absolute', left:0, right:0, top:'100%', zIndex:100}}>
                {/*<IntroFooter parentProps={this.props} />*/}
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(AdminSignIn);