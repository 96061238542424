import UploadingProgressStatusComponent from "./UploadingProgressStatusComponent";
import * as Loops4RPCAPI from "../../../../../api/loops4/Loops4RPCAPI";
import {uploadToLibraryItemBinary} from "../../../../../api/loops4/Loops4BinaryAPI";
import * as React from "react";
import * as PropTypes from "prop-types";


export default class UploadProgressContainer extends React.Component {
    static propTypes = {
        accessTokenModel: PropTypes.object,
        uploadingCollectionId: PropTypes.string,
        file: PropTypes.object,
        onSuccess: PropTypes.func,
        onFailure: PropTypes.func,
    }

    startDelay = 20

    constructor(props, context) {
        super(props, context);

        const fileName = props.file['name']
        const modifiedDate = props.file["lastModifiedDate"]

        // console.log('UploadProgressContainer', {
        //     file: props.file,
        //     fileName,
        //     modifiedDate
        // })

        this.state = {
            fileName: fileName,
            modifiedDate: modifiedDate,
            uploadingProgressComponent: <UploadingProgressStatusComponent uploadingProgressModel={{
                progressPercentage: 0,
                uploadedKB: 0,
                totalKB: 0,
                speedKBPerSec: 0
            }} fileName={fileName} />
        }

        setTimeout(this.startUpload, this.startDelay)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.file !== this.props.file){
            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    fileName: this.props.file['name'],
                    modifiedDate: this.props.file["lastModifiedDate"]
                }
            }, () => {
                setTimeout(this.startUpload, this.startDelay)
            })
        }
    }

    startUpload = async () => {
        const rpcResult = await Loops4RPCAPI.createLibraryItem({
            accessTokenModel: this.props.accessTokenModel,
            libraryCollectionId: this.props.uploadingCollectionId,
            name: this.state.fileName,
        })

        if(rpcResult.isError()){
            this.failBack(rpcResult.getErrorMessage())
        } else {
            this.uploadFormPerFile = new FormData();
            this.uploadFormPerFile.append( this.uploadEventId, this.props.file);

            const libraryItemId = rpcResult.model.entityId

            uploadToLibraryItemBinary({
                accessTokenModel: this.props.accessTokenModel,
                libraryItemId: libraryItemId,
                pictureForm: this.uploadFormPerFile,
                callback: this.callBack,
                failback: this.binaryTransferFallback,
                progressHandler: this.processHandler
            })
        }
    }

    binaryTransferFallback = (jqXHR, textStatus) => {
        // {
        //     "jqXHR": {
        //     "readyState": 4,
        //         "responseText": "{\"error_code\":\"ExecutionRuntimeError\",\"error_message\":\"Error happens during executing RPC__OriginalMessage::null\",\"error_origin_exception\":null}",
        //         "status": 500,
        //         "statusText": "Internal Server Error"
        // },
        //     "textStatus": "error"
        // }
        const message = `Binary Transfer Failure (${textStatus}, ${jqXHR.statusText}(${jqXHR.status}))`
        // console.debug({message, detail: {jqXHR: JSON.parse(JSON.stringify(jqXHR)), textStatus}})
        this.failBack(message)
    }

    callBack = () => {
        this.setState((prevs, props)=> {
            return {
                ...prevs,
                uploadingProgressComponent: null
            }
        }, () => {
            this.props.onSuccess(this.props.file)
        });
    }

    failBack = (message) => {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                uploadingProgressComponent: null
            }
        }, () => {
            this.props.onFailure(this.props.file, message)
        })
    }

    /**
     * @param {UploadingProgressModel} progressModel
     */
    processHandler = (progressModel) => {
        this.setState((prevs, props)=> {
            return {
                ...prevs,
                uploadingProgressComponent: <UploadingProgressStatusComponent uploadingProgressModel={progressModel} fileName={this.state.fileName} />
            }
        });
    }

    render() {
        return (
            this.state.uploadingProgressComponent
        )
    }
}
