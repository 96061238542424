import * as React from "react";
import * as PropTypes from "prop-types";
import {ScrollableTable} from "../../../../ui_template/table/ScrollableTable";
import {getAuthorizationHeader} from "../../../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../../../ui_template/DimLoaderComponent";
import {RouteUtility} from "../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../RouteDictionary";
import {Link} from "react-router-dom";
import DateFormatter from "../../../../model/formatter/DateFormatter";
import {
    GET_ACCOUNT_RELEASE_SUBSCRIBER_IN_RELEASE_COLLECTION__V1_0,
    GET_ORDER_HISTORY__V1_0
} from "../../../../api/loops4/caller/loops4RpcCaller";
import {GetAccountReleaseSubscriberInReleaseCollectionRPCRequest} from "../../../../api/loops4/model/account/release_subscriber/GetAccountReleaseSubscriberInReleaseCollectionRPCRequest";
import {TableHeadCell} from "../../../../ui_template/table/cell/TabelHeadCell";
import {TableBodyCell} from "../../../../ui_template/table/cell/TableBodyCell";
import {GetOrderHistoryRPCRequest} from "../../../../api/loops4/model/commerce/order/get_order_history/GetOrderHistoryRPCRequest";
import ReleaseCollectionNavigator from "../navigator/ReleaseCollectionNavigator";
import {CurrencyMaster, MoneyFormatter} from "../../../../model/formatter/MoneyFormatter";

class OrderGroup{

    constructor({subscriber, orders}) {
        this.subscriber = subscriber
        this.orders = orders
    }
}

export default class ReleaseCollectionOrderHistoryBox extends React.Component {

    static propTypes = {
        languageMaster: PropTypes.object,
        storeId: PropTypes.string,
        releaseCollectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            errorMessageBox: null,
            addSubscriberResult: null,
            subscriberList: [],
            /**
             * @type array<OrderGroup>
             */
            orderGroupList: []
        }
    }


    componentDidMount() {
        this.getSubscriberList(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.getSubscriberList(nextProps)
    }

    genHeader(title) {
        return (
            <div>
                <span style={{marginLeft: 5}}>
                    {title}
                </span>
            </div>
        )
    }

    getSubscriberList(nextProps) {
        console.log('nextProps', nextProps)
        if(nextProps.releaseCollectionModel && nextProps.releaseCollectionModel.entityId){
            this.setState((preState, props) => {
                return {
                    ...preState,
                    isLoading: true,
                    errorMessageBox: null
                }
            }, async () => {
                const requestBody = new GetAccountReleaseSubscriberInReleaseCollectionRPCRequest()
                requestBody.release_collection_entity_id = nextProps.releaseCollectionModel.entityId

                const rpcResult = await GET_ACCOUNT_RELEASE_SUBSCRIBER_IN_RELEASE_COLLECTION__V1_0({
                    headers: getAuthorizationHeader(nextProps.accessTokenModel),
                    paramBody: requestBody
                })

                console.log('rpcResult', rpcResult)

                /**
                 *
                 * @type {array<GetAccountReleaseSubscriberRPCResponse>}
                 */
                const subscribers = rpcResult.model.subscribers
                const orderPromises = subscribers.map( subscriber => {
                    const param = new GetOrderHistoryRPCRequest()
                    param.subscriber_id = subscriber.entity_id
                    return GET_ORDER_HISTORY__V1_0({
                        headers: getAuthorizationHeader(nextProps.accessTokenModel),
                        paramBody: param
                    }).then(orderRpcResult => new OrderGroup({
                        subscriber: subscriber,
                        orders: orderRpcResult.model.orders
                    }))
                })

                await Promise.all(orderPromises)

                const orderGroups = []
                for (const orderHistory of orderPromises){
                    orderGroups.push(await orderHistory)
                }

                if (rpcResult.isError()) {
                    this.setState((preState, props) => {
                        return {
                            ...preState,
                            isLoading: false,
                            errorMessageBox: (
                                <div>
                                    Request got failed:: {rpcResult.getErrorMessage()}
                                </div>
                            )
                        }
                    })
                } else {
                    this.setState((preState, props) => {
                        return {
                            ...preState,
                            isLoading: false,
                            errorMessageBox: null,
                            subscriberList: rpcResult.model.subscribers,
                            orderGroupList: orderGroups
                        }
                    })
                }
            });
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else if (this.state.errorMessageBox) {
            return this.state.errorMessageBox
        } else {
            /**
             * @type {ReleaseCollectionModel}
             */
            const releaseCollectionModel = this.props.releaseCollectionModel

            return (
                <div className="flexContainerColumn">
                    <h3>Order history</h3>
                    <div className="flexItem00">
                        <ul>
                        {this.state.orderGroupList.map(order => {
                            return <li>{order.subscriber.last_name} {order.subscriber.first_name} ({order.subscriber.email}) / {order.orders.length} 件</li>
                        })}
                        </ul>
                    </div>
                    <div className="flexItem00">
                        <ScrollableTable className="ui unstackable small table"
                                         header={<TableHeadCell columns={["Order ID", "Order status", "Order date", "Subscriber", "Price", "Payment method", "Items", ""]}  />}
                                         rows={this.state.subscriberList.map(
                                             /**
                                              * @param {GetAccountReleaseSubscriberRPCResponse} subscriber
                                              * @return {TableBodyCell}
                                              */
                                             (subscriber) => {
                                                 const orderGroup = this.state.orderGroupList
                                                     .find(orderGroup => orderGroup.subscriber.entity_id === subscriber.entity_id)

                                                 /**
                                                  *
                                                  * @type {array<CompactOrderHistoryResponse>}
                                                  */
                                                 let orders = []
                                                 if(orderGroup){
                                                     orders = orderGroup.orders
                                                 } else {
                                                     orders = []
                                                 }

                                                 return orders.map(order => {
                                                     const productSize = order
                                                         .products
                                                         .map(product => product.quantity)
                                                         .reduceRight((a, b) => a + b)

                                                     const currencyMaster = MoneyFormatter.getMaster(order.bill.price.price_currency)

                                                     return <TableBodyCell
                                                         key={order.entity_id}
                                                         columns={[
                                                         (<div>
                                                                 <div>
                                                                     <i className="ui icon cubes"/>
                                                                     <span>{order.entity_id}</span>
                                                                 </div>
                                                             </div>
                                                         ),
                                                         (<div>{order.order_state}</div>),
                                                         (<div>{DateFormatter.getFullDate(order.date_created)}</div>),
                                                         (<div>
                                                             {subscriber.email}<br/>
                                                             {subscriber.first_name} {subscriber.last_name}
                                                         </div>),
                                                         (<div>
                                                             <ul>
                                                                 <li>Buyer</li>
                                                                 <table>
                                                                     <tr><td>Product</td><td>+</td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.product_price_amount, currencyMaster)}</td></tr>
                                                                     <tr><td>Shipping</td><td>+</td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.shipping_price_amount, currencyMaster)}</td></tr>
                                                                     <tr><td>Refund</td><td>-</td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.refund_price_amount, currencyMaster)}</td></tr>
                                                                     <tr><td>Total bill</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount, currencyMaster)}</td></tr>
                                                                 </table>
                                                                 <li>Seller</li>
                                                                 <table>
                                                                     <tr><td>Revenue</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount, currencyMaster)}</td></tr>
                                                                     <tr><td>Fee</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount * 0.2, currencyMaster)}</td></tr>
                                                                     <tr><td>Net</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount * 0.8, currencyMaster)}</td></tr>
                                                                 </table>
                                                             </ul>                                                         </div>),
                                                         (<div>{order.bill.payment_transaction.payment_method}</div>),
                                                         (<div>{productSize}</div>),
                                                         (<div>
                                                             <Link to={RouteUtility.getPathname(RouteDictionary.orderDetailInReleaseCollection, {
                                                                 [RoutePathParam.storeId]: this.props.storeId,
                                                                 [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId,
                                                                 [RoutePathParam.orderId]: order.entity_id
                                                             },{
                                                                 [RouteQueryParam.view_mode]: ReleaseCollectionNavigator.viewMode.orderDetail,
                                                             })}>
                                                                 <input type="button" value="Detail" className="ui button" />
                                                             </Link>
                                                             {/*<input type="button" value="Cancel/Refund" className="ui button"*/}
                                                             {/*       onClick={() => {}}/>*/}
                                                         </div>),
                                                     ]} />
                                                 })
                                             })}
                        />
                        {(this.state.subscriberList.length < 1 ? (
                            <div className="ui message info">
                                you do not have any order item.
                            </div>
                        ) : null)}
                    </div>
                </div>
            )
        }
    }
}