export default class MasterContainer{

    /**
     * @type {string}
     */
    value;

    toJSON(){
        return this.value
    }

    static master = {};
    static getMasterList() {
        let masters = [];
        for ( let masterKey of Object.keys(this.master) ){
            masters.push(this.master[masterKey])
        }
        return masters;
    }

}


