import * as React from "react";
import * as PropTypes from "prop-types";
import {SetupStepTab} from "./StoreReleaseFormByLibraryCollection";
import DomIdConstant from "../../../../constant/DomIdConstant.json";

export default class StoreReleaseFormAddictionMenu extends React.Component {

    static propTypes = {
        submitReleaseCollectionFormAction: PropTypes.func,
        changeTab: PropTypes.func,
    }

    REFS_SET = {};

    constructor(props) {
        super(props);
        this.state = {
            addPopup: null
        };

    }

    itemBaseStyle = {
        height: 39,
        padding: 10,
        display:'inline-block',
        textAlign: "center"
    };


    genOrdering(number){
        return <label className="ui label circular small" style={{boxSizing: 'border-box'}}>{number}</label>
    }

    render() {
        return (
            <div className="flexContainerRow">
                <div className="flexItem00 flexItemAlignStartCenter" >
                    <div className="ui pointing secondary menu inverted fluid" style={{borderBottom: '1px solid #333'}}>
                        <a className="item"
                           id={DomIdConstant.StoreReleaseFormByLibraryCollection.releaseTargetButton}
                           data-tab="second"
                           style={{display: 'inline-block'}}
                           onClick={this.props.changeTab.bind(this, SetupStepTab.target)}>
                            {this.genOrdering(1)} Release Target
                        </a>
                        <a className="item"
                           id={DomIdConstant.StoreReleaseFormByLibraryCollection.releaseItemsButton}
                           data-tab="second"
                           style={{display: 'inline-block'}}
                           onClick={this.props.changeTab.bind(this, SetupStepTab.items)}>
                            {this.genOrdering(2)} Release Items
                        </a>
                    </div>
                </div>

                <div className="flexItem11 flexItemAlignEndCenter">
                    <button className="ui button green tiny"
                            id={DomIdConstant.StoreReleaseFormByLibraryCollection.doneAndPublishButton}
                            onClick={this.props.submitReleaseCollectionFormAction}
                    >
                        <i className="icon large external"/>Done & Publish
                    </button>
                </div>

            </div>
        );
    }
}
