import DateUTCModel from "../DateUTCModel";
import LabelModel from "./LabelModel";

export default class ItemModel{

    entityId
    itemType = "";
    itemName = "";
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();

    fileWidth = 0;
    fileHeight = 0;
    fileExtension = "";
    contentSize = 0;
    // globalAttribute = new ItemAttribute();
    // localAttribute = new ItemAttribute();
    // meta = new ItemMeta();
    labels = [];

    /**
     *
     * @param data
     * @return {ItemModel}
     */
    static deserialize(data){
        let newModel = new ItemModel();

        if(data){
            newModel.entityId = data.entity_id;
            newModel.itemType = data.item_type;
            newModel.itemName = data.item_name;
            newModel.dateCreated = DateUTCModel.deserialize(data.dateCreated);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);

            newModel.fileWidth = data.fileWidth;
            newModel.fileHeight = data.fileHeight;
            newModel.fileExtension = data.fileExtension;
            newModel.contentSize = data.contentSize;
            // newModel.globalAttribute = (data.globalAttribute ? ItemAttribute.deserialize(data.globalAttribute) : new ItemAttribute());
            // newModel.localAttribute = (data.localAttribute ? ItemAttribute.deserialize(data.localAttribute) : new ItemAttribute());
            // newModel.meta = (data.meta ? ItemMeta.deserialize(data.meta) : new ItemMeta());
            newModel.labels = (data.labels ? data.labels.map(label => LabelModel.deserialize(label)) : []);
        }

        return newModel;
    }
}