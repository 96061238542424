

// Package Dir: commerce/order/common
// FileName: OrderAddressRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderAddressRPCBody
// Super types: java.lang.Object

export class OrderAddressRPCBody {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.address_city = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.address_country = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.address_line = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.address_postal_code = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.first_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.last_name = null;

    }
}