/**
 * @param {object} headers
 * @param {any} body
 * @return {Promise<<object>}
 */
import {Loops4ResponseMessage} from "./message/Loops4ResponseMessage";
import {endpointLoops4} from "../../constant/APIConstant";
// to set extend prototypes message
import "./loops4RPCModelExtender"

const DEFAULT_RPC_TIMEOUT = 1000 * 5;

/**
 *
 * @param {object} headers
 * @param {Loops4RequestMessage} body
 * @param {Function} deserializer
 * @returns {Promise<*>}
 */
export const requestToRPC = async ({headers, body, deserializer = (data) => data }) => {
    const RPC_REQUEST = {
        headers,
        body
    }

    let response = null
    let message = new Loops4ResponseMessage()

    try {
        response = await fetch(`${endpointLoops4}/api/loops4rpc`, {
            method: 'POST',
            headers: Object.assign(headers || {}, {'Content-Type': 'application/json'}),
            body: JSON.stringify(body),
        })

        message = Object.assign(new Loops4ResponseMessage(), response.json())

        if(message.data){
            message.model = deserializer(message.data)
        }
    } catch(ex) {
        message.errors.push(ex)
    }

    const report = {
        RPC_METHOD: body.method,
        RPC_VERSION: body.version,
        RPC_ERROR_SIZE: message.errors ? message.errors.length : null,
        RPC_DETAIL:{
            request: RPC_REQUEST,
            response: message
        },
        NETWORK_DETAIL:{
            actual_response: response
        }
    }

    if(message.isError()){
        console.error(report)
    } else {
        // console.log(report)
    }

    return message
}

