import ConstraintPack from "./ConstraintPack";

const SignConstraintPack = {
    email: new ConstraintPack(
        1, 300, /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    name: new ConstraintPack(
        1, 20, null
    ),
    password: new ConstraintPack(
        8, 50, null
    )
}

export default SignConstraintPack