import grpcWeb from "grpc-web";
import {ErrorResponse} from "../../../proto/error/ErrorDefinition_pb";
import {deserializeMsg} from "./GrpcMessageSerializer";

/**
 * @constructor
 * @implements {UnaryInterceptor}
 */
export const ErrorResponseInterceptor = function() {};

/** @override */
ErrorResponseInterceptor.prototype.intercept = function(request, invoker) {
    return invoker(request).catch((err: grpcWeb.RpcError) => {
        const errResString = err.metadata["error-response-string"];
        console.debug("err.metadata.error-response-string", errResString)

        const errResBin = err.metadata["com.pickhours.app.api.grpc.error.errorresponse-bin"];
        console.debug('errResBin', errResBin)

        const byteArrayErrResBin = deserializeMsg(errResBin)

        const errRes = ErrorResponse.deserializeBinary(byteArrayErrResBin)
        console.debug("err.metadata.com.pickhours.app.api.grpc.error.errorresponse-bin", errRes)
        console.debug("errRes.getErrorcode()", errRes.getErrorcode())
        console.debug("errRes.getMessage()", errRes.getMessage())

        throw errRes
    });
};
