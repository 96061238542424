

// Package Dir: library/box
// FileName: GetLibraryBoxRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.library.box.GetLibraryBoxRPCRequest
// Super types: java.lang.Object

export class GetLibraryBoxRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.entity_id = null;

    }
}