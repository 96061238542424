import * as React from "react";
import * as PropTypes from "prop-types";


class ButtonContainerAddictionMenuState {
    style={}
}


export default class ButtonContainerAddictionMenu extends React.Component {

    static propTypes = {
        id: PropTypes.string,
        icon: PropTypes.any,
        label: PropTypes.any,
        clickEvent: PropTypes.func, // (event)=>void
    }

    REFS_SET = {};

    constructor(props, context) {
        super(props, context);
        this.state = new ButtonContainerAddictionMenuState();
        this.state.style = this.itemBaseStyle;

    }

    itemBaseStyle = {
        height: 59,
        padding: 10,
        textAlign: "center",
        color:'grey',
        boxSizing:'border-box'
    };

    itemRow = {
        display: 'block'
    };


    mouseHoverStyle = Object.assign({}, this.itemBaseStyle,{
        color: 'white'
    })

    mouseHover(event){
        this.setState((preState, props)=>{
            preState.style = this.mouseHoverStyle;
            return preState
        });
    }

    mouseOut(event){
        this.setState((preState, props)=>{
            preState.style = this.itemBaseStyle;
            return preState
        });
    }

    render() {

        return (
            <div style={this.state.style}
                 id={this.props.id}
                 onClick={this.props.clickEvent}
                 onMouseOver={this.mouseHover.bind(this)}
                 onMouseOut={this.mouseOut.bind(this)}>
                <div>
                    {this.props.icon}
                </div>
                <div>
                    {this.props.label}
                </div>

            </div>
        );
    }
}
