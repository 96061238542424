import * as React from "react";
import * as PropTypes from "prop-types";

export default class UploadingProgressCompleteComponent extends React.Component {
    static propTypes = {
        fileName: PropTypes.string,
    }

    REFS_SET={
    };

    render() {

        return (
            <table style={{fontSize:14, padding:5, width:'100%'}}>
                <tbody>
                    <tr>
                        <td><i className="icon file outline"/></td>
                        <td style={{maxWidth:190, overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                            {this.props.fileName}
                        </td>
                        <td style={{width:'40%'}}>
                            <div className="ui blue indicating progress" style={{margin:0,padding:0, backgroundColor:'#a0a0a0'}}>
                                <div style={{position:'absolute', textAlign:'center', color:'white', width:'100%', padding:5, fontSize:12, zIndex:2500}}>
                                    <label>COMPLETED</label>
                                </div>
                                <div className="bar" style={{width:`100%`}}></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        )
  }
}
