import MasterContainer from "./MasterContainer";

export class PaymentMethodContainer extends MasterContainer{

    /**
     * @param {string} value
     * @param {string} name
     */
    constructor(value, name) {
        super();
        this.value = value;
        this.name = name;
    }

    /**
     * @param data
     * @return {PaymentMethodContainer}
     */
    static deserialize(data){
        switch (data){
            case PaymentMethodContainer.master.card.value: return PaymentMethodContainer.master.card;
            case PaymentMethodContainer.master.transfer.value: return PaymentMethodContainer.master.transfer;
        }
    }

    static master = {
        card: new PaymentMethodContainer(
            "CREDIT_CARD",
            "CARD"
        ),
        transfer: new PaymentMethodContainer(
            "TRANSFER",
            "TRANSFER"
        )
    };

}