import {toByteArray} from 'base64-js';

export const serializeMsg = (binary: Uint8Array): string => {
    return binary.toString()
}

export const deserializeMsg = (base64String: string): Uint8Array => {
    const paddedErrResBin = pad(base64String)
    const byteArrayErrResBin = toByteArray(paddedErrResBin)

    return byteArrayErrResBin
}

/**
 * Add paddings to a base64 string to make it valid
 * @param unpadded Base64 string without paddings
 * @returns Padded base64 string
 */
export const pad = (unpadded: string) : string =>  {
    while (unpadded.length % 4 !== 0) {
        unpadded += '=';
    }
    return unpadded;
}
