import * as React from "react";
import * as PropTypes from "prop-types";
import {getAuthorizationHeader} from "../../../../../../api/loops4/Loops4RPCAPI";
import ReleaseItemModel from "../../../../../../model/entity/release/ReleaseItemModel";
import * as Loops4BinaryAPI from "../../../../../../api/loops4/Loops4BinaryAPI";
import {CommerceOrderProductWithOrderAttributeRPCResponse} from "../../../../../../api/loops4/model/commerce/cart/product/CommerceOrderProductWithOrderAttributeRPCResponse";
import {TableBodyCell} from "../../../../../../ui_template/table/cell/TableBodyCell";
import {GET_RELEASE_ITEM__V1_0} from "../../../../../../api/loops4/caller/loops4RpcCaller";
import {GetReleaseItemRPCRequest} from "../../../../../../api/loops4/model/release/item/GetReleaseItemRPCRequest";
import {MoneyFormatter} from "../../../../../../model/formatter/MoneyFormatter";
import MiniLoaderComponent from "../../../../../../ui_template/MiniLoaderComponent";

export class CompactReleaseItemInPublishCollectionShoppingCartBox extends React.Component {

    static propTypes = {
        language: PropTypes.object.isRequired,
        languageMaster: PropTypes.object.isRequired,
        accessTokenModel: PropTypes.object.isRequired,
        product: PropTypes.objectOf(CommerceOrderProductWithOrderAttributeRPCResponse).isRequired,
        removeItemFromCart: PropTypes.func.isRequired,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: true,
            releaseItemModel: new ReleaseItemModel(),
        };

        (async () => {
            const param = new GetReleaseItemRPCRequest()
            param.entity_id = props.product.release_item_entity_id
            const rpcResult =await GET_RELEASE_ITEM__V1_0({
                headers: getAuthorizationHeader(props.accessTokenModel),
                paramBody: param
            })

            let contentBox = null
            let foundReleaseItemModel = null
            if (rpcResult.isError()) {
                contentBox = (
                    <div>
                        Request got failed:: {rpcResult.getErrorMessage()}
                    </div>
                )
            } else {
                foundReleaseItemModel = rpcResult.model
            }

            this.setState((prevs, props) => {
                if(foundReleaseItemModel === null)
                    foundReleaseItemModel = prevs.releaseItemModel

                return {
                    ...prevs,
                    isLoading: false,
                    contentBox: contentBox,
                    releaseItemModel: foundReleaseItemModel
                }
            })

        })()
    }


    removeItemFromCart = () => {
        this.props.removeItemFromCart(this.props.product)
    }


    getThumbnailURL(releaseItemId){
        const imageSrc = Loops4BinaryAPI.getReleaseItemPreviewURL({
            accessTokenModel: this.props.accessTokenModel,
            releaseItemId: releaseItemId,
            longTermPixel: 100
        });

        return imageSrc;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <React.Fragment>
                    <MiniLoaderComponent loaderFlag={true} themeStyle={MiniLoaderComponent.themeStyle.bright}/>
                </React.Fragment>
            )
        } else if(this.state.contentBox) {
            return (
                <React.Fragment>
                    {this.state.contentBox}
                </React.Fragment>
            )
        } else {
            /**
             * @type {GetReleaseItemRPCResponse}
             */
            const releaseItemModel = this.state.releaseItemModel
            const product = releaseItemModel.product
            let currencyMaster;
            if(product){
                currencyMaster = MoneyFormatter.getMaster(product.price_currency)
            }

            return (
                <TableBodyCell
                    cellStyles={[
                        {
                            width: 130,
                            maxHeight: 200
                        }
                    ]}
                    columns={[
                    <div>
                        <img src={this.getThumbnailURL(this.props.product.release_item_entity_id)} />
                    </div>,
                    <div>
                        {(product ? (
                            <React.Fragment>
                                <span>
                                    {MoneyFormatter.getAmountByLocalCurrency(product.price_amount, currencyMaster)}
                                </span>
                                <span style={{fontSize: 10, marginLeft:3}}>
                                    {MoneyFormatter.getLocalCurrencyValueByLanguageMaster(this.props.languageMaster, currencyMaster)}
                                </span>
                            </React.Fragment>
                        ) : 'Product is not defined')}
                    </div>,
                    <div>
                    {releaseItemModel.item_name}
                    </div>,
                    <div>
                        {releaseItemModel.product ? (
                            <button className="ui button basic" onClick={this.removeItemFromCart}>カートから削除</button>
                        ) : null}
                    </div>
                ]} />
            )
        }
    }

}