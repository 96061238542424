export class LanguageMaster {
    constructor({masterCode, localName, nameWithEnglish, flagCode}) {
        this.masterCode = masterCode
        this.localName = localName
        this.nameWithEnglish = nameWithEnglish
        this.flagCode = flagCode
    }
}

export const LanguageMasterDefinition = {
    english: new LanguageMaster({
        masterCode: "eng",
        localName: "English",
        nameWithEnglish: 'English',
        flagCode: 'us'
    }),
    japanese: new LanguageMaster({
        masterCode: "jpn",
        localName: "日本語",
        nameWithEnglish: '日本語 - Japanese',
        flagCode: 'jp'
    }),
    korean: new LanguageMaster({
        masterCode: "kor",
        localName: "Korean",
        nameWithEnglish: '한국어 - Korean',
        flagCode: 'kr'
    }),
}

/**
 * @param masterCode
 * @returns {LanguageMaster}
 */
const getMaster = (masterCode) => {
    const masterKey = Object
        .keys(LanguageMasterDefinition)
        .find(key => LanguageMasterDefinition[key].masterCode === masterCode)

    return LanguageMasterDefinition[masterKey]
}

export const getNameWithEnglish = (languageCode) => {
    const master = getMaster(languageCode)
    return master.nameWithEnglish
}

export const getLocalName = (languageCode) => {
    const master = getMaster(languageCode)
    return master.localName
}

export const LanguageFormatter = {
    LanguageMasterDefinition,
    getMaster,
    getNameWithEnglish,
    getLocalName,
}