import {Link} from "react-router-dom";
import React from "react";
import ServiceInfoConstant from "../../constant/ServiceInfoConstant";

export const renderRedirectPage = (redirectPage) => {
    return (
        <div className="flexContainerRow flexItemAlignCenterCenter">
            <div className="flexItem00">
                <div style={{marginTop: '3em', textAlign: 'center'}}>
                    <h2>
                        {ServiceInfoConstant.serviceName}
                    </h2>
                </div>
                <div className="ui placeholder segment" style={{marginTop: '3em'}}>
                    <div className="column" style={{textAlign: 'center'}}>
                        <div className="ui icon header">
                            <i className="user times icon"></i>
                            ログインされていません
                        </div>
                        <Link to={redirectPage} >
                            <div className="ui primary button">
                                ログイン画面へ移動
                            </div>
                        </Link>
                    </div>

                    <div className="ui section divider" />

                    <p>
                        ログインのため、次のページに移動します。<br/>
                        ※リンクをクリックして手動で行うことも出来ます。
                    </p>
                </div>
            </div>
        </div>
    )
}