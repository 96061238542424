import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "../library/JQuery";
import {CountryContainer} from "../model/container/CountryContainer";
import LanguagePack from "../language/LanguagePack";
import OrderRequestLangPack from "../language/publish/order/OrderRequestLangPack";
import {CommerceStockKeepingUnitTypeContainer} from "../model/container/CommerceStockKeepingUnitTypeContainer";

export default class StockKeepingUnitTypeDropdown extends React.Component {

    static propTypes = {
        updateAction: PropTypes.func,
        stockKeepingUnitType: PropTypes.object,
        language: PropTypes.object,
    }


    REFS_SET={
        dropdownInput: "dropdownInput",
        dropdownContainer:"dropdownContainer"
    };


    componentDidMount(){
        $(this.refs[this.REFS_SET.dropdownContainer]).dropdown({
            onChange: (value, text, $choice)=>{
                this.refs[this.REFS_SET.dropdownInput]["value"] = value;

                const master = CommerceStockKeepingUnitTypeContainer.deserialize(value)

                this.props.updateAction(master);
            }
        });
    }

    render() {
        return (
            <div className="ui fluid search selection dropdown" ref={this.REFS_SET.dropdownContainer}>
                <input type="hidden"
                       value={this.props.stockKeepingUnitType.value}
                       ref={this.REFS_SET.dropdownInput}
                />
                <i className="dropdown icon"></i>
                <div className="default text">
                    {/*{LanguagePack.extract(this.props.language, OrderRequestLangPack.shipping.countryGuide)}*/}
                    {this.props.stockKeepingUnitType.name}
                </div>
                <div className="menu">
                    <div className="item" data-value={CommerceStockKeepingUnitTypeContainer.master.UnlimitedSKU.value}>
                        {/*{LanguagePack.extract(this.props.language, OrderRequestLangPack.shipping.countries.japan)}*/}
                        {CommerceStockKeepingUnitTypeContainer.master.UnlimitedSKU.name}
                    </div>
                    <div className="item" data-value={CommerceStockKeepingUnitTypeContainer.master.CountableSKU.value}>
                        {/*{LanguagePack.extract(this.props.language, OrderRequestLangPack.shipping.countries.southKorea)}*/}
                        {CommerceStockKeepingUnitTypeContainer.master.CountableSKU.name}
                    </div>
                </div>
            </div>
        );
    }
}
