import * as React from "react";
import * as PropTypes from "prop-types";
import {LanguageContainer} from '../../../../model/container/LanguageContainer'
import AccountSubscriberModel from "../../../../model/entity/account/AccountSubscriberModel";
import LanguageDropdown from "../../../../ui_component/LanguageDropdown";
import DomIdConstant from "../../../../constant/DomIdConstant";


export default class SubscriberAddingFormComponent extends React.Component {

    static propTypes = {
        addSubscriber: PropTypes.func,
        addSubscriberResult: PropTypes.any,
    }


    constructor(props) {
        super(props);
        this.state = {
            language: LanguageContainer.master.english
        };
        
    }

    REFS_SET = {
        subscriberFirstName:"subscriberFirstName",
        subscriberLastName:"subscriberLastName",
        subscriberEmail:"subscriberEmail",
        subscriberLanguage:"subscriberLanguage",
    };

    addSubscriber(event){
        event.preventDefault();

        let newSubscriber = new AccountSubscriberModel();
        newSubscriber.firstName = this.refs[this.REFS_SET.subscriberFirstName]["value"];
        newSubscriber.lastName = this.refs[this.REFS_SET.subscriberLastName]["value"];
        newSubscriber.email = this.refs[this.REFS_SET.subscriberEmail]["value"];
        newSubscriber.language = this.state.language;

        this.props.addSubscriber(newSubscriber);
    }

    /**
     * @param {LanguageContainer} language
     */
    updateLanguage(language){
        this.setState((prevs, props)=>{
            prevs.language = language;
            return prevs;
        });
    }

    render() {

       return (
           <form className="ui form" onSubmit={this.addSubscriber.bind(this)}>
               <div className="ui attached segment">
                   <div className="two fields">
                       <div className="two fields">
                           <div className="field">
                               <label>First Name</label>
                               <div className="ui input">
                                   <input id={DomIdConstant.DomIdSubscriberAddingFormComponent.firstName} type="text" placeholder="First Name" ref={this.REFS_SET.subscriberFirstName}/>
                               </div>
                           </div>
                           <div className="field">
                               <label>Last Name</label>
                               <div className="ui input">
                                   <input id={DomIdConstant.DomIdSubscriberAddingFormComponent.lastName} type="text" placeholder="Last Name" ref={this.REFS_SET.subscriberLastName}/>
                               </div>
                           </div>
                       </div>
                       <div className="field">
                           <label>E-mail<span
                               style={{color:'grey',fontWeight:100}}> for subscriber</span></label>
                           <div className="ui left icon input">
                               <input id={DomIdConstant.DomIdSubscriberAddingFormComponent.email} type="email" placeholder="E-mail" ref={this.REFS_SET.subscriberEmail}/>
                               <i className="mail icon"/>
                           </div>

                       </div>
                       <div className="field">
                           <label>Language</label>
                           <div className="ui left icon input">
                               <LanguageDropdown
                                   id={DomIdConstant.DomIdSubscriberAddingFormComponent.languageForm}
                                   idEnglish={DomIdConstant.DomIdSubscriberAddingFormComponent.languageFormToEnglish}
                                   idJapanese={DomIdConstant.DomIdSubscriberAddingFormComponent.languageFormToJapanese}
                                   idKorean={DomIdConstant.DomIdSubscriberAddingFormComponent.languageFormToKorean}
                                   updateAction={this.updateLanguage.bind(this)}
                                   defaultLanguage={this.state.language}
                               />
                           </div>

                       </div>
                   </div>
               </div>
               <div className="ui bottom attached segment" style={{backgroundColor:'#F0FFF2'}}>
                   <input id={DomIdConstant.DomIdSubscriberAddingFormComponent.submitButton} type="submit" value="add" className="ui button positive"/>

                   <div>
                       {this.props.addSubscriberResult}
                   </div>
               </div>
           </form>
           )

            }
}
