export class AccessTokenModel {
    /**
     *
     * @param {string} accessToken
     * @param {string} createdAt
     * @param {number} lifeSeconds
     * @param {string} refreshToken
     * @param {string} tokenType
     * @param {array<string>}scope
     */
    constructor({
                    accessToken = null,
                    createdAt = null,
                    lifeSeconds = null,
                    refreshToken = null,
                    tokenType = null,
                    scope = []
    }) {
        this.accessToken = accessToken
        this.createdAt = createdAt
        this.lifeSeconds = lifeSeconds
        this.refreshToken = refreshToken
        this.tokenType = tokenType
        this.scope = scope
    }

    /**
     *
     * @param data
     * @return {AccessTokenModel}
     */
    static deserialize(data){
        return new AccessTokenModel({
            accessToken: data['access_token'],
            lifeSeconds: data['life_seconds'],
            refreshToken: data['refresh_token'],
            createdAt: data['created_at'],
            scope: data['scope'],
            tokenType: data['token_type']
        })
    }


    /**
     *
     * @param data
     * @return {String}
     */
    serialize(){
        return {
            access_token: this.accessToken,
            life_seconds: this.lifeSeconds,
            refresh_token: this.refreshToken,
            created_at: this.createdAt,
            scope: this.scope,
            token_type: this.tokenType,
        }
    }
}