import * as React from "react";
import * as PropTypes from "prop-types";
import {GetLibraryItemRPCResponse} from "../../../api/loops4/model/library/item/GetLibraryItemRPCResponse";
import * as Loops4BinaryAPI from "../../../api/loops4/Loops4BinaryAPI";
import {ImageSizeMasterDefinition} from "../../../model/formatter/ImageSizeFormatter";
import {AccessTokenModel} from "../../../model/auth/AccessTokenModel";
import {pushHistory} from "../../RouteHistory";
import {RouteUtility} from "../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../RouteDictionary";

export default class PictureItemInToolbarComponent extends React.Component{

    static propTypes = {
        accessTokenModel: PropTypes.objectOf(AccessTokenModel),
        itemModel: PropTypes.objectOf(GetLibraryItemRPCResponse),
        isSelectedLastly: PropTypes.bool,
        isSelected: PropTypes.bool,
        selectionAction: PropTypes.func,
        collectionId: PropTypes.string,
        boxId: PropTypes.string
    }

    REFS_SET = {
        miniPictureController: "miniPictureController"
    };

    // subscriber = new Observer_Subscriber<ModelPKObserver.ModelPKStateSetDef>();
    subscriber = {}

    constructor(props, context) {
        super(props, context);
        this.state = {
            imageSrc: this.getImageSrc(props)
        }
    }

    /**
     * @param {this.propTypes} props
     * @returns {string}
     */
    getImageSrc(props) {
        return Loops4BinaryAPI.getLibraryItemPreviewURL({
            accessTokenModel: this.props.accessTokenModel,
            libraryItemId: this.props.itemModel.entity_id,
            longTermPixel: ImageSizeMasterDefinition.SIZE_300.longTermPixel,
        })
    }

    // shouldComponentUpdate(nextProps: PictureItemInToolbarComponentProps, nextState: PictureItemInToolbarComponentState, nextContext: any): boolean {
    //     return (
    //         super.shouldComponentUpdate(nextProps, nextState, nextContext)
    //         ||
    //         nextProps.lastSelectFlag !== this.props.lastSelectFlag
    //         ||
    //         nextProps.selectFlag !== this.props.selectFlag
    //         ||
    //         nextProps.collectionId !== this.props.collectionId
    //     );
    // }



    componentWillUnmount() {
    }


    doubleClick() {
        pushHistory(RouteUtility.getPathname(RouteDictionary.item, {
            [RoutePathParam.boxId]: this.props.boxId,
            [RoutePathParam.collectionId]: this.props.collectionId,
            [RoutePathParam.itemId]: this.props.itemModel.entity_id
        }, {}))
    }

    click(e) {
        // this.props.selectAction(this.props.itemModel, !this.props.selectFlag, e);
        // if(AppContextAction.getContextModel().activityModel.routingTable == RoutingTables.libraryActivityForItem){
        //     this.doubleClick();
        // }
    }

    convertToStar(starNumber, selectFlag){

        let classSet= "icon tiny star ";
        if(selectFlag){
            classSet += "white"
        }else{
            classSet += "grey"
        }

        let stars = [];
        for (let c = 0; c < starNumber; c++){
            stars.push(<i className={classSet} style={{margin:0}} />)
        }
        return stars;
    }

    // drag(itemModelPK:ItemModel, e){
    drag(e){
        console.log('dragged!');
        //If it is already selected element, don't need other action
        if(this.props.isSelected === false){
            this.click(e)
        }

        // e.dataTransfer.setData("model", JSON.stringify(itemModelPK))
    }

    render() {

        let selectedBoxStyle_Outer = {
            border:'3px solid transparent', backgroundColor:null, color:null,
            background:'transparent', boxShadow:'transparent',
            margin:1, display:'inline-block',verticalAlign:'top'
        };
        let selectedBoxStyle_Outer_class = null;
        let selectedBoxStyle_Inner = {
            backgroundColor:null, color:null, padding:0, minHeight:19
        };
        let selectedBoxStyle_Inner_class = "custom imageCardShadow";

        if(this.props.isSelected){
            selectedBoxStyle_Inner.backgroundColor = "#4285f4";
            selectedBoxStyle_Inner.color = "white";
            selectedBoxStyle_Outer.background = "white";
            selectedBoxStyle_Inner_class = null;
            selectedBoxStyle_Outer_class = "custom imageCardShadow"
        }

        if(this.props.isSelectedLastly){
            selectedBoxStyle_Outer.border = "3px solid #0044b2";
            selectedBoxStyle_Outer_class = "custom imageCardShadow"
        }

        let pickFlagStyle = {};
        let pickFlagFlagIcon = null;
        // if(this.props.itemModel.globalAttribute.pickFlag == ItemModel.masters.pickFlag.pick){
        //     pickFlagStyle = {width:65, height:65, position:'absolute', border:'2px solid white'};
        //     pickFlagFlagIcon = <i className="icon flag mini" style={{padding:0, margin:0}}></i>
        // }else if(this.props.itemModel.globalAttribute.pickFlag == ItemModel.masters.pickFlag.reject){
            pickFlagStyle = {width:65, height:65, position:'absolute', border:'2px solid grey', backgroundColor:'rgba(0, 0, 0, 0.57)'};
            pickFlagFlagIcon = <i className="icon flag mini outline" style={{padding:0, margin:0}}></i>
        // }

        return (
            <div className={selectedBoxStyle_Outer_class}
                 style={selectedBoxStyle_Outer}
                 onDragStart={this.drag.bind(this)}
                 draggable
            >
                <div
                    onDoubleClick={this.doubleClick.bind(this)}
                    onClick={this.click.bind(this)}
                >
                    <div>

                        <div style={pickFlagStyle}></div>
                        <div className={selectedBoxStyle_Inner_class}
                             style={{width:65, height:65,
                                 backgroundSize:'contain', backgroundRepeat:'no-repeat',
                                 backgroundColor:'#868686',backgroundPosition:'center',
                                 backgroundImage:`URL(${this.state.imageSrc})`}}>
                        </div>
                    </div>
                    <div style={selectedBoxStyle_Inner}>
                        {/*{CollectionBrowserAction.convertToStar(this.props.itemModel.globalAttribute.rating, this.props.selectFlag)}*/}
                    </div>
                </div>
            </div>

        );
    }

}