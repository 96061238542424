import * as React from "react";
import * as PropTypes from "prop-types";
import CommerceOrderHistoryModel from "../../../../../../model/entity/commerce/CommerceOrderHistoryModel";
import DateFormatter from "../../../../../../model/formatter/DateFormatter";
import {RouteUtility} from "../../../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../../../RouteDictionary";
import {PublishReleaseCollection} from "../../../PublishReleaseCollection"
import {Link} from "react-router-dom";
import {CompactOrderHistoryResponse} from "../../../../../../api/loops4/model/commerce/order/get_order_history/compact/CompactOrderHistoryResponse";
import {LanguageMaster} from "../../../../../../model/formatter/LanguageFormatter";
import {getLocalCurrencyValueByLanguageMaster, MoneyFormatter} from "../../../../../../model/formatter/MoneyFormatter";
import {getShippingArchiveFileURL} from "../../../../../../api/loops4/Loops4BinaryAPI";
import {connect} from "react-redux";
import {
    getShippingMethodAsLocal,
    getShippingStateAsLocal, isInactiveState,
    renderDigitalFileDownloader
} from "../../../../../../model/formatter/ShippingFormatter";
import {getOrderStateAsLocal} from "../../../../../../model/formatter/OrderFormatter";

const mapStateToProps = (state, props) => {
    return {
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.subscriber,
        routeState: state.routeState,
    };
};
class CompactOrderHistoryRow extends React.Component {

    static propTypes = {
        language: PropTypes.object.isRequired,
        languageMaster: PropTypes.objectOf(LanguageMaster),
        accessTokenModel: PropTypes.object.isRequired,
        compactOrder: PropTypes.objectOf(CommerceOrderHistoryModel).isRequired,
        releaseCollectionModel: PropTypes.objectOf(CompactOrderHistoryResponse).isRequired,
    }

    style = {
        summary_column: {
            marginBottom: 5
        },
        detail_title: {
            fontWeight: 'bold'
        }
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context, REFS_SET) {
        super(props, context);
    }

    genLink(orderId, element){
        return <Link to={RouteUtility.getPathname(RouteDictionary.orderInPublishReleaseCollection, {
            [RoutePathParam.orderId]: orderId,
            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId,
        }, {
            [RouteQueryParam.view_mode]: PublishReleaseCollection.viewMode.order_detail,
            [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
            [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
        })}>
            {element}
        </Link>
    }

    render() {
        const language = this.props.language
        const languageMaster = this.props.languageMaster

        /**
         * @type {CompactOrderHistoryResponse}
         */
        const order = this.props.compactOrder
        const currencyMaster  = MoneyFormatter.getMaster(order.bill.price.price_currency)

        return (
            <div className={'ui padded segment'}>
                <div className="flexContainerRow">
                    <div className="flexItem11">
                        <div style={{display: 'block', width: '100%'}}>
                            <div className="ui header medium">
                                <i className="box icon"></i>
                                注文の詳細
                            </div>

                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文ID
                                </div>
                                <div>
                                    {this.genLink(order.entity_id,
                                        <span>{order.entity_id}</span>
                                    )}
                                </div>
                            </div>

                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文詳細
                                </div>
                                <div>
                                    {getOrderStateAsLocal(order.order_state)}
                                </div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文日
                                </div>
                                <div>{DateFormatter.getDateFormatForLocaleByLanguageMaster(languageMaster, order.date_created)}</div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文総額
                                </div>
                                <div>
                                    {MoneyFormatter.getLocalizedAmountWithCurrency(languageMaster, order.bill.price.total_price_amount, currencyMaster)}
                                </div>
                            </div>

                            <div className="ui section divider"></div>

                            <div style={this.style.summary_column}>
                                <div className="ui header medium">
                                    <i className="shipping fast icon"></i>
                                    配送
                                </div>
                                {order.shipping.map(
                                    /**
                                     * @param {CompactRequestedShippingHistoryInCompactOrderHistoryResponse} shipping
                                     * @returns {*}
                                     */
                                    (shipping, index) => {
                                        return (
                                            <div>
                                                {index > 0 ? (<div className="ui hidden divider" />) : null}
                                                <div className={'ui segment ' + (isInactiveState(shipping.shipping_state) ? '  grey' : ' blue')}>
                                                    <div>
                                                        <h4 className={"ui header"}>
                                                            <span className="ui grey circular label">{index + 1}</span> 配送
                                                            <div className="sub header" style={{marginLeft: 35}}>{shipping.entity_id}</div>
                                                        </h4>
                                                    </div>
                                                    <ul>
                                                        <li style={{fontWeight: 'bold'}}>{getShippingStateAsLocal(shipping.shipping_state)}</li>
                                                        <li>{getShippingMethodAsLocal(shipping.shipping_method)}</li>
                                                    </ul>
                                                    <div>
                                                        {shipping.actual_shipping.map(
                                                            /**
                                                             * @param {OrderActualShippingEntityRPCBody} actualShipping
                                                             */
                                                            (actualShipping, index) => {
                                                                console.log({actualShipping})
                                                                return renderDigitalFileDownloader(
                                                                    this.props.accessTokenModel,
                                                                    actualShipping.entity_id,
                                                                    actualShipping.shipping_state,
                                                                    actualShipping.shipping_method,
                                                                    index + 1,
                                                                    shipping.actual_shipping.length
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flexItem00">
                        <div style={{width: 120}}>
                            {this.genLink(order.entity_id,
                                <div className={'ui button grey'}>注文の詳細</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps)(CompactOrderHistoryRow);