import {ORDER_STATE_MASTER} from "../../api/loops4/master/shipping/order_state_master";

export const getOrderStateAsLocal = (orderState) => {
    switch (orderState) {
        case ORDER_STATE_MASTER.PENDING_ORDER_TRANSACTION: return "注文保留"
        case ORDER_STATE_MASTER.DELIVERED_ORDER_TRANSACTION: return "届け完了"
        case ORDER_STATE_MASTER.SHIPPING_ORDER_TRANSACTION: return "配送中"
        case ORDER_STATE_MASTER.CANCELLED_ORDER_TRANSACTION: return "注文取り消し"
        default: {
            const errMessage = `Unknown order state:: ${orderState}`
            console.error(errMessage)
            return errMessage
        }
    }
}
