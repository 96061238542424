import * as React from "react";
import PictureItemInCollectionViewerComponent from "./component/PictureItemInCollectionViewerComponent";
import DomIdConstant from "../../../../constant/DomIdConstant.json";

/**
 *
 * @param {array<SelectionModel>} items
 * @param {SelectionModel} lastSelectedItem
 * @return {array<SelectionModel>}
 */
const mapIfItemIsSelectedLastly = (
    items,
    lastSelectedItemId
) => {
    return items.map((item) => {
        let lastSelectFlag = false;
        if (item.model.entity_id === lastSelectedItemId) lastSelectFlag = true;
        item.lastSelectFlag = lastSelectFlag

        return item
    });
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} parentBoxId
 * @param {string} parentCollectionId
 * @param {array<SelectionModel>} itemPictures
 * @param {function} selectAction
 * @param {string} lastSelectedItemId
 */
export const getItemComponentsInCollectionViewer = (accessTokenModel,
                                                    parentBoxId,
                                                    parentCollectionId,
                                                    itemPictures,
                                                    selectAction = (selectModelPK, selectFlag, keyEvent) => {},
                                                    lastSelectedItemId
) => {

    const itemsWithLasSelectFlag = mapIfItemIsSelectedLastly(
        itemPictures,
        lastSelectedItemId
    )

    return itemsWithLasSelectFlag.map((item, index) =>
        <PictureItemInCollectionViewerComponent
            id={DomIdConstant.DomPictureItemInCollectionViewerComponent.container + '---' + index}
            key={DomIdConstant.DomPictureItemInCollectionViewerComponent.container + '---' + index}
            accessTokenModel={accessTokenModel}
            lastSelectFlag={item.lastSelectFlag}
            selectFlag={item.selectFlag}
            selectAction={selectAction}
            itemModel={item.model}
            parentCollectionId={parentCollectionId}
            parentBoxId={parentBoxId}
        />
    )
}