

// Package Dir: commerce/order/common
// FileName: BillErrorRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.BillErrorRPCBody
// Super types: java.lang.Object

export class BillErrorRPCBody {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.error_code = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.error_description = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.error_message = null;

    }
}