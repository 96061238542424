
class ServiceInfoContextContainer{
    serviceName= "PickHours";
    serviceDomain= "pickhours.com";
    serviceEntryURL = `https://${this.serviceDomain}`;

    departments = {
        concierge: {
            name: `${this.serviceName} Concierge`,
            email: `concierge@${this.serviceDomain}`
        },
        library: {
            name: `${this.serviceName} Library`
        },
        publish: {
            name: `${this.serviceName} Publish`
        }
    }
}

const ServiceInfoConstant = new ServiceInfoContextContainer();

export default ServiceInfoConstant
