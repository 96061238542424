import * as React from "react";
import {connect} from "react-redux";
import {RouteListenerComponent} from "../../RouteListener";
import ReleaseCollectionModel from "../../../model/entity/release/ReleaseCollectionModel";
import {RoutePathParam, RouteQueryParam} from "../../RouteDictionary";
import * as Loops4RPCAPI from "../../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import PublishReleaseCollectionBrowserBox from "./content/browser/PublishReleaseCollectionBrowserBox";
import PublishReleaseCollectionOrderHistoryBox from "./content/order_history/PublishReleaseCollectionOrderHistoryBox";
import * as PropTypes from "prop-types";
import {refreshSubscriberCommerceCart} from "../../../state/redux/action/CommerceAction";
import PublishReleaseCollectionPaymentBox from "./content/payment/PublishReleaseCollectionPaymentBox";
import PublishReleaseCollectionShoppingCartBox from "./content/shopping_cart/PublishReleaseCollectionShoppingCartBox";
import PublishReleaseCollectionOrderDetailBox from "./content/order_detail/PublishReleaseCollectionOrderDetailBox";
import {LanguageMaster} from "../../../model/formatter/LanguageFormatter";
import ContentNavigationMenu from "./sidemenu/ContentNavigationMenu";
import SubscriberProfileMenu from "./sidemenu/SubscriberProfileMenu";
import ApplicationTopBanner from "./sidemenu/ApplicationTopBanner";
import ApplicationSettingMenu from "./sidemenu/ApplicationSettingMenu";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.subscriber,
        routeState: state.routeState,
        subscriberModel: state.subscriberInfo,
        releaseCollectionItemsOfCollection: state.releaseCollectionItemsOfCollection
    };
};

export class PublishReleaseCollection extends RouteListenerComponent {

    static propTypes = {
        accessTokenModel: PropTypes.object.isRequired,
        languageMaster: PropTypes.instanceOf(LanguageMaster).isRequired,
        language: PropTypes.object.isRequired,
        routeState: PropTypes.object.isRequired,
    }

    static viewMode = {
        selection: 'selection',
        shoppingCart: 'shopping_cart',
        order_history: 'order_history',
        order_detail: 'order_detail',
        payment: 'payment',
    }

    style = {
        mainContainer: {
            backgroundColor: 'rgb(250, 250, 250)'
        },
        verticalSpacer: {
            width: 10
        },
        sideMenuContainer: {
            paddingTop: 10,
            paddingBottom: 10
        },
        sideMenuDivider: {
            height: 10,
        },
        contentContainer: {
            width: '100%',
            paddingTop: 10,
            paddingBottom: 10
        }
    }

    REFS_SET = {};

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            contentBox: null,
            releaseCollectionModel: new ReleaseCollectionModel(),
        }

        refreshSubscriberCommerceCart()
    }

    async updateByProps() {
        const nextReleaseCollectionId = this.props.routeState.pathParameter[RoutePathParam.releaseCollectionId]
        const currentReleaseCollectionId = this.state.releaseCollectionModel.entityId

        if(nextReleaseCollectionId && nextReleaseCollectionId !== currentReleaseCollectionId){
            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: true
                }
            }, async () => {
                const rpcResult = await Loops4RPCAPI.getReleaseCollection({
                    accessTokenModel: this.props.accessTokenModel,
                    releaseCollectionId: nextReleaseCollectionId
                })

                if (rpcResult.isError()) {
                    this.setState((prevs, props) => {
                        return {
                            ...prevs,
                            isLoading: false,
                            contentBox: (
                                <div>
                                    Request got failed:: {rpcResult.getErrorMessage()}
                                </div>
                            )
                        }
                    })
                } else {
                    this.setState((prevs, props) => {
                        return {
                            ...prevs,
                            isLoading: false,
                            contentBox: null,
                            releaseCollectionModel: rpcResult.model,
                        }
                    })
                }
            })
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {

            const viewMode = this.props.routeState.queryParameter[RouteQueryParam.view_mode] || PublishReleaseCollection.viewMode.selection

            let viewContent = null
            switch (viewMode) {
                case PublishReleaseCollection.viewMode.selection: {
                    viewContent = (
                        <PublishReleaseCollectionBrowserBox language={this.props.language}
                                                            languageMaster={this.props.languageMaster}
                                                            accessTokenModel={this.props.accessTokenModel}
                                                            releaseCollectionModel={this.state.releaseCollectionModel}
                        />
                    )
                    break;
                }
                case PublishReleaseCollection.viewMode.shoppingCart: {
                    viewContent = (
                        <PublishReleaseCollectionShoppingCartBox language={this.props.language}
                                                                 languageMaster={this.props.languageMaster}
                                                                accessTokenModel={this.props.accessTokenModel}
                        />
                    )
                    break;
                }
                case PublishReleaseCollection.viewMode.payment: {
                    viewContent = (
                        <PublishReleaseCollectionPaymentBox language={this.props.language}
                                                            languageMaster={this.props.languageMaster}
                                                            accessTokenModel={this.props.accessTokenModel}
                                                            releaseCollectionModel={this.state.releaseCollectionModel}
                                                            subscriber={this.props.subscriberModel}
                        />
                    )
                    break;
                }
                case PublishReleaseCollection.viewMode.order_history: {
                    viewContent = (
                        <PublishReleaseCollectionOrderHistoryBox language={this.props.language}
                                                                 languageMaster={this.props.languageMaster}
                                                                accessTokenModel={this.props.accessTokenModel}
                                                                releaseCollectionModel={this.state.releaseCollectionModel}
                                                                subscriber={this.props.subscriberModel}
                        />
                    )
                    break;
                }
                case PublishReleaseCollection.viewMode.order_detail: {
                    viewContent = (
                        <PublishReleaseCollectionOrderDetailBox language={this.props.language}
                                                                languageMaster={this.props.languageMaster}
                                                                releaseCollectionModel={this.state.releaseCollectionModel}
                                                                accessTokenModel={this.props.accessTokenModel}
                        />
                    )
                    break;
                }
            }

            return (
                <div className="flexContainerRow PublishReleaseCollection" style={this.style.mainContainer}>
                    <div className="flexItem00 ">
                        <div style={this.style.sideMenuContainer}>
                            <ApplicationTopBanner />

                            <div style={this.style.sideMenuDivider} />

                            <SubscriberProfileMenu />

                            <div style={this.style.sideMenuDivider} />

                            <ContentNavigationMenu totalItems={this.props.releaseCollectionItemsOfCollection.items.length}
                                                   releaseCollectionId={this.state.releaseCollectionModel.entityId}
                                                   viewMode={viewMode}
                            />

                            <ApplicationSettingMenu />
                        </div>
                    </div>
                    <div className="flexItem00 " style={this.style.verticalSpacer} />
                    <div className="flexItem11 flexItemVerticallyDynamic">
                        <div style={this.style.contentContainer}>
                            <div className="flexContainerColumn ">
                                <div className="flexItem11">
                                    {viewContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(PublishReleaseCollection);