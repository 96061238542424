import * as React from "react";
import * as PropTypes from "prop-types";

export class PagingContext{
    /**
     * @param {number} currentPage
     * @param {number} itemsPerPage
     * @param {number} totalItems
     */
    constructor(currentPage, itemsPerPage, totalItems) {
        this.currentPage = currentPage;
        this.totalItems = totalItems;
        this.itemsPerPage = itemsPerPage;
    }
}
export class PagingOutput{
    /**
     * @param {number} itemFrom
     * @param {number} itemTo
     * @param {number} totalPages
     */
    constructor(itemFrom, itemTo, totalPages) {
        this.itemFrom = itemFrom;
        this.itemTo = itemTo;
        this.totalPages = totalPages;
    }
}

export default class PageSelectorComponent extends React.Component {

    static propTypes = {
        currentPageNumber: PropTypes.number,
        totalPageNumber: PropTypes.number,
        updatePageNumber: PropTypes.number,
    }


    REFS_SET = {
        dimmerContainer:"dimmerContainer"
    };


    constructor(props) {
        super(props);
        this.state = {
        };
    }


    /**
     * @param {PagingContext} pagingContext
     * @return {PagingOutput}
     */
    static getPaging(pagingContext){
        let itemsPerPage = pagingContext.itemsPerPage;
        let totalPages = Math.ceil(pagingContext.totalItems / itemsPerPage);
        let itemFrom = (pagingContext.currentPage - 1) * itemsPerPage;
        let itemTo = pagingContext.currentPage * itemsPerPage;

        return new PagingOutput(
            itemFrom,
            itemTo,
            totalPages
        )
    }
// changePage(pageNumber: number):void{
    //     this.setState((prevs, props) => {
    //         prevs.pageNumber = pageNumber
    //         return prevs;
    //     });
    // }


    render() {
        // let currentPageStartNum = (this.props.pageNumber - 1) * this.props.pageDividerCount
        // let currentPageEndNum = this.props.pageNumber * this.props.pageDividerCount
        // let currentPageProducts = this.props.products.slice(currentPageStartNum, currentPageEndNum)
        // let allPages = parseInt((this.props.products.length / this.props.pageDividerCount).toFixed(0))


        let pageButtons = []
        for(let pageNumber = 1; pageNumber <= this.props.totalPageNumber; pageNumber++){
            pageButtons.push(
                <div className={"ui button compact mini " + (this.props.currentPageNumber === pageNumber ? "green" : null)} onClick={this.props.updatePageNumber.bind(this, pageNumber)}>
                    {pageNumber}
                </div>
            )
        }

       return(
          <div>
              {pageButtons.map( (button, index) => <span key={index}>{button}</span>)}
          </div>
       )
    }

}