import * as React from "react";
import * as PropTypes from "prop-types";

export default class UploadingProgressStatusComponent extends React.Component {

    static propTypes = {
        uploadingProgressModel: PropTypes.object, // UploadingProgressModel
        fileName: PropTypes.string,
    }

    REFS_SET={
        progressBar:'progressBar'
    };

    size_MB = 1024;
    size_GB = this.size_MB * 1024;
    size_TB = this.size_GB * 1024;
    size_Decimal = 2;

    /**
     * @param {number} totalKB
     * @return {string}
     */
    convertTotalSize(totalKB){
        if(totalKB / this.size_TB >= 1){
            return (totalKB / this.size_TB).toFixed(this.size_Decimal) + "TB";
        }else if(totalKB / this.size_GB >= 1){
            return (totalKB / this.size_GB).toFixed(this.size_Decimal) + "GB";
        }else if(totalKB / this.size_MB >= 1){
            return (totalKB / this.size_MB).toFixed(this.size_Decimal) + "MB";
        }else{
            return "unknown size";
        }

    }

    render() {
        let progress = this.props.uploadingProgressModel;
        let totalSize = this.convertTotalSize(progress.totalKB);

        let message = `${totalSize}(${progress.progressPercentage}%)`;
        if(progress.progressPercentage >= 100){
            message = "Processing image ..."
        }

        return (
            <table style={{fontSize:14, padding:5, width:'100%'}}>
                <tbody>
                <tr>
                    <td><i className="icon file outline"/></td>
                    <td style={{maxWidth:190, overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                        {this.props.fileName}
                    </td>
                    <td style={{width:'40%'}}>
                        <div className="ui green indicating progress" style={{margin:0,padding:0, backgroundColor:'#a0a0a0'}}>
                            <div style={{position:'absolute', textAlign:'center', color:'white', width:'100%', padding:2, fontSize:12, zIndex:2500}}>
                                <label>{message}</label>
                            </div>
                            <div className="bar" style={{width:`${progress.progressPercentage}%`}}></div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        )
  }
}
