import * as React from "react";
import LanguagePack from "./LanguagePack";

const SignLangPack = {
    fields: {
        email: new LanguagePack(
            "Email",
            "メールアドレス",
            "이메일 주소"
        ),
        emailGuide: new LanguagePack(
            "It is not standard email format. for example, atingme@gmail.com",
            "標準メールアドレス形式ではありません。例） atingme@gmail.com",
            "표준 이메일 주소 형식이 아닙니다. 예시) atingme@gmail.com"
        ),
        password: new LanguagePack(
            "Password",
            "パスワード",
            "비밀번호"
        ),
        verifyPassword: new LanguagePack(
            "Verify Password",
            "パスワード確認",
            "비밀번호 확인"
        ),
        veryfyPasswordGuide: new LanguagePack(
            "This is not match with the secretKey you entered",
            "入力したパスワードが一致していません。",
            "입력한 비밀번호가 다릅니다."
        ),
        passwordGuide: new LanguagePack(
            "The password should be more than 8 characters composited of alphabet and number",
            "バスワードはアルファベットと数字の組み合わせで８文字以上の形式です。",
            "비밀번호는 알파벳과 숫자의 조합으로 8자 이상이어야 합니다."
        ),
        firstName: new LanguagePack(
            "First Name",
            "名前",
            "이름"
        ),
        lastName: new LanguagePack(
            "Last Name",
            "姓名",
            "성"
        ),
        nameGuide: new LanguagePack(
            "You need at least one character.",
            "最低一文字が必要です。",
            "최소한 한 글자가 필요합니다."
        ),
        agreeGuide: new LanguagePack(
            "You should agree to the terms of use.",
            "利用約款の同意が必要です。",
            "이용정책의 동의가 필요합니다."
        ),
        language: new LanguagePack(
            "Language",
            "言語",
            "언어"
        ),
        servicePlan: new LanguagePack(
            "Plan",
            "プラン",
            "플랜"
        )
    },
    buttons: {
        signIn: new LanguagePack(
            "Sign in",
            "ログイン",
            "로그인"
        ),
        signOut: new LanguagePack(
            "Sign out",
            "ログアウト",
            "로그아웃"
        ),
        signUp: new LanguagePack(
            "Sign up",
            "アカウント作成",
            "회원가입"
        ),
        servicePlan: {
            payAsYouGo: new LanguagePack(
                "Pay As You Go",
                "使用分だけ",
                "사용한 만큼만"
            ),
            studio: new LanguagePack(
                "Studio",
                "スタジオ",
                "스튜디오"
            ),
            studioGuide: new LanguagePack(
                "You can change your plan after signing up",
                "会員加入後プランの変更が可能になります。",
                "회원가입 후 플랜 변경이 가능해집니다."
            )
        }
    },
    guide: {
        footer:{
            contacts:{
                english: "contacts",
                japanese: "連絡先",
                korean: "연락처"
            },
            policy:{
                english: "terms of use",
                japanese: "使用約款",
                korean: "이용정책"
            }
        },
        resultMessageForSucceed: new LanguagePack(
            "Your application is accepted, Please check your email to complete.\n\nWe will move to sign in page.",
            "登録申請が承諾されました。完了する電子メールを確認して下さい。\n\nログインページへ移動します。",
            "등록 신청이 수락되었습니다. 완료하기 위해 이메일을 확인해주세요. \n\n로그인 페이지로 이동합니다."
        ),
        resultMessageForFailed: new LanguagePack(
            "Submitting application is failed.\n\nPlease try it again.",
            "登録に失敗しました。\n\n改めて申請を行って下さい。",
            "등록에 실패했습니다. \n\n다시 한 번 신청해주세요."
        ),
        resultMessageForIdDuplication: new LanguagePack(
            "Same email is already registered, \n\nPlease use another email.",
            "同じメールアドレスが既に登録されております。 \n\n他のメールアドレスをご利用下さい。",
            "동일한 이메일 주소가 이미 등록되어 있습니다. \n\n 다른 메일주소를 이용해주세요."
        ),
        agreementOfTerms: new LanguagePack(
            "Agreement for \"Terms of use\"",
            "\"使用約款\"に対する同意",
            "\"이용정책\"에 대한 동의"
        ),
        openTerms: new LanguagePack(
            "Open \"Terms of use\" page",
            "\"使用約款\"ページを開く",
            "\"이용정책\" 페이지를 열기"
        ),
        notYetOpenedTerms: new LanguagePack(
            (
                <div>
                    <div className="header">
                        You did not check "Terms of use" page yet.
                    </div>
                    Please open and check it.
                </div>
            ),
            (
                <div>
                    <div className="header">
                        "使用約款"ページをまだ確認しておりません。
                    </div>
                    ページを開くご確認お願いします。
                </div>
            ),
            (
                <div>
                    <div className="header">
                        "이용정책" 페이지를 아직 확인하지 않으셨습니다.
                    </div>
                    페이지를 열어 확인 부탁드립니다.
                </div>
            )
        ),
        iReadAndAgreeToIt: new LanguagePack(
            "I checked the terms and agree to it.",
            "私は使用約款を読み、それに同意します。",
            "나는 이 이용정책을 읽고 이에 동의합니다."
        ),
        signUpTitle: new LanguagePack(
            "You will have more chances soon.",
            "まもなくより多くのチャンスを手に入れる事が出来ます。",
            "보다 많은 기회를 곧 얻을 수 있게 됩니다."
        ),
        signUpTitleSub: new LanguagePack(
            "Scale up yourself from now",
            "今からご自身をより拡大していきましょう。",
            "지금부터 자신을 더 확대 해나갈 수 있습니다."
        ),
        doNotYouHaveAnAccount: new LanguagePack(
            "Don't you have an account?",
            "まだアカウントがありませんですか？",
            "아직 계정이 없으신가요?"
        ),
        contactUsIfYouHaveSomething: new LanguagePack(
            (
                <span>
                    If you have an idea, please contacts us.<br />
                    We are going to do our best to make your idea becomes in real<br />
                </span>
            ),
            (
                <span>
                    意見がありましたら、それでは私達へご連絡お願いします。<br />
                    私たちはあなたの意見が現実になるよう、最善を尽くします。<br />
                </span>
            ),
            (
                <span>
                    어떤 의견이 있으십니까? 그러하시다면 우리에게 연락을 부탁드립니다.<br />
                    우리들은 당신의 의견이 현실이 되도록 최선을 다하겠습니다.<br />
                </span>
            )
        ),

    }
}

export default SignLangPack