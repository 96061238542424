import {OrderAddressRPCBody} from "../../../commerce/order/common/OrderAddressRPCBody";
import {OrderPriceRPCBody} from "../../../commerce/order/common/OrderPriceRPCBody";

// Package Dir: commerce/order/common
// FileName: OrderShippingRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderShippingRPCBody
// Super types: java.lang.Object

export class OrderShippingRPCBody {
    constructor() {

        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderAddressRPCBody
         * @type {OrderAddressRPCBody}
         */
        this.address = new OrderAddressRPCBody();
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderPriceRPCBody
         * @type {OrderPriceRPCBody}
         */
        this.price = new OrderPriceRPCBody();
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.order.common.OrderShippingProductWithAttributeRPCBody>
         * @type {array<OrderShippingProductWithAttributeRPCBody>}
         */
        this.products = [];

    }
}