import ItemModel from "./ItemModel";
import DateUTCModel from "../DateUTCModel";

export default class CollectionModel {

    entityId;
    collectionName;
    collectionType;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();
    items = [];

    /**
     * @param data
     * @return {CollectionModel}
     */
    static deserialize(data) {
        let newModel = new CollectionModel();

        if (data) {
            newModel.entityId = data.entity_id;
            newModel.collectionName = data.collection_name;
            newModel.collectionType = data.collection_type;
            newModel.dateCreated = DateUTCModel.deserialize(data.date_created);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);
            if (data.items && data.items.length) {
                newModel.items = data.items.map(
                    pictureItem => ItemModel.deserialize(pictureItem)
                );
            }
        }

        return newModel;
    }
}