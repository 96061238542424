import CommerceProductModel from "../commerce/CommerceProductModel";
import ReleaseItemModel from "./ReleaseItemModel";
import DateUTCModel from "../DateUTCModel";

export default class ReleaseCollectionModel{

    entityId;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();

    collectionName;
    collectionType;
    collectionState;

    product = new CommerceProductModel();

    /**
     * @return {boolean}
     */
    isExpired(){
        return this.collectionState !== 'ReleaseCollectionStateOpenMaster'
    }

    /**
     *
     * @param data
     * @return {ReleaseCollectionModel}
     */
    static deserialize(data){
        let newModel = new ReleaseCollectionModel();

        if(data){
            newModel.entityId = data.entity_id;
            newModel.dateCreated = DateUTCModel.deserialize(data.date_created);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);

            newModel.collectionName = data.collection_name;
            newModel.collectionType = data.collection_type;
            newModel.collectionState = data.collection_state;

            newModel.product = CommerceProductModel.deserialize(data.product)
        }

        return newModel;
    }

}