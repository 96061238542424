

// Package Dir: commerce/cart/product
// FileName: GetCommerceCartProductRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.cart.product.GetCommerceCartProductRPCRequest
// Super types: java.lang.Object

export class GetCommerceCartProductRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.entity_id = null;

    }
}