import CommerceMoneyContainer from "../../container/money/CommerceMoneyContainer";
import DateUTCModel from "../DateUTCModel";
import CommerceStockKeepingUnitModel from "./CommerceStockKeepingUnitModel";

export default class CommerceProductModel {

    entityId;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();

    price = CommerceMoneyContainer.defaultMoney();
    stock = new CommerceStockKeepingUnitModel();

    /**
     *
     * @param data
     * @return {CommerceProductModel}
     */
    static deserialize(data) {
        let newModel = new CommerceProductModel();

        if (data) {
            newModel.entityId = data.entity_id;
            newModel.dateCreated = DateUTCModel.deserialize(data.dateCreated);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);

            newModel.price = CommerceMoneyContainer.deserialize(data) ;
            newModel.stock = CommerceStockKeepingUnitModel.deserialize(data.stock);
        }

        return newModel
    }
}