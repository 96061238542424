import * as React from "react";
import * as PropTypes from "prop-types";

export default class UploadingProgressFailedComponent extends React.Component {

    static propTypes = {
        fileName: PropTypes.string,
        message: PropTypes.string,
        retryFunc: PropTypes.func,
    }

    render() {

        return (
            <table style={{fontSize:14, padding:5, width:'100%'}}>
                <tbody>
                    <tr>
                        <td><i className="icon file outline"/></td>
                        <td style={{maxWidth:190, overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                            {this.props.fileName}
                            <div>
                                {this.props.message}
                            </div>
                        </td>
                        <td style={{width: (this.props.retryFunc ? '20%' : '40%')}}>
                            <div className="ui red indicating progress" style={{margin:0,padding:0, backgroundColor:'#d07321'}}>
                                <div style={{position:'absolute', textAlign:'center', color:'white', width:'100%', padding:5, fontSize:12, zIndex:2500}}>
                                    <label>FAILED</label>
                                </div>
                                <div className="bar" style={{width:`100%`}}></div>
                            </div>
                        </td>
                        {(this.props.retryFunc ? (
                            <td style={{width:'20%'}}>
                                <div className="ui black basic indicating progress" style={{margin:0,padding:0, backgroundColor:'#d07321'}}>
                                    <div style={{position:'absolute', textAlign:'center', color:'white', width:'100%', padding:5, fontSize:12, zIndex:2500}}
                                         onClick={this.props.retryFunc}
                                    >
                                        <label>RETRY</label>
                                    </div>
                                    <div className="bar" style={{width:`100%`}}></div>
                                </div>
                            </td>
                        ) : null )}
                    </tr>
                </tbody>
            </table>

        )
  }
}
