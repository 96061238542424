import * as React from "react";
import * as PropTypes from "prop-types";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import * as CommerceAction from "../../../../../state/redux/action/CommerceAction";
import LanguagePack from "../../../../../language/LanguagePack";
import OrderRequestLangPack from "../../../../../language/publish/order/OrderRequestLangPack";
import {CompactReleaseItemInPublishCollectionShoppingCartBox} from "./viewer/CompactReleaseItemInPublishCollectionShoppingCartBox";
import {connect} from "react-redux";
import {REMOVE_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest} from "../../../../../api/loops4/model/commerce/cart/product/relation_product/RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest";
import {Table} from "../../../../../ui_template/table/Table";
import {TableHeadCell} from "../../../../../ui_template/table/cell/TabelHeadCell";

const mapStateToProps = (state, props) => {
    return {
        commerceCartModel: state.subscriberCommerceCartModel,
        cartId: state.subscriberInfo.commerceCartId,
    };
};

class PublishReleaseCollectionShoppingCartBox extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        languageMaster: PropTypes.object,
        accessTokenModel: PropTypes.object,
        commerceCartModel: PropTypes.object,
        cartId: PropTypes.string,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context, REFS_SET) {
        super(props, context);
        this.state = {
        }
    }

    removeItemFromCart = (product) => {
        console.log({product});
        (async () => {
            const param = new RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest();
            param.commerce_cart_product_entity_id = this.props.cartId
            param.commerce_sale_product_release_item_entity_ids = [product.entity_id]

            const rpcResult = await REMOVE_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0({
                headers: getAuthorizationHeader(this.props.accessTokenModel),
                paramBody: param
            })

            if(rpcResult.isError()){
                window.alert(`Request got failed:: ${rpcResult.getErrorMessage()}`)
            }

            CommerceAction.refreshSubscriberCommerceCart()
            this.setState((prevs, props)=> {
                return {
                    ...prevs,
                    isLoading: false
                }
            })
        })()
    }

    render() {
        /**
         * @type {Array<CommerceOrderProductWithOrderAttributeRPCResponse>}
         */
        const products = this.props.commerceCartModel.products

        return (
            <div className={'contentBox'}>
                <h1>
                    <i className="ui icon payment" /> {LanguagePack.extract(this.props.language, OrderRequestLangPack.order.shoppingCart.title)}
                </h1>
                <div>
                    {LanguagePack.extract(this.props.language, OrderRequestLangPack.order.shoppingCart.guide)}
                </div>
                <div className="ui section divider"></div>

                <div style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 5,
                    marginBottom: 15,
                }}>
                    <div className="statistic">
                        <div className="value">
                            <i className="camera icon"></i> {products.length}
                        </div>
                        <div className="label">
                            選択した製品数
                        </div>
                    </div>
                </div>

                <Table className="ui unstackable small table"
                                 header={<TableHeadCell columns={["イメージ", "価格", "詳細", ""]}  />}
                                 rows={products.map((product)=> {
                                     return <CompactReleaseItemInPublishCollectionShoppingCartBox language={this.props.language}
                                                                                                  languageMaster={this.props.languageMaster}
                                                                                                  accessTokenModel={this.props.accessTokenModel}
                                                                                                  product={product}
                                                                                                  removeItemFromCart={this.removeItemFromCart}
                                     />
                                 })}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(PublishReleaseCollectionShoppingCartBox);