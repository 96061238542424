import * as React from "react";
import * as PropTypes from "prop-types";
import DimLoaderComponent from "../../../../../ui_template/DimLoaderComponent";
import IconBoxComponent from "../../../../../ui_component/icon/IconBoxComponent";
import * as Loops4RPCAPI from "../../../../../api/loops4/Loops4RPCAPI";
import {connect} from "react-redux";


/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return { accessTokenModel: state.auth.user };
};

class BoxAdderComponentState {
    mode = undefined;
    dimLoaderFlag = false;
}

class BoxAdderComponent extends React.Component {

    static propTypes = {
        parentBoxId: PropTypes.string,
        loaderAction: PropTypes.func,
    }

    REFS_SET = {
        nameInput: "nameInput"
    };

    modeConst = {
        ready: "ready",
        edit: "edit"
    };

    constructor(props, context) {
        super(props, context);
        this.state = new BoxAdderComponentState();
        this.state.mode = this.modeConst.ready

    }

    componentDidMount() {

    }

    addDrawer(event) {
        event.preventDefault();

        let input = this.refs[this.REFS_SET.nameInput]
        let newBoxName = input["value"];

        this.setState((prevs, props)=> {
            prevs.dimLoaderFlag = true;
            return prevs;
        }, async () => {

            const createdLibraryBox = await Loops4RPCAPI.createLibraryBox({
                accessTokenModel: this.props.accessTokenModel,
                boxName: newBoxName,
            })

            input["value"] = null;

            this.setState((prevs, props)=> {
                prevs.dimLoaderFlag = false;
                return prevs;
            }, () => {
                this.props.loaderAction(createdLibraryBox);
            });
        });
    }

    render() {
        return (

            <div className="ui form">
                <div className="field">
                    <label>Box Name</label>
                    <div className="ui left icon input">
                        <span style={{position:'absolute', left: 9, top: 11}}><IconBoxComponent /></span>
                        <input type="text" ref={this.REFS_SET.nameInput}/>
                    </div>
                </div>
                <div className="ui blue submit button" onClick={this.addDrawer.bind(this)}>Add Box</div>
                <DimLoaderComponent loaderFlag={this.state.dimLoaderFlag} themeStyle={DimLoaderComponent.themeStyle.bright} />
            </div>
        )
    }
}

export default connect(mapStateToProps)(BoxAdderComponent);