import * as React from "react";

export default class IconBoxComponent extends React.Component {

    REFS_SET = {
    };

    iconPath={
        svg: '/assets/img/icons/icon-drawer.svg'
    }

    render() {
        let path = this.iconPath.svg
        let style= {
            width:19, marginRight:3.5, marginBottom:-2
        }

        return (
            <img src={path} style={style} alt={''} />
        )
    }
}
