

// Package Dir: account/release_subscriber
// FileName: GetAccountReleaseSubscriberInReleaseCollectionRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.account.release_subscriber.GetAccountReleaseSubscriberInReleaseCollectionRPCResponse
// Super types: java.lang.Object

export class GetAccountReleaseSubscriberInReleaseCollectionRPCResponse {
    constructor() {

        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.account.release_subscriber.GetAccountReleaseSubscriberRPCResponse>
         * @type {array<GetAccountReleaseSubscriberRPCResponse>}
         */
        this.subscribers = [];

    }
}