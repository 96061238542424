import CommerceProductModel from "../commerce/CommerceProductModel";
import DateUTCModel from "../DateUTCModel";

export default class ReleaseItemModel{

    entityId;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();

    itemName;
    itemType;
    itemState;

    product;

    /**
     *
     * @param data
     * @return {ReleaseItemModel}
     */
    static deserialize(data){
        let newModel = new ReleaseItemModel();

        if(data){
            newModel.entityId = data.entity_id;
            newModel.dateCreated = DateUTCModel.deserialize(data.date_created);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);

            newModel.itemName = data.item_name;
            newModel.itemType = data.item_type;
            newModel.itemState = data.item_state;


            newModel.product = (data.product ? CommerceProductModel.deserialize(data.product) : null)
        }

        return newModel;
    }

    static masters = {
        pickFlag:{
            none: "NONE",
            reject: "REJECT",
            pick: "PICK",
        },
        itemType:{
            item:"item",
            preview:"preview"
        },
        attributeScope:{
            global:'global',
            local:'local'
        }
    }
}