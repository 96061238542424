const getKeyPrefix = () => {
    return 'PERSIST_'
}
export const getFromLocalStorage = (key) =>{
    const foundValue =  window
        .localStorage
        .getItem(getKeyPrefix() + key);

    // console.log('getFromLocalStorage', {
    //     key, foundValue
    // })

    if(foundValue != null){
        try {
            const parsed = JSON.parse(foundValue)
            // console.log('parsed', parsed)
            return parsed
        } catch (e) {
            // console.log('getFromLocalStorage-error', e)
            return null
        }
    } else {
        return null
    }
}

export const setIntoLocalStorage = (key, value) => {
    let storingValue = value
    if(typeof value === 'object'){
        storingValue = JSON.stringify(value)
    }

    // console.log('setIntoLocalStorage',{
    //     key, value, storingValue
    // })

    window
        .localStorage
        .setItem(getKeyPrefix() + key, storingValue);
}