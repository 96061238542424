import * as React from "react";
import DimLoaderComponent from "../../../../../ui_template/DimLoaderComponent";

export default class StoreReleaseSubmitRequestingComponent extends React.Component{

    render(){
        return (
            <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.bright} >
                <div className="ui indeterminate text loader small">
                    Requesting Publish<br/>
                    <br/>
                    <p style={{color:'grey'}}>
                    It takes 5 ~ 30 seconds.
                    </p>
                </div>
            </DimLoaderComponent>
        )
    }

}
