/**
 * @fileoverview gRPC-Web generated client stub for account
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: account/AccountService.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_AccountService_pb from '../account/AccountService_pb'; // proto import: "account/AccountService.proto"
import * as common_Resource_pb from '../common/Resource_pb'; // proto import: "common/Resource.proto"
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb'; // proto import: "google/protobuf/empty.proto"


export class AccountAuthorizedServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorDeleteUserAccount = new grpcWeb.MethodDescriptor(
    '/account.AccountAuthorizedService/DeleteUserAccount',
    grpcWeb.MethodType.UNARY,
    common_Resource_pb.ResourceIdentifyRequest,
    google_protobuf_empty_pb.Empty,
    (request: common_Resource_pb.ResourceIdentifyRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteUserAccount(
    request: common_Resource_pb.ResourceIdentifyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteUserAccount(
    request: common_Resource_pb.ResourceIdentifyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteUserAccount(
    request: common_Resource_pb.ResourceIdentifyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.AccountAuthorizedService/DeleteUserAccount',
        request,
        metadata || {},
        this.methodDescriptorDeleteUserAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.AccountAuthorizedService/DeleteUserAccount',
    request,
    metadata || {},
    this.methodDescriptorDeleteUserAccount);
  }

  methodDescriptorDeleteSubscriberAccount = new grpcWeb.MethodDescriptor(
    '/account.AccountAuthorizedService/DeleteSubscriberAccount',
    grpcWeb.MethodType.UNARY,
    common_Resource_pb.ResourceIdentifyRequest,
    google_protobuf_empty_pb.Empty,
    (request: common_Resource_pb.ResourceIdentifyRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteSubscriberAccount(
    request: common_Resource_pb.ResourceIdentifyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteSubscriberAccount(
    request: common_Resource_pb.ResourceIdentifyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteSubscriberAccount(
    request: common_Resource_pb.ResourceIdentifyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.AccountAuthorizedService/DeleteSubscriberAccount',
        request,
        metadata || {},
        this.methodDescriptorDeleteSubscriberAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.AccountAuthorizedService/DeleteSubscriberAccount',
    request,
    metadata || {},
    this.methodDescriptorDeleteSubscriberAccount);
  }

  methodDescriptorGetAccountInformationForAdmin = new grpcWeb.MethodDescriptor(
    '/account.AccountAuthorizedService/GetAccountInformationForAdmin',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    account_AccountService_pb.GetAccountAdminResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    account_AccountService_pb.GetAccountAdminResponse.deserializeBinary
  );

  getAccountInformationForAdmin(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<account_AccountService_pb.GetAccountAdminResponse>;

  getAccountInformationForAdmin(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountAdminResponse) => void): grpcWeb.ClientReadableStream<account_AccountService_pb.GetAccountAdminResponse>;

  getAccountInformationForAdmin(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.AccountAuthorizedService/GetAccountInformationForAdmin',
        request,
        metadata || {},
        this.methodDescriptorGetAccountInformationForAdmin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.AccountAuthorizedService/GetAccountInformationForAdmin',
    request,
    metadata || {},
    this.methodDescriptorGetAccountInformationForAdmin);
  }

  methodDescriptorGetAccountInformationForUser = new grpcWeb.MethodDescriptor(
    '/account.AccountAuthorizedService/GetAccountInformationForUser',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    account_AccountService_pb.GetAccountUserResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    account_AccountService_pb.GetAccountUserResponse.deserializeBinary
  );

  getAccountInformationForUser(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<account_AccountService_pb.GetAccountUserResponse>;

  getAccountInformationForUser(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountUserResponse) => void): grpcWeb.ClientReadableStream<account_AccountService_pb.GetAccountUserResponse>;

  getAccountInformationForUser(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.AccountAuthorizedService/GetAccountInformationForUser',
        request,
        metadata || {},
        this.methodDescriptorGetAccountInformationForUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.AccountAuthorizedService/GetAccountInformationForUser',
    request,
    metadata || {},
    this.methodDescriptorGetAccountInformationForUser);
  }

  methodDescriptorGetAccountInformationForSubscriber = new grpcWeb.MethodDescriptor(
    '/account.AccountAuthorizedService/GetAccountInformationForSubscriber',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    account_AccountService_pb.GetAccountSubscriberResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    account_AccountService_pb.GetAccountSubscriberResponse.deserializeBinary
  );

  getAccountInformationForSubscriber(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<account_AccountService_pb.GetAccountSubscriberResponse>;

  getAccountInformationForSubscriber(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountSubscriberResponse) => void): grpcWeb.ClientReadableStream<account_AccountService_pb.GetAccountSubscriberResponse>;

  getAccountInformationForSubscriber(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountSubscriberResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.AccountAuthorizedService/GetAccountInformationForSubscriber',
        request,
        metadata || {},
        this.methodDescriptorGetAccountInformationForSubscriber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.AccountAuthorizedService/GetAccountInformationForSubscriber',
    request,
    metadata || {},
    this.methodDescriptorGetAccountInformationForSubscriber);
  }

}

export class AccountUnauthorizedServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPutAccountUser = new grpcWeb.MethodDescriptor(
    '/account.AccountUnauthorizedService/PutAccountUser',
    grpcWeb.MethodType.UNARY,
    account_AccountService_pb.PutAccountUserRequest,
    account_AccountService_pb.GetAccountUserResponse,
    (request: account_AccountService_pb.PutAccountUserRequest) => {
      return request.serializeBinary();
    },
    account_AccountService_pb.GetAccountUserResponse.deserializeBinary
  );

  putAccountUser(
    request: account_AccountService_pb.PutAccountUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<account_AccountService_pb.GetAccountUserResponse>;

  putAccountUser(
    request: account_AccountService_pb.PutAccountUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountUserResponse) => void): grpcWeb.ClientReadableStream<account_AccountService_pb.GetAccountUserResponse>;

  putAccountUser(
    request: account_AccountService_pb.PutAccountUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_AccountService_pb.GetAccountUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.AccountUnauthorizedService/PutAccountUser',
        request,
        metadata || {},
        this.methodDescriptorPutAccountUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.AccountUnauthorizedService/PutAccountUser',
    request,
    metadata || {},
    this.methodDescriptorPutAccountUser);
  }

}

