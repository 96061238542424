import * as React from "react";
import * as PropTypes from "prop-types";

export default class PublishExpirationIndicatorComponent extends React.Component {

    static propTypes = {
        isExpired: PropTypes.bool.isRequired,
    }

    render() {
       return(
           <label className={`ui label small ${(this.props.isExpired ? 'grey' : 'blue' )}`}>
               {(this.props.isExpired ? 'EXPIRED' : 'AVAILABLE')}
           </label>
       )
    }

}