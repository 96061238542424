import * as React from "react";
import * as PropTypes from "prop-types";

export class TableHeadCell extends React.Component {

    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.element),
    }

    render() {
        const renderedColumns = this.props.columns.map(column => (
            <th style={this.props.headerStyle}>
                {column}
            </th>
        ))

        return (
            <tr>
                {renderedColumns}
            </tr>
        )
    }
}