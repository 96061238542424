import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
    getShippingMethodAsLocal,
    getShippingStateAsLocal,
    isInactiveState,
    renderDigitalFileDownloader
} from "../../../../model/formatter/ShippingFormatter";
import {RouteDictionary, RoutePathParam} from "../../../RouteDictionary";
import {GET_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM__v1_0} from "../../../../api/loops4/caller/loops4RpcCaller";
import {GetOrderCommerceProductReleaseItemRPCRequest} from "../../../../api/loops4/model/commerce/order/get_order_commerce_product_release_item/GetOrderCommerceProductReleaseItemRPCRequest";
import {getAuthorizationHeader} from "../../../../api/loops4/Loops4RPCAPI";
import {RouteUtility} from "../../../RouteUtility";
import OrderHistoryLangPack from "../../../../language/publish/order/OrderHistoryLangPack";
import LanguagePack from "../../../../language/LanguagePack";
import {MoneyFormatter} from "../../../../model/formatter/MoneyFormatter";
import {ReduxMainStore} from "../../../../state/redux/store/ReduxMainStore";
import {COMMERCE_ORDER_DETAIL_SET_SUBSCRIBER} from "../../../../state/redux/constant/ActionType";
import {getOrderStateAsLocal} from "../../../../model/formatter/OrderFormatter";
import DateFormatter from "../../../../model/formatter/DateFormatter";
import {TableHeadCell} from "../../../../ui_template/table/cell/TabelHeadCell";
import {Table} from "../../../../ui_template/table/Table";
import * as Loops4BinaryAPI from "../../../../api/loops4/Loops4BinaryAPI";
import LoadingComponent from "../../../../ui_template/LoadingComponent";
import {TableBodyCell} from "../../../../ui_template/table/cell/TableBodyCell";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        ...props,
        subscriberOrderDetail: state.subscriberOrderDetail,
        routeState: state.routeState,
        accessTokenModel: state.auth.admin,
    };
};

class AdminOrderDetailBox extends React.Component {

    static propTypes = {
        language: PropTypes.object.isRequired,
        languageMaster: PropTypes.object.isRequired,
        accessTokenModel: PropTypes.object.isRequired,
    }

    style = {
        summary_column: {
            marginBottom: 5
        },
        detail_title: {
            fontWeight: 'bold'
        },
        detail_description: {
            marginLeft: 5
        }
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            currentImage: 0,
            lightboxIsOpen: false,
        };

        (async (orderId, accessTokenModel) => {
            console.log({orderId})
            const param = new GetOrderCommerceProductReleaseItemRPCRequest()
            param.commerce_order_entity_id = orderId

            const rpcResult = await GET_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM__v1_0({
                headers: getAuthorizationHeader(accessTokenModel),
                paramBody: param
            })

            if(rpcResult.isError()){
                console.error({rpcResult})
            } else {
                ReduxMainStore.dispatch({
                    type: COMMERCE_ORDER_DETAIL_SET_SUBSCRIBER,
                    payload: rpcResult.model
                })
            }
        })(
            this.props.routeState.pathParameter[RoutePathParam.orderId],
            this.props.accessTokenModel
        );
    }

    render() {
        const language = this.props.language
        const languageMaster = this.props.languageMaster
        const order = this.props.subscriberOrderDetail

        if(order && order.entity_id){

            const currencyMaster  = MoneyFormatter.getMaster(order.bill.price.price_currency)

            return (
                <div className={'contentBox'}>
                    <h1>
                        <i className="ui icon boxes" /> {LanguagePack.extract(this.props.language, OrderHistoryLangPack.orderHistory.title)}
                    </h1>
                    <div>
                        <Link to={RouteUtility.getPathname(RouteDictionary.adminPortal, {
                        },{
                        })}>
                            <div className={'ui button'}>
                                Portalへ戻る
                            </div>
                        </Link>
                    </div>

                    <div className="ui section divider"></div>

                    <div className={'ui padded segment'}>
                        <div style={{display: 'block', width: '100%'}}>
                            <div className="ui header medium">
                                <i className="box icon"></i>
                                注文の詳細
                            </div>

                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文ID
                                </div>
                                <div>
                                    {order.entity_id}
                                </div>
                            </div>

                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文詳細
                                </div>
                                <div>
                                    {getOrderStateAsLocal(order.order_state)}
                                </div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文日
                                </div>
                                <div>{DateFormatter.getDateFormatForLocaleByLanguageMaster(languageMaster, order.date_created)}</div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文総額
                                </div>
                                <div>
                                    {MoneyFormatter.getLocalizedAmountWithCurrency(languageMaster, order.bill.price.total_price_amount, currencyMaster)}
                                </div>
                            </div>

                            <div className="ui section divider"></div>

                            <div style={this.style.summary_column}>
                                <div className="ui header medium">
                                    <i className="shipping fast icon"></i>
                                    配送
                                </div>
                                {order.shipping.map(
                                    /**
                                     * @param {OrderRequestedShippingEntityRPCBody} shipping
                                     * @returns {*}
                                     */
                                    (shipping, index) => {
                                        return (
                                            <div>
                                                {index > 0 ? (<div className="ui hidden divider" />) : null}
                                                <div className={'ui segment ' + (isInactiveState(shipping.shipping_state) ? '  grey' : ' blue')}>
                                                    <div>
                                                        <h4 className={"ui header"}>
                                                            <span className="ui grey circular label">{index + 1}</span> 配送
                                                            <div className="sub header" style={{marginLeft: 35}}>{shipping.entity_id}</div>
                                                        </h4>
                                                    </div>
                                                    <ul>
                                                        <li style={{fontWeight: 'bold'}}>{getShippingStateAsLocal(shipping.shipping_state)}</li>
                                                        <li>{getShippingMethodAsLocal(shipping.shipping_method)}</li>
                                                    </ul>
                                                    <div>
                                                        {shipping.actual_shipping.map(
                                                            /**
                                                             * @param {OrderActualShippingEntityRPCBody} actualShipping
                                                             */
                                                            (actualShipping, index) => {
                                                                console.log({actualShipping})
                                                                return renderDigitalFileDownloader(
                                                                    this.props.accessTokenModel,
                                                                    actualShipping.entity_id,
                                                                    actualShipping.shipping_state,
                                                                    actualShipping.shipping_method,
                                                                    index + 1,
                                                                    shipping.actual_shipping.length
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                    <div style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 5,
                        marginBottom: 15,
                    }}>
                        <div className="statistic">
                            <div className="value">
                                <i className="camera icon"></i> {order.products.length}
                            </div>
                            <div className="label">
                                注文製品数
                            </div>
                        </div>
                    </div>

                    <div>
                        <Table className="ui unstackable small table"
                               header={<TableHeadCell columns={["イメージ", "詳細"]}  />}
                               rows={order.products.map(
                                   /**
                                    * @param {OrderCommerceProductReleaseItemEntityRPCBody} product
                                    */
                                   (product) => {
                                       const currencyMaster  = MoneyFormatter.getMaster(product.release_price.price_currency)

                                       /**
                                        * @type {OrderCommerceRecordReleaseItemEntityRPCBody}
                                        */
                                       const releaseItemId = order.release_items.find(release_item => release_item.product_entity_id === product.entity_id)

                                       const imageSrc = Loops4BinaryAPI.getReleaseItemPreviewURL({
                                           accessTokenModel: this.props.accessTokenModel,
                                           releaseItemId: releaseItemId.entity_id,
                                           longTermPixel: 100
                                       })

                                       return (
                                           <TableBodyCell
                                               cellStyles={[
                                                   {
                                                       width: 130,
                                                       maxHeight: 200
                                                   },
                                                   {}
                                               ]}
                                               columns={[
                                               (
                                                   <img src={imageSrc} />
                                               ),
                                               (
                                                   <ul>
                                                       <li>
                                                       <span style={this.style.detail_title}>
                                                            製品名:
                                                       </span>
                                                       <span style={this.style.detail_description}>
                                                           {releaseItemId.itemName}
                                                       </span>
                                                       </li>
                                                       <li>
                                                       <span style={this.style.detail_title}>
                                                         数量:
                                                       </span>
                                                       <span style={this.style.detail_description}>
                                                           {product.quantity}
                                                       </span>
                                                       </li>
                                                       <li>
                                                       <span style={this.style.detail_title}>
                                                        価格:
                                                       </span>
                                                       <span style={this.style.detail_description}>
                                                           {MoneyFormatter.getLocalizedAmountWithCurrency(languageMaster, product.bill_price.price_amount, currencyMaster)}
                                                       </span>
                                                       </li>
                                                   </ul>
                                               )
                                           ]} />
                                       )
                                   }
                               )}
                        />
                    </div>

                    <div style={{height: 20}}></div>
                </div>
            )
        } else {
            return <LoadingComponent loaderFlag={true} />
        }
    }
}

export default connect(mapStateToProps)(AdminOrderDetailBox);