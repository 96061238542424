import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "../library/JQuery";
import {CurrencyContainer} from "../model/container/money/CurrencyContainer";
import LanguagePack from "../language/LanguagePack";

export default class CurrencyTypeDropdown extends React.Component {

    static propTypes = {
        updateAction: PropTypes.func,
        currencyType: PropTypes.object,
        language: PropTypes.object,
    }


    REFS_SET={
        dropdownInput: "dropdownInput",
        dropdownContainer:"dropdownContainer"
    };


    componentDidMount(){
        $(this.refs[this.REFS_SET.dropdownContainer]).dropdown({
            onChange: (value, text, $choice)=>{
                this.refs[this.REFS_SET.dropdownInput]["value"] = value;

                const master = CurrencyContainer.deserialize(value)

                this.props.updateAction(master);
            }
        });
    }
    
    render() {
        return (
            <div className="ui fluid search selection dropdown" ref={this.REFS_SET.dropdownContainer}>
                <input type="hidden"
                       value={this.props.currencyType.value}
                       ref={this.REFS_SET.dropdownInput}
                />
                <i className="dropdown icon"></i>
                <div className="default text">
                    {LanguagePack.extract(this.props.language, this.props.currencyType.name)}
                </div>
                <div className="menu">
                    <div className="item" data-value={CurrencyContainer.master.JPY.value}>
                        {LanguagePack.extract(this.props.language, CurrencyContainer.master.JPY.name)}
                    </div>
                    <div className="item" data-value={CurrencyContainer.master.KRW.value}>
                        {LanguagePack.extract(this.props.language, CurrencyContainer.master.KRW.name)}
                    </div>
                    <div className="item" data-value={CurrencyContainer.master.USD.value}>
                        {LanguagePack.extract(this.props.language, CurrencyContainer.master.USD.name)}
                    </div>
                </div>
            </div>
        );
    }
}
