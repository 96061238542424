import {
    USER_SET_SUBSCRIBER_INFO,
    USER_SET_ACCOUNT_USER_INFO,
    USER_SET_ACCOUNT_ADMIN_INFO
} from "../constant/ActionType";
import {ReduxMainStore} from "../store/ReduxMainStore";
import AccountUserModel from "../../../model/entity/account/AccountUserModel";
import AccountSubscriberModel from "../../../model/entity/account/AccountSubscriberModel";

export const setAccountUserInfo = (payload) => {
    ReduxMainStore.dispatch({
        type: USER_SET_ACCOUNT_USER_INFO,
        payload
    })
}

export const clearAccountUserInfo = () => {
    ReduxMainStore.dispatch({
        type: USER_SET_ACCOUNT_USER_INFO,
        payload: new AccountUserModel()
    })
}

export const setSubscriberInfo = (payload) => {
    ReduxMainStore.dispatch({
        type: USER_SET_SUBSCRIBER_INFO,
        payload: payload
    })
}

export const clearSubscriberInfo = () => {
    ReduxMainStore.dispatch({
        type: USER_SET_SUBSCRIBER_INFO,
        payload: new AccountSubscriberModel()
    })
}

export const setAccountAdminInfo = (payload) => {
    ReduxMainStore.dispatch({
        type: USER_SET_ACCOUNT_ADMIN_INFO,
        payload
    })
}

export const clearAccountAdminInfo = () => {
    ReduxMainStore.dispatch({
        type: USER_SET_ACCOUNT_ADMIN_INFO,
        payload: new AccountUserModel()
    })
}

