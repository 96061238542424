import {LanguageMasterDefinition} from "./LanguageFormatter";

export class ValueByLanguage {
    constructor(english, japanese, korean){
        this.english = english;
        this.korean = korean;
        this.japanese = japanese;
    }

    /**
     * @param {LanguageMaster} languageMaster
     */
    getByLanguageMaster(languageMaster){
        switch (languageMaster) {
            case LanguageMasterDefinition.english: return this.english
            case LanguageMasterDefinition.korean: return this.korean
            case LanguageMasterDefinition.japanese: return this.japanese
            default: {
                const serializedMaster = typeof languageMaster === 'object' ?
                                        JSON.stringify(languageMaster) :
                                        languageMaster

                throw Error("Unknown language master: " + serializedMaster)
            }
        }
    }
}