import * as React from "react";
import {connect} from "react-redux";
import AbsoluteFullSizeBox from "../../../ui_template/AbsoluteFullSizeBox";
import {RouteListenerComponent} from "../../RouteListener";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../RouteDictionary";
import * as Loops4RPCAPI from "../../../api/loops4/Loops4RPCAPI";
import {getAuthorizationHeader} from "../../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import MainAppLayout from "../../../screen/layout/MainAppLayout";
import ReleaseCollectionBrowserBox from "./browser/ReleaseCollectionBrowserBox";
import ReleaseCollectionNavigator from "./navigator/ReleaseCollectionNavigator";
import ReleaseCollectionModel from "../../../model/entity/release/ReleaseCollectionModel";
import ReleaseCollectionSubscriberBox from "./subscriber/ReleaseCollectionSubscriberBox";
import DateFormatter from "../../../model/formatter/DateFormatter";
import {RouteUtility} from "../../RouteUtility";
import {GetReleaseCollectionRPCRequest} from "../../../api/loops4/model/release/collection/GetReleaseCollectionRPCRequest";
import {GET_RELEASE_ITEMS_OF_COLLECTION_V1_0} from "../../../api/loops4/caller/loops4RpcCaller";
import {GetReleaseItemsOfCollectionRPCResponse} from "../../../api/loops4/model/release/item/GetReleaseItemsOfCollectionRPCResponse";
import ReleaseCollectionOrderHistoryBox from "./order/ReleaseCollectionOrderHistoryBox";
import ReleaseCollectionOrderDetailBox from "./order/ReleaseCollectionOrderDetailBox";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};

class ReleaseCollection extends RouteListenerComponent {

    REFS_SET = {};

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            contentBox: null,
            releaseCollectionModel: new ReleaseCollectionModel(),
            releaseItemsOfCollection: new GetReleaseItemsOfCollectionRPCResponse(),
            storeId: null
        }
        this.state.releaseItemsOfCollection.items = []
    }

    async updateByProps() {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const releaseCollectionId = this.props.routeState.pathParameter[RoutePathParam.releaseCollectionId]
            const storeId = this.props.routeState.pathParameter[RoutePathParam.storeId]

            const getReleaseCollection = async () => {
                const rpcResult = await Loops4RPCAPI.getReleaseCollection({
                    accessTokenModel: this.props.accessTokenModel,
                    releaseCollectionId: releaseCollectionId
                })

                if (rpcResult.isError()) {
                    this.setState((prevs, props) => {
                        return {
                            ...prevs,
                            isLoading: false,
                            contentBox: (
                                <div>
                                    Request got failed:: {rpcResult.getErrorMessage()}
                                </div>
                            )
                        }
                    })
                } else {
                    this.setState((prevs, props) => {
                        return {
                            ...prevs,
                            isLoading: false,
                            contentBox: null,
                            releaseCollectionModel: rpcResult.model,
                            storeId: storeId
                        }
                    })
                }
            }

            const getReleaseItemsOfCollection = async () => {
                const param = new GetReleaseCollectionRPCRequest()
                param.entity_id = releaseCollectionId

                const rpcResult = await GET_RELEASE_ITEMS_OF_COLLECTION_V1_0({
                    headers: getAuthorizationHeader(this.props.accessTokenModel),
                    paramBody: param
                })

                if (rpcResult.isError()) {
                    this.setState((prevs, props) => {
                        return {
                            ...prevs,
                            isLoading: false,
                            contentBox: (
                                <div>
                                    Request got failed:: {rpcResult.getErrorMessage()}
                                </div>
                            )
                        }
                    })
                } else {
                    this.setState((prevs, props) => {
                        return {
                            ...prevs,
                            isLoading: false,
                            contentBox: null,
                            releaseItemsOfCollection: rpcResult.model,
                        }
                    })
                }

            }

            getReleaseCollection()
            getReleaseItemsOfCollection()
        })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {

            const viewMode = this.props.routeState.queryParameter[RouteQueryParam.view_mode] || ReleaseCollectionNavigator.viewMode.browsing

            let viewContent = null
            switch (viewMode) {
                case ReleaseCollectionNavigator.viewMode.browsing: {
                    viewContent = (
                        <ReleaseCollectionBrowserBox accessTokenModel={this.props.accessTokenModel}
                                                     releaseCollectionModel={this.state.releaseCollectionModel}
                                                     releaseItemsOfCollection={this.state.releaseItemsOfCollection}
                                                     storeId={this.state.storeId}
                                                     language={this.props.language}
                        />
                    )
                    break;
                }
                case ReleaseCollectionNavigator.viewMode.subscriber: {
                    viewContent = (
                        <ReleaseCollectionSubscriberBox accessTokenModel={this.props.accessTokenModel}
                                                        releaseCollectionModel={this.state.releaseCollectionModel}
                                                        storeId={this.state.storeId}
                                                        language={this.props.language}
                        />
                    )
                    break;
                }
                case ReleaseCollectionNavigator.viewMode.orderHistory: {
                    viewContent = (
                        <ReleaseCollectionOrderHistoryBox accessTokenModel={this.props.accessTokenModel}
                                                        releaseCollectionModel={this.state.releaseCollectionModel}
                                                        storeId={this.state.storeId}
                                                        languageMaster={this.props.languageMaster}

                        />
                    )
                    break;
                }
                case ReleaseCollectionNavigator.viewMode.orderDetail: {
                    viewContent = (
                        <ReleaseCollectionOrderDetailBox accessTokenModel={this.props.accessTokenModel}
                                                        releaseCollectionModel={this.state.releaseCollectionModel}
                                                         orderId={this.state.orderId}
                                                        storeId={this.state.storeId}
                                                        language={this.props.language}
                        />
                    )
                    break;
                }
            }

            return (
                <MainAppLayout>
                    <div className="flexContainerRow " style={{width: '100%'}}>
                        <div className="flexItem00 ">
                            <ReleaseCollectionNavigator releaseCollectionModel={this.state.releaseCollectionModel}
                                                        accessTokenModel={this.props.accessTokenModel}
                                                        storeId={this.state.storeId}
                            />
                        </div>
                        <div className="flexItem11  flexItemHorizontallyDynamic">
                            <AbsoluteFullSizeBox>
                                <div className="flexContainerColumn ">
                                    <div className="flexItem11">
                                        <AbsoluteFullSizeBox>

                                            {this.state.contentBox}

                                            <h3>Release Collection</h3>
                                            <div className="ui form">
                                                <div className="field">
                                                    <label>ID</label>
                                                    <div className="ui left icon input">
                                                        <a href={RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
                                                            [RoutePathParam.releaseCollectionId]: this.state.releaseCollectionModel.entityId,
                                                        }, {

                                                        })} rel='noopener noreferrer' target={'_blank'}>
                                                            {this.state.releaseCollectionModel.entityId}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label>Name</label>
                                                    <div className="ui left icon input">
                                                        {this.state.releaseCollectionModel.collectionName}
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label>State</label>
                                                    <div className="ui left icon input">
                                                        {this.state.releaseCollectionModel.collectionState}
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label>Created at</label>
                                                    <div className="ui left icon input">
                                                        {DateFormatter.getFullDate(this.state.releaseCollectionModel.dateCreated.date.toString())}<br/>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label>Item size</label>
                                                    <div className="ui left icon input">
                                                        {this.state.releaseItemsOfCollection.items.length}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ui section divider compact"></div>

                                            {viewContent}

                                        </AbsoluteFullSizeBox>
                                    </div>
                                </div>
                            </AbsoluteFullSizeBox>
                        </div>
                    </div>
                </MainAppLayout>
            )
        }
    }
}

export default connect(mapStateToProps)(ReleaseCollection);