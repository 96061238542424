import MasterContainer from "./MasterContainer";

export class PaymentCardContainer extends MasterContainer{

    /**
     * @param {string} value
     * @param {string} name
     * @param {RegExp} regex
     */
    constructor(value, name, regex) {
        super();
        this.value = value;
        this.name = name;
        this.regex = regex;
    }

    /**
     * @param data
     * @return {PaymentCardContainer}
     */
    static deserialize(data){
        switch (data){
            case PaymentCardContainer.master.visa.value: return PaymentCardContainer.master.visa;
            case PaymentCardContainer.master.mastercard.value: return PaymentCardContainer.master.mastercard;
            case PaymentCardContainer.master.americanExpress.value: return PaymentCardContainer.master.americanExpress;
            case PaymentCardContainer.master.discover.value: return PaymentCardContainer.master.discover;
            case PaymentCardContainer.master.dinersClub.value: return PaymentCardContainer.master.dinersClub;
            case PaymentCardContainer.master.jcb.value: return PaymentCardContainer.master.jcb;
            case PaymentCardContainer.master.unknown.value: return PaymentCardContainer.master.unknown;
        }
    }

    static master = {
        visa: new PaymentCardContainer(
            "visa",
            "Visa",
            /^4[0-9]{12}(?:[0-9]{3})?$/
        ),
        mastercard: new PaymentCardContainer(
            "mastercard",
            "Mastercard",
            /^5[1-5]\d{14}$/
        ),
        americanExpress: new PaymentCardContainer(
            "americanExpress",
            "American Express",
            /^3[47][0-9]{13}$/
        ),
        discover: new PaymentCardContainer(
            "discover",
            "Discover",
            /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
        ),
        dinersClub: new PaymentCardContainer(
            "dinersClub",
            "Diners Club",
            /^6(?:011|5[0-9]{2})[0-9]{12}$/
        ),
        jcb: new PaymentCardContainer(
            "jcb",
            "JCB",
            /^(?:2131|1800|35\d{3})\d{11}$/
        ),
        unknown: new PaymentCardContainer(
            "unknown",
            "Unknown",
            /d/
        )
    };

}