import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {ScrollableTable} from "../../../ui_template/table/ScrollableTable";
import PublishExpirationIndicatorComponent from "../../../ui_component/icon/ExpirationIndicatorComponent";
import DateFormatter from "../../../model/formatter/DateFormatter";
import {RouteUtility} from "../../RouteUtility";
import {RouteDictionary, RoutePathParam} from "../../RouteDictionary";
import {Link} from "react-router-dom";
import {TableHeadCell} from "../../../ui_template/table/cell/TabelHeadCell";
import {TableBodyCell} from "../../../ui_template/table/cell/TableBodyCell";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};

class StoreBrowserBox extends React.Component {

    static propTypes = {
        storeModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    genHeader(title){
        return (
            <div>
                <span style={{marginLeft: 5}}>
                    {title}
                </span>
            </div>
        )
    }

    render() {
        return (
            <div className="flexContainerColumn">
                <div className="flexItem00">
                    <ScrollableTable className="ui unstackable small table"
                                     header={<TableHeadCell columns={["CODE", "STATE", "DATE", ""]}  />}
                                     rows={this.props.storeModel.childReleaseCollectionList.map((releaseCollectionModel)=> {
                                         return <TableBodyCell columns={[
                                             (<div>
                                                 <i className="ui icon cubes"/>{releaseCollectionModel.collectionName}<br/>
                                                 {releaseCollectionModel.entityId}
                                             </div>),
                                             <PublishExpirationIndicatorComponent isExpired={releaseCollectionModel.isExpired()} />,
                                             <div>{DateFormatter.getFullDate(releaseCollectionModel.dateCreated.date.toString())}</div>,
                                             <div>
                                                 <Link to={RouteUtility.getPathname(RouteDictionary.releaseCollection, {
                                                     [RoutePathParam.storeId]: this.props.storeModel.entityId,
                                                     [RoutePathParam.releaseCollectionId]: releaseCollectionModel.entityId,
                                                 })}>
                                                     <button className="ui button small basic compact">Detail</button>
                                                 </Link>
                                             </div>
                                         ]}/>
                                     })}
                    />
                    {(this.props.storeModel.childReleaseCollectionList.length < 1 ? (
                        <div className="ui message info">
                            you do not have any publish.
                        </div>
                    ) : null)}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(StoreBrowserBox);