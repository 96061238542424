// ============================================================
// Extends message class - Loops4ResponseMessage
// ============================================================
import {Loops4ResponseMessage} from "./message/Loops4ResponseMessage";

/**
 * @returns {boolean}
 */
Loops4ResponseMessage.prototype.getErrorMessage = () => {
    if (this.isError() && this.errors[0]['error_message']) {
        return this.errors[0]['error_message']
    } else if (this.isError()) {
        return this.errors[0].toString()
    } else {
        return 'Something error but not found detail'
    }
}

/**
 * @return {string}
 */
Loops4ResponseMessage.prototype.getErrorCode = () => {
    if (this.isError() && this.errors[0]['error_code']) {
        return this.errors[0]['error_code']
    } else if (this.isError()) {
        return 500
    } else {
        return 'Something error but not found detail'
    }
}
