

// Package Dir: commerce/order/refund_commerce_order
// FileName: RefundCommerceOrderRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.refund_commerce_order.RefundCommerceOrderRPCRequest
// Super types: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderEntityIdRPCBody

export class RefundCommerceOrderRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.price_amount = 0;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.price_currency = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_order_entity_id = null;

    }
}