import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {APPLICATION_VERSION} from "../../../../constant/ApplicationVersionConstant";
import ServiceInfoConstant from "../../../../constant/ServiceInfoConstant";

const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        languageMaster: state.languageMaster,
    };
};

class ApplicationTopBanner extends React.Component{
    static propTypes = {
        language: PropTypes.object,
        commerceCartModel: PropTypes.object,
    }

    style = {
        mainContainer: {
            padding: 10
        },
        title: {
            color: 'black',
            fontWeight: 'bold'
        },
        message: {
            color: 'rgba(155, 155, 155, 1)',
            marginTop: 5,
            fontSize: '0.8em',

        },
    }

    render() {
        return (
            <div style={this.style.mainContainer}>
                <div style={this.style.title}>
                    {ServiceInfoConstant.serviceName}
                </div>
                <div style={this.style.message}>
                    Photo Gallery v{APPLICATION_VERSION}
                </div>
            </div>
        )

    }
}

export default connect(mapStateToProps)(ApplicationTopBanner);