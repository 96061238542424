import {CurrencyContainer} from "./money/CurrencyContainer";
import MasterContainer from "./MasterContainer";

export class CountryContainer extends MasterContainer {

    /**
     *
     * @param {string} value
     * @param {string} name
     * @param {CurrencyContainer} currency
     */
    constructor(value, name, currency) {
        super();
        this.value = value;
        this.currency = currency;
        this.name = name;
    }

    static master = {
        unitedStates: new CountryContainer(
            "UNITED_STATE",
            "United States",
            CurrencyContainer.master.USD
        ),
        japan: new CountryContainer(
            "JAPAN",
            "Japan",
            CurrencyContainer.master.JPY
        ),
        southKorea: new CountryContainer(
            "SOUTH_KOREA",
            "South Korea",
            CurrencyContainer.master.KRW
        )
    }

    /**
     * @param data
     * @return {CountryContainer}
     */
    static deserialize(data){
        switch (data){
            case CountryContainer.master.unitedStates.value: return CountryContainer.master.unitedStates;
            case CountryContainer.master.japan.value: return CountryContainer.master.japan;
            case CountryContainer.master.southKorea.value: return CountryContainer.master.southKorea;
        }
    }


}