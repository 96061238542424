

// Package Dir: release/item
// FileName: GetReleaseItemRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.release.item.GetReleaseItemRPCRequest
// Super types: java.lang.Object

export class GetReleaseItemRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.entity_id = null;

    }
}