import MasterContainer from "../MasterContainer";
import LanguagePack from "../../../language/LanguagePack";

export class CurrencyContainer extends MasterContainer{

    /**
     * @param {string} value
     * @param {string} character
     * @param {LanguagePack} name
     */
    constructor(value, character, name) {
        super();
        this.value = value;
        this.character = character;
        this.name = name;
    }

    static master = {
        USD: new CurrencyContainer(
            "USD_CommerceCurrencyMaster",
            "$",
            new LanguagePack(
                "Dollars",
                "アメリカドル",
                "미국 달러"
            )
        ),
        JPY: new CurrencyContainer(
            "JPY_CommerceCurrencyMaster",
            "¥",
            new LanguagePack(
                "Yen",
                "円",
                "엔"
            )
        ),
        KRW: new CurrencyContainer(
            "KRW_CommerceCurrencyMaster",
            "₩",
            new LanguagePack(
                "Won",
                "ウォン",
                "원"
            )
        )
    };

    /**
     *
     * @param data
     * @return {CurrencyContainer}
     */
    static deserialize(data){
        switch (data){
            case CurrencyContainer.master.USD.value: return CurrencyContainer.master.USD;
            case CurrencyContainer.master.JPY.value: return CurrencyContainer.master.JPY;
            case CurrencyContainer.master.KRW.value: return CurrencyContainer.master.KRW;
        }
    }
}