import DateUTCModel from "../DateUTCModel";

export default class LabelModel{

    entityId;
    value = "";
    dateCreated = new DateUTCModel();

    /**
     * @param data
     * @return {LabelModel}
     */
    static deserialize(data){
        let newModel = new LabelModel();

        if(data){
            newModel.entityId = data.entity_id;
            newModel.value = data.value;
            newModel.dateCreated = DateUTCModel.deserialize(data.dateCreated);
        }

        return newModel;
    }

}