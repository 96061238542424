import * as React from "react";
import * as PropTypes from "prop-types";
import ButtonContainerAddictionMenu from "../browser/addiction_menu/ButtonContainerAddictionMenu";
import * as Loops4BinaryAPI from "../../../api/loops4/Loops4BinaryAPI";

export default class LibraryViewerAdditionMenu extends React.Component {

	static propTypes = {
		// appContext: PropTypes.object,
		accessTokenModel: PropTypes.object,
		collectionModel: PropTypes.object,
		itemModel: PropTypes.object,
		sideViewVisible: PropTypes.bool,
		openSideView: PropTypes.func,
		closeSideView: PropTypes.func
	}

	REFS_SET = {
		mainPictureImageContainer: "mainPictureImageContainer",
		detailsContainer: "detailsContainer"
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			// attributeScope: ItemModel.masters.attributeScope.global
		};
	}

	itemBaseStyle = {
		height: 59,
		padding: 10,
		textAlign: "center",
		boxSizing:'border-box'
	};

	itemRow = {
		display: 'block'
	};


	// openEditPopup(event) {
	// 	AppContextAction.openDim(
	// 		<div className="ui segment" style={{ textAlign:'left' }}>
	//
	// 			<h4 style={{textAlign:'left'}}>
	// 				Change Name
	// 			</h4>
	// 			<div className="ui divider"></div>
	// 			<div className="column">
	// 				{/*<PictureNameChangerComponent modelPK={this.props.itemModel.getPrimaryKey()}*/}
	// 				{/*							 modelTitle={this.props.itemModel.itemName}/>*/}
	// 			</div>
	//
	// 			<div className="ui divider">
	// 			</div>
	//
	// 			<div className="column">
	//
	//
	// 				{ /* TODO add remove function */}
	// 				<h4 style={{textAlign:'left'}}>
	// 					Remove Picture
	// 				</h4>
	//
	// 				<div style={{textAlign:'center'}}>
	// 					<button className="ui button blue small"
	// 							onClick={this.removeItemFromCollection.bind(this, this.props.collectionModel.getPrimaryKey(), this.props.itemModel.getPrimaryKey())}>
	// 						A - Remove from collection
	// 					</button>
	// 					<button style={{marginLeft:15}} className="ui basic black button small"
	// 							onClick={this.removeItemPermanently.bind(this, this.props.itemModel.getPrimaryKey())}>
	// 						B - Remove permanently <span style={{color:'red'}}>*caution</span>
	// 					</button>
	// 				</div>
	//
	//
	// 			</div>
	//
	//
	// 			<table style={{marginTop:10}}>
	// 				<tbody>
	// 				<tr>
	// 					<td><i className="icon info circle"/></td>
	// 					<td>How different Collection and Drawer?<br/></td>
	// 				</tr>
	// 				<tr>
	// 					<td></td>
	// 					<td>
	// 						<p>
	// 							Collection<br/>
	// 							&nbsp;&nbsp;It is a set of photos. It is same concept with Photo Book in your book shelf<br/>
	// 							The different point is It is virtual space for the picture. You can put same photo for several collections. then, if you change the photo, you can see all photo data is changed at the same time.<br/>
	// 							It will leads you flexible photo management.
	// 						</p>
	// 						<p>
	// 							Drawer<br/>
	// 							&nbsp;&nbsp;It is a set of collections.  It is same concept with Book Sehlf in your room<br/>
	// 							The different point is It is virtual space for the collections. You can put same collection for several drawers. then, you can have collections more flexible and categorized.<br/>
	// 							It will leads you flexible photo management.
	// 						</p>
	// 						... <a
	// 						onClick={(e)=>{e.preventDefault(); alert("The page is on preparing. We will update soon.")}}>learn more</a>
	// 					</td>
	// 				</tr>
	// 				</tbody>
	// 			</table>
	// 		</div>
	// 	)
	// }

	closeEditPopup() {
		// AppContextAction.closeDim()
	}


	removeSuccessAction() {
		if (window.confirm("It is removed successfully.\n\nDo you want to move to collection view?")) {
			// let appContext = AppContextAction.getContextModel();
			// RouteAction.routePage(RoutingTables.libraryActivityForCollection, {
			// 	[RoutingDic.pathParam.drawerId]: appContext.activityModel.drawerModelPK._id,
			// 	[RoutingDic.pathParam.collectionId]: this.props.collectionModel._id
			// });
		}
	}

	removeItemFromCollection(collectionPK, item) {
		// CollectionAction.detachItem(collectionPK, item, ()=> this.removeSuccessAction())
	}

	removeItemPermanently(item) {
		let decisionResult = window.confirm("Are you sure remove it permanently?\n\nYou will lost this data and never get recovery after it.");

		if (decisionResult) {
			// CollectionAction.detachItemPermanently(AuthModel.authNamespace.user, item, ()=> this.removeSuccessAction())
		}
	}

	genDownloadButton() {

		let ConfirmIfDownloadingEntireCollection = ()=> {
			const fileURL = Loops4BinaryAPI.getLibraryItemFileURL({
				accessTokenModel: this.props.accessTokenModel,
				libraryItemId: this.props.itemModel.entity_id
			})
			window.open(fileURL, "_blank")
		};

		return(
		<ButtonContainerAddictionMenu icon={<i className="icon large download"/>}
									  label="Download"
									  clickEvent={ConfirmIfDownloadingEntireCollection.bind(this)}
		/>
		)
	}

	toggleSideView(){
		if(this.props.sideViewVisible){
			this.props.closeSideView();
		}else{
			this.props.openSideView();
		}
	}

	updateAttributeScope(scope	){
		this.setState((prevs, props)=> {
			prevs.attributeScope = scope;
			return prevs;
		});
	}

	render() {
		// let picturePK = this.props.itemModel.getPrimaryKey();

		let itemAttributeScope = this.state.attributeScope;

		return (

		<div className="flexContainerRow library headerBackground">
			<div className="flexItem11 " style={{paddingLeft: 10}}>
				<div className="flexContainerRow flexItemAlignStartCenter">

				</div>
			</div>
			<div className="flexItem00 ">
				<div className="flexContainerRow">
					<div className="flexItem00 flexItemAlignCenterCenter">
						<div className="ui buttons mini">
							{/*<button className={"ui button " + (itemAttributeScope == ItemModel.masters.attributeScope.global ? "active" : null)}*/}
							<button className={"ui button "}
									// onClick={this.updateAttributeScope.bind(this, ItemModel.masters.attributeScope.global)}
							>
								GLOBAL
							</button>
							{/*<button className={"ui button " + (itemAttributeScope == ItemModel.masters.attributeScope.local ? "active" : null)}*/}
							<button className={"ui button "}
									// onClick={this.updateAttributeScope.bind(this, ItemModel.masters.attributeScope.local)}
							>
								LOCAL
							</button>
						</div>
					</div>


					<div className="flexItem00">
						<ButtonContainerAddictionMenu icon={(null
								// <PinButtonPictureViewComponent
								// 	collectionModel={this.props.collectionModel}
								// 	itemModel={this.props.itemModel}
								// 	attributeScope={itemAttributeScope}
								// 	pinStyle={Object.assign({boxSizing:'border-box'})}
								// />
							)}
													  label="Flag"
													  clickEvent={null}
						/>
					</div>

					<div className="flexItem00">
						{/*<ButtonContainerAddictionMenu icon={(*/}
						{/*	<RatingItemComponent listeningModelPK={picturePK}*/}
						{/*						 collectionModel={this.props.collectionModel}*/}
						{/*						 itemModel={this.props.itemModel}*/}
						{/*						 attributeScope={itemAttributeScope}*/}
						{/*						 />*/}
						{/*						 )}*/}
						{/*							  label="Rating"*/}
						{/*							  clickEvent={null}*/}
						{/*/>*/}
					</div>


					{this.genDownloadButton()}

					<div className="flexItem00" style={(this.props.sideViewVisible ? {backgroundColor:'#3c3c3c'} : null)}>
						<ButtonContainerAddictionMenu icon={<i className="icon large info circle"/>}
													  label={(this.props.sideViewVisible ? "Hide" : "Show")}
													  clickEvent={this.toggleSideView.bind(this)}
						/>
					</div>

				</div>
			</div>

			<div className="ui fitted divider"></div>
		</div>
		);
	}
}
