import * as React from "react";
import * as PropTypes from 'prop-types';

export default class MiniLoaderComponent extends React.Component {

    static propTypes = {
        loaderFlag: PropTypes.bool,
        isOnlyIcon: PropTypes.bool,
        themeStyle: PropTypes.string,
    }

    static themeStyle = {
        bright: "bright",
        dark: "dark"
    };

    render() {
        let dimmerColor = (this.props.themeStyle === MiniLoaderComponent.themeStyle.bright ? 'inverted' : '')
        let isOnlyIcon = (this.props.isOnlyIcon !== null ? this.props.isOnlyIcon : false);
        if (this.props.loaderFlag) {
            return (
                <div className={'ui segment'}>
                    <div className={`ui active dimmer ${dimmerColor}`}>
                        <div className={'ui mini loader'}></div>
                    </div>
                </div>
            )
        } else {
            return null
        }

    }

}