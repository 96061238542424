import * as React from "react";
import {
    ValidatingRuleTemplate,
    RegExpDictionary,
    ValidatingRuleNumberTemplate
} from "../Validator";
import DateFormatter from "../../formatter/DateFormatter";

let next20Years = DateFormatter.getNextYears(20);
let thisYear = next20Years[0];
let next20Year = next20Years[next20Years.length];

const PublishPaymentRequestPack = {
    shipping: {
        firstName: new ValidatingRuleTemplate(1, 25, null),
        lastName: new ValidatingRuleTemplate(1, 25, null),
        address: new ValidatingRuleTemplate(1, 255, null),
        city: new ValidatingRuleTemplate(1, 255, null),
        postalCode: new ValidatingRuleTemplate(1, 20, null),
        country: new ValidatingRuleTemplate(1, 20, null)
    },
    payment: {
        method: new ValidatingRuleTemplate(1, 50, null),
        card: {
            number: new ValidatingRuleTemplate(1, 30, null),
            cvc: new ValidatingRuleTemplate(3, 4, null),
            expiration: {
                month: new ValidatingRuleNumberTemplate(1, 12),
                year: new ValidatingRuleNumberTemplate(thisYear, next20Year)
            },
            holder: new ValidatingRuleTemplate(1, 50, null)
        }
    }
};

export default PublishPaymentRequestPack