import * as React from 'react';
import * as PropTypes from "prop-types";

export default class AbsoluteFullSizeBox extends React.Component {

    static propTypes = {
        style: PropTypes.object,
    }


    render() {
        let mergedAbsoluteStyle = Object.assign({
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            position: 'absolute'
        }, this.props.style);

        return (
            <div className={this.props.className} style={mergedAbsoluteStyle}>
                {this.props.children}
            </div>
        );
    }
}
