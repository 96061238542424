const StyleConstant = {
    preventFieldOverflow:{
        textOverflow: 'ellipsis',
        overflow:'hidden',
        whiteSpace: 'nowrap'
    },
    unSelectCSS: {
        MozUserSelect: "none",
        KhtmlUserSelect: "none",
        WebkitUserSelect: "none",

        /*
         Introduced in IE 10.
         See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
         */
        MsUserSelect: "none",
        userSelect: "none"
    },
    imageInitializingSrc: "/assets/img/icons/initializingIamge.png",
    imageWarningSrc: "/assets/img/icons/Warning_Icon_256.png"
};

export default StyleConstant;