import * as React from "react";
import * as PropTypes from "prop-types";
import {getItemComponentsInToolbarViewer} from "./ItemViewerInFooterFactory";
import {GetLibraryCollectionRPCResponse} from "../../../api/loops4/model/library/collection/GetLibraryCollectionRPCResponse";
import {AccessTokenModel} from "../../../model/auth/AccessTokenModel";

export default class LibraryViewerFooterToolbar extends React.Component {

    REFS_SET = {
        ToolbarContainer: "ToolbarContainer"
    };

    static propTypes = {
        accessTokenModel: PropTypes.objectOf(AccessTokenModel),
        // selectionStore: PropTypes.objectOf(SelectionStore)
        selectionStore: PropTypes.object,
        selectionAction: PropTypes.func,
        // collectionModel: PropTypes.objectOf(CollectionModel),
        boxId: PropTypes.string,
        // collectionModel: PropTypes.object,
        collectionModel: PropTypes.objectOf(GetLibraryCollectionRPCResponse),
    }


    constructor(props, context) {
        super(props, context);
        this.state = {
            elasticFilterVisible: false
        }
    }

    componentWillUnmount() {

    }


    componentWillReceiveProps(newProps) {
    }

    componentDidMount() {


    }


    toggleThumbnailsVisible(visibleFlag) {
        // let appContext = AppContextAction.getContextModel();
        // if (visibleFlag) {
        //     appContext.activityModel.componentOption_footerToolbar.windowStatus = ComponentOptionConst.WINDOW_STATUS_MAXIMIZE;
        // } else {
        //     appContext.activityModel.componentOption_footerToolbar.windowStatus = ComponentOptionConst.WINDOW_STATUS_MINIMIZE;
        // }
        //
        // AppContextAction.setContextModel(appContext);
    }

    updateFilterOn(filterFlag){
        // ElasticFilterQueryAction.updateFilter_On(filterFlag);
    }

    updateRating(ratingCount) {
        // ElasticFilterQueryAction.update_Rating(ratingCount);
        // ElasticFilterQueryAction.updateFilter_On(true);

    }

    updatePin(currentPinStatus) {
        // ElasticFilterQueryAction.update_Pin(currentPinStatus);
        // ElasticFilterQueryAction.updateFilter_On(true);

    }

    genViewModeButton(activeModel) {
        let button = null;
        // if (activeModel.itemModelPK._id != 0) {
        //     let pathname = RouteAction.getPathname(RoutingTables.libraryActivityForCollection, {
        //         [RoutingDic.pathParam.drawerId]: activeModel.drawerModelPK._id,
        //         [RoutingDic.pathParam.collectionId]: activeModel.collectionModelPK._id
        //     })
        //
        //     button = <Link to={pathname}>
        //         <button className="ui button tiny" style={{padding:5}}>
        //             <i className="icon block layout"/>Thumbnails
        //         </button>
        //     </Link>
        // }
        return button;
    }

    genSelectingNumbers(selectionStore, collectionModel) {
        let selectedItems = selectionStore.getAllItems();
        let entireItemLength = collectionModel.items.length;
        if (selectedItems.length < 1) {
          return (null)
        } else if (selectedItems.length < 1) {
            return (
                <div className="ui label">
                    Current collection photos
                    <div className="detail">{entireItemLength}</div>
                 </div>
            )
        } else {
            return (
                <div className="ui label">
                        Selected photos
                        <div className="detail">{selectedItems.length}</div>
                    </div>
                )
        }


    }

    updateElasticFilters(filters){
        // let appContext = this.props.appContext;
        // appContext.activityModel.componentOption_elasticFilterQuery.elasticFilters = filters;
        // AppContextAction.setContextModel(appContext, true);
    }

    toggleElasticFilterVisible(){
        this.setState((prevs, props)=> {
            prevs.elasticFilterVisible = !prevs.elasticFilterVisible;
            return prevs;
        });
    }

    render() {
        // let activityModel:ActivityModel = this.props.appContext.activityModel;
        // let thumbnailsWindowStatus = activityModel.componentOption_footerToolbar.windowStatus;
        // let thumbnailsItemWindowStatus = activityModel.componentOption_footerToolbar.itemWindowStatus;

        // let selectedItems = SelectionAction.getSelectionModels(
        //     this.props.collectionModel.items, this.props.selectionStore.getAllItems()
        // );

        let selectedItems = []

        let thumbnailsMinimizingButton = "▼ HIDE";
        let thumbnailsMinimizingMaxFlag = true
        // let thumbnailsMinimizingButton = null;
        // let thumbnailsMinimizingMaxFlag = true;
        // if (thumbnailsWindowStatus == ComponentOptionConst.WINDOW_STATUS_MAXIMIZE) {
        //     thumbnailsMinimizingButton = "▼ HIDE";
        //     thumbnailsMinimizingMaxFlag = true
        // } else if (thumbnailsWindowStatus == ComponentOptionConst.WINDOW_STATUS_MINIMIZE) {
        //     thumbnailsMinimizingButton = "▲ SHOW";
        //     thumbnailsMinimizingMaxFlag = false;
        // }

        /**
         * @type {GetLibraryCollectionRPCResponse}
         */
        const collectionModel = this.props.collectionModel

        return (
            <div className="flexContainerColumn library headerBackground" ref={this.REFS_SET.ToolbarContainer}>
                <div className="flexItem00">
                    {(this.state.elasticFilterVisible ?
                        (
                            <div className="flexContainerColumn library footerBackgroundShadow" style={{
                                    backgroundColor: '#b1b1b1',
                                    padding: 10,
                                    height: '30vh'
                                }}>
                                <div className="flexItem00">
                                    <div className="flexContainerRow" style={{marginBottom: 5}}>
                                        <div className="flexItem11 flexItemAlignStartCenter">
                                            <label>Elastic Filters ( 32 of 100 photos )</label>
                                        </div>
                                        <div className="flexItem11 flexItemAlignEndCenter">
                                            <button className="ui button compact mini"
                                                    onClick={this.toggleElasticFilterVisible.bind(this)}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flexItem11 verticalScroll" style={{backgroundColor: '#CCC', border: '1px solid #9e9e9e'}}>
                                    {/*<ElasticFilterComponentList elasticFilters={this.props.appContext.activityModel.componentOption_elasticFilterQuery.elasticFilters}*/}
                                    {/*                            updateElasticFilters={this.updateElasticFilters.bind(this)}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        )
                        : null
                    )}
                </div>
                <div className="flexItem00">
                    <div className="flexContainerRow library footerBackgroundShadow" style={{padding: 10, color: 'grey'}}>
                        <div className="flexItem11 flexItemAlignStartCenter">
                            {/*{this.genViewModeButton(this.props.appContext.activityModel)}*/}
                            &nbsp;&nbsp;
                            {/*{this.genSelectingNumbers(this.props.selectionStore, this.props.collectionModel)}*/}
                        </div>
                        <div className="flexItem11 flexItemAlignEndCenter">
                            Elastic Filter&nbsp;
                            {/*<FilterToggleComponent*/}
                            {/*    currentFilterOn={activityModel.componentOption_elasticFilterQuery.filterOn}*/}
                            {/*    updateFilterOn={this.updateFilterOn} />*/}
                            <button className="ui button mini compact" onClick={this.toggleElasticFilterVisible.bind(this)}>
                                {(this.state.elasticFilterVisible ? "HIDE" : "SHOW" )}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flexItem00">
                    {/*{(thumbnailsItemWindowStatus == ComponentOptionConst.WINDOW_STATUS_INVISIBLE ? null :  (*/}

                        <div className="flexContainerColumn">
                            <div className="flexItem00">
                                {/*<button style={{*/}
                                {/*            border:0,*/}
                                {/*            textAlign:'center', margin:0,padding:0,*/}
                                {/*            width:'100%', backgroundColor:'#d0d0d0', fontSize:10,*/}
                                {/*            borderBottom:'1px solid #bfbfbf'*/}
                                {/*            }}*/}
                                {/*        onClick={this.toggleThumbnailsVisi/ItemViewerInFooterFactory.jsble.bind(this, !thumbnailsMinimizingMaxFlag)}>*/}
                                {/*    {thumbnailsMinimizingButton}*/}
                                {/*</button>*/}
                            </div>

                            <div className="flexItem00 horizontalScroll" style={{backgroundColor:'#e0e0e0'}}>
                                {/*{(thumbnailsWindowStatus == ComponentOptionConst.WINDOW_STATUS_MAXIMIZE ? (*/}
                                <div className="flexContainerRow flexItemAlignStartCenter" style={{padding:4,whiteSpace:'nowrap' }}>
                                    {collectionModel.items.map(itemModel => (
                                        <div className="flexItem00" key={itemModel.entity_id}>
                                            {getItemComponentsInToolbarViewer(
                                                this.props.accessTokenModel,
                                                this.props.boxId,
                                                this.props.collectionModel.entity_id,
                                                itemModel,
                                                false,
                                                this.props.selectionAction,
                                                false
                                                // this.props.selectionStore.getLastSelectedItem(),
                                            )}
                                        </div>
                                    ))}
                                </div>
                                {/*) : null)}*/}
                            </div>

                        </div>

                    {/*))}*/}
                </div>
            </div>
        );
    }
}
