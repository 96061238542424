import {
    UI_CONTROL_MUTATE_FULL_SIZE_DIM
} from "../constant/ActionType";
import {ReduxMainStore} from "../store/ReduxMainStore";

export const setFullSizeDIM = (payload) => {
    ReduxMainStore.dispatch({
        type: UI_CONTROL_MUTATE_FULL_SIZE_DIM,
        payload
    })
}

export const clearFullSizeDIM = () => {
    ReduxMainStore.dispatch({
        type: UI_CONTROL_MUTATE_FULL_SIZE_DIM,
        payload: null
    })
}
