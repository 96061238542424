import * as React from "react";
import {connect} from "react-redux";
import {RouteListenerComponent} from "../../RouteListener";
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import AdminOrderHistoryBox from "./order_history/AdminOrderHistoryBox";
import * as PropTypes from "prop-types";
import {LanguageMaster} from "../../../model/formatter/LanguageFormatter";
import {GetAccountAdminRPCResponse} from "../../../api/loops4/model/account/admin/GetAccountAdminRPCResponse";
import {RouteQueryParam} from "../../RouteDictionary";
import AdminOrderDetailBox from "./order_detail/AdminOrderDetailBox";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        accessTokenModel: state.auth.admin,
        adminInfo: state.adminInfo,
        routeState: state.routeState,
    };
};

class AdminPortal extends RouteListenerComponent {

    static propTypes = {
        language: PropTypes.object.isRequired,
        languageMaster: PropTypes.objectOf(LanguageMaster),
        accessTokenModel: PropTypes.object.isRequired,

        adminInfo: PropTypes.objectOf(GetAccountAdminRPCResponse).isRequired,
    }

    pageTitleSuffix = 'Admin - loops4'

    getPageTitle() {
        return "Library - " + this.pageTitleSuffix
    }

    static viewMode = {
        order_history: 'order_history',
        order_detail: 'order_detail',
    }


    style = {
        mainContainer: {
            backgroundColor: 'rgb(250, 250, 250)'
        },
        verticalSpacer: {
            width: 10
        },
        sideMenuContainer: {
            paddingTop: 10,
            paddingBottom: 10
        },
        sideMenuDivider: {
            height: 10,
        },
        contentContainer: {
            width: '100%',
            paddingTop: 10,
            paddingBottom: 10
        }
    }

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
        }
    }

    async updateByProps() {
        this.genProperContentBox(this.props)
    }

    genProperContentBox(nextProps, postRouteState){
    }


    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {
            /**
             *
             * @type {GetAccountAdminRPCResponse}
             */
            const adminInfo = this.props.adminInfo

            const viewMode = this.props.routeState.queryParameter[RouteQueryParam.view_mode] || AdminPortal.viewMode.order_history
            let viewContent = null

            switch (viewMode) {
                case AdminPortal.viewMode.order_history: {
                    viewContent = <AdminOrderHistoryBox />
                    break;
                }
                case AdminPortal.viewMode.order_detail: {
                    viewContent = <AdminOrderDetailBox />
                    break;
                }
            }


            return (
                <div className="flexContainerRow PublishReleaseCollection" style={this.style.mainContainer}>
                    <div className="flexItem00 ">
                        <div style={this.style.sideMenuContainer}>
                            Hello {adminInfo.last_name} {adminInfo.first_name}!
                        </div>
                    </div>
                    <div className="flexItem00 " style={this.style.verticalSpacer} />
                    <div className="flexItem11 flexItemVerticallyDynamic">
                        <div style={this.style.contentContainer}>
                            <div className="flexContainerColumn ">
                                <div className="flexItem11">
                                    {viewContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(AdminPortal);