import AbsoluteFullSizeBgBlurBox from "./AbsoluteFullSizeBgBlurBox";
import * as React from "react";
import * as PropTypes from 'prop-types';

export default class DimLoaderComponent extends React.Component {

    static propTypes = {
        loaderFlag: PropTypes.bool,
        isOnlyIcon: PropTypes.bool,
    }

    static themeStyle = {
        bright: "bright",
        dark: "dark"
    };

    render() {
        let isOnlyIcon = (this.props.isOnlyIcon !== null ? this.props.isOnlyIcon : false);
        if (this.props.loaderFlag) {
            return (
                <AbsoluteFullSizeBgBlurBox>
                    <div className="DimLoaderComponent loader" style={{marginBottom: 8}}></div>
                    <div>
                        {(isOnlyIcon ? null : "loading ...")}
                    </div>
                    <div>
                        {this.props.children}
                    </div>
                </AbsoluteFullSizeBgBlurBox>
            )
        } else {
            return null
        }

    }

}