

// Package Dir: library/collection
// FileName: GetLibraryCollectionRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.library.collection.GetLibraryCollectionRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.library.collection.LibraryCollectionRPCBody, com.loops4.controller.loops4rpc.model.library.collection.LibraryCollectionRPCRelation, java.lang.Object

export class GetLibraryCollectionRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_type = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.library.item.GetLibraryItemRPCResponse>
         * @type {array<GetLibraryItemRPCResponse>}
         */
        this.items = [];

    }
}