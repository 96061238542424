export class ImageSizeMaster {
    /**
     *
     * @param {number} masterCode
     */
    constructor(masterCode) {
        this.longTermPixel = masterCode
    }
}

export const devicePixelRatio = window.devicePixelRatio || 1

export const ImageSizeMasterDefinition = {
    SIZE_1920: new ImageSizeMaster(
        1920 * devicePixelRatio
    ),
    SIZE_800: new ImageSizeMaster(
        800 * devicePixelRatio
    ),
    SIZE_300: new ImageSizeMaster(
300 * devicePixelRatio
    ),
    SIZE_100: new ImageSizeMaster(
100 * devicePixelRatio
    )
}

export const ImageSizeFormatter = {
    ImageSizeMaster,
    ImageSizeMasterDefinition,
    devicePixelRatio
}
