import {
    AUTH_SET_ACCESS_TOKEN_FOR_ADMIN,
    AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER,
    AUTH_SET_ACCESS_TOKEN_FOR_USER
} from "../constant/ActionType";
import {ReduxMainStore} from "../store/ReduxMainStore";
import {AccessTokenModel} from "../../../model/auth/AccessTokenModel";

export const setAccessTokenForUser = (payload) => {
    ReduxMainStore.dispatch({
        type: AUTH_SET_ACCESS_TOKEN_FOR_USER,
        payload
    })
}

export const clearAccessTokenForUser = () => {
    ReduxMainStore.dispatch({
        type: AUTH_SET_ACCESS_TOKEN_FOR_USER,
        payload: new AccessTokenModel({})
    })
}

export const setAccessTokenForAdmin = (payload) => {
    ReduxMainStore.dispatch({
        type: AUTH_SET_ACCESS_TOKEN_FOR_ADMIN,
        payload
    })
}

export const clearAccessTokenForAdmin = () => {
    ReduxMainStore.dispatch({
        type: AUTH_SET_ACCESS_TOKEN_FOR_ADMIN,
        payload: new AccessTokenModel({})
    })
}

export const setAccessTokenForSubscriber = (payload) => {
    ReduxMainStore.dispatch({
        type: AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER,
        payload: payload
    })
}

export const clearAccessTokenForSubscriber = () => {
    ReduxMainStore.dispatch({
        type: AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER,
        payload: new AccessTokenModel({})
    })
}
