import * as React from "react";
import * as PropTypes from "prop-types";
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import ChildCollectionElementComponent from "./child/ChildCollectionElementComponent";
import {BoxNaviTreeItem, CollectionNaviTreeItem} from "./TreeBrowsingNavigator";
import ChildBoxElementComponent from "./child/ChildBoxElementComponent";
import {BoxPath} from "./state/LibraryNavigatorStore";
import LibraryNavigatorStore from "./state/LibraryNavigatorStore";

class TreeBrowsingChildState {
    isOpeningChildren = false;
}

export default class TreeBrowsingChild extends React.Component {

    static propTypes = {
        currentBoxModel: PropTypes.object,
        currentCollectionId: PropTypes.string,
        openChildBoxesQueue: PropTypes.array,
        depthCounter: PropTypes.number,
        isOpeningChildrenOnInit: PropTypes.bool,
        boxModelId: PropTypes.string,
        loading: PropTypes.bool
    }

    REFS_SET = {};

    indentInterval = 10

    const = {
        treeStep: 15,
        titleStyle : {fontWeight:200, backgroundColor:'#4E4E4E', color:'#EFEFEF', margin:0, padding:10},
        linkStyle: {color:'#C2C5C7', padding:10, paddingLeft:25}
    };

    constructor(props, context) {
        super(props, context);
        this.state = new TreeBrowsingChildState()
    }

    componentDidMount() {
        this.setChildrenShowing(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext){
        this.setChildrenShowing(nextProps)
    }

    setChildrenShowing(props){
        let path = new BoxPath(props.currentBoxModel.parentBoxes.map( value => value.entity_id), props.currentBoxModel.entity_id)
        let isItOpen = LibraryNavigatorStore.isBoxOpen(path);

        this.setState((prevs, props) => {
            prevs.isOpeningChildren = isItOpen;
            return prevs;
        });
    }

    toggleChildrenShowing(){
        let path = new BoxPath(this.props.currentBoxModel.parentBoxes.map( value => value.entity_id), this.props.currentBoxModel.entity_id)
        let isItOpen = LibraryNavigatorStore.isBoxOpen(path);
        if(isItOpen){
            LibraryNavigatorStore.closeBox(path)
        } else {
            LibraryNavigatorStore.openBox(path)
        }

        this.setState((prevs, props) => {
            prevs.isOpeningChildren = !prevs.isOpeningChildren;
            return prevs;
        });
    }
    /* =========================================================================================================================*/
    /* ============================================== GENERATE ELEMENT==========================================================*/
    /* =========================================================================================================================*/

    isOnQueue(box){
        if(this.props.openChildBoxesQueue && this.props.openChildBoxesQueue.length > 0){
            let currentBox = this.props.openChildBoxesQueue[0]
            return (currentBox.entity_id === box.entity_id)
        }else {
            return false;
        }
    }

    genChildren(isOpenChildren){

        if(isOpenChildren){


            let childCollectionList = this.props.currentBoxModel.collections.map(itemSet => this.modelIntoCollectionSet(itemSet, this.props.currentCollectionId));

            let childBoxListElements = this.props.currentBoxModel.childBoxes.map((box, index)=> {

                let isOnQueue = this.isOnQueue(box)
                let openChildBoxesQueue = (isOnQueue ? this.props.openChildBoxesQueue.splice(1) : []);

                return (
                    <div key={index}>
                        <div className="ui fitted divider" />
                        <TreeBrowsingChild boxModelId={box.entity_id}
                                           currentBoxModel={box}
                                           currentCollectionId={this.props.currentCollectionId}
                                           openChildBoxesQueue={openChildBoxesQueue}
                                           depthCounter={this.props.depthCounter + 1}
                                           isOpeningChildrenOnInit={isOnQueue}
                        />
                    </div>
                )
            });

            let childCollectionListElements = childCollectionList.map((collectionSet, order)=> (
                    <div key={order}>
                        <div className="ui fitted divider" />
                        <ChildCollectionElementComponent treeCollectionSet={collectionSet}
                                                         boxModel={this.props.currentBoxModel}
                                                         paddingLeft={10}/>
                    </div>
                )
            );

            return (
                <div>
                    {childBoxListElements}
                    {childCollectionListElements}
                </div>
            )
        }else{
            return null;
        }
    }


    modelIntoCollectionSet(collectionModel, curViewModelId) {
        const openFlag = (collectionModel.entity_id === curViewModelId);

        return new CollectionNaviTreeItem(
            collectionModel.collection_name,
            (collectionModel.items ? collectionModel.items.length : 0),
            collectionModel.entity_id,
            openFlag);
    }

    modelIntoBoxSet(boxModel, curViewModelId) {
        const openFlag = (boxModel.entity_id === curViewModelId);

        return new BoxNaviTreeItem(
            boxModel.box_name,
            boxModel.entity_id,
            openFlag);
    }


    /* =========================================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/
    /* =========================================================================================================================*/

    render() {
        if(this.props.loading){
            return <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
        }else{
            /* ==================================================== PRE-PROCESSING ==========================================================*/
            let currentBoxSet = this.modelIntoBoxSet(this.props.currentBoxModel, this.props.boxModelId);


            /* ==================================================== RETURN RENDERING ==========================================================*/
            return (
                <div style={{position: 'relative'}}>
                    <ChildBoxElementComponent treeCollectionSet={currentBoxSet}
                                              loadTheModel={this.toggleChildrenShowing.bind(this)}
                                              parentBoxId={currentBoxSet.entity_id}
                                              paddingLeft={0}/>
                    {(this.state.isOpeningChildren ? (
                            <div>
                                <div style={{paddingLeft: this.props.depthCounter * this.indentInterval}}>
                                    {this.genChildren(this.state.isOpeningChildren)}
                                </div>
                            </div>
                        ) : null)}

                </div>
            );
        }
    }
}
