

// Package Dir: commerce/order/get_order_history
// FileName: GetOrderHistoryRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.get_order_history.GetOrderHistoryRPCRequest
// Super types: java.lang.Object

export class GetOrderHistoryRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.subscriber_id = null;

    }
}