import {OrderBillRPCBody} from "../../../commerce/order/common/OrderBillRPCBody";

// Package Dir: commerce/order/put_order_commerce_product_release_item_from_commerce_cart_product
// FileName: PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.put_order_commerce_product_release_item_from_commerce_cart_product.PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCRequest
// Super types: java.lang.Object

export class PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCRequest {
    constructor() {

        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillRPCBody
         * @type {OrderBillRPCBody}
         */
        this.bill = new OrderBillRPCBody();
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_cart_product_entity_id = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.order.common.OrderCommerceProductReleaseItemEntityRPCBody>
         * @type {array<OrderCommerceProductReleaseItemEntityRPCBody>}
         */
        this.commerce_sale_product_release_item_list = [];
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.order.common.OrderShippingRPCBody>
         * @type {array<OrderShippingRPCBody>}
         */
        this.shipping = [];

    }
}