import React from "react";
import * as PropTypes from "prop-types";
import PhotoMetaUtility from "../../../utility/PhotoMetaUtility";
import UiLayoutTemplate from "../../../ui_template/UiLayoutTemplate";
import DateUtility from "../../../model/formatter/DateFormatter";

export default class LibraryViewerSideView extends React.Component {

    static propTypes = {
        languageMaster: PropTypes.object,
        collectionModel: PropTypes.object,
        itemModel: PropTypes.object,
    }

    style={
        sideViewContentBox: {border: '1px solid #333', color: 'grey', padding: 6, backgroundColor:'#555'}
    };


    genSideViewOneRaw(title, description){
        return (
            <div className="flexContainerRow" style={{marginBottom:3}}>
                <div className="flexItem00" style={{minWidth: 100, textAlign:'right', color:'#c1c1c1'}}>{title}</div>
                <div className="flexItem11" style={{marginLeft: 10, color:'#dedede'}}>{description}</div>
            </div>
        )
    }

    genSideViewHeader(label){
        return (
            <h3 className="ui header inverted" style={{textAlign:'right', color:'grey', marginBottom:5, marginTop: 10, marginRight: 5}}>{label}</h3>
        )
    }

    genSideViewSubHeader(label){
        return (
            <h4 className="ui header inverted" style={{textAlign:'left', color:'grey', marginBottom:5, marginTop: 5}}>{label}</h4>
        )
    }


    render() {
        /**
         *
         * @type {GetLibraryItemRPCResponse}
         */
        let itemModel = this.props.itemModel;
        let pixels = itemModel.file_width * itemModel.file_height;

        return(
            <div className="flexContainerColumn " style={{backgroundColor:'#3a3939'}}>

                <div className="flexItem00 flexItemAlignStartCenter">
                    <div className="ui pointing secondary menu inverted fluid" style={{borderBottom: '1px solid #333'}}>
                        <a className="active item" data-tab="first" style={{display: 'inline-block'}}>ORGANIZE</a>
                    </div>
                </div>

                <div className="flexItem11 verticalScroll" style={{padding: 10, marginTop: 7}}>

                    <div>
                        <div>
                            <div className="ui inverted segment tiny">
                                <div>
                                    <div className="ui grey inverted statistic tiny">
                                        <div className="value">
                                            {PhotoMetaUtility.convertPixelSizeOnlySize(pixels)}
                                        </div>
                                        <div className="label">
                                            {PhotoMetaUtility.convertPixelSizeOnlyUnit(pixels)}pixels
                                        </div>
                                    </div>
                                    <div className="ui grey inverted statistic tiny">
                                        <div className="value">
                                            {itemModel.meta_exif_iso}
                                        </div>
                                        <div className="label">
                                            ISO
                                        </div>
                                    </div>
                                    <div className="ui grey inverted statistic tiny">
                                        <div className="value">
                                            {itemModel.meta_exif_focal_length}
                                        </div>
                                        <div className="label">
                                            focal length
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ui grey inverted statistic tiny">
                                        <div className="value">
                                            f/{itemModel.meta_exif_aperture}
                                        </div>
                                        <div className="label">
                                            apecture
                                        </div>
                                    </div>
                                    <div className="ui grey inverted statistic tiny">
                                        <div className="value">
                                            {itemModel.meta_exif_shutter_speed} sec
                                        </div>
                                        <div className="label">
                                            shutter speed
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {this.genSideViewHeader("ATTRIBUTES")}

                        <div style={this.style.contentBox}>

                            {this.genSideViewSubHeader(
                                <div>
                                    Global
                                    <span style={{marginLeft: 5, fontSize: 12, color: '#666'}}>
                                    entire library
                                </span>
                                </div>
                            )}

                            {UiLayoutTemplate.sideViewDivider}

                            {/*{this.genSideViewOneRaw("Pick Flag", (*/}
                            {/*    <PinButtonPictureViewComponent*/}
                            {/*        collectionModel={this.props.collectionModel}*/}
                            {/*        itemModel={itemModel}*/}
                            {/*        attributeScope={ItemModel.masters.attributeScope.global}*/}
                            {/*        pinStyle={Object.assign({boxSizing:'border-box'})}*/}
                            {/*    />*/}
                            {/*))}*/}
                            {/*{this.genSideViewOneRaw("Rating", (*/}
                            {/*    <RatingItemComponent listeningModelPK={itemModel}*/}
                            {/*                         collectionModel={this.props.collectionModel}*/}
                            {/*                         itemModel={itemModel}*/}
                            {/*                         attributeScope={ItemModel.masters.attributeScope.global}/>*/}
                            {/*))}*/}
                        </div>

                        <div style={this.style.contentBox}>

                            {this.genSideViewSubHeader(
                                <div>
                                    Local
                                    <span style={{marginLeft: 5, fontSize: 12, color: '#666'}}>
                                    only in collection #{this.props.collectionModel._id} {this.props.collectionModel.collectionName}
                                </span>
                                </div>
                            )}

                            {UiLayoutTemplate.sideViewDivider}

                            {/*{this.genSideViewOneRaw("Pick Flag", (*/}
                            {/*    <PinButtonPictureViewComponent*/}
                            {/*        collectionModel={this.props.collectionModel}*/}
                            {/*        itemModel={itemModel}*/}
                            {/*        attributeScope={ItemModel.masters.attributeScope.local}*/}
                            {/*        pinStyle={Object.assign({boxSizing:'border-box'})}*/}
                            {/*    />*/}
                            {/*))}*/}
                            {/*{this.genSideViewOneRaw("Rating", (*/}
                            {/*    <RatingItemComponent listeningModelPK={itemModel}*/}
                            {/*                         collectionModel={this.props.collectionModel}*/}
                            {/*                         itemModel={itemModel}*/}
                            {/*                         attributeScope={ItemModel.masters.attributeScope.local}/>*/}
                            {/*))}*/}
                        </div>

                        {this.genSideViewHeader("LABELING")}
                        <div>
                            {/*<PropertyKeywordComponent itemModel={this.props.itemModel} />*/}
                        </div>

                        {this.genSideViewHeader("PROFILE / EXIF")}
                        <div style={this.style.contentBox}>
                            {this.genSideViewOneRaw("Dimensions", `${itemModel.meta_exif_image_width} x ${itemModel.meta_exif_image_Height}`)}
                            {this.genSideViewOneRaw("Cropped", "")}
                            {this.genSideViewOneRaw("Created", itemModel.meta_exif_create_date)}
                            {this.genSideViewOneRaw("Modified", itemModel.meta_exif_modify_date)}
                            {this.genSideViewOneRaw("Orientation", itemModel.meta_exif_camera_orientation)}
                            {this.genSideViewOneRaw("Exposure", itemModel.meta_exif_exposure_mode)}
                            {this.genSideViewOneRaw("Focal Length", itemModel.meta_exif_focal_length)}
                            {this.genSideViewOneRaw("Brightness Value", itemModel.meta_exif_brightness)}
                            {this.genSideViewOneRaw("Exposure Bias", "")}
                            {this.genSideViewOneRaw("ISO Speed Rating", itemModel.meta_exif_iso)}
                            {this.genSideViewOneRaw("Aperture", itemModel.meta_exif_aperture)}
                            {this.genSideViewOneRaw("Shutter Speed", itemModel.meta_exif_shutter_speed)}
                            {this.genSideViewOneRaw("Flash", itemModel.meta_exif_flash)}
                            {this.genSideViewOneRaw("Exposure Program", itemModel.meta_exif_exposure_program)}
                            {this.genSideViewOneRaw("Metering Mode", itemModel.meta_exif_metering_mode)}
                            {this.genSideViewOneRaw("Make", itemModel.meta_exif_make)}
                            {this.genSideViewOneRaw("Model", itemModel.meta_exif_camera_model_name)}
                            {this.genSideViewOneRaw("Lense", itemModel.meta_exif_lens_model)}
                            {this.genSideViewOneRaw("GPS", `${itemModel.meta_exif_latitude} ${itemModel.meta_exif_longitude}`)}
                        </div>

                        {this.genSideViewHeader("FILE")}
                        <div style={this.style.contentBox}>
                            {/*{this.genSideViewOneRaw("Size", FileUtility.convertMBUnitFileSize(itemModel))}*/}
                            {/*{this.genSideViewOneRaw("Extension", itemModel.file_entity_id)}*/}
                            {this.genSideViewOneRaw("Created", DateUtility.getDateFormatForLocaleByLanguageMaster(this.props.languageMaster, itemModel.date_created))}
                            {this.genSideViewOneRaw("Modified", DateUtility.getDateFormatForLocaleByLanguageMaster(this.props.languageMaster, itemModel.date_updated))}
                        </div>
                    </div>

                </div>


            </div>
        )
    }

}