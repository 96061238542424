import CollectionModel from "./CollectionModel";
import DateUTCModel from "../DateUTCModel";

export default class BoxModel{

    entityId;
    boxName;
    boxType;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();
    childBoxes = [];
    childCollections = [];
    parentBoxes = [];

    /**
     *
     * @param data
     * @return {BoxModel}
     */
    static deserialize(data){
        let newModel = new BoxModel();

        if(data){
            newModel.entityId = data.entity_id;
            newModel.boxName = data.box_name;
            newModel.boxType = data.box_type;
            newModel.dateCreated = DateUTCModel.deserialize(data.date_created);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);
            if(data.childBoxes && data.childBoxes.length){
                newModel.childBoxes = data.childBoxes.map( Box => BoxModel.deserialize(Box));
            }
            if(data.collections && data.collections.length){
                newModel.childCollections = data.collections.map( collection => CollectionModel.deserialize(collection));
            }
            if(data.parentBoxes && data.parentBoxes.length){
                newModel.parentBoxes = data.parentBoxes.map( Box => BoxModel.deserialize(Box));
            }
        }

        return newModel;
    }

}