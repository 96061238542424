import {Component} from 'react';
import {RouteUtility} from "./RouteUtility";
import * as PropTypes from 'prop-types';
import ServiceInfoConstant from "../constant/ServiceInfoConstant";

export class RouteListenerComponent extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    getPageTitle() {
        return `${ServiceInfoConstant.serviceName} - プライベートギャラリー`
    }

    setPageTitle() {
        document.title = this.getPageTitle()
    }

    componentDidMount() {
        // console.log('RouteListenerComponent-componentDidMount-nextProps', this.props)
        RouteUtility.updateRouteState(this.props)
        this.updateWorkflow()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log('RouteListenerComponent-componentWillReceiveProps-nextProps', nextProps)
        return RouteUtility.updateRouteState(nextProps)
    }

    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            console.debug('props are up to date by updating routeState')
            this.forceUpdate(async() => {
                await this.updateWorkflow()
            })
        } else {
            console.debug('props are not up to date by updating routeState')
        }
    }


    /**
     * DO NOT OVERRIDE IT
     * @returns {Promise<void>}
     */
    async updateWorkflow(){
        await this.updateByProps()
        this.setPageTitle()
    }

    async updateByProps(){

    }
}