import React from 'react';
import * as AuthAPI from "../../api/loops4/AuthAPI";
import {RouteUtility} from "../RouteUtility";
import {RouteDictionary} from "../RouteDictionary";

import {Link} from "react-router-dom";
import SigningValidator from "./SigningValidator";
import {ReduxMainStore} from "../../state/redux/store/ReduxMainStore";
import {renderRedirectPage} from "./UnSignedAccountRedirectView";

class SignedAccountUserValidator extends SigningValidator{
    async validateTheAccount(reduxState, routeState){
        const accessTokenModel =  reduxState.auth.user;

        const isSignedIn = await AuthAPI.isValidAccessToken({
            accessTokenModel: accessTokenModel
        })

        return isSignedIn
    }

    renderRedirectPage(reduxState, routeState) {
        const redirectPage = RouteUtility.getPathname(RouteDictionary.signIn, {

        }, {

        })

        return renderRedirectPage(redirectPage)
    }
}

export const signedAccountUserValidator = new SignedAccountUserValidator()