import {endpointLoops4} from "../../constant/APIConstant";
import AccountUserModel from "../../model/entity/account/AccountUserModel";
import StoreModel from "../../model/entity/release/StoreModel";
import ReleaseCollectionModel from "../../model/entity/release/ReleaseCollectionModel";
import BoxModel from "../../model/entity/library/BoxModel";
import CollectionModel from "../../model/entity/library/CollectionModel";
import ItemModel from "../../model/entity/library/ItemModel";
import ReleaseItemModel from "../../model/entity/release/ReleaseItemModel";
import CommerceProductModel from "../../model/entity/commerce/CommerceProductModel";
import AccountSubscriberModel from "../../model/entity/account/AccountSubscriberModel";
import {CompactOrderHistoryRow} from "../../route/publish/collection/content/order_history/viewer/CompactOrderHistoryRow";

const RPC_API_ENDPOINT = `${endpointLoops4}/api/loops4rpc`

/**
 * @return {Loops4RPCResult<T>}
 */
export class Loops4RPCResult {
    constructor(response, error) {
        if (error) {
            this.version = 'UNKNOWN'
            this.method = 'UNKNOWN'
            this.data = {}
            /**
             * @type {T}
             */
            this.model = undefined
            this.errors = [
                {
                    error_code: 'FETCH_ERROR',
                    error_message: error.message
                },
                error
            ]
        } else {
            this.version = response.body['version']
            this.method = response.body['method']
            this.data = response.body['data']
            this.model = response.model
            this.errors = response.body['errors'] || []
        }

        this.httpResponse = response
    }

    /**
     * @return {boolean}
     */
    isError() {
        return this.errors.length > 0
    }

    /**
     * @return {string}
     */
    getErrorMessage() {
        if (this.isError() && this.errors[0]['error_message']) {
            return this.errors[0]['error_message']
        } else if (this.isError()) {
            return this.errors[0].toString()
        } else {
            return 'Something error but not found detail'
        }
    }

    /**
     * @return {string}
     */
    getErrorCode() {
        if (this.isError() && this.errors[0]['error_code']) {
            return this.errors[0]['error_code']
        } else if (this.isError()) {
            return 500
        } else {
            return 'Something error but not found detail'
        }
    }
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @return {{Authorization: string}}
 */
export const getAuthorizationHeader = (accessTokenModel) => {
    return {
        Authorization: `${accessTokenModel.tokenType} ${accessTokenModel.accessToken}`
    }
}

export const validateParameter = (parameterSet) => {
    Object.keys(parameterSet)
        .forEach(key => {
            const value = parameterSet[key]
            if(value === null || value === undefined){
                const errorMessage = `The parameter has empty value:: key - ${key} :: ${JSON.stringify(parameterSet)}`
                console.error(errorMessage)
                throw Error(errorMessage)
            }
        })
}

/**
 * @param {object} headers
 * @param {any} body
 * @return {Promise<Loops4RPCResult<T>>>}
 */
export const requestToRPC = async ({headers, body, deserializer = (data) => data }) => {
    const RPC_REQUEST = {
        headers,
        body
    }

    // console.log({RPC_REQUEST: RPC_REQUEST})

    return await fetch(RPC_API_ENDPOINT, {
        method: 'POST',
        headers: Object.assign(headers || {}, {'Content-Type': 'application/json'}),
        body: JSON.stringify(body),
    })
        .then(response => {
            const jsonResult = response.json()
            if(jsonResult && jsonResult.data){
                response.model = deserializer(response.body.data)
            }
            return new Loops4RPCResult(response, null)
        })
        .catch(err => {
            return new Loops4RPCResult({
                options: err.options,
                response: err.response,
            }, err)
        })
        .then(loops4RPCResult => {
            // console.log({
            //     RPC_RESULT: loops4RPCResult,
            //     RPC_REQUEST
            // })

            return loops4RPCResult
        })
}

export const createAccount = async ({
                                        accountId,
                                        accountPassword,
                                        firstName,
                                        lastName,
                                        language,
}) => {
    return requestToRPC({
        headers: {},
        body: {
            "method": "PutAccountUser",
            "version": "1.0",
            "parameter": {
                "account_id": accountId,
                "account_password": accountPassword,
                "first_name": firstName,
                "last_name": lastName,
                "language": language
            }
        }
    })
}

/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} accountId AccountUser-account_id, AccountReleaseSubscriber-access_key
 * @return {Promise<Loops4RPCResult<GetAccountInformationByAuthSessionRPCRequest>>}
 */
export const getAccountInformationByAuthSession_WithAccountUserModel = async ({accessTokenModel, accountId}) => {
    validateParameter({accessTokenModel, accountId})

    return await requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetAccountInformationByAuthSession",
            "version": "1.0",
            "parameter": {
                "account_id": accountId,
            }
        },
        deserializer: (data) => AccountUserModel.deserialize(data) 
    })
}
/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} accountId AccountUser-account_id, AccountReleaseSubscriber-access_key
 * @return {Promise<Loops4RPCResult>}
 */
export const getAccountInformationByAuthSession_WithAccountSubscriberModel = async ({accessTokenModel, accountId}) => {
    validateParameter({accessTokenModel, accountId})

    return await requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetAccountInformationByAuthSession",
            "version": "1.0",
            "parameter": {
                "account_id": accountId,
            }
        },
        deserializer: (data) => AccountSubscriberModel.deserialize(data)
    })
}
/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} subscriberId
 * @return {Promise<Loops4RPCResult>}
 */
export const getAccountReleaseSubscriber = async ({accessTokenModel, subscriberId}) => {
    validateParameter({accessTokenModel, subscriberId})

    return await requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetAccountReleaseSubscriber",
            "version": "1.0",
            "parameter": {
                "entity_id": subscriberId,
            }
        },
        deserializer: (data) => AccountSubscriberModel.deserialize(data)
    })
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} libraryBoxId
 * @param {string} name
 * @return {Promise<Loops4RPCResult>}
 */
export const createLibraryBox = async ({accessTokenModel, libraryBoxId, name}) => {
    validateParameter({accessTokenModel, libraryBoxId, name})

    return await requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "PutLibraryBox",
            "version": "1.0",
            "parameter": {
                "library_box_entity_id": libraryBoxId,
                "box_type": "BoxType",
                "box_name": name
            }
        },
        deserializer: (data) => BoxModel.deserialize(data)
    })
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} entityId
 * @return {Promise<Loops4RPCResult>}
 */
export const getLibraryBox = async ({accessTokenModel, entityId}) => {
    validateParameter({accessTokenModel, entityId})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetLibraryBox",
            "version": "1.0",
            "parameter": {
                "entity_id": entityId
            }
        },
        deserializer: (data) => BoxModel.deserialize(data)
    })
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} libraryBoxId
 * @param {string} name
 * @return {Promise<Loops4RPCResult>}
 */
export const createLibraryCollection = async ({accessTokenModel, libraryBoxId, name}) => {
    validateParameter({accessTokenModel, libraryBoxId, name})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "PutLibraryCollection",
            "version": "1.0",
            "parameter": {
                "library_box_entity_id": libraryBoxId,
                "collection_type": "CollectionType",
                "collection_name": name
            }
        },
        deserializer: (data) => CollectionModel.deserialize(data)
    })
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} entityId
 * @return {Promise<Loops4RPCResult>}
 */
export const getLibraryCollection = async ({accessTokenModel, entityId}) => {
    validateParameter({accessTokenModel, entityId})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetLibraryCollection",
            "version": "1.0",
            "parameter": {
                "entity_id": entityId
            }
        },
        deserializer: (data) => CollectionModel.deserialize(data)
    })
}


/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} libraryCollectionId
 * @param {string} name
 * @return {Promise<Loops4RPCResult>}
 */
export const createLibraryItem = async ({accessTokenModel, libraryCollectionId, name}) => {
    validateParameter({accessTokenModel, libraryCollectionId, name})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "PutLibraryItem",
            "version": "1.0",
            "parameter": {
                "library_collection_entity_id": libraryCollectionId,
                "item_type": "ItemType",
                "item_name": name
            }
        },
        deserializer: (data) => ItemModel.deserialize(data)
    })
}

/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} releaseCollectionId
 * @return {Promise<Loops4RPCResult>}
 */
export const getReleaseCollection = async ({
                                               accessTokenModel,
                                               releaseCollectionId,
                                           }) => {
    validateParameter({accessTokenModel, releaseCollectionId})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetReleaseCollection",
            "version": "1.0",
            "parameter": {
                "entity_id": releaseCollectionId,
            }
        },
        deserializer: (data) => ReleaseCollectionModel.deserialize(data)
    })
}


/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} releaseItemId
 * @return {Promise<Loops4RPCResult>}
 */
export const getReleaseItem = async ({
                                         accessTokenModel,
                                         releaseItemId,
                                     }) => {
    validateParameter({accessTokenModel, releaseItemId})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetReleaseItem",
            "version": "1.0",
            "parameter": {
                "entity_id": releaseItemId,
            }
        },
        deserializer: (data) => ReleaseItemModel.deserialize(data)
    })
}


/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} releaseItemId
 * @return {Promise<Loops4RPCResult>}
 */
export const createCommerceSaleProductReleaseItem = async ({
                                            accessTokenModel,
                                            releaseItemId,
                                            priceCurrency,
                                            priceAmount,
                                            stockSKUType,
                                            stockQuantity,
                                        }) => {
    validateParameter({accessTokenModel, releaseItemId, priceCurrency, priceAmount, stockSKUType, stockQuantity})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "PutCommerceSaleProductReleaseItem",
            "version": "1.0",
            "parameter": {
                "release_item_entity_id": releaseItemId,
                "price_currency": priceCurrency,
                "price_amount": priceAmount,
                "stock": {
                    "stock_keeping_unit_type": stockSKUType,
                    "quantity": stockQuantity
                }
            }
        },
        deserializer: (data) => CommerceProductModel.deserialize(data)
    })
}

/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} releaseItemId
 * @return {Promise<Loops4RPCResult>}
 */
export const updateCommerceSaleProductReleaseItem = async ({
                                            accessTokenModel,
                                            productId,
                                            priceCurrency,
                                            priceAmount,
                                            stockSKUType,
                                            stockQuantity,
                                        }) => {
    validateParameter({accessTokenModel, productId, priceCurrency, priceAmount, stockSKUType, stockQuantity})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "ModifyCommerceSaleProductReleaseItem",
            "version": "1.0",
            "parameter": {
                "entity_id": productId,
                "price_currency": priceCurrency,
                "price_amount": priceAmount,
                "stock": {
                    "stock_keeping_unit_type": stockSKUType,
                    "quantity": stockQuantity
                }
            }
        },
        deserializer: (data) => CommerceProductModel.deserialize(data)
    })
}


/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} storeId
 * @return {Promise<Loops4RPCResult>}
 */
export const getReleaseStore = async ({
                                          accessTokenModel,
                                          storeId,
                                      }) => {
    validateParameter({accessTokenModel, storeId})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "GetReleaseStore",
            "version": "1.0",
            "parameter": {
                "entity_id": storeId,
            }
        },
        deserializer: (data) => StoreModel.deserialize(data)
    })
}

/**
 *
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} releaseCollectionId
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} language
 * @return {Promise<Loops4RPCResult>}
 */
export const createAccountSubscriber = async ({
                                        accessTokenModel,
                                        releaseCollectionId,
                                        email,
                                        firstName,
                                        lastName,
                                        language ,
}) => {
    validateParameter({accessTokenModel, releaseCollectionId, email, firstName, lastName, language})

    return requestToRPC({
        headers: getAuthorizationHeader(accessTokenModel),
        body: {
            "method": "PutAccountReleaseSubscriber",
            "version": "1.0",
            "parameter": {
                "release_collection_entity_id": releaseCollectionId,
                "email": email,
                "first_name": firstName,
                "last_name": lastName,
                "language": language
            }
        },
        deserializer: (data) => AccountSubscriberModel.deserialize(data)
    })
}
