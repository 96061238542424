import * as React from 'react';
import LanguagePack from "../../LanguagePack";

const NotFoundLangPack = {
    title: new LanguagePack(
        "Not Found 404",
        "存在して無いページ 404",
        "존재하지 않는 페이지 404"
    ),
    addressGuide: new LanguagePack(
        "Accessed address",
        "接続されたホームページのアドレス",
        "접속한 홈페이지 주소"
    ),
    description: new LanguagePack(
        "The address is not valid, please check and retry it again",
        "ホームページのアドレスが有効ではありません。確認して改めて接続して下さい。",
        "홈페이지 주소가 유효하지 않습니다. 확인 후 재시도 해주시길 바랍니다."
    )
}

export default NotFoundLangPack