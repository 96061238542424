import {OrderPriceRPCBody} from "../../../commerce/order/common/OrderPriceRPCBody";

// Package Dir: commerce/order/common
// FileName: OrderCommerceProductReleaseItemEntityRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderCommerceProductReleaseItemEntityRPCBody
// Super types: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderCommerceProductReleaseItemAttributeRPCBody, com.loops4.controller.loops4rpc.model.general.EntityRPCResponse

export class OrderCommerceProductReleaseItemEntityRPCBody {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderPriceRPCBody
         * @type {OrderPriceRPCBody}
         */
        this.bill_price = new OrderPriceRPCBody();
        /**
         *
         * JVM type: kotlin.Int
         * @type {number}
         */
        this.quantity = 0;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderPriceRPCBody
         * @type {OrderPriceRPCBody}
         */
        this.release_price = new OrderPriceRPCBody();

    }
}