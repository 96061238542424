import * as React from "react";
import LanguagePack from "../../LanguagePack";

const OrderRequestLangPack = {
    order:{
        placeOrderButton: new LanguagePack(
            "Place order",
            "注文する",
            "주문하기"
        ),
        total: new LanguagePack(
            "Order total",
            "注文総額",
            "주문 총액"
        ),
        shoppingCart:{
            title: new LanguagePack(
                "Shopping Cart",
                "ショッピングカート",
                "쇼핑카트"
            ),
            guide: new LanguagePack(
                "Please confirm your photos",
                "注文写真を確認して下さい。",
                "주문할 사진을 확인해주세요."
            )
        },
        checkout:{
            title: new LanguagePack(
                "Payment",
                "決済",
                "결제"
            ),
            guide: new LanguagePack(
                "It's almost done, please confirm your bill",
                "注文がほとんど終わりました。請求詳細を確認して決済を行って下さい。",
                "주문이 거의 다 끝났습니다. 주문상세를 확인 후 결제를 진행해주세요."
            )
        },
        items:{
            title: new LanguagePack(
                "Purchasing Products",
                "注文商品",
                "주문 상품"
            ),
            guideForNoneSelecting: new LanguagePack(
                "You did not pick up any photos to purchase, please go back to selection and pick up some photos",
                "購入する写真を選択しておりません。写真選択画面に戻り購入する写真を選択して下さい。",
                "아직 구매할 사진을 선택하지 않으셨습니다. 사진선택 화면으로 돌아가 구매할 사진을 선택해주세요."
            ),
            guideForMoreConsidering: new LanguagePack(
                "You have not selected any items. Please select them",
                "ショッピングカートに選択した商品がございません。もう少し選択を考えますか？",
                "쇼핑카트에 들어있는 상품이 없습니다. 더 생각해보시겠습니까?"
            ),
            button: new LanguagePack(
                "Back to selection",
                "写真選択へ戻る",
                "사진선택으로 돌아가기"
            )
        }
    },
    items: {
        header: {
            watermarkPreview: new LanguagePack(
                "Watermark Preview",
                "ウォーターマークプレビュー",
                "워터마크 미리보기"
            ),
            originalPreview: new LanguagePack(
                "Original",
                "オリジナル",
                "원본"
            ),
            description: new LanguagePack(
                "Product Description",
                "商品詳細",
                "상품 상세"
            ),
            unitPrice: new LanguagePack(
                "Unit Price",
                "価格",
                "가격"
            ),
            quantity: new LanguagePack(
                "Quantity",
                "数量",
                "수량"
            ),
            extendedPrice: new LanguagePack(
                "Extended Price",
                "小計",
                "소계"
            ),
        },
        contents: {
            productCode: new LanguagePack(
                "Product Code",
                "商品コード",
                "상품 코드"
            ),
            productName: new LanguagePack(
                "Product Name",
                "商品名",
                "상품 이름"
            ),
            totalPrice: new LanguagePack(
                "Total",
                "総額",
                "총액"
            ),
            removeProduct: new LanguagePack(
                "Remove This Product From Cart",
                "ショッピングカートから削除",
                "쇼핑카트에서 제거하기"
            )
        }
    },
    shipping:{
        title: new LanguagePack(
            "Billing Contacts",
            "決済者連絡先",
            "결재자 연락처"
        ),
        guide: new LanguagePack(
            "Required for invoice and customer support",
            "請求書及び顧客対応のため必要です",
            "청구서 및 고객대응을 위해 필요합니다"
        ),
        email: {
            title: new LanguagePack(
                "Email",
                "メールアドレス",
                "이메일 주소"
            ),
            guide: new LanguagePack(
                "It is fixed to your inviting Email address for security",
                "セキュリティーのため招待メールアドレスに固定されます",
                "보안을 위해 초대 이메일 주소로 고정되었습니다"
            )
        },
        name: {
            title: new LanguagePack(
                "Name",
                "氏名",
                "성명"
            ),
            description: {
                firstName: new LanguagePack(
                    "First Name(Greg)",
                    "名前（例、拓哉）",
                    "이름(수현)"
                ),
                lastName: new LanguagePack(
                    "Last Name(John)",
                    "名字（例、木村）",
                    "성(김)"
                ),
            }
        },
        address: {
            title: new LanguagePack(
                "Street Address",
                "住所",
                "주소"
            ),
            description: new LanguagePack(
                "ex) ",
                "例）江戸川区平井２−１３−１８尾頭２階",
                "예) 경상남도 사천시 용강동 430"
            )
        },
        city: {
            title: new LanguagePack(
                "City",
                "都市名",
                "도시"
            ),
            description: new LanguagePack(
                "ex) New York",
                "例）東京都",
                "예) 서울시"
            )
        },
        postalCode: {
            title: new LanguagePack(
                "Postal code (ZIP)",
                "郵便番号",
                "우편 번호"
            ),
            description: new LanguagePack(
                "ex) 133223 (without hyphen)",
                "例）1320035（特集記号無し）",
                "예) 1320035"
            )
        },
        country: new LanguagePack(
            "Country",
            "国",
            "국가"
        ),
        countryGuide: new LanguagePack(
            "Select Country",
            "国を選択して下さい",
            "국가를 선택해주세요"
        ),
        countries: {
            unitedStates:new LanguagePack(
                "United States",
                "アメリカ",
                "미국"
            ),
            japan:new LanguagePack(
                "Japan",
                "日本",
                "일본"
            ),
            southKorea:new LanguagePack(
                "South Korea",
                "韓国",
                "대한민국"
            )
        }
    },
    payment: {
        title: new LanguagePack(
            "Payment",
            "支払い",
            "지불"
        ),
        paymentMethodDescription: new LanguagePack(
            "These payment is available in your region",
            "お客様の値域では下記のような決済手段が使用出来ます。",
            "고객님 지역에서는 아래와 같은 결재수단이 이용 가능합니다."
        ),
        paymentMethods: {
            card: new LanguagePack(
                "Credit/Debit Card",
                "クレジットカード・デビットカード",
                "신용카드/데빗카드"
            ),
            transfer: new LanguagePack(
                "Transfer",
                "銀行口座振込",
                "은행계좌이체"
            )
        },
        transfer:{
          guide : new LanguagePack(
              "Make an transfer by following description",
              "下記の案内に従い、弊社口座へ振り込んで下さい。",
              "뒤에 따르는 안내에 따라 은행계좌에 계좌이체해주세요"
          ),
          whatHappensNext : new LanguagePack(
              "Since your transfer is confirmed, You will be able to using what you ordered. It took 1~2 business days until confirming",
              "お客様の入金が確認出来た次第、注文した商品のご利用が可能になります。確認まで営業日１〜２日かかります。",
              "고객님의 입금이 확인되는대로, 주문한 상품의 이용이 가능해집니다 확인까지 영업일 1~2일정도 소요됩니다."
          ),
          transferInformation : {
              bank: {
                  title: new LanguagePack(
                      "Bank",
                      "銀行",
                      "은행"
                  ),
                  description: new LanguagePack(
                      "SMBC Bank(三井住友銀行)",
                      "三井住友銀行(SMBC)",
                      "미쓰이스미토모은행(三井住友銀行)"
                  )
              },
              branch: {
                  title: new LanguagePack(
                      "Branch",
                      "支店",
                      "지점"
                  ),
                  description: new LanguagePack(
                      "254, KAMEIDO(亀戸支店) Branch",
                      "254, 亀戸支店",
                      "254, KAMEIDO(亀戸支店) Branch"
                  )
              },
              accountType: {
                  title: new LanguagePack(
                      "Account Type",
                      "口座種類",
                      "계좌종류"
                  ),
                  description: new LanguagePack(
                      "Transfering",
                      "普通口座",
                      "보통계좌"
                  )
              },
              accountNumber: {
                  title: new LanguagePack(
                      "Account Number",
                      "口座番号",
                      "계좌번호"
                  ),
                  description: new LanguagePack(
                      "7958821",
                      "7958821",
                      "7958821"
                  )
              },
              accountHolder: {
                  title: new LanguagePack(
                      "Account Holder",
                      "口座名義者",
                      "계좌 명의자"
                  ),
                  description: new LanguagePack(
                      "Post Visioning YEO GYEONGHO",
                      "ポスト　ビジョニング　ヨ　ギョンホ　Post Visioning YEO GYEONGHO",
                      "Post Visioning YEO GYEONGHO"
                  )
              },
              depositor: {
                  title: new LanguagePack(
                      "Depositor Name",
                      "入金者名",
                      "입금자명"
                  ),
                  description: new LanguagePack(
                      "Post Visioning YEO GYEONGHO",
                      "ポスト　ビジョニング　ヨ　ギョンホ　Post Visioning YEO GYEONGHO",
                      "Post Visioning YEO GYEONGHO"
                  )
              }
          }
        },
        card: {
            number: new LanguagePack(
                (
                    <div>
                        CARD NUMBER<br/>
                        (Credit Card / Debit Card)
                    </div>

                ),
                (
                    <div>
                        カード番号<br/>
                        （クレジットカード・デビットカード）
                    </div>

                ),
                (
                    <div>
                        카드 번호<br/>
                        (신용카드 / 데빗카드)
                    </div>

                )
            ),
            cvc: {
                    title: new LanguagePack(
                        (<span>*SECURITY CODE<br/>(CVC)</span>),
                        (<span>セキュリティーコード<br/>(CVC)</span>),
                        (<span>*비밀코드<br/>(CVC)</span>)
                    ),
                    guide: {
                        title: new LanguagePack(
                            `* Or “CVV” or “CID”`,
                            `* 又は“CVV”、“CID”`,
                            `* 또는 “CVV”, “CID”`
                            ),
                        description: new LanguagePack(
                            (<div>A 3-digit code if it is on back,<br/>
                             A 4-digit code if it is on front</div>),
                            (<div>裏側であれば３桁の数字コード,<br/>
                             前側であれば4桁の数字コード</div>),
                            (<div>뒷면이라면 3자리 숫자코드,<br/>
                             앞면이라면 4자리 숫자코드</div>)
                        )
                    }
            },
            expiration: {
                title: new LanguagePack(
                    "EXPIRATION",
                    "有効期間",
                    "유효기간"
                ),
                month: new LanguagePack(
                    "Month",
                    "月",
                    "월"
                ),
                year: new LanguagePack(
                    "Year",
                    "年",
                    "년"
                )
            },
            holder: new LanguagePack(
                "CARD HOLDER",
                "所有者氏名",
                "소유자 성명"
            )
        }
    }
}

export default OrderRequestLangPack