import * as React from "react";
import * as PropTypes from "prop-types";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import {PUT_RELEASE_COLLECTION_PREVIEW__V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {PutReleaseCollectionPreviewRPCRequest} from "../../../../../api/loops4/model/release/collection/PutReleaseCollectionPreviewRPCRequest";

export default class ReleaseItemPreviewGenerateForm extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        releaseCollectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }


    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    requestGeneration = async (e) => {
        const param = new PutReleaseCollectionPreviewRPCRequest()
        param.entity_id = this.props.releaseCollectionModel.entityId

        const rpcResult = await PUT_RELEASE_COLLECTION_PREVIEW__V1_0({
            headers: getAuthorizationHeader(this.props.accessTokenModel),
            paramBody: param
        })

        if(rpcResult.isError()){
            alert("Failed to request")
        } else {
            alert("Succeed to request")
        }
    }

    render() {
        return (
            <form className="ui form" onSubmit={this.requestSetAllPrice} >
                <div className="two fields">
                    <div className="field">
                        <div className="ui message">
                            <label style={{marginRight: 10}}>
                                Request Preview Generation
                            </label>
                            <div className="ui button green mini"
                                 style={{verticalAlign: 'middle', margin: 0}}
                                 onClick={this.requestGeneration}
                            >
                                Generate Preview
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}