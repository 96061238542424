import * as React from "react";
import LanguagePack from "../../LanguagePack";
import ServiceInfoConstant from "../../../constant/ServiceInfoConstant";

const OrderHistoryLangPack = {
    orderHistory: {
        title: new LanguagePack(
            "Orders",
            "注文履歴",
            "주문 이력"
        ),
        headers: {
            orderCode: new LanguagePack(
                "Order Code",
                "注文コード",
                "주문코드"
            ),
            date: new LanguagePack(
                "Date",
                "日付",
                "날짜"
            ),
            state: new LanguagePack(
                "State",
                "状態",
                "상태"
            ),
            products: new LanguagePack(
                "Products",
                "商品数",
                "상품수"
            )
        },
        content: {
            none: new LanguagePack(
                "NONE",
                "無",
                "없음"
            ),
            orderDate: new LanguagePack(
                "Order Date",
                "注文日付",
                "주문일자"
            ),
            expirationDate: new LanguagePack(
                "Expiration Date",
                "満了日付",
                "만료일자"
            ),
            orderState: {
                accepted:　new LanguagePack(
                    "Accepted",
                    "承り",
                    "접수됨"
                ),
                ordering: new LanguagePack(
                    "Ordering",
                    "注文中",
                    "주문중"
                ),
                refunded: new LanguagePack(
                    "Refunded",
                    "払い戻し",
                    "환불됨"
                ),
                inRefunding:　new LanguagePack(
                    "In Refunding",
                    "払い戻し手続き中",
                    "환불 처리 중"
                ),
                rejected: new LanguagePack(
                    "Rejected",
                    "拒否",
                    "거부됨"
                ),
                waitingPayment: new LanguagePack(
                    "Waiting Payment",
                    "支払い待ち",
                    "지불 대기"
                ),
                delivered: new LanguagePack(
                    "Delivered",
                    "納品完了",
                    "납품 완료"
                ),
                unknownState: new LanguagePack(
                    "Unknown State",
                    "不明",
                    "불명"
                )
            },
            shippingState:{
                delivered: new LanguagePack(
                    "Delivered",
                    "配達済",
                    "배송완료"
                ),
                onDelivering: new LanguagePack(
                    "Delivering",
                    "配達中",
                    "배송중"
                ),
                preparing: new LanguagePack(
                    "Preparing",
                    "準備中",
                    "준비중"
                ),
                returned: new LanguagePack(
                    "Returned",
                    "返却済",
                    "반환됨"
                ),
                onReturning: new LanguagePack(
                    "On Returning",
                    "返却中",
                    "반환중"
                ),
                unknownState: new LanguagePack(
                    "Unknown State",
                    "不明",
                    "불명"
                )
            },
            shippingHeader:{
                deliveryCompany: new LanguagePack(
                    "Delivery Company",
                    "配送会社",
                    "배송회사"
                ),
                trackingCode: new LanguagePack(
                    "Tracking Code",
                    "追跡コード",
                    "송장코드"
                ),
                state: new LanguagePack(
                    "State",
                    "状態",
                    "상태"
                ),
                due: new LanguagePack(
                    "Due / Expiration",
                    "予定日 / 満了日",
                    "예정일 / 만료일"
                )
            },
            buttons: {
                browse: new LanguagePack(
                    "Browsing",
                    "詳細",
                    "상세보기"
                ),
                downloadAll: new LanguagePack(
                    "Download All",
                    "全てダウンロード",
                    "전체 다운로드"
                ),
                tracking: new LanguagePack(
                    "Tracking",
                    "トラッキング",
                    "추적"
                )
            }
        },
        guide: {
            noneOrders: new LanguagePack(
                "You have not placed order, please select photos in selection",
                "注文履歴がございません。写真選択画面に戻り購入する写真を選択して下さい。",
                "주문 이력이 없습니다. 사진 선택화면으로 돌아가 사진을 선택해주세요."
            ),
            expiration: {
                title: new LanguagePack(
                    "About Expiration",
                    "満了について",
                    "만료에 대해"
                ),
                content: new LanguagePack(
                    (
                        <span>
                            By <a>terms of use</a> that you agreed,
                            You can not use purchased photos anymore if the expiration date is over.<br/>
                            Please download and keep it on safety place before expiration.
                        </span>
                    ),
                    (
                        <span>
                            ご同意を頂いた使用約款より,満了日付を超えた商品はダウンロードすることが出来ません。<br/>
                            必ず満了日付前にダウンロードし、安全なところへ保管して下さい。
                        </span>
                    ),
                    (
                        <span>
                            동의한 이용정책에 따라, 만료일을 초과한 경우 구매한 상품은 더이상 다운로드 할 수 없습니다.<br/>
                            반드시 만료일 전에 다운로드해서 안전한 곳에 보관하시기를 바랍니다.
                        </span>
                    )
                )

            },
            refund: {
                title: new LanguagePack(
                    "About refund",
                    "支払い戻しについて",
                    "환불에 관해"
                ),
                content: new LanguagePack(
                    (
                        <span>
                            By terms of use that you agreed, refund needs permit of your seller.<br/>
                            If you have a trouble with {ServiceInfoConstant.serviceName} system problem, please contact us <a>concierge@ating.me</a>
                        </span>
                    ),
                    (
                        <span>
                            ご同意を頂いた使用約款より,支払い戻しについては販売者の同意が必要です。<br/>
                            もしも、{ServiceInfoConstant.serviceName}のシステム上の問題がある場合は我々へ連絡をお願いします。<a>concierge@ating.me</a>
                        </span>
                    ),
                    (
                        <span>
                            동의한 이용정책에 따라, 환불을 위해서는 판매자의 동의가 필요합니다.<br/>
                            만약 {ServiceInfoConstant.serviceName}의 시스템상의 문제를 겪고 계시다면 우리에게 연락을 부탁드립니다.  <a>concierge@ating.me</a>
                        </span>
                    )
                )

            }
        }
    }
}

export default OrderHistoryLangPack