import {OrderBillRPCBody} from "../../../commerce/order/common/OrderBillRPCBody";

// Package Dir: commerce/order/refund_commerce_order
// FileName: RefundCommerceOrderRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.refund_commerce_order.RefundCommerceOrderRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderCommerceProductReleaseItemBody

export class RefundCommerceOrderRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillRPCBody
         * @type {OrderBillRPCBody}
         */
        this.bill = new OrderBillRPCBody();
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.order_state = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.order.common.OrderCommerceProductReleaseItemEntityRPCBody>
         * @type {array<OrderCommerceProductReleaseItemEntityRPCBody>}
         */
        this.products = [];
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.order.common.OrderCommerceRecordReleaseItemEntityRPCBody>
         * @type {array<OrderCommerceRecordReleaseItemEntityRPCBody>}
         */
        this.release_items = [];
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.order.common.OrderRequestedShippingEntityRPCBody>
         * @type {array<OrderRequestedShippingEntityRPCBody>}
         */
        this.shipping = [];

    }
}