import * as React from "react";
import * as PropTypes from "prop-types";

export class TableBodyCell extends React.Component {

    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.element),
        cellStyles: PropTypes.array,
    }

    render() {
        const renderedColumns = this.props.columns.map((column, index) => {
            const curStyle = this.props.cellStyles ? (this.props.cellStyles[index] ? this.props.cellStyles[index] : {}) : {}

            return (
                <td style={curStyle}>
                    {column}
                </td>
            )
        })

        return (
            <tr>
                {renderedColumns}
            </tr>

        )
    }
}