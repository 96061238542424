import * as React from "react";
import TreeBrowsingNavigator from "./TreeBrowsingNavigator";
import * as PropTypes from "prop-types";

export default class LibraryNavigatorState extends React.Component {

    static propTypes = {
        rootLibraryBoxEntityId: PropTypes.string.isRequired,
        accessTokenModel: PropTypes.object.isRequired,
    }


    REFS_SET = {};


    const = {
        treeStep: 15,
        titleStyle : {fontWeight:200, backgroundColor:'#4E4E4E', color:'#EFEFEF', margin:0, padding:10},
        linkStyle: {color:'#C2C5C7', padding:10, paddingLeft:25}
    };

    /* =========================================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/
    /* =========================================================================================================================*/

    render() {
        return (
            <div className="flexContainerColumn " style={{position:'relative', backgroundColor:'#24272D'}}>

                <div className="flexItem00 flexItemAlignStartCenter">
                    <div className="ui pointing secondary menu inverted fluid">
                        <span className="active item" data-tab="first" style={{display: 'inline-block'}}>Browser</span>
                        <span className=" item" data-tab="second" style={{display: 'inline-block'}}>Favorites</span>
                    </div>
                </div>

                <div className="flexItem11 bothScroll">
                    <div style={{backgroundColor:'#313131', width:'100%', height:'100%', border: '2px solid #212121'}}>
                        <TreeBrowsingNavigator rootLibraryBoxEntityId={this.props.rootLibraryBoxEntityId} accessTokenModel={this.props.accessTokenModel}/>
                    </div>
                </div>


                <div className="flexItem00">
                    <div className="flexContainerRow">
                        <div className="flexItem11">
                        </div>
                        <div className="flexItem00">
                            <button className="ui button basic mini compact grey">
                                QUOTES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
