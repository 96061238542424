import * as React from "react";
import {connect} from "react-redux";
import {RouteListenerComponent} from "../../../RouteListener";
import {clearFullSizeDIM, setFullSizeDIM} from "../../../../state/redux/action/UIControlAction";
import * as Loops4RPCAPI from "../../../../api/loops4/Loops4RPCAPI";
import {getAuthorizationHeader} from "../../../../api/loops4/Loops4RPCAPI";
import {CountryContainer} from "../../../../model/container/CountryContainer";
import {CurrencyContainer} from "../../../../model/container/money/CurrencyContainer";
import {LanguageContainer} from "../../../../model/container/LanguageContainer";
import DimLoaderComponent from "../../../../ui_template/DimLoaderComponent";
import HeaderToolbarLayouter from "../../../../ui_template/HeaderToolbarLayouter";
import StoreReleaseFormAddictionMenu from "./StoreReleaseFormAddictionMenu";
import * as PropTypes from "prop-types";
import StoreReleaseSubmitFailResultComponent from "./message/StoreReleaseSubmitFailResultComponent";
import StoreReleaseSubmitSuccessResultComponent from "./message/StoreReleaseSubmitSuccessResultComponent";
import StoreReleaseSubmitRequestingComponent from "./message/StoreReleaseSubmitRequestingComponent";
import ReleaseTargetSelector from "./steps/ReleaseTargetSelector";
import PublishSetupStepProducts from "./steps/PublishSetupStepProducts";
import {RoutePathParam, RouteQueryParam} from "../../../RouteDictionary";
import CollectionModel from "../../../../model/entity/library/CollectionModel";
import StoreModel from "../../../../model/entity/release/StoreModel";
import MainAppLayout from "../../../../screen/layout/MainAppLayout";
import {
    ADD_LIBRARY_ITEM_INTO_RELEASE_COLLECTION__V1_0,
    GET_RELEASE_COLLECTION__V1_0,
    PUT_RELEASE_COLLECTION__V1_0
} from "../../../../api/loops4/caller/loops4RpcCaller";
import {PutReleaseCollectionRPCRequest} from "../../../../api/loops4/model/release/collection/PutReleaseCollectionRPCRequest";
import {AddLibraryItemIntoReleaseCollectionRPCRequest} from "../../../../api/loops4/model/release/collection/relation_item/AddLibraryItemIntoReleaseCollectionRPCRequest";
import {GetReleaseCollectionRPCRequest} from "../../../../api/loops4/model/release/collection/GetReleaseCollectionRPCRequest";

export const SetupStepTab = {
    target: 'target',
    items: 'items',
}

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};

class StoreReleaseFormByLibraryCollection extends RouteListenerComponent {

    static propTypes = {
        language: PropTypes.object,
        accessTokenModel: PropTypes.object,
        routeState: PropTypes.object,
    };


    REFS_SET = {};

    const = {
        defaultValue: {
            productPrice: 10,
            productCurrency: CurrencyContainer.master.USD,
        },
        fixed: 2,
        priceStep: 0.05,
        minimumPrice: 0
    }

    static RELEASE_TARGET_TYPE = {
        INSERT_TO_EXISTING_RELEASE_COLLECTION: 'INSERT_TO_EXISTING_RELEASE_COLLECTION',
        CREATE_NEW_RELEASE_COLLECTION: 'CREATE_NEW_RELEASE_COLLECTION'
    }

    constructor(props) {
        super(props);

        console.log('StoreReleaseFormByLibraryCollection')

        this.state = {
            isLoading: true,
            sourceLibraryCollectionModel: new CollectionModel(),
            sourceStoreModel: new StoreModel(),
            currency: CurrencyContainer.master.USD,
            country: CountryContainer.master.unitedStates,
            language: LanguageContainer.master.english,
            setupStep: {
                subscribers: false,
                settings: false,
                items: true
            },
            setupStepTab: SetupStepTab.target,
            content: null,
            submittingTargetReleaseItemList: [],
            submittingReleaseCollectionName: '',
            submittingReleaseCollectionId: '',
            submittingTargetType: StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.CREATE_NEW_RELEASE_COLLECTION

        }
    }


    async updateByProps() {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const storeId = this.props.routeState.pathParameter[RoutePathParam.storeId]
            const libraryCollectionId = this.props.routeState.queryParameter[RouteQueryParam.sourceCollectionId]

            const rpcResultForStore = await Loops4RPCAPI.getReleaseStore({
                accessTokenModel: this.props.accessTokenModel,
                storeId: storeId,
            })
            const rpcResultForLibraryCollection = await Loops4RPCAPI.getLibraryCollection({
                accessTokenModel: this.props.accessTokenModel,
                entityId: libraryCollectionId,
            })

            let errorMessageForStore = null
            let errorMessageForLibraryCollection = null

            if (rpcResultForStore.isError()) {
                errorMessageForStore = (
                    <div>
                        Request got failed:: {rpcResultForStore.getErrorMessage()}
                    </div>
                )
            }

            if (rpcResultForLibraryCollection.isError()) {
                errorMessageForLibraryCollection = (
                    <div>
                        Request got failed:: {rpcResultForLibraryCollection.getErrorMessage()}
                    </div>
                )
            }

            if (errorMessageForStore !== null || errorMessageForLibraryCollection !== null) {
                this.setState((prevs, props) => {
                    return {
                        ...prevs,
                        isLoading: false,
                        content: (
                            <div>
                                {errorMessageForStore}
                                {errorMessageForLibraryCollection}
                            </div>
                        )
                    }
                })
            } else {
                /**
                 * @type {StoreModel}
                 */
                const storeModel = rpcResultForStore.model

                /**
                 * @type {CollectionModel}
                 */
                const libraryCollectionModel = rpcResultForLibraryCollection.model

                this.setState((prevs, props) => {
                    return {
                        ...prevs,
                        isLoading: false,
                        content: null,
                        sourceStoreModel: storeModel,
                        sourceLibraryCollectionModel: libraryCollectionModel,
                        submittingTargetReleaseItemList: libraryCollectionModel.items,
                        submittingReleaseCollectionName: libraryCollectionModel.collectionName
                    }
                })
            }
        });
    }

    getTotalPrice(products) {
        // return PublishSubscriberAction.summarizeTotalPriceOfProducts(products);
    }

    updatePublish(updatingPublishModel) {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                publishModel: updatingPublishModel(prevs.publishModel)
            }
        });
    }

    updateSubmittingTargetType(updatingSubmittingTarget) {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                submittingTargetType: updatingSubmittingTarget
            }
        });
    }

    updateSubmittingReleaseCollectionName(name) {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                submittingReleaseCollectionName: name
            }
        });
    }

    updateSubmittingReleaseCollectionId(entity_id) {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                submittingReleaseCollectionId: entity_id
            }
        });
    }


    submitReleaseCollectionForm() {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                fullSizeBox: (
                    <StoreReleaseSubmitRequestingComponent/>
                )
            };
        }, async () => {
            /**
             * @type {GetReleaseCollectionRPCResponse}
             */
            let resultOfReleaseCollectionModel = null
            let rpcResult

            switch (this.state.submittingTargetType){
                case StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.CREATE_NEW_RELEASE_COLLECTION: {
                    const param = new PutReleaseCollectionRPCRequest()
                    param.collection_name = this.state.submittingReleaseCollectionName
                    param.collection_type = "ReleaseCollectionType"
                    param.collection_state = "ReleaseCollectionStateOpenMaster"
                    param.library_item_entity_ids = this.state.submittingTargetReleaseItemList.map(item => item.entityId)
                    param.release_store_entity_id = this.state.sourceStoreModel.entityId

                    /**
                     * @type {Loops4ResponseMessage | {model: GetReleaseCollectionRPCResponse}}
                     */
                    rpcResult = await PUT_RELEASE_COLLECTION__V1_0({
                        headers: getAuthorizationHeader(this.props.accessTokenModel),
                        paramBody: param
                    })

                    resultOfReleaseCollectionModel = rpcResult.model

                    break;
                }
                case StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.INSERT_TO_EXISTING_RELEASE_COLLECTION: {
                    const targetReleaseCollectionId = this.state.submittingReleaseCollectionId

                    const param = new AddLibraryItemIntoReleaseCollectionRPCRequest()
                    param.release_collection_entity_id = targetReleaseCollectionId
                    param.library_item_entity_ids = this.state.submittingTargetReleaseItemList.map(item => item.entityId)

                    rpcResult = await ADD_LIBRARY_ITEM_INTO_RELEASE_COLLECTION__V1_0({
                        headers: getAuthorizationHeader(this.props.accessTokenModel),
                        paramBody: param
                    })

                    if(rpcResult.isError()){
                        console.error('error', rpcResult)
                    }

                    const param2 = new GetReleaseCollectionRPCRequest()
                    param2.entity_id = targetReleaseCollectionId
                    rpcResult = await GET_RELEASE_COLLECTION__V1_0({
                        headers: getAuthorizationHeader(this.props.accessTokenModel),
                        paramBody: param2
                    })

                    resultOfReleaseCollectionModel = rpcResult.model
                    break;
                }
            }


            if (rpcResult.isError()) {
                setFullSizeDIM(
                    <StoreReleaseSubmitFailResultComponent errorCode={rpcResult.getErrorCode()}
                                                           errorMessage={rpcResult.getErrorMessage()}
                                                           closeAction={() => {
                                                               clearFullSizeDIM()
                                                           }}
                    />
                )
            } else {
                setFullSizeDIM(
                    <StoreReleaseSubmitSuccessResultComponent releaseCollectionModel={resultOfReleaseCollectionModel}
                                                              storeId={this.state.sourceStoreModel.entityId}
                    />
                )
            }
        });
    }


    changeTab(setupStepTab) {
        this.setState((prevs, props) => {
            prevs.setupStepTab = setupStepTab;
            return prevs;
        });
    }


    /* =========================================================================================================================*/
    /* ============================================== GENERATE ELEMENT==========================================================*/

    /* =========================================================================================================================*/


    /**
     * @param {boolean} finished
     * @param {string} title
     * @return {*}
     */
    genTitleWithSignal(finished, title) {
        let iconSet = "icon check square";
        return <span><i className={iconSet}/>{title}</span>
    }

    /**
     * @param {number} elementOrder
     * @param {string} title
     * @param {any} content
     * @return {*}
     */
    genListElement(elementOrder, title, content) {
        return (
            <li style={{marginBottom: 10}}>
                <label className="ui black circular label"
                       style={{marginLeft: -30, marginRight: 5}}>{elementOrder}</label>
                <b className="label">{title}</b><br/>
                {content}
            </li>
        )
    }

    genTabContent(tabIndicator) {
        switch (tabIndicator) {
            case SetupStepTab.target : {
                return <ReleaseTargetSelector libraryCollectionModel={this.state.sourceLibraryCollectionModel}
                                              submittingReleaseCollectionModel={this.state.submittingReleaseCollectionModel}
                                              submittingTargetType={this.state.submittingTargetType}
                                              updateSubmittingTargetType={this.updateSubmittingTargetType.bind(this)}
                                              updatingReleaseCollectionName={this.state.submittingReleaseCollectionName}
                                              updatingReleaseCollectionId={this.state.submittingReleaseCollectionId}
                                              updateSubmittingReleaseCollectionName={this.updateSubmittingReleaseCollectionName.bind(this)}
                                              updateSubmittingReleaseCollectionId={this.updateSubmittingReleaseCollectionId.bind(this)}
                />
            }
            case SetupStepTab.items : {
                return <PublishSetupStepProducts accessTokenModel={this.props.accessTokenModel}
                                                 submittingTargetReleaseItemList={this.state.submittingTargetReleaseItemList}
                                                 updatePublishModel={this.updatePublish.bind(this)}
                />
            }
        }
    }

    genTabTitle(tabIndicator) {
        switch (tabIndicator) {
            case SetupStepTab.target : {
                return this.genTitleWithSignal(this.state.setupStep.settings, "Release Target")
            }
            case SetupStepTab.items : {
                return this.genTitleWithSignal(this.state.setupStep.settings, "Release Items")
            }
        }
    }

    /* =========================================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/

    /* =========================================================================================================================*/


    render() {

        /* ==================================================== PRE-PROCESSING ==========================================================*/

        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.bright}/>
            )
        } else {
            return (
                <MainAppLayout>
                    <div className="flexContainerColumn" style={{height: '100%'}}>
                        <div className="flexItem00 library headerBackground headerBackgroundShadow">
                            <HeaderToolbarLayouter addictionMenu={(
                                <StoreReleaseFormAddictionMenu
                                    submitReleaseCollectionFormAction={this.submitReleaseCollectionForm.bind(this)}
                                    changeTab={this.changeTab.bind(this)}
                                />
                            )}/>
                        </div>

                        <div className="flexItem11  verticalScroll">

                            <div className="ui container">
                                <div className="flexContainerColumn">
                                    {this.genTabTitle(this.state.setupStepTab)}
                                    {this.genTabContent(this.state.setupStepTab)}
                                    {this.state.content}
                                </div>
                            </div>

                        </div>
                    </div>
                </MainAppLayout>
            )
        }
    }
}

export default connect(mapStateToProps)(StoreReleaseFormByLibraryCollection);