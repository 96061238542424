import * as React from "react";
import LanguagePack from "../../LanguagePack";


const preDefined = {
    onlyAlphabet: new LanguagePack(
        "Required and Only alphabet is allowed",
        "文字のみ入力可能",
        "글자만 입력 가능"
    ),
    onlyNumber: new LanguagePack(
        "Required and Only number is allowed",
        "数字のみ入力可能",
        "글자만 입력 가능"
    ),
    required: new LanguagePack(
        "Required",
        "入力必要",
        "입력필요"
    )
};

const OrderRequestVaidationGuideLangPack = {
    shipping:{
        firstName: preDefined.onlyAlphabet,
        lastName: preDefined.onlyAlphabet,
        address: preDefined.required,
        city: preDefined.required,
        postalCode: preDefined.onlyNumber,
        country: preDefined.required
    },
    payment: {
        card: {
            number: preDefined.onlyNumber,
            cvc: preDefined.onlyNumber,
            expiration: {
                month: preDefined.required,
                year: preDefined.required,
            },
            holder: preDefined.onlyAlphabet,
        }
    }
}

export default OrderRequestVaidationGuideLangPack