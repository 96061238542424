class PhotoMetaUtilityContainer{

    convertPixelSize(pixelSize){

        let convertingDivider = 1000;
        let fixedDigit = 1;

        let convert = (size)=>{
            return parseFloat((size / convertingDivider).toFixed(fixedDigit))
        };

        let resultSize = pixelSize;
        let resultUnit = "pixels";

        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "kilopixels"
        }

        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "megapixels"
        }
        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "gigapixels"
        }
        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "terapixels"
        }

        return `${resultSize.toFixed(fixedDigit)} ${resultUnit}`
    }

    convertPixelSizeByDimension(horizonPixel, verticalPixel){
        return this.convertPixelSize(horizonPixel * verticalPixel)
    }

    convertPixelSizeOnlyUnit(pixelSize){

        let convertingDivider = 1000;
        let fixedDigit = 1;

        let convert = (size)=>{
            return parseFloat((size / convertingDivider).toFixed(fixedDigit))
        };

        let resultSize = pixelSize;
        let resultUnit = "";

        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "kilo"
        }

        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "mega"
        }
        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "giga"
        }
        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
            resultUnit = "tera"
        }

        return resultUnit
    }

    convertPixelSizeOnlySize(pixelSize){

        let convertingDivider = 1000;
        let fixedDigit = 1;

        let convert = (size)=>{
            return parseFloat((size / convertingDivider).toFixed(fixedDigit))
        };

        let resultSize = pixelSize;

        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
        }

        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
        }
        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
        }
        if(resultSize > convertingDivider){
            resultSize = convert(resultSize);
        }

        return resultSize
    }

}

const PhotoMetaUtility = new PhotoMetaUtilityContainer();

export default PhotoMetaUtility;