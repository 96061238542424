

// Package Dir: account/admin
// FileName: GetAccountAdminRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.account.admin.GetAccountAdminRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.account.user.AccountUserRPCBody, java.lang.Object

export class GetAccountAdminRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.account_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.first_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.language = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.last_name = null;

    }
}