import * as React from "react";
import * as PropTypes from "prop-types";
import LanguagePack from "../../../../../../../../language/LanguagePack";
import SelectionLangPack from "../../../../../../../../language/publish/selection/SelectionLangPack";
import {GetReleaseItemRPCResponse} from "../../../../../../../../api/loops4/model/release/item/GetReleaseItemRPCResponse";
import {MoneyFormatter} from "../../../../../../../../model/formatter/MoneyFormatter";
import {LanguageMaster} from "../../../../../../../../model/formatter/LanguageFormatter";
import {connect} from "react-redux";
import ReleaseItemMetadata from "./ReleaseItemMetadata";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        languageMaster: state.languageMaster,
    };
};

class ProductShoppingPresentationInThumbnail extends React.Component {

    static propTypes = {
        language: PropTypes.object.isRequired,
        languageMaster: PropTypes.objectOf(LanguageMaster).isRequired,

        releaseItemModel: PropTypes.objectOf(GetReleaseItemRPCResponse).isRequired,
        isItAddedInCart: PropTypes.bool.isRequired,
        pickItemIntoCart: PropTypes.func.isRequired,
        removeItemFromCart: PropTypes.func.isRequired
    }

    pickItemIntoCart = (e) => {
        e.preventDefault();
        const product = this.props.releaseItemModel.product

        if(product)
            this.props.pickItemIntoCart(product);
    }
    removeItemFromCart = (e) => {
        e.preventDefault();
        const product = this.props.releaseItemModel.product

        if(product)
            this.props.removeItemFromCart(product);
    }

    /**
     * @return {*}
     */
    genPickupRejectButton = () => {
        let langType = this.props.language

        let button = null;

        // Buttons should be generated on time,
        // Because event should be assigned
        let pickingUpButton = (
            <button className="ui small button basic green"
                    onClick={this.pickItemIntoCart}
            >
                <i className="icon cart"/>
                {LanguagePack.extract(langType, SelectionLangPack.selection.buttons.pick)}
            </button>
        );

        let pickedUpButton = (
            <button className="ui small button green"
                    onClick={this.removeItemFromCart}
            >
                <i className="icon cart"/>
                {LanguagePack.extract(langType, SelectionLangPack.selection.buttons.picked)}
            </button>
        );

        if(this.props.isItAddedInCart === true){
            button = pickedUpButton;
        }else if(this.props.isItAddedInCart === false){
            button = pickingUpButton;
        }else {
            button = pickingUpButton;
        }

        return (
            <div>
                {button}
            </div>

        )
    }

    render() {
        /**
         * @type {GetReleaseItemRPCResponse}
         */
        const releaseItemModel = this.props.releaseItemModel
        /**
         *
         * @type {GetCommerceSaleProductReleaseItemRPCResponse}
         */
        const product = this.props.releaseItemModel.product
        let currencyMaster = null;

        if(product){
            currencyMaster = MoneyFormatter.getMaster(product.price_currency)
        }

        return (
            <div className="flexContainerRow">
                <div className="flexItem00">
                    {(product ? (
                        <div>
                            {this.genPickupRejectButton()}

                            <span>
                                    {MoneyFormatter.getAmountByLocalCurrency(product.price_amount, currencyMaster)}
                                </span>
                            <span style={{fontSize: 10, marginLeft:3}}>
                                    {MoneyFormatter.getLocalCurrencyValueByLanguageMaster(this.props.languageMaster, currencyMaster)}
                                </span>
                        </div>
                    ) : (
                        <div>
                            No Product
                        </div>
                    ))}

                    <div className={'detail'}>
                        {ReleaseItemMetadata.generateMetadataForThumbnail(releaseItemModel, this.props.languageMaster)}
                    </div>
                </div>
                <div className="flexItem11">

                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps)(ProductShoppingPresentationInThumbnail);