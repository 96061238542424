import {endpointLoops4} from "../../constant/APIConstant";
import {getAuthorizationHeader, validateParameter} from "./Loops4RPCAPI";
import DateFormatter from "../../model/formatter/DateFormatter";
import UploadingProgressModel from "../../model/entity/UploadingProgressModel";
import * as RequestNetwork from "../../network/HttpRequest";
import $ from "../../library/JQuery";

const BINARY_API_ENDPOINT = `${endpointLoops4}/api/data`

// export const createItemWithUpload = (authType:string, collectionPK:PrimaryKeyModel, pictureForm:FormData, callback: (itemModels:Array<ItemModel>) => any, failback: ()=> any, progressHandler:(uploadingProgressModel:UploadingProgressModel) => any):void{
/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} libraryItemId
 * @param {form} pictureForm
 * @param {function} callback
 * @param {function} failback
 * @param {function} progressHandler
 */
export const uploadToLibraryItemBinary = ({
                                              accessTokenModel,
                                              libraryItemId,
                                              pictureForm,
                                              callback,
                                              failback,
                                              progressHandler = (uploadingProgressModel) => {}
                                          }) => {

    let previousTime = DateFormatter.genUTCEpoch();
    let previousUploadedKB = 0;

    /**
     * @param progressEvent
     * @return {UploadingProgressModel|null}
     */
    const computeProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
            // console.debug("progressEvent::", progressEvent)
            let progressPercentage = Math.round(progressEvent.loaded / progressEvent.total * 100);
            let uploadedKB = Math.round(progressEvent.loaded / 1024);
            let totalKB = Math.round(progressEvent.total / 1024);

            let currentTime = DateFormatter.genUTCEpoch();
            let spentTime = currentTime - previousTime;
            previousTime = currentTime;

            let spentKB = uploadedKB - previousUploadedKB;
            previousUploadedKB = uploadedKB;
            let amountPerSec = Math.round((spentKB / spentTime * 1000));

            return new UploadingProgressModel(uploadedKB, totalKB, progressPercentage, amountPerSec, progressEvent.AT_TARGET);
        } else {
            // Unable to compute progress information since the total size is unknown
            return null;
        }
    }

    RequestNetwork.requestAjax(
        `${BINARY_API_ENDPOINT}/library/item/file?item_id=${libraryItemId}`,
        'POST',
        null,
        getAuthorizationHeader(accessTokenModel),
        (msg)=>{
            console.log('requestAjax-callback', {msg})
            callback(msg);
        },
        (jqXHR, textStatus ) => {
            console.log('requestAjax-failback', {jqXHR: JSON.parse(JSON.stringify(jqXHR)), textStatus})
            failback(jqXHR, textStatus);
        },
        {
            data: pictureForm,
            dataType: null,
            processData: false,
            contentType: false,
            xhr: function() {  // custom xhr
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) { // check if upload property exists
                    myXhr.upload.addEventListener('progress', (progressEvent) => progressHandler(computeProgress(progressEvent)), false); // for handling the progress of the upload
                }
                return myXhr;
            }
        }
    );

}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} libraryItemId
 * @return {string}
 */
export const getLibraryItemFileURL = ({accessTokenModel, libraryItemId}) => {
    validateParameter({accessTokenModel, libraryItemId})
    return `${BINARY_API_ENDPOINT}/library/item/file?=item_id=${libraryItemId}&access_token=${accessTokenModel.accessToken}`
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} libraryItemId
 * @return {string}
 */
export const getLibraryItemPreviewURL = ({accessTokenModel, libraryItemId, longTermPixel}) => {
    validateParameter({accessTokenModel, libraryItemId, longTermPixel})
    return `${BINARY_API_ENDPOINT}/library/item/preview?=item_id=${libraryItemId}&long_term_px=${longTermPixel}&access_token=${accessTokenModel.accessToken}`
}
/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} releaseItemId
 * @return {string}
 */
export const getReleaseItemFileURL = ({accessTokenModel, releaseItemId}) => {
    validateParameter({accessTokenModel, releaseItemId})
    return `${BINARY_API_ENDPOINT}/release/item/file?=item_id=${releaseItemId}&access_token=${accessTokenModel.accessToken}`
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} releaseItemId
 * @param {number} longTermPixel
 * @return {string}
 */
export const getReleaseItemPreviewURL = ({accessTokenModel, releaseItemId, longTermPixel}) => {
    validateParameter({accessTokenModel, releaseItemId, longTermPixel})
    return `${BINARY_API_ENDPOINT}/release/item/preview?=item_id=${releaseItemId}&long_term_px=${longTermPixel}&access_token=${accessTokenModel.accessToken}`
}


/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} shippingId
 * @return {string}
 */
export const getShippingArchiveFileURL = ({accessTokenModel, shippingId}) => {
    validateParameter({accessTokenModel, shippingId})
    return `${BINARY_API_ENDPOINT}/shipping/archive?item_id=${shippingId}&access_token=${accessTokenModel.accessToken}`
}

