import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import AccountSubscriberModel from "../../../../model/entity/account/AccountSubscriberModel";
import {clearAccessTokenForSubscriber} from "../../../../state/redux/action/AuthAction";
import {clearSubscriberInfo} from "../../../../state/redux/action/UserAction";
import {pushHistory} from "../../../RouteHistory";
import {RouteUtility} from "../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../RouteDictionary";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        routeState: state.routeState,
        language: state.language,
        languageMaster: state.languageMaster,
        subscriberInfo: state.subscriberInfo,
    };
};

class SubscriberProfileMenu extends React.Component{
    static propTypes = {
        language: PropTypes.object,
        languageMaster: PropTypes.object,

        subscriberInfo: PropTypes.instanceOf(AccountSubscriberModel)
    }

    style = {
        mainContainer: {
            padding: 10
        },
        menu: {
            minWidth: 300
        },
        mainText: {
            color: 'black'
        },
        guideContainer: {
            fontSize: '0.9em',
            color: 'rgba(100, 100, 100, 1)',
            padding: 5,
        },
        info_user_name: {
            color: 'rgba(50, 50, 50, 1)',
            fontWeight: 'bold'
        },
        info_email: {
            color: 'rgba(155, 155, 155, 1)'
        },
        subText: {
            color: 'rgba(155, 155, 155, 1)',
            paddingLeft: 30,
            marginTop: 5,
            fontSize: '0.8em',
        },
        statusBox: {
            textAlign: 'center'
        },
        statusIcon: {
            minWidth: 60
        }
    }

    sigOutSubscriber = () => {
        const redirectURL = RouteUtility.getPathname(RouteDictionary.subscriberSignIn, {
            [RoutePathParam.releaseCollectionId]: this.props.routeState.pathParameter[RoutePathParam.releaseCollectionId]
        }, {
            [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
            [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
        })

        clearAccessTokenForSubscriber()
        clearSubscriberInfo()

        pushHistory(redirectURL)
    }
    
    render() {
        return (
            <div style={this.style.mainContainer}>
                <div>
                    <div className="item flexContainerRow">
                        <div className={'flexItem11'} style={this.style.mainText}>
                            <div className="ui tag label">アカウント</div>
                        </div>
                        <div className={'flexItem00'} style={this.style.statusBox}>
                            <button className={'ui button inverted pink mini'}
                                    onClick={this.sigOutSubscriber}
                            >
                                ログアウト
                            </button>
                        </div>
                    </div>
                </div>
                <div style={this.style.guideContainer}>
                    <div>
                        <span style={this.style.info_user_name}>{this.props.subscriberInfo.lastName} {this.props.subscriberInfo.firstName} </span>
                        様
                    </div>
                    <div style={this.style.info_email}>
                        ( {this.props.subscriberInfo.email} )
                    </div>
                </div>
            </div>
        )

    }
}

export default connect(mapStateToProps)(SubscriberProfileMenu);