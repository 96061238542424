

// Package Dir: account/user
// FileName: GetAccountInformationByAuthSessionRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.account.user.GetAccountInformationByAuthSessionRPCRequest
// Super types: java.lang.Object

export class GetAccountInformationByAuthSessionRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.account_id = null;

    }
}