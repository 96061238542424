import {COMMERCE_CART_SET_SUBSCRIBER} from "../constant/ActionType";
import {ReduxMainStore} from "../store/ReduxMainStore";
import {getAuthorizationHeader} from "../../../api/loops4/Loops4RPCAPI";
import {GET_COMMERCE_CART_PRODUCT__V1_0} from "../../../api/loops4/caller/loops4RpcCaller";
import {GetCommerceCartProductRPCRequest} from "../../../api/loops4/model/commerce/cart/product/GetCommerceCartProductRPCRequest";

export const setSubscriberCommerceCartModel = (payload) => {
    ReduxMainStore.dispatch({
        type: COMMERCE_CART_SET_SUBSCRIBER,
        payload
    })
}

export const refreshSubscriberCommerceCart = async () => {
    /**
     * @type {ReduxStoreState}
     */
    const reduxState = ReduxMainStore.getState()

    const param = new GetCommerceCartProductRPCRequest()
    param.entity_id = reduxState.subscriberInfo.commerceCartId

    const rpcResult = await GET_COMMERCE_CART_PRODUCT__V1_0({
        headers: getAuthorizationHeader(reduxState.auth.subscriber),
        paramBody: param
    })

    if(rpcResult.isError()){
        console.log(`Failed to refresh commerce cart:: ${rpcResult.getErrorMessage()}`)
    } else {
        setSubscriberCommerceCartModel(rpcResult.model)
    }
}
