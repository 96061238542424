

// Package Dir: 
// FileName: Loops4RequestMessage.js
// RPC model: com.loops4.controller.loops4rpc.abstraction.message.Loops4RequestMessage
// Super types: java.lang.Object

export class Loops4RequestMessage {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.method = null;
        /**
         *
         * JVM type: Parameter
         * @type {Parameter}
         */
        this.parameter = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.version = null;

    }
}