import * as React from "react";
import * as PropTypes from "prop-types";
import DomIdConstant from "../../../../constant/DomIdConstant.json";
import UploadProgressContainer from "./uploader/UploadeProgressContainer";
import UploadingProgressStatusComponent from "./uploader/UploadingProgressStatusComponent";
import UploadingProgressFailedComponent from "./uploader/UploadingProgressFailedComponent";
import UploadingProgressCompleteComponent from "./uploader/UploadingProgressCompleteComponent";

export default class AddPhotoComponent extends React.Component {

    static propTypes = {
        collectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }

    REFS_SET={
        UPLOAD_FORM:"UPLOAD_FORM",
        UPLOAD_INPUT_SELECTOR:"UPLOAD_INPUT_SELECTOR",
        UPLOAD_INPUT_DROP_ZONE:"UPLOAD_INPUT_DROP_ZONE"
    };

    constructor(props, context){
        super(props, context);
        this.state = {
            fileQueueToUpload: [],
            fileQueueUploading: [],
            fileQueueUploadedWithSuccess: [],
            fileQueueUploadedWithFailure: [],
        }
    }

    parallelism = 5;
    supportFiles = ".A, .AAI, .ART, .AVS, .B, .BGR, .BGRA, .BMP, .BMP2, .BMP3, .BRF, .C, .CAL, .CALS, .CANVAS, .CAPTION, .CIN, .CIP, .CLIP, .CMYK, .CMYKA, .CUR, .CUT, .DCM, .DCX, .DDS, .DFONT, .DPX, .EPS2, .EPS3, .FAX, .FITS, .FRACTAL, .FTS, .G, .G3, .GIF, .GIF87, .GRADIENT, .GRAY, .GROUP4, .HALD, .HDR, .HISTOGRAM, .HRZ, .HTM, .HTML, .ICB, .ICO, .ICON, .INLINE, .IPL, .ISOBRL, .J2C, .J2K, .JNG, .JP2, .JPC, .JPEG, .JPG, .JPX, .K, .LABEL, .M, .MAC, .MAP, .MATTE, .MIFF, .MNG, .MONO, .MPC, .MSL, .MTV, .MVG, .NULL, .O, .OTB, .OTF, .PAL, .PALM, .PAM, .PANGO, .PATTERN, .PBM, .PCD, .PCDS, .PCT, .PCX, .PDB, .PES, .PFA, .PFB, .PFM, .PGM, .PGX, .PICON, .PICT, .PIX, .PJPEG, .PLASMA, .PNG, .PNG24, .PNG32, .PNG8, .PNM, .PPM, .PREVIEW, .PS2, .PS3, .PSB, .PSD, .PTIF, .PWP, .R, .RADIAL-GRADIENT, .RAS, .RGB, .RGBA, .RGBO, .RLA, .RLE, .SCR, .SCT, .SFW, .SGI, .SHTML, .STEGANO, .SUN, .TEXT, .TGA, .THUMBNAIL, .TIFF, .TIFF64, .TILE, .TIM, .TTC, .TTF, .TXT, .UBRL, .UIL, .UYVY, .VDA, .VICAR, .VID, .VIFF, .VST, .WBMP, .WMF, .WMZ, .WPG, .X, .XBM, .XC, .XCF, .XPM, .XV, .XWD, .Y, .YCbCr, .YCbCrA, .YUV"


    uploadFilesBySubmit = (event) => {
        event.preventDefault();

        let fileForm = this.refs[this.REFS_SET.UPLOAD_INPUT_SELECTOR];
        var formsFileFields = fileForm["files"];

        this.pushFilesToUploadQueue(formsFileFields);
        fileForm["value"]='';
    };

    uploadFilesByDropEventPreventor = (event) => {
        event.preventDefault();
    };

    uploadFilesByDrop = (event) => {
        event.preventDefault();

        let droppedFiles = event.dataTransfer.files;

        this.pushFilesToUploadQueue(droppedFiles);
    };

    fileIncrement = 0
    fileIdKey = 'add_photo_component__id'
    injectFileId = (file) => {
        file[this.fileIdKey] = 'file_index_' + ++this.fileIncrement
        return file
    };

    retryToUpload = (file) => {
        this.setState((prevS, props) => {
            return {
                ...prevS,
                fileQueueUploadedWithFailure: prevS.fileQueueUploadedWithFailure.filter(({fileInQueue, message}) => this.getFileId(fileInQueue) !== this.getFileId(file)),
            }
        }, () => this.pushFilesToUploadQueue([file]) )
    };

    getFileName = (file) => {
        return file['name']
    };

    getFileId = (file) => {
        return file[this.fileIdKey]
    };

    /**
     * @param {array<File> | File} files
     */
    pushFilesToUploadQueue(files){
        const inputFiles = [...files]
        const idInjectedFiles = inputFiles.map(file => this.injectFileId(file))

        this.setState((prevS, props) => {
            prevS.fileQueueToUpload.push(...idInjectedFiles)

            return {
                ...prevS,
            }
        }, this.scheduleFileQueue)
    }

    onCompleteWithSuccess = (file) => {
        this.setState((prevS, props) => {
            prevS.fileQueueUploadedWithSuccess.push(file)

            return {
                ...prevS,
                fileQueueUploading: prevS.fileQueueUploading.filter(fileInQueue => this.getFileId(fileInQueue) !== this.getFileId(file)),
            }
        }, this.scheduleFileQueue )
    }

    onCompleteWithFailure = (file, message) => {
        this.setState((prevS, props) => {
            prevS.fileQueueUploadedWithFailure.push({file, message})

            return {
                ...prevS,
                fileQueueUploading: prevS.fileQueueUploading.filter(fileInQueue => this.getFileId(fileInQueue) !== this.getFileId(file)),
            }
        }, this.scheduleFileQueue )
    }

    scheduleFileQueue = () => {
        this.setState((prevS, props) => {
            if(prevS.fileQueueUploading.length < this.parallelism){
                const leftSlotLength = this.parallelism - prevS.fileQueueUploading.length
                const nextFiles = []

                for(let shiftCount = 1; shiftCount <= leftSlotLength; shiftCount++){
                    nextFiles.push(prevS.fileQueueToUpload.shift())
                }

                const validNextFiles = nextFiles.filter(file => file !== undefined && file !== null)
                if(validNextFiles.length > 0)
                    prevS.fileQueueUploading.push(...validNextFiles)
            }

            return {
                ...prevS,
            }
        })
    }

    render() {

      let path_collection = <u></u>;

      //TODO change into link
      if(this.props.collectionModel.entity_id !== 0) path_collection = <u>{this.props.collectionModel.collection_name}</u>;

      return (
          <div style={{color:'black', textAlign:'left'}}>
              <form className="ui fluid"
                    encType="multipart/form-data"
                    onSubmit={this.uploadFilesBySubmit}
                    ref={this.REFS_SET.UPLOAD_FORM}
              >
                  <table style={{width:'100%'}}>
                      <tbody>
                      <tr>
                          <td>
                              <input className="ui button"
                                     id={DomIdConstant.DomAddPhotoComponent.fileSelector}
                                     type="file"
                                     accept={this.supportFiles}
                                     multiple={true}
                                     style={{padding:11, width:'100%'}}
                                     ref={this.REFS_SET.UPLOAD_INPUT_SELECTOR}
                              />
                          </td>
                          <td style={{paddingLeft:7}}>
                              <input type="submit"
                                     id={DomIdConstant.DomAddPhotoComponent.submitButton}
                                     className="ui button blue align right"
                                     value="Upload"
                                     style={{width:'100%'}}
                              />
                          </td>
                      </tr>
                      </tbody>
                  </table>
                  <div className="ui fluid teal message"
                       style={{padding:90, textAlign:'center'}}
                       onDragOver={this.uploadFilesByDropEventPreventor}
                       onDrop={this.uploadFilesByDrop}
                       accept={this.supportFiles}
                       ref={this.REFS_SET.UPLOAD_INPUT_DROP_ZONE}
                  >
                      <h2>Drop files here</h2>
                      <p>(The file will be uploaded automatically, as you drop here)</p>
                  </div>
              </form>

              <table style={{marginTop:10}}>
                  <tbody>
                  <tr>
                      <td><i className="icon info circle" /></td>
                      <td>Most of digital camera RAW files are available.<br/></td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>raw, arw, tiff, jpg, png, etc...  </td>
                  </tr>
                  </tbody>
              </table>


              <div className="ui horizontal divider">
                  In Progress [ {this.state.fileQueueUploading.length} ]
              </div>

              <div style={{maxHeight:200, overflowY: 'auto', overflowX: 'hidden'}}>
                  {this.state.fileQueueUploading.map(file =>(
                      <UploadProgressContainer file={file}
                                               accessTokenModel={this.props.accessTokenModel}
                                               uploadingCollectionId={this.props.collectionModel.entity_id}
                                               onSuccess={this.onCompleteWithSuccess}
                                               onFailure={this.onCompleteWithFailure}
                                               key={this.getFileId(file)}
                      />
                  ))}
              </div>

              <div className="ui horizontal divider">
                  Uploading Queue [ {this.state.fileQueueToUpload.length} ]
              </div>

              <div style={{maxHeight:200, overflowY: 'auto', overflowX: 'hidden'}}>
                  {this.state.fileQueueToUpload.map(file =>(
                      <UploadingProgressStatusComponent uploadingProgressModel={{
                                                              progressPercentage: 0,
                                                              uploadedKB: 0,
                                                              totalKB: 0,
                                                              speedKBPerSec: 0
                                                          }}
                                                        fileName={this.getFileName(file)}
                                                        key={this.getFileId(file)}
                      />
                  ))}
              </div>

              <div className="ui horizontal divider">
                  Failed Files [ {this.state.fileQueueUploadedWithFailure.length} ]
              </div>

              <div style={{maxHeight:200, overflowY: 'auto', overflowX: 'hidden'}}>
                  {this.state.fileQueueUploadedWithFailure.map( ({file, message}) =>(
                      <UploadingProgressFailedComponent fileName={this.getFileName(file)}
                                                        message={message}
                                                        retryFunc={this.retryToUpload.bind(this, file)}
                                                        key={this.getFileId(file)}
                      />
                  ))}
              </div>

              <div className="ui horizontal divider">
                  Succeeded Files [ {this.state.fileQueueUploadedWithSuccess.length} ]
              </div>

              <div style={{maxHeight:200, overflowY: 'auto', overflowX: 'hidden'}}>
                  {this.state.fileQueueUploadedWithSuccess.map(file =>(
                      <UploadingProgressCompleteComponent fileName={this.getFileName(file)}
                                                          key={this.getFileId(file)}
                      />
                  ))}
              </div>

          </div>
      );
  }
}
