

// Package Dir: release/item
// FileName: GetReleaseItemsOfCollectionRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.release.item.GetReleaseItemsOfCollectionRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, java.lang.Object

export class GetReleaseItemsOfCollectionRPCResponse {
    constructor() {

        /**
         *
         * JVM type: kotlin.collections.List<kotlin.String>
         * @type {array<string>}
         */
        this.items = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;

    }
}