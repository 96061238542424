import {
    AUTH_SET_ACCESS_TOKEN_FOR_ADMIN,
    AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER,
    AUTH_SET_ACCESS_TOKEN_FOR_USER,
    COMMERCE_CART_SET_SUBSCRIBER,
    COMMERCE_ORDER_DETAIL_SET_SUBSCRIBER,
    COMMERCE_ORDER_HISTORY_SET_ADMIN,
    COMMERCE_ORDER_HISTORY_SET_SUBSCRIBER,
    COMMERCE_RELEASE_COLLECTION_ITEMS_OF_COLLECTION,
    INCREMENT,
    ROUTE_SET_STATE,
    SELECTION_BUCKET_SET,
    SET_ROUTER_INJECTOR,
    UI_CONTROL_MUTATE_FULL_SIZE_DIM,
    USER_SET_ACCOUNT_ADMIN_INFO,
    USER_SET_ACCOUNT_USER_INFO,
    USER_SET_SUBSCRIBER_INFO
} from "../constant/ActionType";
import {RouteState} from "../../../route/RouteUtility";
import {getFromLocalStorage, setIntoLocalStorage} from "../storage/ReduxLocalStorage";
import {AccessTokenModel} from "../../../model/auth/AccessTokenModel";
import AccountSubscriberModel from "../../../model/entity/account/AccountSubscriberModel";
import {LanguageContainer} from "../../../model/container/LanguageContainer";
import {LanguageMasterDefinition} from "../../../model/formatter/LanguageFormatter";
import {
    GetCommerceCartProductRPCResponse
} from "../../../api/loops4/model/commerce/cart/product/GetCommerceCartProductRPCResponse";
import {GetAccountAdminRPCResponse} from "../../../api/loops4/model/account/admin/GetAccountAdminRPCResponse";
import {
    GetReleaseItemsOfCollectionRPCResponse
} from "../../../api/loops4/model/release/item/GetReleaseItemsOfCollectionRPCResponse";
import {GetAccountUserResponse} from "../../../proto/account/AccountService_pb";
import {deserializeMsg, serializeMsg} from "../../../api/pickhours/grpc/GrpcMessageSerializer";

class ReduxStoreState{
    constructor() {

        const authUserInput = getFromLocalStorage(AUTH_SET_ACCESS_TOKEN_FOR_USER)
        let authUser = undefined;
        if(authUserInput != null) {
            authUser = AccessTokenModel.deserialize(authUserInput)
        } else {
            authUser = new AccessTokenModel({});
        }


        const authSubscriberInput = getFromLocalStorage(AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER)
        let authSubscriber = undefined;
        if(authSubscriberInput !=  null) {
            authSubscriber = AccessTokenModel.deserialize(authSubscriberInput)
        } else {
            authSubscriber = new AccessTokenModel({});
        }


        const authAdminInput = getFromLocalStorage(AUTH_SET_ACCESS_TOKEN_FOR_ADMIN)
        let authAdmin = undefined;
        if(authAdminInput !=  null) {
            authAdmin = AccessTokenModel.deserialize(authAdminInput)
        } else {
            authAdmin = new AccessTokenModel({});
        }


        const userInfoInput = getFromLocalStorage(USER_SET_ACCOUNT_USER_INFO)
        let userInfo = undefined;
        if(userInfoInput != null) {
            const binary = deserializeMsg(userInfoInput)
            userInfo = GetAccountUserResponse.deserializeBinary(binary)
        } else {
            userInfo = new GetAccountUserResponse()
        }

        let subscriberInfoInput = getFromLocalStorage(USER_SET_SUBSCRIBER_INFO)
        let subscriberInfo = undefined;
        if(subscriberInfoInput != null) {
            subscriberInfo = AccountSubscriberModel.deserialize(subscriberInfoInput)
        } else {
            subscriberInfo = new AccountSubscriberModel({});
        }

        const adminInfoInput = getFromLocalStorage(USER_SET_ACCOUNT_ADMIN_INFO)
        let adminInfo = undefined;
        if(adminInfoInput != null) {
            adminInfo = Object.assign(new GetAccountAdminRPCResponse(), adminInfoInput);
        } else {
            adminInfo = new GetAccountAdminRPCResponse();
        }


        this.language = LanguageContainer.master.japanese
        this.languageMaster = LanguageMasterDefinition.japanese
        this.fullScreenDim = null;
        this.auth = {
            user: authUser,
            admin: authAdmin,
            subscriber: authSubscriber,
        };
        /**
         *
         * @type {GetAccountUserResponse}
         */
        this.userInfo = userInfo
        /**
         *
         * @type {AccountSubscriberModel}
         */
        this.subscriberInfo = subscriberInfo
        /**
         *
         * @type {AccountAdminModel}
         */
        this.adminInfo = adminInfo
        /**
         *
         * @type {GetCommerceCartProductRPCResponse}
         */
        this.subscriberCommerceCartModel = new GetCommerceCartProductRPCResponse()
        /**
         *
         * @type {GetReleaseItemsOfCollectionRPCResponse}
         */
        this.releaseCollectionItemsOfCollection = new GetReleaseItemsOfCollectionRPCResponse()
        this.releaseCollectionItemsOfCollection.items = []
        /**
         *
         * @type {Array<CommerceOrderHistoryModel>}
         */
        this.subscriberOrderHistory = []
        /**
         *
         * @type {GetOrderCommerceProductReleaseItemRPCResponse}
         */
        this.subscriberOrderDetail = null;

        /**
         *
         * @type {Array<CommerceOrderHistoryModel>}
         */
        this.adminOrderHistory = []


        this.routeState = new RouteState()
        this.selectionBucket = {

        }
    }
}

const reducer = (state = new ReduxStoreState(), action) => {
    // Important to bring next state
    const nextState = {...state}


    switch (action.type) {
        case INCREMENT: {
            nextState.fuga += 1;
            break;
        }
        case UI_CONTROL_MUTATE_FULL_SIZE_DIM: {
            nextState.fullScreenDim = action.payload;
            break;
        }
        case AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER: {
            nextState.auth.subscriber = action.payload;
            setIntoLocalStorage(AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER, nextState.auth.subscriber.serialize())
            break;
        }
        case AUTH_SET_ACCESS_TOKEN_FOR_USER: {
            nextState.auth.user = action.payload;
            setIntoLocalStorage(AUTH_SET_ACCESS_TOKEN_FOR_USER, nextState.auth.user.serialize())
            break;
        }
        case AUTH_SET_ACCESS_TOKEN_FOR_ADMIN: {
            nextState.auth.admin = action.payload;
            setIntoLocalStorage(AUTH_SET_ACCESS_TOKEN_FOR_ADMIN, nextState.auth.admin.serialize())
            break;
        }
        case USER_SET_ACCOUNT_USER_INFO: {
            nextState.userInfo = action.payload;
            const binary = serializeMsg(nextState.userInfo.serializeBinary())
            setIntoLocalStorage(USER_SET_ACCOUNT_USER_INFO, binary)
            break;
        }
        case USER_SET_SUBSCRIBER_INFO: {
            nextState.subscriberInfo = action.payload;
            setIntoLocalStorage(USER_SET_SUBSCRIBER_INFO, nextState.subscriberInfo.serialize())
            break;
        }
        case USER_SET_ACCOUNT_ADMIN_INFO: {
            nextState.adminInfo = action.payload;
            setIntoLocalStorage(USER_SET_ACCOUNT_ADMIN_INFO, JSON.stringify(nextState.adminInfo))
            break;
        }
        case ROUTE_SET_STATE: {
            nextState.routeState = action.payload;
            break;
        }
        case COMMERCE_CART_SET_SUBSCRIBER: {
            // /**
            //  * @type {GetCommerceCartProductRPCResponse}
            //  */
            // const nextCartModel = action.payload
            // /**
            //  * @type {GetCommerceCartProductRPCResponse}
            //  */
            // const prevCartModel = nextState.subscriberCommerceCartModel
            //
            // console.log('COMMERCE_CART_SET_SUBSCRIBER-before', nextState.subscriberCommerceCartModel)
            // console.log('COMMERCE_CART_SET_SUBSCRIBER-nextCartModel', nextCartModel)
            // nextState.subscriberCommerceCartModel = updatePrevProperties(nextState.subscriberCommerceCartModel, nextCartModel)
            //
            // console.log('COMMERCE_CART_SET_SUBSCRIBER-after', nextState.subscriberCommerceCartModel)

            nextState.subscriberCommerceCartModel = action.payload
            break;
        }
        case COMMERCE_RELEASE_COLLECTION_ITEMS_OF_COLLECTION: {
            nextState.releaseCollectionItemsOfCollection = action.payload;
            break;
        }
        case COMMERCE_ORDER_HISTORY_SET_ADMIN: {
            nextState.adminOrderHistory = action.payload;
            break;
        }
        case COMMERCE_ORDER_HISTORY_SET_SUBSCRIBER: {
            nextState.subscriberOrderHistory = action.payload;
            break;
        }
        case COMMERCE_ORDER_DETAIL_SET_SUBSCRIBER: {
            nextState.subscriberOrderDetail = action.payload;
            break;
        }
        case SELECTION_BUCKET_SET: {
            nextState.selectionBucket = action.payload;
            break;
        }
        case SET_ROUTER_INJECTOR: {
            const {
                languageMasterByContainer,
                languageMasterByFormatter
            } = action.payload;

            if(languageMasterByContainer)
                nextState.language = languageMasterByContainer

            if(languageMasterByFormatter)
                nextState.languageMaster = languageMasterByFormatter

            break;
        }
        default: {}
    }

    return nextState;
};

export default reducer
