import {ServiceDomainDictionary} from './service_domain.dictionary';

class PathDictionaryDefinition {
    path: string;
    title: string;
    siteMap: boolean;

    constructor(path: string, title: string, siteMap: boolean = true) {
        this.path = path;
        this.title = title;
        this.siteMap = siteMap;
    }
}

const PortalPathDictionary = {
    home: {
        index: new PathDictionaryDefinition(
            '/',
            'PickHours',
        ),
    },
    company: {
        contacts: new PathDictionaryDefinition(
            '/company/contacts',
            'Contacts',
        ),
        policy: new PathDictionaryDefinition(
            '/company/policy',
            'Contacts',
        ),
        guide: new PathDictionaryDefinition(
            '/guide',
            'Contacts',
        ),
        priceAndFeature: new PathDictionaryDefinition(
            '/price-and-feature',
            'Contacts',
        ),
    },
    app: {
        index: new PathDictionaryDefinition(
            `${ServiceDomainDictionary.dashboard}`,
            'loops4 - application',
        ),
        signIn: new PathDictionaryDefinition(
            `${ServiceDomainDictionary.dashboard}/sign/in`,
            'Sign in',
        ),
        signUp: new PathDictionaryDefinition(
            `${ServiceDomainDictionary.dashboard}/sign/up`,
            'Sign Up',
        ),
    },
    public: {
        assets: new PathDictionaryDefinition(
            '/assets/',
            'loops4 - app',
        ),
    },
    seo: {
        robots_txt: new PathDictionaryDefinition(
            '/robots.txt',
            'Robot',
            false,
        ),
        sitemap_xml: new PathDictionaryDefinition(
            '/sitemap.xml',
            'Sitemap',
            false,
        ),
    },
};

const getControllerDictionaries = () => {
    const listUpRecursively = (jsonObj: object) => {
        let list: any[] = [];
        Object
            .keys(jsonObj)
            .map(jsonObjKey => {
                const objectValue = jsonObj[jsonObjKey];
                if (objectValue instanceof  PathDictionaryDefinition === false && typeof objectValue === 'object'){
                    const subList = listUpRecursively(objectValue);
                    list = list.concat(subList);
                } else {
                    list.push(objectValue);
                }
            });
        return list;
    };
    const foundList = listUpRecursively(PortalPathDictionary);
    return foundList;
};

export { PortalPathDictionary, PathDictionaryDefinition, getControllerDictionaries };