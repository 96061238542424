

// Package Dir: general
// FileName: NotifyOnlyOkRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.general.NotifyOnlyOkRPCResponse
// Super types: java.lang.Object

export class NotifyOnlyOkRPCResponse {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.message = null;

    }
}