import {OrderBillPaymentDetailRPCBody} from "../../../commerce/order/common/OrderBillPaymentDetailRPCBody";

// Package Dir: commerce/order/common
// FileName: OrderBillPaymentTransactionRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillPaymentTransactionRPCBody
// Super types: java.lang.Object

export class OrderBillPaymentTransactionRPCBody {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.payment_country = null;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillPaymentDetailRPCBody
         * @type {OrderBillPaymentDetailRPCBody}
         */
        this.payment_detail = new OrderBillPaymentDetailRPCBody();
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.payment_method = null;

    }
}