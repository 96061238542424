import * as moment from "moment"
import DateUTCModel from "../entity/DateUTCModel";
import {LanguageContainer} from "../container/LanguageContainer";
import {LanguageMasterDefinition} from "./LanguageFormatter";

/**
 * @return {DateUTCModel}
 */
const genUTCDate = () => {
    return new DateUTCModel(new Date());
}

/**
 * @return {number}
 */
const genUTCEpoch = () => {
    return Date.now();
}


/**
 * @param {number} nextYears
 * @return {Array<number>}
 */
const getNextYears = (nextYears) => {
    let cardYearFor20Year = [];
    let thisYear = new Date().getFullYear();

    for (let year = thisYear; year <= thisYear + nextYears; year++){
        cardYearFor20Year.push(year)
    }

    return cardYearFor20Year
}

/**
 * @param {string} currencyCode
 * @return {string}
 */
const getCurrencyMark = (currencyCode) => {

    let code = currencyCode.toUpperCase();

    switch (code){
        case "USD": return "$";
        case "JPY": return "¥";
        case "KRW": return "₩";
        default: return "code";
    }
}

/**
 * @param {string} dateString
 * @return {string}
 */
const getFullDate = (dateString) => {
    return moment(dateString).format('MMMM Do YYYY, h:mm:ss a')
}

/**
 * @param {string} dateString
 * @return {string}
 */
const getDateFormatForHTML5 = (dateString) => {
    //2011-12-21
    return moment(dateString).format('YYYY-MM-DD')
}
/**
 * @param {string} dateString
 * @return {string}
 */
const getDateTimeFormatForHTML5 = (dateString) => {
    //2011-12-21T11:33:23
    return moment(dateString).format('YYYY-MM-DDThh:mm:ss')
}
/**
 * @param {string} dateString
 * @return {string}
 */
const getDateTimeFormatForHTML5ToLocal = (dateString) => {
    //2011-12-21T11:33:23
    return moment(dateString).local().format('YYYY-MM-DDThh:mm:ss')
}
/**
 * @param {LanguageContainer} langType
 * @param {Date} date
 * @return {string}
 */
const getDateFormatForLocale = (langType, date) => {
    switch (langType){
        case LanguageContainer.master.english: return moment(date).locale("en").format('MMMM Do YYYY, h:mm:ss a')
        case LanguageContainer.master.japanese: return moment(date).locale("ja").format('YYYY年 MM月 DD日 , h:mm:ss a')
        case LanguageContainer.master.korean: return moment(date).locale("kr").format('YYYY년 MM월 DD일 , h:mm:ss a')
        //August 9th 2016, 7:20:47 am
        default: return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    }

}
/**
 * @param {LanguageMaster} languageMaster
 * @param {Date} date
 * @return {string}
 */
const getDateFormatForLocaleByLanguageMaster = (languageMaster, date) => {
    switch (languageMaster){
        case LanguageMasterDefinition.english: return moment(date).locale("en").format('MMMM Do YYYY, h:mm:ss a')
        case LanguageMasterDefinition.japanese: return moment(date).locale("ja").format('YYYY年 MM月 DD日 , h:mm:ss a')
        case LanguageMasterDefinition.korean: return moment(date).locale("kr").format('YYYY년 MM월 DD일 , h:mm:ss a')
        //August 9th 2016, 7:20:47 am
        default: return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    }

}
const DateFormatter = {
    genUTCDate,
    genUTCEpoch,
    getNextYears,
    getCurrencyMark,
    getFullDate,
    getDateFormatForHTML5,
    getDateTimeFormatForHTML5,
    getDateTimeFormatForHTML5ToLocal,
    getDateFormatForLocale,
    getDateFormatForLocaleByLanguageMaster
};

export default DateFormatter;