import {connect} from "react-redux";
import * as React from "react";
import * as PropTypes from "prop-types";
import * as Loops4BinaryAPI from "../../../api/loops4/Loops4BinaryAPI";
import {ImageSizeMasterDefinition} from "../../../model/formatter/ImageSizeFormatter";
import AbsoluteFullSizeBox from "../../../ui_template/AbsoluteFullSizeBox";
import LibraryViewerSideView from "./LibraryViewerSideView";
import LibraryViewerAdditionMenu from "./LibraryViewerAdditionMenu";
import HeaderToolbarLayouter from "../../../ui_template/HeaderToolbarLayouter";
import LibraryViewerFooterToolbar from "./LibraryViewerFooterToolbar";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};

class LibraryViewerBox extends React.Component {
    static propTypes = {
        boxId: PropTypes.string,
        itemModel: PropTypes.object,
        collectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
        loading: PropTypes.bool,
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            sideViewVisible: true
        }
    }

    render() {

        const imageSrc = Loops4BinaryAPI.getLibraryItemPreviewURL({
            accessTokenModel: this.props.accessTokenModel,
            libraryItemId: this.props.itemModel.entity_id,
            longTermPixel: ImageSizeMasterDefinition.SIZE_1920.longTermPixel,
        })

        let addictionMenu = (
            <div className="flexContainerRow library headerBackground">
                <div className="flexItem11 flexItemAlignStartCenter">
                </div>
                <div className="flexItem00 ">
                    <LibraryViewerAdditionMenu collectionModel={this.props.collectionModel}
                                               itemModel={this.props.itemModel}
                                               accessTokenModel={this.props.accessTokenModel}
                                               sideViewVisible={false}
                                               openSideView={() => {}}
                                               closeSideView={() => {}}
                    />
                </div>

            </div>
        );

        return (
            (
                <div className="flexContainerColumn ">
                    <div className="flexItem00 library headerBackground headerBackgroundShadow">
                        <HeaderToolbarLayouter appContext={this.props.appContextModel} addictionMenu={addictionMenu}/>
                    </div>
                    <div className="flexItem11 library mainContentBackground">
                        <div className=" flexContainerRow">
                            <div className="flexItem11">
                                <AbsoluteFullSizeBox  absoluteStyle={{left: 10, right: 10, top: 10, bottom: 10}}>
                                        <div className=""
                                             style={{width: '100%', height: '100%',
                                                 backgroundSize:'contain', backgroundRepeat:'no-repeat',
                                                 backgroundColor:'#868686',backgroundPosition:'center',
                                                 backgroundImage:`URL(${imageSrc})`}}>
                                        </div>
                                </AbsoluteFullSizeBox>
                            </div>
                            <div className="flexItem00 ">
                                {(this.state.sideViewVisible ? (
                                    <LibraryViewerSideView appContextModel={this.props.appContextModel}
                                                           collectionModel={this.props.collectionModel}
                                                           itemModel={this.props.itemModel}
                                                           languageMaster={this.props.languageMaster}
                                    />
                                ) : null)}
                            </div>
                        </div>                    </div>
                    <div className="flexItem00 library headerBackground">
                        <LibraryViewerFooterToolbar appContext={this.props.appContextModel}
                                               // selectionStore={this.props.selectionStore}
                                               collectionModel={this.props.collectionModel}
                                               boxId={this.props.boxId}
                                               selectionAction={this.selectionAction}
                                               accessTokenModel={this.props.accessTokenModel}
                        />
                    </div>
                </div>
            )
        )
    }
}
export default connect(mapStateToProps)(LibraryViewerBox);