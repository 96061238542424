import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import LanguageDropdown from "../../../../ui_component/LanguageDropdown";
import {RouteQueryParam} from "../../../RouteDictionary";
import {RouteUtility} from "../../../../route/RouteUtility";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        languageMaster: state.languageMaster,
    };
};

class ApplicationSettingMenu extends React.Component{
    static propTypes = {
        language: PropTypes.object,
        languageMaster: PropTypes.object,
    }

    style = {
        mainContainer: {
            padding: 10
        }
    }

    updateLanguage = (nextLanguage) => {
        console.log('nextLanguage', nextLanguage)
        RouteUtility.changeAndPushQueryParameter(RouteQueryParam.language, nextLanguage.value)
    }
    
    render() {
        return (
            <div style={this.style.mainContainer}>
                <LanguageDropdown
                    className={'mini'}
                    updateAction={this.updateLanguage}
                    defaultLanguage={this.props.language}
                />
            </div>
        )

    }
}

export default connect(mapStateToProps)(ApplicationSettingMenu);