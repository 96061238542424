import DateUTCModel from "./DateUTCModel";
import {PaymentMethodContainer} from "../container/PaymentMethodContainer";
import {PaymentCardContainer} from "../container/PaymentCardContainer";
import CommerceMoneyContainer from "../container/money/CommerceMoneyContainer";

export default class BillModel{

    entityId;
    billMoney = CommerceMoneyContainer.defaultMoney();
    billType = "";
    paymentResult = false;
    paymentMessage = "";
    buyerFirstName = "";
    buyerLastName = "";
    buyerAddressLine = "";
    buyerAddressCity = "";
    buyerAddressCountryCode = "";
    buyerAddressPostalCode = "";
    paymentMethod = PaymentMethodContainer.master.card;
    cardNumber = "";
    cardType = PaymentCardContainer.master.unknown;
    cardHolder = "";
    cardValidationExpiringMonth = 0;
    cardValidationExpiringYear = 0;
    cardValidationCode = "";
    paymentRequestedDate = new DateUTCModel();


    /**
     * @param data
     * @return {BillModel}
     */
    static deserialize(data){
        let newModel = new BillModel();

        if(data){
            newModel.entityId = data.entity_id;
            newModel.billMoney = CommerceMoneyContainer.deserialize(data.billMoney);
            newModel.billType = data.billType;
            newModel.paymentResult = data.paymentResult;
            newModel.paymentMessage = data.paymentMessage;
            newModel.buyerFirstName = data.buyerFirstName;
            newModel.buyerLastName = data.buyerLastName;
            newModel.buyerAddressLine = data.buyerAddressLine;
            newModel.buyerAddressCity = data.buyerAddressCity;
            newModel.buyerAddressCountryCode = data.buyerAddressCountryCode;
            newModel.buyerAddressPostalCode = data.buyerAddressPostalCode;
            newModel.paymentMethod = PaymentMethodContainer.deserialize(data.paymentMethod);
            newModel.cardNumber = data.cardNumber;
            newModel.cardType = PaymentCardContainer.deserialize(data.cardType);
            newModel.cardHolder = data.cardHolder;
            newModel.cardValidationExpiringMonth = data.cardValidationExpiringMonth;
            newModel.cardValidationExpiringYear = data.cardValidationExpiringYear;
            newModel.cardValidationCode = data.cardValidationCode;
            newModel.paymentRequestedDate =  DateUTCModel.deserialize(data.paymentRequestedDate);
        }


        return newModel;
    }

}