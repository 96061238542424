import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import * as Loops4RPCAPI from "../../../../../api/loops4/Loops4RPCAPI";
import IconCollectionComponent from "../../../../../ui_component/icon/IconCollectionComponent";
import DimLoaderComponent from "../../../../../ui_template/DimLoaderComponent";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return { accessTokenModel: state.auth.user };
};


class CollectionAdderComponentState {
    dimLoaderFlag = false;
    elasticFilters = [];
}

class CollectionAdderComponent extends React.Component {

    static propTypes = {
        parentBoxId: PropTypes.string,
        loaderAction: PropTypes.func,
    }

    REFS_SET = {
        collectionNameInput: "collectionNameInput",
    };

    constructor(props, context) {
        super(props, context);
        this.state = new CollectionAdderComponentState();

    }

    addCollection(event) {
        event.preventDefault();

        let input = this.refs[this.REFS_SET.collectionNameInput];
        let newCollectionName = input["value"];

        this.setState((prevs, props)=> {
            prevs.dimLoaderFlag = true;
            return prevs;
        }, async () => {
            await Loops4RPCAPI.createLibraryCollection({
                accessTokenModel: this.props.accessTokenModel,
                libraryBoxId: this.props.parentBoxId,
                name: newCollectionName
            })

            input["value"] = null;

            this.setState((prevs, props)=> {
                prevs.dimLoaderFlag = false;
                return prevs;
            }, () => {
                this.props.loaderAction(this.props.parentBoxId);
            });
        });
    }

    updateElasticFilters(filters){
        this.setState((prevs, props)=> {
            prevs.elasticFilters = filters;
            return prevs;
        });
    }

    render() {

        return (
            <div className="ui form">
                <div className="field">
                    <label>Collection Name</label>
                    <div className="ui left icon input">
                        <span style={{position:'absolute', left: 9, top: 11}}><IconCollectionComponent /></span>
                        <input type="text" ref={this.REFS_SET.collectionNameInput}/>
                    </div>
                </div>
                <div className="ui blue submit button" onClick={this.addCollection.bind(this)}>Add Collection</div>
                <DimLoaderComponent loaderFlag={this.state.dimLoaderFlag} />
            </div>
        )
    }
}

export default connect(mapStateToProps)(CollectionAdderComponent);