export class BoxPath{

    parents = []
    currentBoxId = undefined

    constructor(parents, currentBoxId) {
        this.parents = parents;
        this.currentBoxId = currentBoxId;
    }

    getPath(){
        return this.parents.join(BoxPath.pathDivider) + BoxPath.pathDivider + this.currentBoxId;
    }

    static state = {
        open: true,
        close: false
    };

    static pathDivider = "/"

}

class LibraryNavigatorStore{

    cacheNamespace = "LibraryBoxStructure"
    pathMap = {}

    openBox(boxPath){
        let path = boxPath.getPath();
        this.pathMap[path] = BoxPath.state.open

    }

    closeBox(boxPath){
        let path = boxPath.getPath();
        this.pathMap[path] = BoxPath.state.close
    }

    isBoxOpen(boxPath){
        let path = boxPath.getPath();
        let foundValue = this.pathMap[path];
        return (foundValue === BoxPath.state.open)
    }
}

const container = new LibraryNavigatorStore()

export default container;