import * as React from "react";
import * as PropTypes from "prop-types";
import {getAuthorizationHeader} from "../../../../../../../../api/loops4/Loops4RPCAPI";
import MiniLoaderComponent from "../../../../../../../../ui_template/MiniLoaderComponent";
import * as CommerceAction from "../../../../../../../../state/redux/action/CommerceAction";
import {
    ADD_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0,
    GET_RELEASE_ITEM__V1_0,
    REMOVE_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0
} from "../../../../../../../../api/loops4/caller/loops4RpcCaller";
import {GetReleaseItemRPCRequest} from "../../../../../../../../api/loops4/model/release/item/GetReleaseItemRPCRequest";
import {GetReleaseItemRPCResponse} from "../../../../../../../../api/loops4/model/release/item/GetReleaseItemRPCResponse";
import {AddCommerceProductReleaseItemIntoCommerceCartProductRPCRequest} from "../../../../../../../../api/loops4/model/commerce/cart/product/relation_product/AddCommerceProductReleaseItemIntoCommerceCartProductRPCRequest";
import {CommerceOrderProductWithOrderAttributeRPCRequest} from "../../../../../../../../api/loops4/model/commerce/cart/product/relation_product/CommerceOrderProductWithOrderAttributeRPCRequest";
import {OrderPriceRPCBody} from "../../../../../../../../api/loops4/model/commerce/order/common/OrderPriceRPCBody";
import {RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest} from "../../../../../../../../api/loops4/model/commerce/cart/product/relation_product/RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest";
import {connect} from "react-redux";
import {createSelector} from "reselect"
import {
    selectAccessTokenModelForSubscriber,
    selectSubscriberCartId
} from "../../../../../../../../state/redux/selector/GeneralSelector";

const selectMyCartProduct = createSelector(
    [
        /**
         * @param {ReduxStoreState} state
         * @param props
         * @returns {string}
         */
        (state, props) => state.subscriberCommerceCartModel.products,
        /**
         * @param {ReduxStoreState} state
         * @param props
         * @returns {string}
         */
            (state, props) => props.releaseItemId
        ],
    (products, releaseItemId) => products.find(productInCart => productInCart.release_item_entity_id === releaseItemId)
)

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        accessTokenModel: selectAccessTokenModelForSubscriber(state, props),
        myCartProduct: selectMyCartProduct(state, props),
        cartId: selectSubscriberCartId(state, props)
    };
};

class ProductShoppingController extends React.Component {

    static propTypes = {
        // By redux
        accessTokenModel: PropTypes.object,
        myCartProduct: PropTypes.object,
        cartId: PropTypes.string,

        // By parent component
        releaseItemId: PropTypes.string,
        presentationalComponent: PropTypes.any,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context, REFS_SET) {
        super(props, context);
        this.state = {
            isLoading: true,
            releaseItemModel: new GetReleaseItemRPCResponse(),
        };

        this.refreshReleaseItem(props.releaseItemId, props.accessTokenModel)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevProps.releaseItemId != this.props.releaseItemId ||
            prevProps.accessTokenModel != this.props.accessTokenModel
        ) {
            this.refreshReleaseItem(this.props.releaseItemId, this.props.accessTokenModel)
        }
    }


    refreshReleaseItem = async (releaseItemId, accessTokenModel) => {
        const param = new GetReleaseItemRPCRequest()
        param.entity_id = releaseItemId
        const rpcResult = await GET_RELEASE_ITEM__V1_0({
            headers: getAuthorizationHeader(accessTokenModel),
            paramBody: param
        })

        let contentBox = null
        let foundReleaseItemModel = null

        if (rpcResult.isError()) {
            contentBox = (
                <div>
                    Request got failed:: {rpcResult.getErrorMessage()}
                </div>
            )
        } else {
            foundReleaseItemModel = rpcResult.model
        }

        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: false,
                contentBox: contentBox,
                releaseItemModel: foundReleaseItemModel || prevs.releaseItemModel
            }
        })
    }

    pickItemIntoCart = (product) => {
        this.setState((prevs, props)=> {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const product1 = new CommerceOrderProductWithOrderAttributeRPCRequest()
            product1.commerce_sale_product_release_item_entity_id = product.entity_id
            product1.quantity = 1
            product1.bill_price = new OrderPriceRPCBody()
            product1.bill_price.price_currency = product.price_currency
            product1.bill_price.price_amount = product.price_amount
            product1.release_price = new OrderPriceRPCBody()
            product1.release_price.price_currency = product.price_currency
            product1.release_price.price_amount = product.price_amount

            const param = new AddCommerceProductReleaseItemIntoCommerceCartProductRPCRequest()
            param.commerce_cart_product_entity_id = this.props.cartId
            param.commerce_sale_product_release_item_entity_ids = [product1]

            const rpcResult = await ADD_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0({
                headers: getAuthorizationHeader(this.props.accessTokenModel),
                paramBody: param
            })

            if(rpcResult.isError()){
                window.alert(`Request got failed:: ${rpcResult.getErrorMessage()}`)
            }

            CommerceAction.refreshSubscriberCommerceCart()
            this.setState((prevs, props)=> {
                return {
                    ...prevs,
                    isLoading: false
                }
            })
        })
    }

    removeItemFromCart = (product) => {
        this.setState((prevs, props)=> {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const param = new RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest()
            param.commerce_cart_product_entity_id = this.props.cartId
            param.commerce_sale_product_release_item_entity_ids = [product.entity_id]

            const rpcResult = await REMOVE_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0({
                headers: getAuthorizationHeader(this.props.accessTokenModel),
                paramBody: param
            })

            if(rpcResult.isError()){
                window.alert(`Request got failed:: ${rpcResult.getErrorMessage()}`)
            }

            CommerceAction.refreshSubscriberCommerceCart()
            this.setState((prevs, props)=> {
                return {
                    ...prevs,
                    isLoading: false
                }
            })
        })
    }

    render() {
        const { presentationalComponent: PresentationalComponent, ...restProps} = this.props;


        if (this.state.isLoading) {
            return (
                <MiniLoaderComponent loaderFlag={true} themeStyle={MiniLoaderComponent.themeStyle.bright}/>
            )
        } else if(this.state.contentBox) {
            return this.state.contentBox
        } else {
            const childProps = {
                ...restProps,
                isItAddedInCart: (this.props.myCartProduct !== undefined),
                releaseItemModel: this.state.releaseItemModel,
                pickItemIntoCart: this.pickItemIntoCart,
                removeItemFromCart: this.removeItemFromCart,
            }

            if(PresentationalComponent){
                return <PresentationalComponent {...childProps} />
            } else {
                return (
                    <div>
                        presentationalComponent is not set
                    </div>
                )
            }
        }
    }

}

export default connect(mapStateToProps)(ProductShoppingController);