import {OrderAddressRPCBody} from "../../../commerce/order/common/OrderAddressRPCBody";
import {OrderBillPaymentTransactionRPCBody} from "../../../commerce/order/common/OrderBillPaymentTransactionRPCBody";
import {OrderBillPriceRPCBody} from "../../../commerce/order/common/OrderBillPriceRPCBody";

// Package Dir: commerce/order/common
// FileName: OrderBillRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillRPCBody
// Super types: java.lang.Object

export class OrderBillRPCBody {
    constructor() {

        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderAddressRPCBody
         * @type {OrderAddressRPCBody}
         */
        this.payment_sender_address = new OrderAddressRPCBody();
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillPaymentTransactionRPCBody
         * @type {OrderBillPaymentTransactionRPCBody}
         */
        this.payment_transaction = new OrderBillPaymentTransactionRPCBody();
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderBillPriceRPCBody
         * @type {OrderBillPriceRPCBody}
         */
        this.price = new OrderBillPriceRPCBody();

    }
}