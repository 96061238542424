

// Package Dir: commerce/cart/product/relation_product
// FileName: AddCommerceProductReleaseItemIntoCommerceCartProductRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.cart.product.relation_product.AddCommerceProductReleaseItemIntoCommerceCartProductRPCRequest
// Super types: java.lang.Object

export class AddCommerceProductReleaseItemIntoCommerceCartProductRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_cart_product_entity_id = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.cart.product.relation_product.CommerceOrderProductWithOrderAttributeRPCRequest>
         * @type {array<CommerceOrderProductWithOrderAttributeRPCRequest>}
         */
        this.commerce_sale_product_release_item_entity_ids = [];

    }
}