import * as React from "react";
import * as PropTypes from "prop-types";
import MainNavigator from "./MainNavigator";

export default class MainAppLayout extends React.Component {

    static propTypes = {
        style: PropTypes.object,
    }

    render() {
        return (
            <div className="flexContainerRow" style={this.props.style}>
                <div className="flexItem00 ">
                    <MainNavigator />
                </div>
                <div className="flexItem11  flexItemHorizontallyDynamic">
                    {this.props.children}
                </div>
            </div>
        )
    }
}