import {ServiceDomainDictionary} from "../share/dictionary/service_domain.dictionary";

export const endpointLoops4 = ServiceDomainDictionary.rest_api;


export const AuthUserType = {
    user: 'AccountUser_AccountAuthUserTypeMaster',
    admin: 'AccountAdmin_AccountAuthUserTypeMaster',
    subscriber: 'AccountReleaseSubscriber_AccountAuthUserTypeMaster',
    shareSubscriber: 'shareSubscriber',
    none: 'none',
};
