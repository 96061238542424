import * as React from "react";
import {connect} from "react-redux";
import AbsoluteFullSizeBox from "../../ui_template/AbsoluteFullSizeBox";
import LibraryNavigatorContainer from "./navigator/LibraryNavigatorState";
import {RouteListenerComponent} from "../RouteListener";
import {RoutePathParam} from "../RouteDictionary";
import LibraryBrowserBox from "./browser/LibraryBrowserBox";
import {getAuthorizationHeader} from "../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../ui_template/DimLoaderComponent";
import MainAppLayout from "../../screen/layout/MainAppLayout";
import {GET_LIBRARY_COLLECTION__V1_0, GET_LIBRARY_ITEM__V1_0} from "../../api/loops4/caller/loops4RpcCaller";
import {GetLibraryCollectionRPCRequest} from "../../api/loops4/model/library/collection/GetLibraryCollectionRPCRequest";
import {GetLibraryItemRPCRequest} from "../../api/loops4/model/library/item/GetLibraryItemRPCRequest";
import LibraryViewerBox from "./viewer/LibraryViewerBox";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        accessTokenModel: state.auth.user,
        rootLibraryBoxEntityId: state.userInfo.rootLibraryBoxId,
        routeState: state.routeState,
    };
};

class Library extends RouteListenerComponent {

    pageTitleSuffix = 'Intro'

    getPageTitle() {
        return "Library - " + this.pageTitleSuffix
    }

    REFS_SET = {
    };

    constructor(props){
        super(props)
        this.state = {
            browsingBox: this.emptyBox,
            isLoading: true,
        }
    }

    emptyBox = (
        <div>
            Collection ID was not specified
        </div>
    )


    async updateByProps() {
        this.genProperContentBox(this.props)
    }

    genProperContentBox(nextProps, postRouteState){
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true,
                browsingBox: null,
            };
        }, async () => {
            let boxId = nextProps.routeState.pathParameter[RoutePathParam.boxId];
            let collectionId = nextProps.routeState.pathParameter[RoutePathParam.collectionId];
            let itemId = nextProps.routeState.pathParameter[RoutePathParam.itemId];

            let collectionModel
            let itemModel

            if(itemId){
                const requestBody = new GetLibraryItemRPCRequest()
                requestBody.entity_id = itemId

                const rpcResult = await GET_LIBRARY_ITEM__V1_0({
                    headers: getAuthorizationHeader(nextProps.accessTokenModel),
                    paramBody: requestBody
                })

                if(rpcResult.isError()){
                } else {
                    itemModel = rpcResult.model
                }
            }

            if(collectionId){
                const requestBody = new GetLibraryCollectionRPCRequest()
                requestBody.entity_id = collectionId

                const rpcResult = await GET_LIBRARY_COLLECTION__V1_0({
                    headers: getAuthorizationHeader(nextProps.accessTokenModel),
                    paramBody: requestBody
                })

                if(rpcResult.isError()){
                    boxContent = (
                        <div>
                            Request got failed:: {rpcResult.getErrorMessage()}
                        </div>
                    )
                } else {
                    collectionModel = rpcResult.model
                }
            }

            let boxContent

            if(itemModel && collectionModel){
                this.pageTitleSuffix = 'Item - ' + itemModel.item_name
                boxContent = <LibraryViewerBox
                    boxId={boxId}
                    itemModel={itemModel}
                    collectionModel={collectionModel}
                />
            } else if(collectionModel){
                this.pageTitleSuffix = 'Collection - ' + collectionModel.collection_name
                boxContent = <LibraryBrowserBox
                    boxId={boxId}
                    collectionModel={collectionModel}
                    accessTokenModel={nextProps.accessTokenModel}
                />
            } else {
                boxContent = (
                    <div>
                        Collection ID was not specified
                    </div>
                )
            }

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: false,
                    browsingBox: boxContent,
                };
            });
        });
    }


    render() {
        let content;
        let navigation;

        if(this.state.isLoading){
            content = (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark} />
            )
            navigation = content
        }else{
            content = this.state.browsingBox
            navigation = <LibraryNavigatorContainer rootLibraryBoxEntityId={this.props.rootLibraryBoxEntityId} accessTokenModel={this.props.accessTokenModel}/>
        }

        return (
            <MainAppLayout>
                <div className="flexContainerRow " style={{width: '100%'}}>
                    <div className="flexItem00 ">
                        {navigation}
                    </div>
                    <div className="flexItem11  flexItemHorizontallyDynamic">
                        <AbsoluteFullSizeBox>
                            <div className="flexContainerColumn ">
                                <div className="flexItem11">
                                    <AbsoluteFullSizeBox>
                                        {content}
                                    </AbsoluteFullSizeBox>
                                </div>
                            </div>
                        </AbsoluteFullSizeBox>
                    </div>
                </div>
            </MainAppLayout>
        )
    }
}

export default connect(mapStateToProps)(Library);