import * as React from "react";
import {connect} from "react-redux";
import AbsoluteFullSizeBox from "../../../ui_template/AbsoluteFullSizeBox";
import {RouteListenerComponent} from "../../RouteListener";
import {RoutePathParam} from "../../RouteDictionary";
import * as Loops4RPCAPI from "../../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import MainAppLayout from "../../../screen/layout/MainAppLayout";
import DateFormatter from "../../../model/formatter/DateFormatter";
import * as Loops4BinaryAPI from "../../../api/loops4/Loops4BinaryAPI";
import {Link} from "react-router-dom";
import LanguagePack from "../../../language/LanguagePack";
import SignLangPack from "../../../language/SignLangPack";
import SignConstraintPack from "../../../constraints/SignConstraintPack";
import {CurrencyContainer} from "../../../model/container/money/CurrencyContainer";
import {CommerceStockKeepingUnitTypeContainer} from "../../../model/container/CommerceStockKeepingUnitTypeContainer";
import StockKeepingUnitTypeDropdown from "../../../ui_component/StockKeepingUnitTypeDropdown";
import CurrencyTypeDropdown from "../../../ui_component/CurrencyTypeDropdown";
import CommerceProductModel from "../../../model/entity/commerce/CommerceProductModel";
import ReleaseItemModel from "../../../model/entity/release/ReleaseItemModel";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};

class ReleaseItem extends RouteListenerComponent {

    REFS_SET = {
        NAME_INPUT: 'NAME_INPUT'
    };

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            contentBox: null,
            releaseItemModel: new ReleaseItemModel(),
            commerceProductModel: new CommerceProductModel()
        }
    }

    async updateByProps() {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const releaseItemId = this.props.routeState.pathParameter[RoutePathParam.releaseItemId]

            const rpcResult = await Loops4RPCAPI.getReleaseItem({
                accessTokenModel: this.props.accessTokenModel,
                releaseItemId: releaseItemId
            })

            if (rpcResult.isError()) {
                this.setState((prevs, props) => {
                    return {
                        ...prevs,
                        isLoading: false,
                        contentBox: (
                            <div>
                                Request got failed:: {rpcResult.getErrorMessage()}
                            </div>
                        ),
                    }
                })
            } else {
                const foundReleaseItemModel = rpcResult.model
                console.log({foundReleaseItemModel})

                this.setState((prevs, props) => {
                    return {
                        ...prevs,
                        isLoading: false,
                        releaseItemModel: foundReleaseItemModel,
                        commerceProductModel: (foundReleaseItemModel && foundReleaseItemModel.product ? foundReleaseItemModel.product : prevs.commerceProductModel),
                    }
                }, () => {
                    console.log('Init state', this.state)
                })
            }
        })
    }

    async submitUpdatingReleaseItem() {

    }

    submitUpdatingCommerceProduct() {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const productId = (this.state.releaseItemModel.product ? this.state.releaseItemModel.product.entityId : undefined)

            console.log({productId, result: productId === undefined || productId === null})

            let rpcResult
            if(productId === undefined || productId === null){
                rpcResult = await Loops4RPCAPI.createCommerceSaleProductReleaseItem({
                    accessTokenModel: this.props.accessTokenModel,
                    releaseItemId: this.state.releaseItemModel.entityId,
                    stockQuantity: this.state.commerceProductModel.stock.quantity,
                    stockSKUType: this.state.commerceProductModel.stock.skuType.value,
                    priceAmount: this.state.commerceProductModel.price.amount,
                    priceCurrency: this.state.commerceProductModel.price.currency.value,
                })
            } else {
                rpcResult = await Loops4RPCAPI.updateCommerceSaleProductReleaseItem({
                    accessTokenModel: this.props.accessTokenModel,
                    productId: productId,
                    stockQuantity: this.state.commerceProductModel.stock.quantity,
                    stockSKUType: this.state.commerceProductModel.stock.skuType.value,
                    priceAmount: this.state.commerceProductModel.price.amount,
                    priceCurrency: this.state.commerceProductModel.price.currency.value,
                })
            }

            let contentBox = null
            if (rpcResult.isError()) {
                contentBox = (
                    <div>
                        Request got failed:: {rpcResult.getErrorMessage()}
                    </div>
                )
                this.setState((prevs, props) => {
                    return {
                        ...prevs,
                        isLoading: false,
                        contentBox: contentBox,
                    }
                })
            } else {
                await this.updateByProps();
            }
        })
    }

    updateReleaseItemName(e){
        const value = e.target.value;
        this.setState((prevs, props) => {
            const postState = {
                ...prevs
            }
            postState.releaseItemModel.itemName = value;
            return postState
        })
    }
    updatePriceAmount(e){
        const value = e.target.value;
        this.setState((prevs, props) => {
            const postState = {
                ...prevs
            }
            postState.commerceProductModel.price.amount = value;
            return postState
        })
    }
    updatePriceCurrency(value){
        console.log('updatePriceCurrency', value)
        this.setState((prevs, props) => {
            const postState = {
                ...prevs
            }
            postState.commerceProductModel.price.currency = value;
            return postState
        })
    }
    updateProductQuantity(e){
        const value = e.target.value;
        this.setState((prevs, props) => {
            const postState = {
                ...prevs
            }
            postState.commerceProductModel.stock.quantity = value;
            return postState
        })
    }
    updateSKUType(value){
        this.setState((prevs, props) => {
            const postState = {
                ...prevs
            }
            postState.commerceProductModel.stock.skuType = value;
            return postState
        })
    }


    render() {
        let content;

        if (this.state.isLoading) {
            content = (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else if (this.state.contentBox) {
            content = this.state.contentBox
        } else {
            /**
             * @type {ReleaseItemModel}
             */
            const releaseItemModel = this.state.releaseItemModel
            const commerceProductModel = this.state.commerceProductModel

            const imageSrc = Loops4BinaryAPI.getReleaseItemPreviewURL({
                accessTokenModel: this.props.accessTokenModel,
                releaseItemId: releaseItemModel.entityId,
                longTermPixel: 300
            })


            content = (
                <div>
                    <form className="ui form">
                        <div className="two fields">
                            <div className="field">


                                <div className="ui message">
                                    <label style={{marginRight: 10}}>
                                        Release Item Information
                                    </label>
                                    <div className="ui button green mini"
                                         style={{verticalAlign: 'middle', margin: 0}}
                                         onClick={this.submitUpdatingReleaseItem.bind(this)}
                                    >
                                        Update
                                    </div>
                                </div>

                                <div className="field">
                                    <label>
                                        Item name
                                    </label>
                                    <div className="ui left icon input">
                                        <div className="ui left icon input">
                                            <input type="text"
                                                   ref={this.REFS_SET.UPLOAD_INPUT_SELECTOR}
                                                   value={releaseItemModel.itemName}
                                                   onChange={this.updateReleaseItemName.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        Preview
                                    </label>
                                    <div className="ui left icon input">
                                        <img src={imageSrc} style={{maxWidth: 500, maxHeight: 300}}/>
                                    </div>
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        Date created at
                                    </label>
                                    <div className="ui left icon input">
                                        {DateFormatter.getFullDate(releaseItemModel.dateCreated.date.toString())}
                                    </div>
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        Date updated at
                                    </label>
                                    <div className="ui left icon input">
                                        {DateFormatter.getFullDate(releaseItemModel.dateUpdated.date.toString())}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>

                    <div className="ui section divider compact"></div>

                    <form className="ui form">
                        <div className="two fields">
                            <div className="field">
                                <div className="ui message">
                                    <label style={{marginRight: 10}}>
                                        Product Information
                                    </label>
                                    <div className="ui button green mini"
                                         style={{verticalAlign: 'middle', margin: 0}}
                                         onClick={this.submitUpdatingCommerceProduct.bind(this)}
                                    >
                                        Update
                                    </div>
                                </div>

                                <div className="field">
                                    <label>
                                        Is price information set?
                                    </label>
                                    <div className="ui left icon input">
                                        {(releaseItemModel.product ? 'YES' : 'NO')}
                                    </div>
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        Price Amount
                                    </label>
                                    <div className="ui left icon input">
                                        <input type="text"
                                               ref={this.REFS_SET.UPLOAD_INPUT_SELECTOR}
                                               value={commerceProductModel.price.amount}
                                               onChange={this.updatePriceAmount.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        Price Currency
                                    </label>
                                    <div className="ui left icon input">
                                        <CurrencyTypeDropdown language={this.props.language}
                                                              currencyType={commerceProductModel.price.currency}
                                                              updateAction={this.updatePriceCurrency.bind(this)}
                                        />
                                    </div>
                                </div>


                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        Price Quantity
                                    </label>
                                    <div className="ui left icon input">
                                        <input type="text"
                                               ref={this.REFS_SET.UPLOAD_INPUT_SELECTOR}
                                               value={commerceProductModel.stock.quantity}
                                               onChange={this.updateProductQuantity.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        Price Stock Keeping Unit
                                    </label>
                                    <div className="ui left icon input">
                                        <StockKeepingUnitTypeDropdown language={this.props.language}
                                                                      stockKeepingUnitType={commerceProductModel.stock.skuType}
                                                                      updateAction={this.updateSKUType.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )
        }

        return (
            <MainAppLayout>
                <div className="flexContainerRow " style={{width: '100%'}}>
                    <div className="flexItem11  flexItemHorizontallyDynamic">
                        <AbsoluteFullSizeBox>
                            <div className="flexContainerColumn ">
                                <div className="flexItem11">
                                    <AbsoluteFullSizeBox>
                                        {content}
                                    </AbsoluteFullSizeBox>
                                </div>
                            </div>
                        </AbsoluteFullSizeBox>
                    </div>
                </div>
            </MainAppLayout>
        )
    }
}

export default connect(mapStateToProps)(ReleaseItem);