import {SHIPPING_STATE_MASTER} from "../../api/loops4/master/shipping/shipping_state_master";
import {SHIPPING_METHOD_MASTER} from "../../api/loops4/master/shipping/shipping_method_master";
import {getShippingArchiveFileURL} from "../../api/loops4/Loops4BinaryAPI";
import * as React from "react";

export const getShippingStateAsLocal = (shippingState) => {
    switch (shippingState) {
        case SHIPPING_STATE_MASTER.PENDING_SHIPPING: return "配送保留"
        case SHIPPING_STATE_MASTER.PREPARING_SHIPPING: return "配送準備中"
        case SHIPPING_STATE_MASTER.REQUESTING_SHIPPING: return "配送要請中"
        case SHIPPING_STATE_MASTER.DELIVERED_SHIPPING: return "配送完了"
        case SHIPPING_STATE_MASTER.DELIVERING_SHIPPING: return "配送中"
        case SHIPPING_STATE_MASTER.CANCELLED_SHIPPING: return "配送取り消し"
        case SHIPPING_STATE_MASTER.EXPIRED_SHIPPING: return "配送満了"
        default: {
            const errMessage = `Unknown shipping state:: ${shippingState}`
            console.error(errMessage)
            return errMessage
        }
    }
}

export const isInactiveState = (shippingState) => {
    switch (shippingState) {
        case SHIPPING_STATE_MASTER.PENDING_SHIPPING:
        case SHIPPING_STATE_MASTER.PREPARING_SHIPPING:
        case SHIPPING_STATE_MASTER.REQUESTING_SHIPPING:
        case SHIPPING_STATE_MASTER.DELIVERED_SHIPPING:
        case SHIPPING_STATE_MASTER.DELIVERING_SHIPPING: return false
        case SHIPPING_STATE_MASTER.CANCELLED_SHIPPING:
        case SHIPPING_STATE_MASTER.EXPIRED_SHIPPING: return true
        default: {
            const errMessage = `Unknown shipping state:: ${shippingState}`
            console.error(errMessage)
            return errMessage
        }
    }
}


export const getShippingMethodAsLocal = (shippingMethod) => {
    switch (shippingMethod) {
        case SHIPPING_METHOD_MASTER.DIGITAL_CONTENT_FILE_DOWNLOAD: return "デジタルコンテンツ転送"
        case SHIPPING_METHOD_MASTER.DELIVERY_PROVIDER_DOMESTIC: return "国内配送"
        case SHIPPING_METHOD_MASTER.DELIVERY_PROVIDER_INTERNATIONAL: return "国際配送"
        case SHIPPING_METHOD_MASTER.HAND_TO_HAND: return "手渡し"
        default: {
            const errMessage = `Unknown shipping method:: ${shippingMethod}`
            console.error(errMessage)
            return errMessage
        }
    }
}

export const renderDigitalFileDownloader = (accessTokenModel, shippingId, shippingState, shippingMethod, index, total) => {
    if(
        shippingMethod === SHIPPING_METHOD_MASTER.DIGITAL_CONTENT_FILE_DOWNLOAD &&
        shippingState === SHIPPING_STATE_MASTER.DELIVERING_SHIPPING
    ) {
        const isDelivering = shippingState === SHIPPING_STATE_MASTER.DELIVERING_SHIPPING

        const shippingArchiveURL = isDelivering ? getShippingArchiveFileURL({
            accessTokenModel: accessTokenModel,
            shippingId: shippingId
        }) : null

        const classDef = 'ui button ' + (isDelivering ? 'yellow' : 'grey disabled')
        const suffixForIndex = ` (${index}/${total})`
        const buttonMessage = (isDelivering ? `ダウンロード` : 'ダウンロード不可') + suffixForIndex

        if(
            shippingMethod === SHIPPING_METHOD_MASTER.DIGITAL_CONTENT_FILE_DOWNLOAD &&
            shippingState === SHIPPING_STATE_MASTER.DELIVERING_SHIPPING
        ){
            return (
                <a href={shippingArchiveURL}
                   target={'_blank'}
                   className={classDef}
                >
                    {buttonMessage}
                </a>
            )
        }
    } else {
        return null
    }
}