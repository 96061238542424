import * as React from "react";
import {connect} from "react-redux";
import ServiceInfoConstant from "../../constant/ServiceInfoConstant";
import {RouteUtility} from "../../route/RouteUtility";
import {Link} from "react-router-dom";
import {RouteDictionary, RoutePathParam} from "../../route/RouteDictionary";
import {deleteAccessToken} from "../../api/loops4/AuthAPI"
import {clearAccessTokenForUser} from "../../state/redux/action/AuthAction";
import {clearAccountUserInfo} from "../../state/redux/action/UserAction";
import {pushHistory} from "../../route/RouteHistory";
/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        rootLibraryBoxId: state.userInfo.rootLibraryBoxId,
        rootStoreId: state.userInfo.rootStoreId,
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};


class NavigatorMainButtonContainerState {
    titleContainer = null;
    isNaviExpanded = false;
}

class MainNavigator extends React.Component {

    REFS_SET = {
    };

    constructor(props, context){
        super(props, context);
        this.state = new NavigatorMainButtonContainerState();
    }

    iconTdStyle = {
        padding:6,paddingTop:10,paddingBottom:10
    };
    labelTdStyle = {
        width:'100%'
    };

    inActiveStyle = {
        border:0, width:'100%',background:'inherit'
    };
    activeStyle = {
        border:0, width:'100%',backgroundColor:'#24272d'
    };


    async signOut(){

        try{
            await deleteAccessToken({
                accessTokenModel: this.props.accessTokenModel,
            })
        } catch (e) {
            console.error(e)
        } finally {
            pushHistory(RouteUtility.getPathname(RouteDictionary.signIn))
            clearAccessTokenForUser()
            clearAccountUserInfo()
        }
    }

    genTr(iconClass, label, style = {}){
        return (
            <div className="flexItem00" style={{width:'100%'}}>
                <div className="flexContainerColumn flexItemAlignCenterCenter"
                     // style={Object.assign({width: '100%', padding: 8}, (activeButton === activeConst ? this.activeStyle : this.inActiveStyle), customStyle)}
                     style={Object.assign({width: '100%', padding: 8}, style)}
                >
                    <div className="flexItem00">
                        {/*<i className={`icon large ${iconClass} ${(activeButton === activeConst ? 'white' : null)}`} />*/}
                        <i className={`icon large ${iconClass}`} />
                    </div>
                    <div className="flexItem11" style={{fontSize: 10}}>
                        <div style={this.labelTdStyle}>
                            {label}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    genSubtreeElement(title){
        return (
            <div className="flexContainerRow" style={{padding: 10, minHeight: 35}} >
                <div className="flexItem00" style={{marginRight: 5, marginLeft: 10}}><i className="ui icon minus small" /> </div>
                <div className="flexItem11">{title}</div>
            </div>
        )
    }


    render() {
        return (
            <div className="flexContainerColumn "
                 style={{backgroundColor:'#2b333d', color:'white', transition:'all 0.1s ease-in'}}
            >
                <div className="flexItem00">
                    <div className="flexContainerColumn flexItemAlignStartCenter">
                        <Link to={RouteUtility.getPathname(RouteDictionary.serviceMainHomepage)}>
                            {this.genTr('green', ServiceInfoConstant.serviceName, {color:'#21ba45'})}
                        </Link>

                        <div className="ui fitted divider" />

                        <Link to={RouteUtility.getPathname(RouteDictionary.library, {
                            [RoutePathParam.boxId]: this.props.rootLibraryBoxId
                        })}>
                            {this.genTr('disk outline grey', 'LIBRARY')}
                        </Link>

                        <div className="ui fitted divider" />

                        <Link to={RouteUtility.getPathname(RouteDictionary.store,{
                            [RoutePathParam.storeId]: this.props.rootStoreId
                        })}>
                            {this.genTr('cubes grey', 'STORE')}
                        </Link>


                        <div className="ui fitted divider" />

                        <Link to={RouteUtility.getPathname(RouteDictionary.store)}>
                            {this.genTr( 'user grey', 'USER')}
                        </Link>

                        <div className="ui fitted divider" />
                    </div>
                </div>
                <div className="flexItem11 flexItemVerticallyDynamic">
                </div>
                <div className="flexItem00 ">
                    <div className="flexContainerColumn flexItemAlignEndCenter">
                        <div onClick={this.signOut.bind(this)}>
                            {this.genTr( 'sign out red', 'SIGN OUT')}
                        </div>
                    </div>
                </div>
            </div>

        );
  }
}
export default connect(mapStateToProps)(MainNavigator);