export default class ConstraintPack {

    minLength;
    maxLength;
    regEx;

    /**
     *
     * @param {number} minLength
     * @param {number} maxLength
     * @param {RegExp} regEx
     */
    constructor(minLength, maxLength, regEx){
        this.minLength = minLength;
        this.maxLength = maxLength;
        this.regEx = regEx;
    }

}