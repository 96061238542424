import {CurrencyContainer} from "./CurrencyContainer";

export default class CommerceMoneyContainer{

    currency = CurrencyContainer.master.JPY;

    amount = 0;


    /**
     * @return {CommerceMoneyContainer}
     */
    static defaultMoney(){
        return new CommerceMoneyContainer(
            CurrencyContainer.master.JPY,
            0
        );
    }

    /**
     * @param {CurrencyContainer} currency
     * @param {number} amount
     */
    constructor(currency, amount) {
        this.currency = currency;
        this.amount = amount;
    }

    /**
     *
     * @param data
     * @return {CommerceMoneyContainer}
     */
    static deserialize(data){
        let newModel = new CommerceMoneyContainer(
            CurrencyContainer.deserialize(data.price_currency),
            data.price_amount
        );

        return newModel;
    }
}