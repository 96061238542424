const startURL = ''

export const RoutePathParam = {
    boxId:"boxId",
    collectionId:"collectionId",
    publishId:"publishId",
    releaseCollectionId:"releaseCollectionId",
    releaseItemId:"releaseItem",
    storeId:"storeId",
    orderId:"orderId",
    itemId:"itemId",
    shippingId:"shippingId",
    shareId:"shareId"
}

export const RouteQueryParam = {
    rating:"rating",
    pin:"pin",
    fitems:"fitems",
    keyword:"keyword",
    language:"lang",
    accessKey:"accessKey",
    forwarded: "forwarded",
    pageOrders:"pageOrders",
    pagePublishes:"pagePublishes",
    pagePublishProducts:"pagePublishProducts",
    sourceCollectionId:"sourceCollectionId",
    view_mode: 'view_mode',

    access_key: 'access_key'
}

export const RouteDictionary = {

    // General
    serviceMainHomepage: `/`,
    index: `${startURL}/`,
    notFound: `${startURL}/*`,

    // Application
    signIn: `${startURL}/sign/in`,
    signUp: `${startURL}/sign/up`,
    library: `${startURL}/library`,
    box: `${startURL}/library/box/:${RoutePathParam.boxId}`,
    collection: `${startURL}/library/box/:${RoutePathParam.boxId}/collection/:${RoutePathParam.collectionId}`,
    item: `${startURL}/library/box/:${RoutePathParam.boxId}/collection/:${RoutePathParam.collectionId}/item/:${RoutePathParam.itemId}`,
    store: `${startURL}/store/:${RoutePathParam.storeId}`,
    storeReleaseFormByLibraryCollection: `${startURL}/store/:${RoutePathParam.storeId}/release_form`,
    orderDetailInReleaseCollection: `${startURL}/store/:${RoutePathParam.storeId}/release/collection/:${RoutePathParam.releaseCollectionId}/order/:${RoutePathParam.orderId}`,
    releaseCollection: `${startURL}/store/:${RoutePathParam.storeId}/release/collection/:${RoutePathParam.releaseCollectionId}/browsing`,
    releaseItem: `${startURL}/store/:${RoutePathParam.storeId}/release/collection/:${RoutePathParam.releaseCollectionId}/item/:${RoutePathParam.releaseItemId}`,

    // Subscriber
    subscriberSignIn: `${startURL}/publish/collection/:${RoutePathParam.releaseCollectionId}/sign/in`,
    publishReleaseCollection: `${startURL}/publish/collection/:${RoutePathParam.releaseCollectionId}`,
    orderInPublishReleaseCollection: `${startURL}/publish/collection/:${RoutePathParam.releaseCollectionId}/order/:${RoutePathParam.orderId}`,

    // Admin
    adminSignIn: `${startURL}/admin/sign/in`,
    adminPortal: `${startURL}/admin/portal`,
    adminPortal_OrderDetail: `${startURL}/admin/portal/order/:${RoutePathParam.orderId}`,
}
