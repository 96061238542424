import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import CollectionViewingAddictionMenu from "./LibraryBrowserAddictionMenu";
import HeaderToolbarLayouter from "../../../ui_template/HeaderToolbarLayouter";
import SelectionModel from "../../../model/entity/SelectionModel";
import * as ItemViewerInBrowserFactory from "./viewer/ItemViewerInBrowserFactory";
import ScrollReachLoader from "../../../ui_component/ScrollReachLoader";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
        rootStoreId: state.userInfo.rootStoreId,
    };
};

class LibraryBrowserBox extends React.Component {

    static propTypes = {
        boxId: PropTypes.string,
        collectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
        rootStoreId: PropTypes.string,
        loading: PropTypes.bool,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: true,
            mainContent: null,
            itemViewers: []
        }
    }

    // selectionAction(selectModelId:PrimaryKeyModel, selectFlag:boolean, keyEvent) {
    //     SelectionAction.selectionAction(selectModelId, selectFlag, this.props.collectionModel.items, keyEvent)
    // }

    componentDidMount() {
        this.updateMainContent(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateMainContent(nextProps)
    }

    itemPerPage = 20
    nextPage = (page) => {
        const itemsInPage = this.state.itemViewers.slice(this.itemPerPage * (page - 1), this.itemPerPage * page)

        if(itemsInPage.length > 0){
            return itemsInPage
        } else {
            return null
        }
    }


    updateMainContent(nextProps){
        if(nextProps.collectionModel.items.length < 1){
            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: false,
                    mainContent: this.genNoneContentMessage(),
                }
            })
        } else {
            const selections = this.props.collectionModel.items.map(item => {
                // const isSelected = (this.state.selectionStore.getAllItems().filter(selectedItem => selectedItem._id === item._id).length) > 0;
                const isSelected = false;
                return new SelectionModel(isSelected, item)
            });
            const itemViewers = ItemViewerInBrowserFactory.getItemComponentsInCollectionViewer(
                this.props.accessTokenModel,
                this.props.boxId,
                this.props.collectionModel.entity_id,
                selections,
                this.selectionAction,
                // this.state.selectionStore.getLastSelectedItem()
                ''
            );

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: false,
                    mainContent: null,
                    itemViewers: itemViewers
                }
            })
        }
    }

    genNoneContentMessage(){
        let message = null;
        // if(this.props.appContextModel.activityModel.componentOption_elasticFilterQuery.filterOn === true){
        //     //Empty when filter on
        //     message =  (
        //     <div className="flexItem00">
        //         <i className="ui icon filter huge circular inverted" /><br/>
        //         <br/>
        //         No Photos with this filter<br/>
        //         Please adjust filter option
        //     </div>
        //     )
        // }else{
            //Empty collection
            message = (
                <div className="flexItem00">
                    <i className="ui icon picture huge circular inverted" /><br/>
                    <br/>
                    <span>
                        No Photos
                    </span><br/>
                    <span>
                        Drag and drop here your photos!
                    </span>
                </div>
            )
        // }

        return (
            <div className="flexContainerColumn flexItemAlignCenterCenter" style={{color:'white'}}>
                <div className="flexItem11"></div>
                <div className="flexItem00 flexContainerRow flexItemAlignCenterCenter">
                    <div className="flexItem11"></div>
                    <div className="flexItem00 flexItemAlignStartStart">
                        {message}
                    </div>
                    <div className="flexItem11"></div>
                </div>
                <div className="flexItem11"></div>
            </div>
        )
    }

    unSelectCSS = {
        MozUserSelect: "none",
        KhtmlUserSelect: "none",
        WebkitUserSelect: "none",

        /*
         Introduced in IE 10.
         See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
         */
        MsUserSelect: "none",
        userSelect: "none"
    }

    noPhotoMessageStyle = {width:'100%',height:'100%',textAlign:'center',verticalAlign:'middle'}

    /* =========================================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/
    /* =========================================================================================================================*/


    render() {

        /* ==================================================== PRE-PROCESSING ==========================================================*/


        /* ==================================================== RETURN RENDERING ==========================================================*/

        if(this.state.isLoading){
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark} />
            )
        }else{

            let addictionMenu = (
                <div className="flexContainerRow library headerBackground">
                    <div className="flexItem11 flexItemAlignStartCenter">
                    </div>
                    <div className="flexItem00 ">
                        <CollectionViewingAddictionMenu collectionModel={this.props.collectionModel}
                                                        // selectionStore={this.state.selectionStore}
                                                        accessTokenModel={this.props.accessTokenModel}
                                                        rootStoreId={this.props.rootStoreId}
                        />
                    </div>

                </div>
            );

            return  (
                <div className="flexContainerColumn ">
                    <div className="flexItem00 library headerBackground headerBackgroundShadow">
                        <HeaderToolbarLayouter appContext={this.props.appContextModel} addictionMenu={addictionMenu}/>
                    </div>
                    <div className="flexItem00 library headerBackgroundShadow" style={{backgroundColor:'grey'}}>
                        <div className="flexContainerRow">
                            <div className="flexItem11">
                                <div className="flexContainerRow flexItemAlignStartCenter">
                                    <div className="flexItem00">
                                        {/*<FlatButton label={(<i className="material-icons">sort_by_alpha</i>)}*/}
                                        {/*            className="compact mini" />*/}
                                    </div>
                                    <div className="flexItem00">
                                        {/*<FlatButton label={(*/}
                                        {/*<div><span style={{marginTop: -15}}>NAME</span><i className="material-icons">arrow_drop_down</i></div>*/}
                                    {/*)} className="compact mini" />*/}
                                    </div>
                                </div>
                            </div>
                            <div className="flexItem11">
                                <div className="flexContainerRow flexItemAlignEndCenter">
                                    <div className="flexItem00">
                                        {/*<FlatButton label={(<i className="material-icons">view_list</i>)}*/}
                                        {/*            className="compact mini"/>*/}
                                        {/*<FlatButton label={(<i className="material-icons">view_comfy</i>)}*/}
                                        {/*            className="compact mini"/>*/}
                                    </div>
                                    <div className="flexItem00">
                                        &nbsp; | &nbsp;
                                    </div>
                                    <div className="flexItem00">
                                        {/*<FlatButton label={(<i className="material-icons">style</i>)}*/}
                                        {/*            className="compact mini"/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.state.mainContent ? (
                        <div className={'flexItem11 library mainContentBackground'}>
                            {this.state.mainContent}
                        </div>
                    ) : (
                        <ScrollReachLoader className={'flexItem11 library mainContentBackground'}
                                           style={{}}
                                           renderNextPage={this.nextPage}
                        />
                    ))}
                    <div className="flexItem00 library headerBackground ">
                        {/*<FooterToolbarLayouter appContext={this.props.appContextModel}*/}
                        {/*                       selectionStore={this.state.selectionStore}*/}
                        {/*                       collectionModel={this.props.collectionModel}*/}
                        {/*                       selectionAction={this.selectionAction.bind(this)}/>*/}
                    </div>
                </div>

            )
        }
    }
}

export default connect(mapStateToProps)(LibraryBrowserBox);