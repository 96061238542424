import * as React from "react";
import * as PropTypes from "prop-types";

export class ScrollableTable extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        header: PropTypes.object,
        headerStyle: PropTypes.object,
        rows: PropTypes.arrayOf(PropTypes.object),
        rowStyle: PropTypes.object,
    }


    REFS_SET = {
        table: 'table'
    };

    componentDidMount() {
        // $(this.refs[this.REFS_SET.table]).tableHeadFixer({
        //     head: true
        // });
    }

    render() {
        return (
            <table ref={this.REFS_SET.table} className={this.props.className} style={Object.assign({height:'100%'}, this.props.style)}>
                <thead >
                    {this.props.header}
                </thead>
                <tbody className="verticalScroll" >
                    {this.props.rows}
                </tbody>
            </table>
        )
    }
}
