import React from 'react';
import '../../App.css';
import SignLangPack from "../../language/SignLangPack";
import LanguagePack from "../../language/LanguagePack";
import {connect} from "react-redux";
import SignConstraintPack from "../../constraints/SignConstraintPack";
import {RouteDictionary} from "../RouteDictionary";
import {Link} from "react-router-dom";
import ServiceInfoConstant from "../../constant/ServiceInfoConstant"
import {clearFullSizeDIM, setFullSizeDIM} from "../../state/redux/action/UIControlAction"
import {clearAccessTokenForUser, setAccessTokenForUser} from "../../state/redux/action/AuthAction"
import {issueAccessToken} from "../../api/loops4/AuthAPI"
import {AccessTokenModel} from "../../model/auth/AccessTokenModel";
import LoadingComponent from "../../ui_template/LoadingComponent";
import {pushHistory} from "../RouteHistory";
import {AuthUserType} from "../../constant/APIConstant";
import {RouteListenerComponent} from "../RouteListener";
import {clearAccountUserInfo, setAccountUserInfo} from "../../state/redux/action/UserAction";
import DomIdConstant from "../../constant/DomIdConstant";
import {APPLICATION_VERSION} from "../../constant/ApplicationVersionConstant";
import {AccountAuthorizedServiceClient} from "../../proto/account/AccountServiceServiceClientPb"
import {Empty} from "google-protobuf/google/protobuf/empty_pb";
import {getGrpcClient} from "../../api/pickhours/grpc/GrpcClientFactory";

const grpcClient = getGrpcClient(AccountAuthorizedServiceClient)

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
    };
};

class SignIn extends RouteListenerComponent {

    REFS_SET = {
        signForm_accountId: 'signForm_accountId',
        signForm_accountPassword: 'signForm_accountPassword',
    }

    signIn(e){
        e.preventDefault();

        setFullSizeDIM(<LoadingComponent loaderFlag={true} />)

        let accountId = this.refs[this.REFS_SET.signForm_accountId]["value"];
        let accountPassword = this.refs[this.REFS_SET.signForm_accountPassword]["value"];

        let accessToken;

        issueAccessToken({
            accountId,
            accountPassword,
            AuthUserType: AuthUserType.user,
            },
        )
            .then(async (response) => {
                const responseJson = await response.json()
                accessToken = new AccessTokenModel({
                    accessToken: responseJson['access_token'],
                    lifeSeconds: responseJson['life_seconds'],
                    refreshToken: responseJson['refresh_token'],
                    createdAt: responseJson['created_at'],
                    scope: responseJson['scope'],
                    tokenType: responseJson['token_type']
                })

                try {
                    const userInfoResult = await grpcClient.getAccountInformationForUser(new Empty(), {
                        Authorization: `${accessToken.tokenType} ${accessToken.accessToken}`
                    })

                    return userInfoResult
                // } catch (ex: ErrorResponse) {
                } catch (ex) {
                    throw Error(`failed to get user information, Please try it again or inquiry to administrator`)
                }
            }).then( (accountUserModel) => {
                setAccessTokenForUser(accessToken)
                setAccountUserInfo(accountUserModel)
                pushHistory(RouteDictionary.library)
            })
            .catch(e => {
                clearAccessTokenForUser()
                clearAccountUserInfo()
                alert(`failed to sign in, please check your account e-mail and secretKey, Error:: ${e.message}`)
            })
            .finally(_ => {
                clearFullSizeDIM();
            })
    }


  render() {
    const langType = this.props.language

    return (
        <div style={{width:'100%', height:'100%', backgroundColor: '#EFEFEF',
            backgroundImage: `url('${process.env.PUBLIC_URL}/assets/img/backgrounds/trimed2_1080.jpg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: '70% 0px'
        }} >
            <div style={{verticalAlign:'middle'}}>
                {/*<IntroMenu isLoginButton={false} parentProps={this.props} />*/}

                <div className="ui container" style={{position:'relative', zIndex:100, paddingTop:'20%', paddingBottom:'20%'}}>

                    <div style={{width:390}}>
                        <div>
                            <h2>
                                {ServiceInfoConstant.serviceName}<br/>
                            </h2>
                            <h4 style={{marginTop:0, color: '#CCC'}}>
                                v{APPLICATION_VERSION}
                            </h4>
                        </div>

                        <div className="ui hidden divider"></div>

                        <form className="ui form" onSubmit={this.signIn.bind(this)}>
                            <div className="field">
                                <label>
                                    {LanguagePack.extract(langType, SignLangPack.fields.email)}
                                </label>
                                <div className="ui left icon input">
                                    <input type="email"
                                           placeholder="email@domain.com"
                                           autoFocus={true}
                                           id={DomIdConstant.DomIdSignIn.accountId}
                                           ref={this.REFS_SET.signForm_accountId}
                                           minLength={SignConstraintPack.email.minLength}
                                           maxLength={SignConstraintPack.email.maxLength}
                                    />
                                    <i className="mail icon" />
                                </div>
                            </div>
                            <div className="field">
                                <label>
                                    {LanguagePack.extract(langType, SignLangPack.fields.password)}
                                </label>
                                <div className="ui left icon input">
                                    <input type="password"
                                           id={DomIdConstant.DomIdSignIn.accountPassword}
                                           ref={this.REFS_SET.signForm_accountPassword}
                                           minLength={SignConstraintPack.password.minLength}
                                           maxLength={SignConstraintPack.password.maxLength}
                                    />
                                    <i className="lock icon" />
                                </div>
                            </div>
                            <button className="ui blue submit green button"
                                    id={DomIdConstant.DomIdSignIn.submitButton}
                            >
                                {LanguagePack.extract(langType, SignLangPack.buttons.signIn)}
                            </button>
                        </form>

                        <div className="ui section divider compact"></div>


                        <div>
                            {LanguagePack.extract(langType, SignLangPack.guide.doNotYouHaveAnAccount)} &nbsp;&nbsp;
                            <Link to={RouteDictionary.signUp} >
                                <div className="ui button basic compact green">
                                    {LanguagePack.extract(langType, SignLangPack.buttons.signUp)}
                                </div>
                            </Link>
                        </div>

                        <div className="ui section divider compact"></div>

                        <div className="ui">
                            <p>
                                {LanguagePack.extract(langType, SignLangPack.guide.contactUsIfYouHaveSomething)}
                                <a href={`mailto:${ServiceInfoConstant.departments.concierge.email}`} style={{color:'#93C4F5'}}>{ServiceInfoConstant.departments.concierge.email}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{position:'absolute', left:0, right:0, top:'100%', zIndex:100}}>
                {/*<IntroFooter parentProps={this.props} />*/}
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(SignIn);