import {LanguageContainer} from "../model/container/LanguageContainer";

export default class LanguagePack {
    constructor(english, japanese, korean){
        this.english = english;
        this.korean = korean;
        this.japanese = japanese;
    }

    static extract(languageType, languagePack){
        switch (languageType) {
            case LanguageContainer.master.english: return (languagePack.english);
            case LanguageContainer.master.japanese : return (languagePack.japanese);
            case LanguageContainer.master.korean : return (languagePack.korean);
            default : {
                return (languagePack.english);
            }
        }
    }
}