import {LanguageContainer} from "../../../../model/container/LanguageContainer";
import {LanguageFormatter} from "../../../../model/formatter/LanguageFormatter";
import {RouteQueryParam} from "../../../../route/RouteDictionary";
import {ROUTE_SET_STATE, SET_ROUTER_INJECTOR} from "../../constant/ActionType";

export const RouterInjector = ({ getState, dispatch }) => next => action => {
    const returnValue = next(action)

    // Hook ROUTE_SET_STATE update
    // and update master data following route state
    if(returnValue.type === ROUTE_SET_STATE){
        /**
         * @type {RouteState}
         */
        const routeState = returnValue.payload

        const languageValueByQuery = routeState.queryParameter[RouteQueryParam.language]

        if(languageValueByQuery){
            let languageMasterByContainer = LanguageContainer.deserialize(languageValueByQuery);
            if(languageMasterByContainer === undefined || languageMasterByContainer === null)
                languageMasterByContainer = LanguageContainer.master.japanese;

            let languageMasterByFormatter = LanguageFormatter.getMaster(languageValueByQuery) ;
            if(languageMasterByFormatter === undefined || languageMasterByFormatter === null)
                languageMasterByFormatter = LanguageFormatter.LanguageMasterDefinition.japanese;


            dispatch({
                type: SET_ROUTER_INJECTOR,
                payload: {
                    languageMasterByContainer,
                    languageMasterByFormatter
                }
            })
        }
    }

    return returnValue
}