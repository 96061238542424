import * as React from "react";
import PictureItemInToolbarComponent from "./PictureItemInToolbarComponent";

/**
 * @param {AccessTokenModel} accessTokenModel
 * @param {string} boxId
 * @param {string} collectionId
 * @param {GetLibraryItemRPCResponse} itemModel
 * @param {boolean} isSelected
 * @param {function} selectAction
 * @param {boolean} isSelectedLastly
 */
export const getItemComponentsInToolbarViewer = (accessTokenModel,
                                                 boxId,
                                                 collectionId,
                                                 itemModel,
                                                 isSelected,
                                                 selectAction = (picturePK, selectFlag, keyEvent) => {},
                                                 isSelectedLastly
) => {

    return (
        <PictureItemInToolbarComponent
            accessTokenModel={accessTokenModel}
            lastSelectFlag={isSelectedLastly}
            isSelected={isSelected}
            selectAction={selectAction}
            itemModel={itemModel}
            collectionId={collectionId}
            boxId={boxId}
        />
    )
}