export default class SelectionModel{
    /**
     * @param {boolean} selectFlag
     * @param {object} model
     */
    constructor(selectFlag, model) {
        this.selectFlag = selectFlag;
        this.model = model;
    }
    
    selectFlag = false;
    model = null;

    /**
     * @param data
     * @return {SelectionModel}
     */
    static deserialize(data) {
        let newModel = new SelectionModel(false, null);

        if(data){
            newModel = new SelectionModel(data.selectFlag, data.cartModel);
        }

        return newModel;
    }

}