import * as React from "react";
import * as PropTypes from "prop-types";
import LanguagePack from "../../../../../language/LanguagePack";
import OrderHistoryLangPack from "../../../../../language/publish/order/OrderHistoryLangPack";
import CompactOrderHistoryRow from "./viewer/CompactOrderHistoryRow";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import CommerceOrderHistoryModel from "../../../../../model/entity/commerce/CommerceOrderHistoryModel";
import {connect} from "react-redux";
import ReleaseCollectionModel from "../../../../../model/entity/release/ReleaseCollectionModel";
import {GET_ORDER_HISTORY__V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {ReduxMainStore} from "../../../../../state/redux/store/ReduxMainStore";
import {COMMERCE_ORDER_HISTORY_SET_SUBSCRIBER} from "../../../../../state/redux/constant/ActionType";
import {LanguageMaster} from "../../../../../model/formatter/LanguageFormatter";
import LoadingComponent from "../../../../../ui_template/LoadingComponent";
import {GetOrderHistoryRPCRequest} from "../../../../../api/loops4/model/commerce/order/get_order_history/GetOrderHistoryRPCRequest";
import AccountSubscriberModel from "../../../../../model/entity/account/AccountSubscriberModel";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        ...props,
        subscriberOrderHistory: state.subscriberOrderHistory
    };
};

class PublishReleaseCollectionOrderHistoryBox extends React.Component {

    static propTypes = {
        language: PropTypes.object.isRequired,
        languageMaster: PropTypes.objectOf(LanguageMaster),
        accessTokenModel: PropTypes.object.isRequired,

        subscriber: PropTypes.objectOf(AccountSubscriberModel).isRequired,
        subscriberOrderHistory: PropTypes.arrayOf(CommerceOrderHistoryModel).isRequired,
        releaseCollectionModel: PropTypes.objectOf(ReleaseCollectionModel).isRequired,

    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
        }

    }


    componentDidMount() {
        this.load()
    }

    async load(){
        const accessTokenModel = this.props.accessTokenModel

        this.setState((prevs, props) => {
            return {
                ...prevs,
                loading: true
            }
        }, async () => {
            const param = new GetOrderHistoryRPCRequest()
            param.subscriber_id = this.props.subscriber.entityId

            const rpcResult = await GET_ORDER_HISTORY__V1_0({
                headers: getAuthorizationHeader(accessTokenModel),
                paramBody: param
            })

            if(rpcResult.isError()){
                console.error({rpcResult})
            } else {
                ReduxMainStore.dispatch({
                    type: COMMERCE_ORDER_HISTORY_SET_SUBSCRIBER,
                    payload: rpcResult.model.orders
                })
            }

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    loading: false
                }
            })
        })
    }

    render() {
        let content = <LoadingComponent loaderFlag={true} />
        if(this.state.loading === false){
            content = <React.Fragment>
                <div style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                }}>
                    <div className="statistic">
                        <div className="value">
                            <i className="shopping bag icon" /> {this.props.subscriberOrderHistory.length}
                        </div>
                        <div className="label">
                            注文件数
                        </div>
                    </div>
                </div>

                <div style={{marginTop: 10}}>
                    {this.props.subscriberOrderHistory.length === 0 ? (
                        <div className="ui message info">
                            注文履歴がございません。
                        </div>
                    ) : (
                        this.props.subscriberOrderHistory.map((orderHistory)=> {
                            return <CompactOrderHistoryRow releaseCollectionModel={this.props.releaseCollectionModel}
                                                           compactOrder={orderHistory}
                            />
                        })
                    )}
                </div>
            </React.Fragment>
        }

        return (
            <div className={'contentBox'}>
                <h1>
                    <i className="ui icon boxes" /> {LanguagePack.extract(this.props.language, OrderHistoryLangPack.orderHistory.title)}
                </h1>
                <div>
                    {LanguagePack.extract(this.props.language, OrderHistoryLangPack.orderHistory.guide)}
                </div>

                <div className="ui section divider" />

                <div>
                    <div className="flexContainerRow flexItemAlignEndCenter">
                        <div className="flexItem11">
                            ※注文直後履歴が現れない場合がございますが、メールにて注文完了の通知が届きますのでご確認下さい。<br/>
                            ※注文完了と配送の手続きが別途のため、配送がまだ準備中だと表示される可能性があります。
                        </div>
                        <div className="flexItem00">
                            <div className={'ui button grey'} onClick={this.load.bind(this)}>ページを更新</div>
                        </div>
                    </div>
                </div>

                <div className="ui section divider" />

                {content}
            </div>
        )
    }
}

export default connect(mapStateToProps)(PublishReleaseCollectionOrderHistoryBox);