import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "../library/JQuery";
import {LanguageContainer} from '../model/container/LanguageContainer'

export default class LanguageDropdown extends React.Component {

    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        idEnglish: PropTypes.string,
        idJapanese: PropTypes.string,
        idKorean: PropTypes.string,
        updateAction: PropTypes.func,
        defaultLanguage: PropTypes.object,
    }


    REFS_SET = {
        UI_DROPDOWN: 'UI_DROPDOWN' // $('.ui.dropdown')
    };

    componentDidMount() {
        $(this.refs[this.REFS_SET.UI_DROPDOWN]).dropdown({
            action: 'combo'
        })
    }

    buttonStyle = {
        marginRight: 12
    }

    genSearchForm() {
        return (
            <div className="ui icon search input" style={{width: 'auto'}}>
                <i className="search icon"></i>
                <input type="text" placeholder="Language..."/>
            </div>
        )
    }

    /**
     * @param {LanguageContainer} language
     * @param {boolean} withText
     * @param {boolean} withIcon
     * @return {*}
     */
    genCurrentButton(language, withText = true, withIcon = true) {
        const title = language.nameWithEnglish;
        const flagIconName = language.flagCode;

        if (withText && withIcon) {
            return this.genLangButton(title, flagIconName, () => {
            }, 0)
        } else if (withText) {
            return this.genLangButton(title, null, () => {
            }, 0)
        } else if (withIcon) {
            return this.genLangButton(null, flagIconName, () => {
            }, 0)
        } else {
            return this.genLangButton(null, null, () => {
            }, 0)
        }


    }

    genLanguageButtons() {
        let keys = 100;
        return [
            this.genLangButton(LanguageContainer.master.english.nameWithEnglish, LanguageContainer.master.english.flagCode, this.props.updateAction.bind(this, LanguageContainer.master.english), keys += 1, this.props.idEnglish),
            this.genLangButton(LanguageContainer.master.japanese.nameWithEnglish, LanguageContainer.master.japanese.flagCode, this.props.updateAction.bind(this, LanguageContainer.master.japanese), keys += 1, this.props.idJapanese),
            this.genLangButton(LanguageContainer.master.korean.nameWithEnglish, LanguageContainer.master.korean.flagCode, this.props.updateAction.bind(this, LanguageContainer.master.korean), keys += 1, this.props.idKorean)
        ]
    }

    genLangButton(title, flagIconName, clickAction, key, id) {

        let icon = null
        if (flagIconName) {
            icon = <i className={`${flagIconName} flag`}></i>
        }


        return (
            <span id={id ? id : null} className="item" onClick={clickAction} key={key}>
                {icon}
                {title}
            </span>
        )
    }

    render() {
        return (
            <div id={this.props.id ? this.props.id : null} className={`ui dropdown search selection ${this.props.className}`} ref={this.REFS_SET.UI_DROPDOWN}>
                <span className="text">
                        {this.genCurrentButton(this.props.defaultLanguage, true, true)}
                    &nbsp;<i className="ui icon dropdown" style={{float: 'right'}}></i>
                    </span>
                <div className="menu">
                    <div className="scrolling menu">
                        {this.genLanguageButtons()}
                    </div>
                </div>
            </div>
        );
    }

}
