import * as React from "react";
import * as PropTypes from "prop-types";
import AbsoluteFullSizeBgBlurBox from "../../../../../ui_template/AbsoluteFullSizeBgBlurBox";
import ServiceInfoConstant from "../../../../../constant/ServiceInfoConstant";

export default class StoreReleaseSubmitFailResultComponent extends React.Component{


    static propTypes = {
        closeAction: PropTypes.func,
        errorMessage: PropTypes.string,
        errorCode: PropTypes.string,
    }

    errorHead = {fontWeight: "bold", textAlign: "right", paddingRight: 10}
    errorContent = {textAlign: "left"}
    divider = <div style={{height: 15}}></div>

    render(){
        return (
            <AbsoluteFullSizeBgBlurBox>
                <div style={{marginTop: 140, maxWidth: 600}}>
                    <h2>
                        <i className="icon warning circle" />
                         I'm sorry, Problem occurs
                    </h2>

                    {this.divider}
                    <table style={{display:'inline-block'}}>
                        <tbody>
                        <tr>
                            <td style={this.errorHead}>
                                Code
                            </td>
                            <td style={this.errorContent}>
                                {this.props.errorCode}
                            </td>
                        </tr>
                        <tr>
                            <td style={this.errorHead}>
                                Message
                            </td>
                            <td style={this.errorContent}>
                                <p>
                                    {this.props.errorMessage}
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {this.divider}
                    {this.divider}

                    <button
                        className="ui button positive"
                        onClick={this.props.closeAction.bind(this)}
                    >
                        Back
                    </button>

                    {this.divider}
                    {this.divider}

                    <p>
                        <i className="icon help circle outline" />If you keep a problem, please contact payment company or us <br/>
                        <a href="mailto:concierge@ating.me">{ServiceInfoConstant.departments.concierge.email}</a>
                    </p>
                </div>
            </AbsoluteFullSizeBgBlurBox>
        )
    }

}
