import * as React from "react";
import * as PropTypes from "prop-types";
import {CurrencyContainer} from "../../../../../model/container/money/CurrencyContainer";
import PublishItemListComponent from "./products/PublishItemListComponent";

export default class PublishSetupStepProducts extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        accessTokenModel: PropTypes.object,
        libraryCollectionModel: PropTypes.object,
        submittingTargetReleaseItemList: PropTypes.object,
        updatePublishModel: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            setAllPrice: 10.00
        };
    }

    REFS_SET = {
        announce_date_expired:"announce_date_expired",
        itemsContainer: "itemsContainer",
        subscriberFirstName:"subscriberFirstName",
        subscriberLastName:"subscriberLastName",
        subscriberEmail:"subscriberEmail",
        subscriberLanguage:"subscriberLanguage",
        allPriceInput:"allPriceInput",
        currencyDropdown:"currencyDropdown",
        countryDropdown:"countryDropdown"
    };


    const = {
        defaultValue: {
            productPrice: 0,
            productCurrency: CurrencyContainer.master.USD,
        },
        fixed: 2,
        minimumPrice:0
    }

    /**
     *
     * @param {PublishProductModel} product
     * @param event
     */
    deleteItem(product, event){
        //Search and update value
        this.props.updatePublishModel(
            (publishModel) =>  {
                publishModel.products.result = publishModel.products.result.filter(oldProduct => (oldProduct.item !== product.item))
                return publishModel;
            }
        );
    }

    /* =========================================================================================================================*/
    /* ============================================== GENERATE ELEMENT==========================================================*/
    /* =========================================================================================================================*/

    updatePriceForSetAll(event){
        //Make sure int value
        let updateValue = event.target.value;
        if(updateValue.length <= 0) {
            updateValue = 0.00;
        }else{
            updateValue = parseFloat(parseFloat(updateValue).toFixed(this.const.fixed));
        }

        this.setState((prevState, props) => {
            prevState.setAllPrice = updateValue;
            return prevState
        });



    }


    /* =======================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/
    /* =========================================================================================================================*/


    render() {

        /* ==================================================== PRE-PROCESSING ==========================================================*/

        let thStyle = {
            verticalAlign:'top',
            backgroundColor:'#F0FFF2'
        };

        return (
            <PublishItemListComponent
                accessTokenModel={this.props.accessTokenModel}
                items={this.props.submittingTargetReleaseItemList}
                thead={(
                             <tr>
                                    <th style={thStyle}></th>
                                    <th style={thStyle}></th>
                                    <th style={thStyle}>
                                    </th>
                                    <th style={thStyle}>
                                    </th>
                                </tr>
                        )}
            />
       )
    }
}
