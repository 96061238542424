import * as React from "react";
import * as PropTypes from "prop-types";
import StoreReleaseFormByLibraryCollection from "../StoreReleaseFormByLibraryCollection";
import {GET_RELEASE_STORE__V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import {GetReleaseStoreRPCRequest} from "../../../../../api/loops4/model/release/store/GetReleaseStoreRPCRequest";
import {RoutePathParam} from "../../../../RouteDictionary";
import {connect} from "react-redux";
import {GetReleaseStoreRPCResponse} from "../../../../../api/loops4/model/release/store/GetReleaseStoreRPCResponse";
import DateFormatter from "../../../../../model/formatter/DateFormatter";
import $ from "../../../../../library/JQuery";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};


class ReleaseTargetSelector extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        libraryCollectionModel: PropTypes.object,
        submittingReleaseCollectionModel: PropTypes.object,
        submittingTargetType: PropTypes.string,
        updateSubmittingTargetType: PropTypes.func,
        updateSubmittingReleaseCollectionName: PropTypes.func,
        updateSubmittingReleaseCollectionId: PropTypes.func,
        updatingReleaseCollectionName: PropTypes.string,
        updatingReleaseCollectionId: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = {
            collections: []
        };

        this.REFS_SET = {
            referencingCollectionSelector: React.createRef()
        }
    }

    updateCollectionName(e){
        this.props.updateSubmittingReleaseCollectionName(e.target.value)
    }

    updateCollectionTargetId(value){
        this.props.updateSubmittingReleaseCollectionId(value)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.submittingTargetType === StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.INSERT_TO_EXISTING_RELEASE_COLLECTION){
            this.getExistingReleaseCollections()
        }
    }

    async getExistingReleaseCollections(){
        const storeId = this.props.routeState.pathParameter[RoutePathParam.storeId]

        const param = new GetReleaseStoreRPCRequest()
        param.entity_id = storeId

        /**
         *
         * @type {Loops4ResponseMessage|{model: GetReleaseStoreRPCResponse}}
         */
        const rpcResult = await GET_RELEASE_STORE__V1_0({
            headers: getAuthorizationHeader(this.props.accessTokenModel),
            paramBody: param
        })

        /**
         *
         * @type {array<GetLibraryCollectionRPCResponse>|array<GetReleaseCollectionRPCResponse>|*}
         */
        const collections = rpcResult.data.collections

        this.setState((prevs, props) => {
            return {
                ...prevs,
                collections: collections
            }
        }, () => {
            $(this.REFS_SET.referencingCollectionSelector.current).dropdown({
                onChange: (value, text, $selectedItem) => {
                    // console.log({value, text, $selectedItem})
                    this.updateCollectionTargetId(value)
                }
            })
        })

    }


    REFS_SET = {
    };

    render() {
        const isNewReleaseCollectionActive = this.props.submittingTargetType === StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.CREATE_NEW_RELEASE_COLLECTION
        const isInsertingReleaseCollectionActive = this.props.submittingTargetType === StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.INSERT_TO_EXISTING_RELEASE_COLLECTION

        const previousTargetCollection = this.state.collections.find(collection => collection.entity_id === this.props.updatingReleaseCollectionId)

        const existingReleaseCollectionSelector = (
            <div className="ui form">
                <div className="field">
                    <label>Release Collection ID</label>
                    <div className="ui left icon input">
                        <div className="ui floating dropdown labeled search icon button" ref={this.REFS_SET.referencingCollectionSelector} >
                            <i className="cubes icon"></i>
                            {previousTargetCollection ? (
                                <span className="text">
                                    <span>{previousTargetCollection.collection_name}</span>
                                    <span style={{color: '#CCC'}}> / {DateFormatter.getDateFormatForLocale(previousTargetCollection.date_created)}</span>
                                </span>
                            ) : (
                                <span className="text">Select releasing collection</span>
                            )}
                            <div className="menu">
                            {this.state.collections.map(collection => {
                                return (
                                    <div className={'item'} data-value={collection.entity_id}>
                                        <span>{collection.collection_name}</span>
                                        <span style={{color: '#CCC'}}> / {DateFormatter.getDateFormatForLocale(collection.date_created)}</span>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        const newReleaseCollectionInput = (
            <div className={'ui form'}>
                <div className="two fields">
                    <div className="field">
                        <label>Release Collection Name</label>
                    </div>
                    <div className="field">
                        <input className={'ui input fluid'} type="text" onChange={this.updateCollectionName.bind(this)} value={this.props.updatingReleaseCollectionName} />
                    </div>
                </div>
            </div>
        )

        return (
            <div className="ui attached segment">
                <div className="two fields">

                    <div className="field">
                        <form className="ui form">
                            <div className="field">
                                <label>
                                    Release type
                                </label>

                                <div style={{marginTop:5, textAlign:'right'}}>
                                    <div className="ui buttons compact tiny basic" >
                                        <div className={"ui button " + (isNewReleaseCollectionActive ? "active" : "")}
                                                onClick={this.props.updateSubmittingTargetType.bind(this, StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.CREATE_NEW_RELEASE_COLLECTION)}
                                            >
                                            As new release collection
                                        </div>
                                        <div className={"ui button " + (isInsertingReleaseCollectionActive ? "active" : "")}
                                                onClick={this.props.updateSubmittingTargetType.bind(this, StoreReleaseFormByLibraryCollection.RELEASE_TARGET_TYPE.INSERT_TO_EXISTING_RELEASE_COLLECTION)}
                                            >
                                            Into existing release collection
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="field">
                        {isInsertingReleaseCollectionActive ? existingReleaseCollectionSelector : newReleaseCollectionInput}
                    </div>

                </div>

            </div>
           )

            }
}

export default connect(mapStateToProps)(ReleaseTargetSelector);