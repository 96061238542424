import {RoutePathParam, RouteQueryParam} from "./RouteDictionary";
import {ReduxMainStore} from "../state/redux/store/ReduxMainStore";
import {ROUTE_SET_STATE} from "../state/redux/constant/ActionType";
import {pushHistory} from "./RouteHistory";

export class RouteState {
    pathName=null
    pathParameter= {
        [RoutePathParam.boxId]: null,
        [RoutePathParam.collectionId]: null,
        [RoutePathParam.publishId]: null,
        [RoutePathParam.releaseCollectionId]: null,
        [RoutePathParam.releaseItemId]: null,
        [RoutePathParam.storeId]: null,
        [RoutePathParam.orderId]: null,
        [RoutePathParam.itemId]: null,
        [RoutePathParam.shippingId]: null,
        [RoutePathParam.shareId]: null,
    }

    queryParameter= {
        [RouteQueryParam.rating]: null,
        [RouteQueryParam.pin]: null,
        [RouteQueryParam.fitems]: null,
        [RouteQueryParam.keyword]: null,
        [RouteQueryParam.language]: null,
        [RouteQueryParam.accessKey]: null,
        [RouteQueryParam.forwarded]: null,
        [RouteQueryParam.pageOrders]: null,
        [RouteQueryParam.pagePublishes]: null,
        [RouteQueryParam.pagePublishProducts]: null,
    }
}

class RouteUtilityContainer {
    getPathname(routingPath, pathParam = {}, queryParam = {}){
        let pathResult = routingPath;
        let pathRecognizer = ":";

        //Inject PathParam
        let pathParamKeys = Object.keys(pathParam);
        pathParamKeys.forEach( key => pathResult = pathResult.replace(pathRecognizer + key, pathParam[key]));

        const queryURL = '?' + Object
            .keys(queryParam)
            .map(query => `${query}=${queryParam[query]}`)
            .join('&')

        // const encodedQueryURL = encodeURIComponent(queryURL)
        const encodedQueryURL = queryURL

        return pathResult + encodedQueryURL;
    }

    /**
     * @param {Location} location
     * @return {RouteState}
     */
    extractRouteState(props){
        const routeState = new RouteState()

        if(props.location){
            routeState.pathName = props.location.pathname
            Object.values(RouteQueryParam)
                .forEach(queryKey => {
                    const foundValue = this.getParameterByName(queryKey, props.location.search)
                    routeState.queryParameter[queryKey] = foundValue
                })
        }

        const match = props.match || props.computedMatch
        routeState.pathParameter= match.params

        return routeState
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        // name = name.replace(/[\[\]]/g, '\\$&');
        name = name.replace(/[[]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     *
     * @param nextProps
     * @return {RouteState}
     */
    updateRouteState(nextProps){
        const currentReduxState = ReduxMainStore.getState().routeState
        const postRouteState = RouteUtility.extractRouteState(nextProps)

        if(JSON.stringify(postRouteState) !== JSON.stringify(currentReduxState)){
            ReduxMainStore.dispatch({
                type: ROUTE_SET_STATE,
                payload: postRouteState
            })
        }

        return postRouteState
    }

    changeAndPushQueryParameter(key, value){
        const currentPathname = window.location.pathname
        const currentSearch = window.location.search
        let postSearch
        if(currentSearch.indexOf(`${key}=`) > -1){
            const regex = new RegExp(`${key}=\\w+`, 'g')
            postSearch = currentSearch.replace(regex, `${key}=${value}`)
        } else {
            if(currentSearch.indexOf(`?`) > -1){
                postSearch = currentSearch + `&${key}=${value}`
            } else {
                postSearch = currentSearch + `?${key}=${value}`
            }
        }
        const postURL = currentPathname + postSearch
        pushHistory(postURL)
    }
}

export const RouteUtility = new RouteUtilityContainer()
