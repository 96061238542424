import * as React from "react";
import { Component } from "react";
import AbsoluteFullSizeBox from "./AbsoluteFullSizeBox";

export default class AbsoluteFullSizeBgBlurBox extends Component {

    REFS_SET = {};

    static themeStyle={
        superBright: "superBright",
        bright: "bright",
        dark: "dark"
    }

    themeStyleCss = {
        superBright: {
            display: 'block',
            opacity: 0.95,
            filter: 'blur(5px)',
            backgroundColor:'white',
            color: "#222"
        },
        bright: {
            display: 'block',
            opacity: 0.9,
            filter: 'blur(5px)',
            backgroundColor:'#EFEFEF',
            color: "#222"
        },
        dark: {
            display: 'block',
            opacity: 0.9,
            filter: 'blur(5px)',
            backgroundColor:'#333',
            color: "#EFEFEF"
        }
    }

    getDimmerStyle(){
        switch (this.props.themeStyle){
            case AbsoluteFullSizeBgBlurBox.themeStyle.superBright :{
                return this.themeStyleCss.superBright;
            }
            case AbsoluteFullSizeBgBlurBox.themeStyle.bright :{
                return this.themeStyleCss.bright;
            }
            case AbsoluteFullSizeBgBlurBox.themeStyle.dark :{
                return this.themeStyleCss.dark;
            }
            default :{
                return this.themeStyleCss.bright;
            }
        }
    }

    render() {

       return (
       <AbsoluteFullSizeBox style={{display: 'flex', zIndex: 1000}}>
           <AbsoluteFullSizeBox style={this.getDimmerStyle()}>

           </AbsoluteFullSizeBox>
           <div className="flexContainerRow flexItemAlignCenterCenter">
               <div className="flexItem11"></div>
               <div className="flexItem00">
                   <div className="flexContainerColumn">
                       <div className="flexItem11"></div>
                       <div className="flexItem00">
                           <div style={{display:'block', height: '100%', width: '100%'}}>
                               {this.props.children}
                           </div>
                       </div>
                       <div className="flexItem11"></div>
                   </div>
               </div>
               <div className="flexItem11"></div>
           </div>
       </AbsoluteFullSizeBox>
       )
    }
}
