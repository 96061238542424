import * as React from "react";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import IconCollectionComponent from "../../../../ui_component/icon/IconCollectionComponent";
import {RouteUtility} from "../../../RouteUtility";
import {RouteDictionary, RoutePathParam} from "../../../RouteDictionary";

class ChildCollectionElementComponentState {
    dropActionPopup = null;
    hoverActionPopup = null;
    elementStyle = {};
}

export default class ChildCollectionElementComponent extends React.Component {

    static propTypes = {
        treeCollectionSet: PropTypes.object,
        boxModel: PropTypes.object,
        paddingLeft: PropTypes.number,
    }


    REFS_SET = {
        itemContainer: "itemContainer"
    };


    constructor(props, context) {
        super(props, context);
        this.state = new ChildCollectionElementComponentState();

        this.state.elementStyle = Object.assign({}, this.elementBaseStyle, this.getOpenFlagStyle());
    }

    elementBaseStyle = {margin:0, color:"#BCC0C9",padding:10,paddingLeft:this.props.paddingLeft, fontWeight:200};
    elementMouseOverStyle = {backgroundColor:"#424242", color:'#BCC0C9'};
    elementOpenFlagStyle = {backgroundColor:'rgb(179, 179, 179)', color:"black"};

    dropOverItem(e){
        e.preventDefault();

        let hoverPopup = null;
        if(this.isItemsSameWithCurrentCollection()){
            hoverPopup = (
                <div className="ui message warn compact">
                    You can not drop here because it is same collection
                </div>
            )
        }else{
            hoverPopup = (
                <div className="ui message info compact">
                    You can drop here to move/copy/clone
                </div>
            )
        }

        this.setState((prevs, props)=> {
            prevs.hoverActionPopup = (
                <div style={{position:'absolute', zIndex: 1000,left: '100%', width: '200%', display:'block'}}>
                    {hoverPopup}
                </div>
            );
            prevs.elementStyle = Object.assign({}, this.elementBaseStyle, this.elementMouseOverStyle, this.getOpenFlagStyle());
            return prevs;
        });
    }

    dropLeaveItem(e){
        this.setState((prevs, props)=> {
            prevs.hoverActionPopup = null;
            prevs.elementStyle = Object.assign({}, this.elementBaseStyle, this.getOpenFlagStyle());
            return prevs;
        });
    }

    droppedItem(e) {
        e.preventDefault();

        // let dropBox = null;
        //
        // if(this.isItemsSameWithCurrentCollection()) {
        //     dropBox = (
        //         <div>
        //             It is same collection you can not do drop here
        //             <a className="active red item" onClick={this.closeDropPopup.bind(this)}>
        //                 <i className="ui icon close"/>CANCLE
        //             </a>
        //         </div>
        //     )
        // } else {
        //     let droppedItems = SelectionAction.getAllItems();
        //
        //     let container = this.refs[this.REFS_SET.itemContainer];
        //
        //     //TODO store should have workspace(collection) state. improve it but not global
        //     let sourceCollectionId = AppContextAction.getContextModel().activityModel.collectionModelId;
        //     let targetCollectionId = this.props.treeCollectionSet.modelId;
        //
        //     let moveList = (items) => {
        //         items.map(itemId => {
        //             CollectionAction.moveItem(sourceCollectionId, targetCollectionId, itemId, ()=> {
        //             })
        //         })
        //     }
        //
        //     let copyList =(items) => {
        //         items.map(itemId => {
        //             CollectionAction.attachItem(targetCollectionId, itemId, ()=> {
        //             })
        //         })
        //     }
        //
        //     let cloneList = (items) => {
        //         items.map(itemId => {
        //             CollectionAction.cloneItem(sourceCollectionId, itemId, targetCollectionId, ()=> {
        //             })
        //         })
        //     }
        //
        //     dropBox = (
        //         <div className="flexContainerRow" style={{padding: 6, backgroundColor: '#292929'}}>
        //             <div className="flexItem11 flexContainerColumn flexItemAlignCenterCenter" style={{paddingRight:6}}>
        //                 <div>
        //                     {droppedItems.slice(0, 3).map( (item, index) =>  <PictureBlobBoxContainer
        //                         key={index}
        //                         itemModel={item}
        //                         itemType={LibraryItemConstant.itemType.item}
        //                         authType={AuthUserType.user}
        //                         renderBody={(imageSrc, loadFlag, progressLoaded, progressTotal) =>(
        //                                                  <div
        //                                                        style={{width:65, height:65,
        //                                                        backgroundSize:'contain', backgroundRepeat:'no-repeat',
        //                                                        backgroundColor: 'transparent',backgroundPosition:'center',
        //                                                        backgroundImage:`URL(${imageSrc})`}}>
        //
        //                                                     <ProgressIndicatorComponentWrappingBox progressTotal={progressTotal}
        //                                                                                           progressLoaded={progressLoaded}
        //                                                                                           themeStyle={ProgressIndicatorComponent.themeStyle.bright}
        //                                                                                           loadFlag={loadFlag}
        //                                                             />
        //                                                 </div>
        //                                          )}
        //                     />)}
        //                 </div>
        //                 <div style={{color: '#949090', marginTop: 4}}>
        //                     {droppedItems.length} Item{(droppedItems.length > 1 ? 's' : null)}
        //                 </div>
        //             </div>
        //             <div className="flexItem00 ">
        //                 <div className="flexContainerColumn flexItemAlignStartCenter">
        //                     <div className="flexItem00">
        //                         <i className="ui icon caret grey large up" />
        //                     </div>
        //                     <div className="flexItem00">
        //                         <div className="ui vertical small compact inverted menu">
        //                             <a className="item" onClick={moveList.bind(this, droppedItems)}>
        //                                 <i className="ui icon paste" />MOVE
        //                             </a>
        //                             <a className="item" onClick={copyList.bind(this, droppedItems)}>
        //                                 <i className="ui icon copy" />COPY
        //                             </a>
        //                             <a className="item" onClick={cloneList.bind(this, droppedItems)}>
        //                                 <i className="ui icon clone" />CLONE
        //                             </a>
        //                             <a className="active red item" onClick={this.closeDropPopup.bind(this)}>
        //                                 <i className="ui icon close"/>CANCLE
        //                             </a>
        //                         </div>
        //                     </div>
        //                 </div>
        //
        //             </div>
        //         </div>
        //     );
        //
        // }
        //
        // this.setState((prevs, props)=> {
        //     prevs.dropActionPopup = dropBox;
        //     return prevs;
        // });
    }

    closeDropPopup() {
        let newState = Object.assign({}, this.state);
        newState.dropActionPopup = null;
        this.setState(newState);

    }

    mouseLeave(e){
        let newState = Object.assign({}, this.state);
        newState.elementStyle = Object.assign({}, this.elementBaseStyle, this.getOpenFlagStyle());
        this.setState(newState);
    }

    mouseOver(){
        let newState = Object.assign({}, this.state);
        newState.elementStyle = Object.assign({}, this.elementBaseStyle, this.elementMouseOverStyle, this.getOpenFlagStyle());
        this.setState(newState);
    }

    getOpenFlagStyle(){
        if (this.props.treeCollectionSet.openFlag) {
            return this.elementOpenFlagStyle
        } else{ return {} }
    }

    render() {
        return (
            <div ref={this.REFS_SET.itemContainer}>
                {this.state.hoverActionPopup}
                <Link
                    to={RouteUtility.getPathname(RouteDictionary.collection, {
                            [RoutePathParam.boxId]: this.props.boxModel.entity_id,
                            [RoutePathParam.collectionId]: this.props.treeCollectionSet.entity_id,
                        })}
                    onDrop={this.droppedItem.bind(this)}
                    onDragOver={this.dropOverItem.bind(this)}
                    onDragLeave={this.dropLeaveItem.bind(this)}
                    onMouseOver={this.mouseOver.bind(this)}
                    onMouseLeave={this.mouseLeave.bind(this)}
                >
                    <h4 style={Object.assign({}, this.state.elementStyle,{cursor: 'pointer'})}>
                        <IconCollectionComponent />

                        <span>
                            {this.props.treeCollectionSet.title}
                        </span>
                    </h4>
                </Link>
                <div style={{transition: 'all 0.5s ease-in', width:'100%', minHeight: 0}}>
                    {this.state.dropActionPopup}
                </div>
            </div>
        )
    }
}
