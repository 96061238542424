import * as React from "react";
import * as PropTypes from "prop-types";
import {setFullSizeDIM} from "../../../../state/redux/action/UIControlAction";
import IconBoxComponent from "../../../../ui_component/icon/IconBoxComponent";
import LibraryAdderComponent, {AdderTabType} from "../adder/LibraryAdderComponent";

class ChildBoxElementComponentState {
    addPopup = null;
    elementStyle = {};
}

export default class ChildBoxElementComponent extends React.Component {

    static propTypes = {
        parentBoxId: PropTypes.string,
        treeCollectionSet: PropTypes.object,
        loadTheModel: PropTypes.func,
        paddingLeft: PropTypes.number,
    }


    REFS_SET = {
        itemContainer: "itemContainer",
        boxNameInput: "nameInput",
        collectionNameInput: "collectionNameInput"
    };


    constructor(props, context) {
        super(props, context);
        this.state = new ChildBoxElementComponentState();
        this.state.elementStyle = Object.assign({}, this.elementBaseStyle);
    }

    elementBaseStyle = {margin: 0, color: "#BCC0C9", padding: 10, paddingLeft: this.props.paddingLeft, fontWeight: 200};
    elementMouseOverStyle = {backgroundColor: "#424242"};

    actionButtonStyle = {
        container: {
            marginLeft: 13,
            float: 'right',
            color: '#BCC0C9',
            fontSize: 12
        },
        icon: {
            margin: 0
        }
    }


    openAddPopup(event) {
        event.preventDefault();
        event.stopPropagation();

        setFullSizeDIM(
            <LibraryAdderComponent parentBoxId={this.props.treeCollectionSet.entity_id}
                                   loadTheModel={this.props.loadTheModel}
                                   adderTabType={AdderTabType.box}
            />)
    }


    openEditPopup(event) {
        event.preventDefault();
        event.stopPropagation();

        setFullSizeDIM(
            <div className="ui segment" style={{color: 'black', textAlign: 'left'}}>

                <h4 style={{textAlign: 'left'}}>
                    Change Name
                </h4>
                <div className="column">
                    {/*<BoxNameChangerComponent boxId={this.props.treeCollectionSet.entity_id}*/}
                    {/*                            boxTitle={this.props.treeCollectionSet.title}*/}
                    {/*                            loaderAction={this.props.loadTheModel.bind(this)} />*/}
                </div>

                <div className="ui divider">
                </div>


                <div className="column">


                    { /* TODO add remove function */}
                    <h4 style={{textAlign: 'left'}}>
                        Remove Box
                    </h4>

                    <div style={{textAlign: 'center'}}>
                        <button className="ui button blue small"
                                onClick={this.removeBox.bind(this, this.props.treeCollectionSet.entity_id, this.props.parentBoxId)}>
                            A - Remove
                        </button>
                        <button style={{marginLeft: 15}} className="ui basic black button small"
                                onClick={this.removeBoxPermanently.bind(this, this.props.treeCollectionSet.entity_id)}>
                            B - Remove permanently <span style={{color: 'red'}}>*caution</span>
                        </button>
                    </div>


                </div>
            </div>
        )
    }


    removeBox(childBoxId, parentBoxId) {
        // BoxAction.deleteBox(
        //     childBoxId,
        //     parentBoxId,
        //     ()=>{ console.debug(childBoxId + " is removed ")}
        // )
    }

    removeBoxPermanently(boxId) {
        let decisionResult = window.confirm("Are you sure remove it permanently?\n\nYou will lost this data and never get recovery after it.");

        if (decisionResult) {
            // BoxAction.deleteBoxPermanently(
            //     boxId,
            //     ()=>{ console.debug(boxId + " is removed ")}
            // )

        }

    }


    mouseLeave() {
        let newState = Object.assign({}, this.state);
        newState.elementStyle = Object.assign({}, this.elementBaseStyle);
        this.setState(newState);
    }

    mouseOver() {
        let newState = Object.assign({}, this.state);
        newState.elementStyle = Object.assign({}, this.elementBaseStyle, this.elementMouseOverStyle);
        this.setState(newState);
    }


    render() {
        let arrowOpen = "right";
        if (this.props.treeCollectionSet.openFlag) {
            arrowOpen = "down"
        }
        return (
            <span>
                <h4 style={Object.assign({}, this.state.elementStyle, {cursor: 'pointer'})}
                    onClick={this.props.loadTheModel.bind(this, this.props.treeCollectionSet.entity_id)}
                    onMouseOver={this.mouseOver.bind(this)}
                    onMouseLeave={this.mouseLeave.bind(this)}
                >
                    <i className={`icon caret thin  ${arrowOpen}`}/>
                    <IconBoxComponent/>
                    <span>
                        {this.props.treeCollectionSet.title}
                    </span>
                    <span style={this.actionButtonStyle.container}>
                         {( !this.props.parentBoxId || this.props.parentBoxId.entity_id === 0 ? null :
                                 <label className="ui label circular black small">
                                     <i className="icon edit outline"
                                        onClick={this.openEditPopup.bind(this)}
                                        style={this.actionButtonStyle.icon}
                                     />
                                 </label>
                         )}
                        <label className="ui label circular black small">
                            <i className="icon plus outline"
                               onClick={this.openAddPopup.bind(this)}
                               style={this.actionButtonStyle.icon}
                            />
                         </label>
                    </span>

                </h4>
                {this.state.addPopup}
            </span>
        )
    }
}
