import * as React from "react";
import TreeBrowsingChild from "./TreeBrowsingChild";
import * as PropTypes from 'prop-types';
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import {getAuthorizationHeader} from "../../../api/loops4/Loops4RPCAPI";
import {GET_LIBRARY_BOX__V1_0} from "../../../api/loops4/caller/loops4RpcCaller";
import {GetLibraryBoxRPCRequest} from "../../../api/loops4/model/library/box/GetLibraryBoxRPCRequest";

export class NaviTreeItem {
    title = null;
    itemSize = 0;
    entity_id = null;
    isOpen = false;
}
export class CollectionNaviTreeItem extends NaviTreeItem {
    constructor(title, itemCounter, collectionEntityId, openFlag) {
        super();
        this.title = title;
        this.itemSize = itemCounter;
        this.entity_id = collectionEntityId;
        this.isOpen = openFlag;
    }
}
export class BoxNaviTreeItem extends NaviTreeItem {
    constructor(title, collectionEntityId, openFlag) {
        super();
        this.title = title;
        this.entity_id = collectionEntityId;
        this.isOpen = openFlag;
    }
}

export default class TreeBrowsingNavigator extends React.Component {

    static propTypes = {
        rootLibraryBoxEntityId: PropTypes.string.isRequired,
        accessTokenModel: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            boxModel: null
        };
    }

    componentDidMount() {
        this.updateBox(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateBox(nextProps)
    }

    updateBox(nextProps){
        this.getBoxModel(nextProps)
            .then(boxModel => {
                this.setState((prevs, props) => {
                    return {
                        ...prevs,
                        isLoading: false,
                        boxModel: boxModel,
                    };
                });
            })
    }

    async getBoxModel(nextProps){
        const boxId = nextProps.rootLibraryBoxEntityId

        if(boxId){
            const param = new GetLibraryBoxRPCRequest()
            param.entity_id = boxId

            const rpcResult = await GET_LIBRARY_BOX__V1_0({
                headers: getAuthorizationHeader(this.props.accessTokenModel),
                paramBody: param
            })

            if(rpcResult.isError()){
                return null
            } else {
                rpcResult.model.parentBoxes = []
                rpcResult.model.childBoxes = []
                return rpcResult.model
            }
        } else {
            return null
        }
    }


    REFS_SET = {};

    const = {
        treeStep: 15,
        titleStyle : {fontWeight:200, backgroundColor:'#4E4E4E', color:'#EFEFEF', margin:0, padding:10},
        linkStyle: {color:'#C2C5C7', padding:10, paddingLeft:25}
    };


    modelIntoCollectionSet(collectionModel, curViewModelId) {
        let openFlag = (collectionModel.entityId === curViewModelId);
        return new CollectionNaviTreeItem(
            collectionModel.collection_name,
            (collectionModel.items ? collectionModel.items.length : 0),
            collectionModel.entity_id,
            openFlag);
    }

    modelIntoBoxSet(boxModel, curViewModelId) {
        let openFlag = (boxModel.entity_id === curViewModelId);
        return new BoxNaviTreeItem(
            boxModel.box_name,
            boxModel.entity_id,
            openFlag);
    }

    render() {

        if(this.state.isLoading){
            return <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
        } else {

            let treeContent = <div>The box were not existed</div>
            if(this.state.boxModel){
                treeContent =  <TreeBrowsingChild currentBoxModel={this.state.boxModel}
                                                  boxModelId={this.state.boxModel.entity_id}
                                                  currentCollectionId={null}
                                                  depthCounter={1}
                                                  isOpeningChildrenOnInit={true}
                />
            }

            return (
                <div style={{height:'100%'}}>
                    <div className="ui fitted divider" />
                    {treeContent}
                </div>
            );
        }


    }
}
