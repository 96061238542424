import * as React from "react";
import * as PropTypes from "prop-types";
import {
    ScrollableTable,
    ScrollableTableCell
} from "../../../../ui_template/table/ScrollableTable";
import SubscriberAddingFormComponent from './SubscriberAddingFormComponent'
import * as Loops4RPCAPI from "../../../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../../../ui_template/DimLoaderComponent";
import {RouteUtility} from "../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../RouteDictionary";
import {Link} from "react-router-dom";
import DateFormatter from "../../../../model/formatter/DateFormatter";
import {GET_ACCOUNT_RELEASE_SUBSCRIBER_IN_RELEASE_COLLECTION__V1_0} from "../../../../api/loops4/caller/loops4RpcCaller";
import {getAuthorizationHeader} from "../../../../api/loops4/Loops4RPCAPI";
import {GetAccountReleaseSubscriberInReleaseCollectionRPCRequest} from "../../../../api/loops4/model/account/release_subscriber/GetAccountReleaseSubscriberInReleaseCollectionRPCRequest";
import {getNameWithEnglish} from "../../../../model/formatter/LanguageFormatter";
import {TableHeadCell} from "../../../../ui_template/table/cell/TabelHeadCell";
import {TableBodyCell} from "../../../../ui_template/table/cell/TableBodyCell";

export default class ReleaseCollectionSubscriberBox extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        storeId: PropTypes.string,
        releaseCollectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            errorMessageBox: null,
            addSubscriberResult: null,
            subscriberList: []
        }
    }


    componentDidMount() {
        this.getSubscriberList(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.getSubscriberList(nextProps)
    }

    genHeader(title) {
        return (
            <div>
                <span style={{marginLeft: 5}}>
                    {title}
                </span>
            </div>
        )
    }

    getSubscriberList(nextProps) {
        this.setState((preState, props) => {
            return {
                ...preState,
                isLoading: true,
                errorMessageBox: null
            }
        }, async () => {
            const requestBody = new GetAccountReleaseSubscriberInReleaseCollectionRPCRequest()
            requestBody.release_collection_entity_id = nextProps.releaseCollectionModel.entityId

             const rpcResult = await GET_ACCOUNT_RELEASE_SUBSCRIBER_IN_RELEASE_COLLECTION__V1_0({
                 headers: getAuthorizationHeader(nextProps.accessTokenModel),
                 paramBody: requestBody
             })


            if (rpcResult.isError()) {
                this.setState((preState, props) => {
                    return {
                        ...preState,
                        isLoading: false,
                        errorMessageBox: (
                            <div>
                                Request got failed:: {rpcResult.getErrorMessage()}
                            </div>
                        )
                    }
                })
            } else {
                this.setState((preState, props) => {
                    return {
                        ...preState,
                        isLoading: false,
                        errorMessageBox: null,
                        subscriberList: rpcResult.model.subscribers
                    }
                })
            }
        });
    }

    /**
     * @param {AccountSubscriberModel} accountSubscriber
     * @return {Promise<void>}
     */
    addSubscriber(accountSubscriber) {
        console.log('addSubscriber::data', accountSubscriber)
        this.setState((preState, props) => {
            preState.addSubscriberResult = 'Requesting ...';
            return preState
        }, async () => {
            const rpcResult = await Loops4RPCAPI.createAccountSubscriber({
                accessTokenModel: this.props.accessTokenModel,
                releaseCollectionId: this.props.releaseCollectionModel.entityId,
                email: accountSubscriber.email,
                lastName: accountSubscriber.lastName,
                firstName: accountSubscriber.firstName,
                language: accountSubscriber.language.value,
            })

            if (rpcResult.isError()) {
                this.setState((preState, props) => {
                    preState.addSubscriberResult = (
                        <div>
                            Request got failed:: {rpcResult.getErrorMessage()}
                        </div>
                    );
                    return preState
                })
            } else {
                this.setState((preState, props) => {
                    preState.addSubscriberResult = 'success!'
                    return preState
                })
            }
        });
    }

    deleteSubscriber(subscriberId) {
        if (window.confirm(`Is this user really deleted? ${subscriberId}`)) {
            console.log('yes')
        } else {
            console.log('no')
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else if (this.state.errorMessageBox) {
            return this.state.errorMessageBox
        } else {
            /**
             * @type {ReleaseCollectionModel}
             */
            const releaseCollectionModel = this.props.releaseCollectionModel

            return (
                <div className="flexContainerColumn">
                    <h3>Subscriber</h3>
                    <div className="flexItem00">
                        <SubscriberAddingFormComponent addSubscriber={this.addSubscriber.bind(this)}
                                                       addSubscriberResult={this.state.addSubscriberResult}/>
                    </div>
                    <div className="flexItem00">
                        <ScrollableTable className="ui unstackable small table"
                                         header={<TableHeadCell columns={["Email / AccessKey", "First name", "Last name", "Language", "Added at", ""]}  />}
                                         rows={this.state.subscriberList.map(
                                             /**
                                              * @param {GetAccountReleaseSubscriberRPCResponse} subscriber
                                              * @return {TableBodyCell}
                                              */
                                             (subscriber) => {
                                                 return <TableBodyCell
                                                    key={subscriber.entity_id}
                                                     columns={[
                                                     (<div>
                                                             <div>
                                                                 <i className="ui icon cubes"/>
                                                                 <span>{subscriber.email}</span>
                                                             </div>
                                                             <div style={{color: '#CCCCCC'}}>
                                                                 {subscriber.entity_id}
                                                             </div>
                                                             <span>ACCESS_URL: </span>
                                                             <Link
                                                                 to={RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
                                                                     [RoutePathParam.releaseCollectionId]: releaseCollectionModel.entityId
                                                                 }, {
                                                                     [RouteQueryParam.access_key]: subscriber.access_key
                                                                 })}
                                                                 target="_blank"
                                                             >
                                                                 <span>{subscriber.access_key}</span>
                                                             </Link>
                                                         </div>
                                                     ),
                                                     (<div>{subscriber.first_name}</div>),
                                                     (<div>{subscriber.last_name}</div>),
                                                     (<div>
                                                         <i className={`${subscriber.language.flagCode} flag`}></i>
                                                         {getNameWithEnglish(subscriber.language)}
                                                     </div>),
                                                     (<div>
                                                         {DateFormatter.getFullDate(subscriber.date_created)}
                                                     </div>),
                                                     (<div>
                                                         <input type="button" value="Delete" className="ui button"
                                                                onClick={this.deleteSubscriber.bind(this, subscriber.entityId)}/>
                                                     </div>),
                                                 ]} />
                                             })}
                        />
                        {(this.state.subscriberList.length < 1 ? (
                            <div className="ui message info">
                                you do not have any subscriber item.
                            </div>
                        ) : null)}
                    </div>
                </div>
            )
        }
    }
}