

// Package Dir: release/store
// FileName: GetReleaseStoreRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.release.store.GetReleaseStoreRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.release.store.ReleaseStoreRPCBody, com.loops4.controller.loops4rpc.model.release.store.ReleaseStoreRPCRelation, java.lang.Object

export class GetReleaseStoreRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.store_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.store_state = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.store_type = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.release.collection.GetReleaseCollectionRPCResponse>
         * @type {array<GetReleaseCollectionRPCResponse>}
         */
        this.collections = [];

    }
}