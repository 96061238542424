import {CommerceStockKeepingUnitTypeContainer} from "../../container/CommerceStockKeepingUnitTypeContainer";
import DateUTCModel from "../DateUTCModel";

export default class CommerceStockKeepingUnitModel {

    entityId;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();

    quantity = 0;
    skuType = CommerceStockKeepingUnitTypeContainer.master.UnlimitedSKU

    /**
     *
     * @param data
     * @return {CommerceStockKeepingUnitModel}
     */
    static deserialize(data) {
        let newModel = new CommerceStockKeepingUnitModel();

        if (data) {
            newModel.entityId = data.entity_id;
            newModel.dateCreated = DateUTCModel.deserialize(data.dateCreated);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);

            newModel.quantity = data.quantity;
            newModel.skuType = CommerceStockKeepingUnitTypeContainer.deserialize(data.stock_keeping_unit_type);
        }

        return newModel
    }

}