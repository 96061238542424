import * as React from 'react';
import LanguagePack from "../../LanguagePack";

const SignInLangPack = {
    title: new LanguagePack(
        "Private Gallery",
        "プライベートギャラリー",
        "비밀보호 갤러리"
    ),
    invalidAccessKey: new LanguagePack(
        "You do not have access key",
        "アクセスキーが無効です",
        "비밀키가 무효합니다"
    ),
    invalidAccessKeyGuide: new LanguagePack(
        "Please access again with link attached in your invitation mail",
        "招待メールに添付されたリンクをご利用して改めて接続して下さい。",
        "초대 메일안에 포함된 링크를 이용해서 다시 접속해주세요."
    ),
    secretKey: new LanguagePack(
        "SECRET KEY",
        "秘密キー",
        "비밀키"
    ),
    secretKeyGuide: new LanguagePack(
        "Please input your Secret Key.",
        "秘密キーを入力して下さい。",
        "비밀키를 입력해주세요."
    ),
    secretKeyGuideWhere: new LanguagePack(
        " * the key is included in your invitation mail.",
        " * 秘密キーは招待メールへ含まれています。",
        "비밀키는 초대 메일안에 포함되어 있습니다."
    )
}

export default SignInLangPack