import * as React from "react";

class UiLayoutTemplateContainer {

    sideViewDivider= (
        <div style={{marginTop: 5, marginBottom: 5}}>
            <div style={{width:'100%', height: 1, backgroundColor:'#272727'}}></div>
            <div style={{width:'100%', height: 1, backgroundColor:'#7b7a7a'}}></div>
        </div>
    );

    style={
        sideViewContentBox: {border: '1px solid #333', color: 'grey', padding: 6, backgroundColor:'#555'}
    };

    genSideViewContentBox(content){
        return (
            <div style={this.style.sideViewContentBox}>
                {content}
            </div>
        )
    }

    genDivider(){
        return (
            <div style={{height: 15}}></div>
        )
    }

    genDarkVerticalDivider(){
        return (
            <div style={{
                    background: "#121212",
                    boxShadow: "1px 0 #2a2a2a",
                    width: 1,
                    height: '100%'
            }}></div>
        )
    }

    genDarkHorizontalDivider(){
        return (
            <div style={{
                    background: "#121212",
                    boxShadow: "1px 0 #2a2a2a",
                    height: 1,
                    width: '100%'
            }}></div>
        )
    }

    genBrightVerticalDivider(){
        return (
            <div style={{
                    background: "#e4e4e4",
                    boxShadow: "1px 0 #FFFFFF",
                    width: 1,
                    height: '100%'
            }}></div>
        )
    }

    genBrightHorizontalDivider(){
        return (
            <div style={{
                    background: "#e4e4e4",
                    boxShadow: "1px 0 #FFFFFF",
                    height: 1,
                    width: '100%'
            }}></div>
        )
    }

}

const UiLayoutTemplate = new UiLayoutTemplateContainer();
export default UiLayoutTemplate