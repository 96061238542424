import * as React from "react";
import DimLoaderComponent from "./DimLoaderComponent";
import * as PropTypes from "prop-types";
import {CSSProperties} from "react";

export default class LoadingComponent extends DimLoaderComponent{

    static propTypes = {
        style: PropTypes.object,
    }


    /* =====================
    ====================================================================================================*/
    /* ============================================== COMPONENT BASE ===========================================================*/
    /* =========================================================================================================================*/


    /* =========================================================================================================================*/
    /* =================================================== CONTROLLING =========================================================*/
    /* =========================================================================================================================*/


    /* =========================================================================================================================*/
    /* ============================================== GENERATE ELEMENT==========================================================*/
    /* =========================================================================================================================*/


    /* =========================================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/
    /* =========================================================================================================================*/


    divider = <div style={{height: 15}}></div>

    genContent(){
        return (
            <div className="ui indeterminate text loader small" style={this.props.style}>
                Loading<br/>
                <br/>
                <p style={{color:'grey'}}>
                    Wait for a moment
                </p>
            </div>
        )
    }


}
