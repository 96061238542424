import {ValueByLanguage} from "./ValueByLanguage";
import {CurrencyContainer} from "../container/money/CurrencyContainer";

export class CurrencyMaster {
    /**
     * 
     * @param {string} masterCode
     * @param {string} character
     * @param {ValueByLanguage} localName
     * @param {number} localDecimalPointer
     */
    constructor(masterCode, character, localName, localDecimalPointer) {
        this.masterCode = masterCode
        this.character = character
        this.localName = localName
        this.localDecimalPointer = localDecimalPointer
    }
}

export const CurrencyMasterDefinition = {
    USD: new CurrencyMaster(
        "USD_CommerceCurrencyMaster",
        "$",
        new ValueByLanguage(
            "Dollars",
            "アメリカドル",
            "미국 달러"
        ),
        2
    ),
    JPY: new CurrencyMaster(
        "JPY_CommerceCurrencyMaster",
        "¥",
        new ValueByLanguage(
            "Yen",
            "円",
            "엔"
        ),
        0
    ),
    KRW: new CurrencyMaster(
        "KRW_CommerceCurrencyMaster",
        "₩",
        new ValueByLanguage(
            "Won",
            "ウォン",
            "원"
        ),
        0
    )
}

/**
 * @param masterCode
 * @returns {CurrencyMaster}
 */
const getMaster = (masterCode) => {
    const masterKey = Object
        .keys(CurrencyMasterDefinition)
        .find(key => CurrencyMasterDefinition[key].masterCode === masterCode)

    if(!masterKey)
        throw Error(`master code [${masterKey}] is not found in CurrencyMaster`)

    return CurrencyMasterDefinition[masterKey]
}



/**
 *
 * @param {LanguageMaster} languageMaster
 * @param {CurrencyMaster} currencyMaster
 * @return {string}
 */
const getLocalCurrencyValueByLanguageMaster = (languageMaster, currencyMaster) => {
    return currencyMaster.localName.getByLanguageMaster(languageMaster)
}


/**
 * @param {number} amount
 * @param {CurrencyMaster} currencyMaster
 * @return {string}
 */
const getAmountByLocalCurrency = (amount, currencyMaster) => {

    let decimalPointer = currencyMaster.localDecimalPointer;
    let seperaterUnit = ",";

    let amountString = amount.toFixed(decimalPointer);
    let amountSplit = amountString.split(".");

    let amountUpperDivider = amountSplit[0];
    let amountLowerDivider = amountSplit[1];

    let amountDownConverted = amountLowerDivider || "";

    let amountUpConverted = amountUpperDivider.toString().replace(/\B(?=(\d{3})+(?!\d))/g, seperaterUnit);

    let money = amountUpConverted + (amountDownConverted.length > 0 ? "." + amountDownConverted : "");

    return money;
}

/**
 *
 * @param {LanguageMaster} languageMaster
 * @param {number} amount
 * @param {CurrencyMaster} currencyMaster
 * @return {string}
 */
const getLocalizedAmountWithCurrency = (languageMaster, amount, currencyMaster) => {
    const localizedAmount = getAmountByLocalCurrency(amount, currencyMaster)
    const localizedCurrency = getLocalCurrencyValueByLanguageMaster(languageMaster, currencyMaster)

    return `${localizedAmount} ${localizedCurrency}`
}


export const MoneyFormatter = {
    getMaster,
    getLocalCurrencyValueByLanguageMaster,
    getAmountByLocalCurrency,
    getLocalizedAmountWithCurrency
}
