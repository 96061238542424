

// Package Dir: release/collection/relation_item
// FileName: AddLibraryItemIntoReleaseCollectionRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.release.collection.relation_item.AddLibraryItemIntoReleaseCollectionRPCRequest
// Super types: java.lang.Object

export class AddLibraryItemIntoReleaseCollectionRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.collections.List<kotlin.String>
         * @type {array<string>}
         */
        this.library_item_entity_ids = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.release_collection_entity_id = null;

    }
}