import * as React from "react";
import * as PropTypes from "prop-types";
import LoadingComponent from "../../../../../ui_template/LoadingComponent";

export default class ImageWithLoader extends React.Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        style: PropTypes.object,
        loaderstyle: PropTypes.object
    }

    getInitialState() {
        return {
            isLoading: true,
            message: null
        }
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = this.getInitialState()

        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                console.log(`Set SRC: ${this.props.src}`)
                entry.target.src = this.props.src
            })
        })
    }


    componentDidMount() {
        this.observer.observe(this.ref.current)
    }

    componentWillUnmount() {
        this.observer.unobserve(this.ref.current)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.imageUrl != this.props.imageUrl){
            this.setState(this.getInitialState())
        }
    }

    handleImageLoaded = () => {
        this.setState((prevs, props)=>{
            return {
                ...prevs,
                isLoading: false,
            }
        })
    }

    handleImageErrored = () => {
        this.setState((prevs, props)=>{
            return {
                ...prevs,
                isLoading: false,
                message: <div>failed to load</div>
            }
        })
    }

    render() {
        return (
            <React.Fragment　>
                <img
                    {...this.props}
                    ref={this.ref}
                    // src={this.props.src}
                    style={{
                        height: 'auto',
                        maxHeight: '100vh',
                        maxWidth: '100vw',
                        userSelect: 'none',
                        ...this.props.style
                    }}
                    onLoad={this.handleImageLoaded}
                    onError={this.handleImageErrored}
                />
                <LoadingComponent loaderFlag={this.state.isLoading} style={this.props.loaderStyle}/>
                {this.state.message}
            </React.Fragment>
        );
    }
}