import * as React from "react";
import $ from "../../library/JQuery";
import {connect} from "react-redux";
import {clearFullSizeDIM, setFullSizeDIM} from "../../state/redux/action/UIControlAction";
import {RouteDictionary} from "../RouteDictionary";
import {pushHistory} from "../RouteHistory";
import LanguagePack from "../../language/LanguagePack";
import SignLangPack from "../../language/SignLangPack";
import SignConstraintPack from "../../constraints/SignConstraintPack";
import LoadingComponent from "../../ui_template/LoadingComponent";
import {RouteListenerComponent} from "../RouteListener";
import {LanguageContainer} from "../../model/container/LanguageContainer";
import LanguageDropdown from "../../ui_component/LanguageDropdown";
import DomIdConstant from "../../constant/DomIdConstant.json";
import {PortalPathDictionary} from "../../share/dictionary/portal_path.dictionary";
import {ServiceDomainDictionary} from "../../share/dictionary/service_domain.dictionary";
import {AccountUnauthorizedServiceClient} from "../../proto/account/AccountServiceServiceClientPb";
import {PutAccountUserRequest} from "../../proto/account/AccountService_pb";
import {LanguageMaster} from "../../proto/master/language/LanguageMaster_pb";
import {ErrorResponse, ErrorResponseCode} from "../../proto/error/ErrorDefinition_pb";
import {getGrpcClient} from "../../api/pickhours/grpc/GrpcClientFactory"

const grpcClient = getGrpcClient(AccountUnauthorizedServiceClient)
/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
    };
};

class SignUpActivityState {
    termsCheckFlag = false;
    termsCheckNoticeBoxOpenFlag = false;
    signForm_messageFlag_lastName = false;
    signForm_messageFlag_firstName = false;
    signForm_messageFlag_accountId = false;
    signForm_messageFlag_accountPassword = false;
    signForm_messageFlag_accountPasswordConfirm = false;
    signForm_messageFlag_agree = false;
}

class SignUp extends RouteListenerComponent {

    REFS_SET = {
        signForm: "signForm",
        signForm_lastName: "signForm_lastName",
        signForm_firstName: "signForm_firstName",
        signForm_accountId: "signForm_accountId",
        signForm_accountPassword: "signForm_accountPassword",
        signForm_accountPasswordConfirm: "signForm_accountPasswordConfirm",
        signForm_agree: "signForm_agree",
        signForm_agreeInput: "signForm_agreeInput",
        signForm_language: "signForm_language",
        signForm_servicePlan: "signForm_servicePlan",
    };

    constructor(props) {
        super(props);
        this.state = new SignUpActivityState();
    }

    componentDidMount() {
        $(this.refs[this.REFS_SET.signForm_agree]).checkbox();

        // if(ApiConnector.MODE === ApiConnector.CONST.MODE.PRODUCTION){
        // TODO remove after releases
        // alert("Sorry, we do not accpet new account currently for expanding server.\n\nPlease visit again since 20 October, 2016.\n\nWe will redirect to Sign In page \n\n すみません。現在サーバー拡張のため新規のアカウントは一時停止されています。１０月２０日以来再度ご接続お願いします。\n\n　ログインページへ移動されます。 ")
        // window.location.href="/"
        // }
    }


    async signUp(e) {
        e.preventDefault();

        //Get parameters from input
        let user_id = this.refs[this.REFS_SET.signForm_accountId]["value"];
        let user_password = this.refs[this.REFS_SET.signForm_accountPassword]["value"];
        let user_passwordReType = this.refs[this.REFS_SET.signForm_accountPasswordConfirm]["value"];
        let user_firstName = this.refs[this.REFS_SET.signForm_firstName]["value"];
        let user_lastName = this.refs[this.REFS_SET.signForm_lastName]["value"];
        let sign_up_agree_result = this.refs[this.REFS_SET.signForm_agreeInput]["checked"];
        let sign_up_language = this.refs[this.REFS_SET.signForm_language]["value"];
        // let sign_up_servicePlan = this.refs[this.REFS_SET.signForm_servicePlan]["value"];
        // let sign_up_servicePlan = "PayAsYouGoServicePlan";

        //Validation
        let validationResult = [];

        validationResult.push(this.routeValidatingInput(this.REFS_SET.signForm_accountId, {target: {value: user_id}}))
        validationResult.push(this.routeValidatingInput(this.REFS_SET.signForm_accountPassword, {target: {value: user_password}}));
        validationResult.push(this.routeValidatingInput(this.REFS_SET.signForm_accountPasswordConfirm, {target: {value: user_passwordReType}}));
        validationResult.push(this.routeValidatingInput(this.REFS_SET.signForm_firstName, {target: {value: user_firstName}}));
        validationResult.push(this.routeValidatingInput(this.REFS_SET.signForm_lastName, {target: {value: user_lastName}}));
        validationResult.push(this.routeValidatingInput(this.REFS_SET.signForm_agreeInput, {target: {value: sign_up_agree_result}}));

        let everFalseResult = validationResult.filter(result => result === false).length > 0;
        if (everFalseResult === false) {
            setFullSizeDIM(<LoadingComponent loaderFlag={true} />)

            const langType = this.props.language

            await grpcClient.putAccountUser(new PutAccountUserRequest()
                .setAccountId(user_id)
                .setAccountPassword(user_password)
                .setFirstName(user_firstName)
                .setLastName(user_lastName)
                // .setLanguage(sign_up_language)
                .setLanguage(LanguageMaster.JAPANESELANGUAGEMASTER)
            , null)
                .then(response => {
                    let message = LanguagePack.extract(langType, SignLangPack.guide.resultMessageForSucceed);
                    window.alert(message);
                    pushHistory(RouteDictionary.signIn)
                })
                .catch((err: ErrorResponse) => {
                    let message: String = ''
                    if(err.getErrorcode() == ErrorResponseCode.ER_409_CONFLICT){
                        message = LanguagePack.extract(langType, SignLangPack.guide.resultMessageForIdDuplication)
                    } else {
                        message = LanguagePack.extract(langType, SignLangPack.guide.resultMessageForFailed);
                    }

                    window.alert(message);
                })
                .finally(_ => {
                    clearFullSizeDIM();
                })
        }
    }


    checkedTerms() {
        this.setState((prevs, props)=> {
            return {
                ...prevs,
                termsCheckFlag: true,
                termsCheckNoticeBoxOpenFlag: false
            };
        });
    }

    checkAgreeButton(event) {
        console.log({event})
        if (this.state.termsCheckFlag === false) {
            this.setState((prevs, props)=> {
                return {
                    ...prevs,
                    termsCheckNoticeBoxOpenFlag: true
                };
            });
        } else {
            this.setState((prevs, props)=> {
                return {
                    ...prevs,
                    termsCheckNoticeBoxOpenFlag: false,
                    signForm_messageFlag_agree: false
                };
            });
        }
    }

    routeValidatingInput(formTarget, event) {
        let value = event.target.value;
        let validateResult = this.validateSignForm(formTarget, value);

        //Pass function to guarantee immutable state
        let newState = (prevState, props) => {

            let invertedResult = !(validateResult)
            switch (formTarget) {
                case this.REFS_SET.signForm_accountId:
                    prevState.signForm_messageFlag_accountId = invertedResult;
                    break;
                case this.REFS_SET.signForm_accountPassword: {

                    //Own secretKey
                    prevState.signForm_messageFlag_accountPassword = invertedResult;

                    let passwordCrossConfirming = () => {
                        //Confirming secretKey
                        let confirmFieldValue = this.refs[this.REFS_SET.signForm_accountPasswordConfirm]["value"];
                        let passwordConfirmResult = this.validateSignForm(this.REFS_SET.signForm_accountPasswordConfirm, confirmFieldValue);
                        return passwordConfirmResult;
                    }

                    //confirm
                    prevState.signForm_messageFlag_accountPasswordConfirm = !(passwordCrossConfirming())
                    break;
                }
                case this.REFS_SET.signForm_accountPasswordConfirm:
                    prevState.signForm_messageFlag_accountPasswordConfirm = invertedResult;
                    break;
                case this.REFS_SET.signForm_lastName:
                    prevState.signForm_messageFlag_lastName = invertedResult;
                    break;
                case this.REFS_SET.signForm_firstName:
                    prevState.signForm_messageFlag_firstName = invertedResult;
                    break;
                case this.REFS_SET.signForm_agreeInput:
                    prevState.signForm_messageFlag_agree = invertedResult;
                    break;
                default: throw Error('unexpected formTarget::' + formTarget)
            }

            return prevState
        }
        this.setState(newState);

        return validateResult;
    }

    validateSignForm(formTarget, targetValue) {

        switch (formTarget) {
            case this.REFS_SET.signForm_accountId:
                return (targetValue.indexOf("@") > 0 && targetValue.indexOf(".") > 0)
            case this.REFS_SET.signForm_accountPassword: {
                let regExpChar = /[a-z.]+/g.exec(targetValue);
                let regExpNum = /[\d]+/g.exec(targetValue);

                return (
                    targetValue.length >= 8 &&
                    regExpChar &&
                    regExpChar.length > 0 &&
                    regExpNum &&
                    regExpNum.length > 0
                )
            }
            case this.REFS_SET.signForm_accountPasswordConfirm: {
                // it may not need becuase other secretKey already chekced it.
                // let ownValueValidation = (targetValue.length >= 8 && /[a-z.]+/.exec(targetValue).length > 0 && /[\d]/.exec(targetValue).length > 0)
                let comparingPassword = this.refs[this.REFS_SET.signForm_accountPassword]["value"];
                let comparedResult = (targetValue === comparingPassword);

                // return (ownValueValidation && comparedResult)
                return comparedResult;
            }
            case this.REFS_SET.signForm_lastName:
                return (targetValue.length >= 1);
            case this.REFS_SET.signForm_firstName:
                return (targetValue.length >= 1);
            case this.REFS_SET.signForm_agreeInput:
                return (targetValue === true);
            default:
                return false;
        }
    }

    updateLanguageValue(langType) {
        this.refs[this.REFS_SET.signForm_language]["value"] = langType.value
        this.setState(this.state);
    }

    genLanguageDropdown() {
        let defaultValue = this.props.language
        let inputForLanguage = this.refs[this.REFS_SET.signForm_language]
        if (inputForLanguage && inputForLanguage["value"] && inputForLanguage["value"] !== "") {
            defaultValue = LanguageContainer.deserialize(inputForLanguage["value"])
        }

        return (
            <LanguageDropdown
                id={DomIdConstant.DomIdSignUp.languageForm}
                idEnglish={DomIdConstant.DomIdSignUp.languageFormToEnglish}
                idJapanese={DomIdConstant.DomIdSignUp.languageFormToJapanese}
                idKorean={DomIdConstant.DomIdSignUp.languageFormToKorean}
                updateAction={this.updateLanguageValue.bind(this)}
                defaultLanguage={defaultValue}
            />
        )
    }


    appContainerStyle = {
        padding: 0, paddingTop: 0, paddingBottom: 0, overflow: 'scroll'
    }

    render() {
        /**
         * @type {LanguageContainer}
         */
        const langauge = this.props.language

        return (
            <div style={{width: '100%', height:'100%', overflowY: 'scroll'}}>
                {/*<IntroMenu isLoginButton={true} parentProps={this.props}/>*/}

                <div style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/assets/img/pixabay/animal-1238239.jpg')`,
                    backgroundColor: 'white',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className="ui container">
                        <div style={{paddingTop: 90, paddingBottom: 70}}>
                            <div style={{height: 60}}></div>
                            <div style={{textAlign: 'right'}}>
                                <h1>
                                    {LanguagePack.extract(langauge, SignLangPack.guide.signUpTitle)}
                                </h1>
                                <p>
                                    {LanguagePack.extract(langauge, SignLangPack.guide.signUpTitleSub)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{padding: 10}}></div>
                <div className="ui container">

                    <div style={{height: 10}}></div>

                    <form className="ui form" onSubmit={this.signUp.bind(this)} ref={this.REFS_SET.signForm}>


                        <div className="two fields">
                            <div className="field">

                                <div className="field">
                                    <label>
                                        {LanguagePack.extract(langauge, SignLangPack.fields.email)}
                                    </label>
                                    <div className="ui left icon input">
                                        <input type="email"
                                               id={DomIdConstant.DomIdSignUp.accountId}
                                               ref={this.REFS_SET.signForm_accountId}
                                               onChange={this.routeValidatingInput.bind(this, this.REFS_SET.signForm_accountId)}
                                               minLength={SignConstraintPack.email.minLength}
                                               maxLength={SignConstraintPack.email.maxLength}
                                               required pattern={SignConstraintPack.email.regEx}
                                        />
                                        <i className="mail icon"/>
                                    </div>
                                    {(this.state.signForm_messageFlag_accountId ? (
                                        <div className="ui negative message">
                                            {LanguagePack.extract(langauge, SignLangPack.fields.emailGuide)}
                                        </div>
                                    ) : null)}
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        {LanguagePack.extract(langauge, SignLangPack.fields.password)}<br/>
                                        <div style={{
                                            color: 'grey',
                                            fontWeight: 100,
                                            padding: 5,
                                            paddingBottom: 10
                                        }}> * {LanguagePack.extract(langauge, SignLangPack.fields.passwordGuide)}</div>
                                    </label>
                                    <div className="ui left icon input">
                                        <input type="password"
                                               id={DomIdConstant.DomIdSignUp.accountPassword}
                                               ref={this.REFS_SET.signForm_accountPassword}
                                               onChange={this.routeValidatingInput.bind(this, this.REFS_SET.signForm_accountPassword)}
                                               minLength={SignConstraintPack.password.minLength}
                                               maxLength={SignConstraintPack.password.maxLength}
                                               required pattern={SignConstraintPack.password.regEx}
                                        />
                                        <i className="lock icon"/>
                                    </div>
                                    {(this.state.signForm_messageFlag_accountPassword ? (
                                        <div className="ui negative message">
                                            {LanguagePack.extract(langauge, SignLangPack.fields.passwordGuide)}
                                        </div>
                                    ) : null)}
                                </div>

                                <div className="field">
                                    <label>
                                        {LanguagePack.extract(langauge, SignLangPack.fields.verifyPassword)}
                                    </label>
                                    <div className="ui left icon input">
                                        <input type="password"
                                               id={DomIdConstant.DomIdSignUp.accountPasswordConfirm}
                                               ref={this.REFS_SET.signForm_accountPasswordConfirm}
                                               onChange={this.routeValidatingInput.bind(this, this.REFS_SET.signForm_accountPasswordConfirm)}
                                               minLength={SignConstraintPack.password.minLength}
                                               maxLength={SignConstraintPack.password.maxLength}
                                               required pattern={SignConstraintPack.password.regEx}
                                        />
                                        <i className="lock icon"/>
                                    </div>
                                    {(this.state.signForm_messageFlag_accountPasswordConfirm ? (
                                        <div className="ui negative message">
                                            {LanguagePack.extract(langauge, SignLangPack.fields.veryfyPasswordGuide)}
                                        </div>
                                    ) : null)}
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="two fields">
                                    <div className="field">
                                        <label>
                                            {LanguagePack.extract(langauge, SignLangPack.fields.firstName)}
                                        </label>
                                        <div className="ui input">
                                            <input type="text"
                                                   id={DomIdConstant.DomIdSignUp.firstName}
                                                   ref={this.REFS_SET.signForm_firstName}
                                                   onChange={this.routeValidatingInput.bind(this, this.REFS_SET.signForm_firstName)}
                                                   minLength={SignConstraintPack.name.minLength}
                                                   maxLength={SignConstraintPack.name.maxLength}
                                            />
                                        </div>
                                        {(this.state.signForm_messageFlag_firstName ? (
                                            <div className="ui negative message">
                                                {LanguagePack.extract(langauge, SignLangPack.fields.nameGuide)}
                                            </div>
                                        ) : null)}
                                    </div>
                                    <div className="field">
                                        <label>
                                            {LanguagePack.extract(langauge, SignLangPack.fields.lastName)}
                                        </label>
                                        <div className="ui input">
                                            <input type="text"
                                                   ref={this.REFS_SET.signForm_lastName}
                                                   id={DomIdConstant.DomIdSignUp.lastName}
                                                   onChange={this.routeValidatingInput.bind(this, this.REFS_SET.signForm_lastName)}
                                                   minLength={SignConstraintPack.password.minLength}
                                                   maxLength={SignConstraintPack.password.maxLength}
                                            />
                                        </div>
                                        {(this.state.signForm_messageFlag_lastName ? (
                                            <div className="ui negative message">
                                                please type your last name at least 1 character.
                                            </div>
                                        ) : null)}
                                    </div>
                                </div>

                                <div style={{padding: 4}}></div>

                                <div className="field">
                                    <label>
                                        {LanguagePack.extract(langauge, SignLangPack.fields.language)}
                                    </label>
                                    <input type="hidden"
                                           ref={this.REFS_SET.signForm_language}
                                           defaultValue={this.props.language.value}
                                    />
                                    {this.genLanguageDropdown()}
                                </div>

                            </div>
                            <div className="field">

                                <div style={{padding: 4}}></div>

                                <div className="ui segment">
                                    <h4 className="ui dividing header">
                                        {LanguagePack.extract(langauge, SignLangPack.guide.agreementOfTerms)}
                                    </h4>
                                    <div className="field">
                                        <a href={`${ServiceDomainDictionary.portal}${PortalPathDictionary.company.policy.path}?lang=${langauge.value}`}
                                           id={DomIdConstant.DomIdSignUp.policyLink}
                                           target={"_blank"}
                                           rel={"noopenner noreferrer"}
                                           onClick={this.checkedTerms.bind(this)}
                                        >
                                            {LanguagePack.extract(langauge, SignLangPack.guide.openTerms)}
                                        </a>
                                        <br/>
                                        {
                                            (this.state.termsCheckNoticeBoxOpenFlag ?
                                                <div className="ui negative message">
                                                    {LanguagePack.extract(langauge, SignLangPack.guide.notYetOpenedTerms)}
                                                </div>
                                                : null)
                                        }
                                        <br/>
                                    </div>
                                    <div className="field">
                                        <div ref={this.REFS_SET.signForm_agree}
                                             id={DomIdConstant.DomIdSignUp.policyAgreement}
                                             className="ui toggle checkbox"
                                             onClick={this.checkAgreeButton.bind(this)}
                                        >
                                            <input ref={this.REFS_SET.signForm_agreeInput}
                                                   type="checkbox"
                                                   name="gift"
                                                   tabIndex={0}
                                                   className="hidden"/>
                                            <label>{LanguagePack.extract(langauge, SignLangPack.guide.iReadAndAgreeToIt)}</label>
                                        </div>
                                    </div>
                                    {(this.state.signForm_messageFlag_agree ? (
                                        <div className="ui negative message">
                                            {LanguagePack.extract(langauge, SignLangPack.fields.agreeGuide)}
                                        </div>
                                    ) : null)}
                                </div>
                                <button className="ui blue submit green button"
                                        id={DomIdConstant.DomIdSignUp.submitButton}
                                        tabIndex={0}
                                        onClick={this.signUp.bind(this)}>
                                    {LanguagePack.extract(langauge, SignLangPack.buttons.signUp)}
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
                <div style={{height: 100, marginTop: 25}}/>
            </div>
        )
    }
}

export default connect(mapStateToProps)(SignUp);
