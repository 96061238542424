import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {ADMIN_GET_ORDER_HISTORY__V1_0} from "../../../../api/loops4/caller/loops4RpcCaller";
import {COMMERCE_ORDER_HISTORY_SET_ADMIN} from "../../../../state/redux/constant/ActionType";
import {LanguageMaster} from "../../../../model/formatter/LanguageFormatter";
import CommerceOrderHistoryModel from "../../../../model/entity/commerce/CommerceOrderHistoryModel";
import {getAuthorizationHeader} from "../../../../api/loops4/Loops4RPCAPI";
import {EmptyRequest} from "../../../../api/loops4/model/general/EmptyRequest";
import LanguagePack from "../../../../language/LanguagePack";
import OrderHistoryLangPack from "../../../../language/publish/order/OrderHistoryLangPack";
import AdminCompactOrderHistoryRow from "./viewer/AdminCompactOrderHistoryRow";
import {ReduxMainStore} from "../../../../state/redux/store/ReduxMainStore";
import DimLoaderComponent from "../../../../ui_template/DimLoaderComponent";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        ...props,
        language: state.language,
        accessTokenModel: state.auth.admin,
        adminInfo: state.adminInfo,
        routeState: state.routeState,
        adminOrderHistory: state.adminOrderHistory
    };
};

class AdminOrderHistoryBox extends React.Component {

    static propTypes = {
        language: PropTypes.object.isRequired,
        languageMaster: PropTypes.objectOf(LanguageMaster),
        accessTokenModel: PropTypes.object.isRequired,

        adminOrderHistory: PropTypes.arrayOf(CommerceOrderHistoryModel).isRequired,
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
        };

        (async (accessTokenModel) => {
            const rpcResult = await ADMIN_GET_ORDER_HISTORY__V1_0({
                headers: getAuthorizationHeader(accessTokenModel),
                paramBody: new EmptyRequest()
            })

            if(rpcResult.isError()){
                console.error({rpcResult})
            } else {
                ReduxMainStore.dispatch({
                    type: COMMERCE_ORDER_HISTORY_SET_ADMIN,
                    payload: rpcResult.model.orders
                })
            }

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: false
                }
            })
        })(
            this.props.accessTokenModel
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {
            return (
                <div className={'contentBox'}>
                    <h1>
                        <i className="ui icon boxes"/> {LanguagePack.extract(this.props.language, OrderHistoryLangPack.orderHistory.title)}
                    </h1>
                    <div>
                        {LanguagePack.extract(this.props.language, OrderHistoryLangPack.orderHistory.guide)}
                    </div>

                    <div className="ui section divider"/>

                    <div style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                    }}>
                        <div className="statistic">
                            <div className="value">
                                <i className="shopping bag icon"/> {this.props.adminOrderHistory.length}
                            </div>
                            <div className="label">
                                注文件数
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop: 10}}>
                        {this.props.adminOrderHistory.map((orderHistory) => {
                            return <AdminCompactOrderHistoryRow compactOrder={orderHistory}
                            />
                        })}
                    </div>
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(AdminOrderHistoryBox);