import * as React from "react";
import * as PropTypes from "prop-types";
import {getAuthorizationHeader} from "../../../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../../../ui_template/DimLoaderComponent";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../RouteDictionary";
import DateFormatter from "../../../../model/formatter/DateFormatter";
import {GET_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM__v1_0} from "../../../../api/loops4/caller/loops4RpcCaller";
import {GetOrderCommerceProductReleaseItemRPCRequest} from "../../../../api/loops4/model/commerce/order/get_order_commerce_product_release_item/GetOrderCommerceProductReleaseItemRPCRequest";
import {connect} from "react-redux";
import OrderRefundForm from "./form/OrderRefundForm";
import LanguagePack from "../../../../language/LanguagePack";
import OrderHistoryLangPack from "../../../../language/publish/order/OrderHistoryLangPack";
import {RouteUtility} from "../../../RouteUtility";
import ReleaseCollectionNavigator from "../navigator/ReleaseCollectionNavigator";
import {Link} from "react-router-dom";
import {getOrderStateAsLocal} from "../../../../model/formatter/OrderFormatter";
import {MoneyFormatter} from "../../../../model/formatter/MoneyFormatter";
import {
    getShippingMethodAsLocal,
    getShippingStateAsLocal,
    isInactiveState
} from "../../../../model/formatter/ShippingFormatter";
import {Table} from "../../../../ui_template/table/Table";
import {TableBodyCell} from "../../../../ui_template/table/cell/TableBodyCell";
import {TableHeadCell} from "../../../../ui_template/table/cell/TabelHeadCell";
import * as Loops4BinaryAPI from "../../../../api/loops4/Loops4BinaryAPI";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        ...props,
        routeState: state.routeState,
        languageMaster: state.languageMaster,
    };
};

class ReleaseCollectionOrderDetailBox extends React.Component {

    static propTypes = {
        languageMaster: PropTypes.object,
        storeId: PropTypes.string,
        releaseCollectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }

    style = {
        summary_column: {
            marginBottom: 5
        },
        detail_title: {
            fontWeight: 'bold'
        },
        detail_description: {
            marginLeft: 5
        }
    }


    REFS_SET = {
        itemsContainer: "itemsContainer"
    };

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            errorMessageBox: null,
            /**
             * @type GetOrderCommerceProductReleaseItemRPCResponse
             */
            order: null
        }
    }


    componentDidMount() {
        this.getOrderDetail(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.getOrderDetail(nextProps)
    }

    genHeader(title) {
        return (
            <div>
                <span style={{marginLeft: 5}}>
                    {title}
                </span>
            </div>
        )
    }

    getOrderDetail(nextProps) {
        console.log('nextProps', nextProps)
        if(nextProps.releaseCollectionModel && nextProps.releaseCollectionModel.entityId){
            this.setState((preState, props) => {
                return {
                    ...preState,
                    isLoading: true,
                    errorMessageBox: null
                }
            }, async () => {
                const orderId = nextProps.routeState.pathParameter[RoutePathParam.orderId]

                const param = new GetOrderCommerceProductReleaseItemRPCRequest()
                param.commerce_order_entity_id = orderId

                const rpcResult = await GET_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM__v1_0({
                    headers: getAuthorizationHeader(nextProps.accessTokenModel),
                    paramBody: param
                })

                console.log('rpcResult', rpcResult)

                if (rpcResult.isError()) {
                    this.setState((preState, props) => {
                        return {
                            ...preState,
                            isLoading: false,
                            errorMessageBox: (
                                <div>
                                    Request got failed:: {rpcResult.getErrorMessage()}
                                </div>
                            )
                        }
                    })
                } else {
                    this.setState((preState, props) => {
                        return {
                            ...preState,
                            isLoading: false,
                            errorMessageBox: null,
                            order: rpcResult.model
                        }
                    })
                }
            });
        }
    }

    render() {
        const orderId = this.props.routeState.pathParameter[RoutePathParam.orderId]

        let orderDetail = null
        if (this.state.isLoading) {
            orderDetail = (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else if (this.state.errorMessageBox) {
            orderDetail = this.state.errorMessageBox
        } else {
            const order = this.state.order
            const currencyMaster  = MoneyFormatter.getMaster(order.bill.price.price_currency)

            orderDetail = (
                <div className={'contentBox'}>
                    <h1>
                        <i className="ui icon boxes" /> {LanguagePack.extract(this.props.language, OrderHistoryLangPack.orderHistory.title)}
                    </h1>
                    <div>
                        <Link to={RouteUtility.getPathname(RouteDictionary.releaseCollection, {
                            [RoutePathParam.storeId]: this.props.storeId,
                            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId
                        },{
                            [RouteQueryParam.view_mode]: ReleaseCollectionNavigator.viewMode.orderHistory,
                        })}>
                            <div className={'ui button'}>
                                注文履歴へ戻る
                            </div>
                        </Link>
                    </div>

                    <div className="ui section divider"></div>

                    <div className={'ui padded segment'}>
                        <div style={{display: 'block', width: '100%'}}>
                            <div className="ui header medium">
                                <i className="box icon"></i>
                                注文の詳細
                            </div>

                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文ID
                                </div>
                                <div>
                                    {order.entity_id}
                                </div>
                            </div>

                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文詳細
                                </div>
                                <div>
                                    {getOrderStateAsLocal(order.order_state)}
                                </div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文日
                                </div>
                                <div>{DateFormatter.getDateFormatForLocaleByLanguageMaster(this.props.languageMaster, order.date_created)}</div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文総額
                                </div>
                                <div>
                                    <ul>
                                        <li>Buyer</li>
                                        <table>
                                            <tr><td>Product</td><td>+</td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.product_price_amount, currencyMaster)}</td></tr>
                                            <tr><td>Shipping</td><td>+</td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.shipping_price_amount, currencyMaster)}</td></tr>
                                            <tr><td>Refund</td><td>-</td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.refund_price_amount, currencyMaster)}</td></tr>
                                            <tr><td>Total bill</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount, currencyMaster)}</td></tr>
                                        </table>
                                        <li>Seller</li>
                                        <table>
                                            <tr><td>Revenue</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount, currencyMaster)}</td></tr>
                                            <tr><td>Fee</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount * 0.2, currencyMaster)}</td></tr>
                                            <tr><td>Net</td><td></td><td style={{textAlign: 'right'}}>{MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, order.bill.price.total_price_amount * 0.8, currencyMaster)}</td></tr>
                                        </table>
                                    </ul>
                                </div>
                            </div>

                            <div className="ui section divider"></div>

                            <div style={this.style.summary_column}>
                                <div className="ui header medium">
                                    <i className="shipping fast icon"></i>
                                    配送
                                </div>
                                {order.shipping.map(
                                    /**
                                     * @param {OrderRequestedShippingEntityRPCBody} shipping
                                     * @returns {*}
                                     */
                                    (shipping, index) => {
                                        return (
                                            <div>
                                                {index > 0 ? (<div className="ui hidden divider" />) : null}
                                                <div className={'ui segment ' + (isInactiveState(shipping.shipping_state) ? '  grey' : ' blue')}>
                                                    <div>
                                                        <h4 className={"ui header"}>
                                                            <span className="ui grey circular label">{index + 1}</span> 配送
                                                            <div className="sub header" style={{marginLeft: 35}}>{shipping.entity_id}</div>
                                                        </h4>
                                                    </div>
                                                    <ul>
                                                        <li style={{fontWeight: 'bold'}}>{getShippingStateAsLocal(shipping.shipping_state)}</li>
                                                        <li>{getShippingMethodAsLocal(shipping.shipping_method)}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                    <div style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 5,
                        marginBottom: 15,
                    }}>
                        <div className="statistic">
                            <div className="value">
                                <i className="camera icon"></i> {order.products.length}
                            </div>
                            <div className="label">
                                注文製品数
                            </div>
                        </div>
                    </div>

                    <div>
                        <Table className="ui unstackable small table"
                               header={<TableHeadCell columns={["イメージ", "詳細"]}  />}
                               rows={order.products.map(
                                   /**
                                    * @param {OrderCommerceProductReleaseItemEntityRPCBody} product
                                    */
                                   (product) => {
                                       const currencyMaster  = MoneyFormatter.getMaster(product.release_price.price_currency)

                                       /**
                                        * @type {OrderCommerceRecordReleaseItemEntityRPCBody}
                                        */
                                       const releaseItemId = order.release_items.find(release_item => release_item.product_entity_id === product.entity_id)

                                       const imageSrc = Loops4BinaryAPI.getReleaseItemPreviewURL({
                                           accessTokenModel: this.props.accessTokenModel,
                                           releaseItemId: releaseItemId.entity_id,
                                           longTermPixel: 100
                                       })

                                       return (
                                           <TableBodyCell
                                               cellStyles={[
                                                   {
                                                       width: 130,
                                                       maxHeight: 200
                                                   },
                                                   {}
                                               ]}
                                               columns={[
                                                   (
                                                       <img src={imageSrc} />
                                                   ),
                                                   (
                                                       <ul>
                                                           <li>
                                                       <span style={this.style.detail_title}>
                                                            製品名:
                                                       </span>
                                                               <span style={this.style.detail_description}>
                                                           {releaseItemId.itemName}
                                                       </span>
                                                           </li>
                                                           <li>
                                                       <span style={this.style.detail_title}>
                                                         数量:
                                                       </span>
                                                               <span style={this.style.detail_description}>
                                                           {product.quantity}
                                                       </span>
                                                           </li>
                                                           <li>
                                                       <span style={this.style.detail_title}>
                                                        価格:
                                                       </span>
                                                               <span style={this.style.detail_description}>
                                                           {MoneyFormatter.getLocalizedAmountWithCurrency(this.props.languageMaster, product.bill_price.price_amount, currencyMaster)}
                                                       </span>
                                                           </li>
                                                       </ul>
                                                   )
                                               ]} />
                                       )
                                   }
                               )}
                        />
                    </div>

                    <div style={{height: 20}}></div>
                </div>
            )
        }

        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {
            return (<React.Fragment>
                <div className="flexContainerColumn">
                    <h3>Order detail</h3>
                    <div className="flexItem00">
                        <OrderRefundForm orderId={orderId} />
                    </div>
                    {orderDetail}
                </div>
            </React.Fragment>)
        }
    }
}

export default connect(mapStateToProps)(ReleaseCollectionOrderDetailBox);