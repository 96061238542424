import {ReleaseItemExifRPCBody} from "../../release/item/ReleaseItemExifRPCBody";
import {GetCommerceSaleProductReleaseItemRPCResponse} from "../../commerce/sale/product/release/item/GetCommerceSaleProductReleaseItemRPCResponse";

// Package Dir: release/item
// FileName: GetReleaseItemRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.release.item.GetReleaseItemRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.release.item.ReleaseItemRPCBody, com.loops4.controller.loops4rpc.model.release.item.ReleaseItemRPCRelation, java.lang.Object

export class GetReleaseItemRPCResponse {
    constructor() {

        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.release.item.ReleaseItemExifRPCBody
         * @type {ReleaseItemExifRPCBody}
         */
        this.exif = new ReleaseItemExifRPCBody();
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.file_height = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.file_width = 0;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.item_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.item_state = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.item_type = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.library_item_entity_id = null;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.sale.product.release.item.GetCommerceSaleProductReleaseItemRPCResponse?
         * @type {GetCommerceSaleProductReleaseItemRPCResponse}
         */
        this.product = new GetCommerceSaleProductReleaseItemRPCResponse();

    }
}