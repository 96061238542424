import * as React from "react";
import * as PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import * as UUID from "uuid";

export default class ScrollReachLoader extends React.Component {

    static propTypes = {
        renderContainer: PropTypes.func,
        className: PropTypes.string,
        style: PropTypes.object,
        renderNextPage: PropTypes.func,
        delay: PropTypes.number,
    }

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            hasMore: true,
            currentPageNumber: 0
        };
    }

    scrollId = 'ScrollReachLoader-' + UUID.v4()
    defaultDelay = 20

    waitFor = async (miliseconds) => {
        return new Promise((resolve) => {
            setTimeout(resolve, miliseconds)
        })
    };

    loadNextPage = async (callback) => {
        await this.waitFor(this.props.delay || this.defaultDelay)

        let nextHasMore = false
        let nextPageItems = []
        let nextPageNumber = this.state.currentPageNumber

        const foundNextPage = this.props.renderNextPage(this.state.currentPageNumber + 1)

        if(foundNextPage){
            if(Array.isArray(foundNextPage)){
                if(foundNextPage.length > 0){
                    nextPageItems = foundNextPage
                    nextHasMore = true
                    nextPageNumber += 1
                } else {
                    nextHasMore = false
                }
            } else {
                nextPageItems = [foundNextPage]
                nextPageNumber += 1
                nextHasMore = true
            }
        }

        this.setState((prevS, prop) => {
            prevS.items.push(...nextPageItems)
            prevS.hasMore = nextHasMore
            prevS.currentPageNumber = nextPageNumber

            return prevS
        }, () => {
            if(callback){
                callback(nextHasMore)
            }
        });
    }

    componentDidMount() {
        this.loadNextPage()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadNextPage()
    }

    style = {
        mainContainer: {
            overflowX: 'hidden',
            overflowY: 'scroll',
            display: 'block'
        }
    }

    render() {
        return (
            <div className={this.props.className}
                 id={this.scrollId}
                 style={Object.assign({}, this.style.mainContainer, this.props.style)}
            >
                <InfiniteScroll
                    dataLength={this.state.items.length}
                    next={this.loadNextPage}
                    hasMore={this.state.hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: "center", marginTop: 20 }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    scrollableTarget={this.scrollId}
                >
                    {this.props.renderContainer ? (
                        this.props.renderContainer(this.state.items)
                    ) : (
                        this.state.items.map((i, index) => (
                            <React.Fragment key={index}>
                                {i}
                            </React.Fragment>
                        ))
                    )}
                </InfiniteScroll>
            </div>
        )
    }
}
