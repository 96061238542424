export default class UploadingProgressModel{
    /**
     * @param {number} uploadedKB
     * @param {number} totalKB
     * @param {number} progressPercentage
     * @param {number} speedKBPerSec
     * @param {number} target
     */
    constructor(uploadedKB, totalKB, progressPercentage, speedKBPerSec, target) {
        this.uploadedKB = uploadedKB;
        this.totalKB = totalKB;
        this.progressPercentage = progressPercentage;
        this.speedKBPerSec = speedKBPerSec;
        this.target = target;
    }
}