

// Package Dir: commerce/order/ship
// FileName: AdminCancelDeliveryHandlerRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.ship.AdminCancelDeliveryHandlerRPCRequest
// Super types: java.lang.Object

export class AdminCancelDeliveryHandlerRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_shipping_entity_id = null;

    }
}