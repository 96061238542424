import * as React from "react";
import AbsoluteFullSizeBgBlurBox from "../../../../../ui_template/AbsoluteFullSizeBgBlurBox";
import ServiceInfoConstant from "../../../../../constant/ServiceInfoConstant";
import * as PropTypes from "prop-types";
import {pushHistory} from "../../../../RouteHistory";
import {RouteUtility} from "../../../../RouteUtility";
import {RouteDictionary, RoutePathParam} from "../../../../RouteDictionary";
import {clearFullSizeDIM} from "../../../../../state/redux/action/UIControlAction";
import {GetReleaseCollectionRPCResponse} from "../../../../../api/loops4/model/release/collection/GetReleaseCollectionRPCResponse";

export default class StoreReleaseSubmitSuccessResultComponent extends React.Component{

    static propTypes = {
        storeId: PropTypes.string,
        releaseCollectionModel: PropTypes.instanceOf(GetReleaseCollectionRPCResponse),
    }

    goToTheReleaseCollection(){
        clearFullSizeDIM()
        pushHistory(RouteUtility.getPathname(RouteDictionary.releaseCollection, {
            [RoutePathParam.storeId]: this.props.storeId,
            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entity_id,
        }))
    }

    divider = <div style={{height: 15}}></div>

    render(){
        return (
            <AbsoluteFullSizeBgBlurBox>
                <div style={{marginTop: 140}}>

                    <h2>
                        <i className="icon announcement" />
                        Thank you, Your release request is accepted
                    </h2>

                    <div>
                        <ul style={{display:'inline-block', textAlign:'left'}}>
                            <li>Release source id: {this.props.releaseCollectionModel.entity_id}</li>
                            <li>Release collection name: {this.props.releaseCollectionModel.collection_name}</li>
                        </ul>
                    </div>

                    {this.divider}
                    {this.divider}

                    <button
                        className="ui button positive"
                        onClick={this.goToTheReleaseCollection.bind(this)}
                    >
                        Go to the collection
                    </button>

                    {this.divider}
                    {this.divider}


                    <p><i className="icon help circle outline" />If you keep a problem, please contact us <br/>
                        <a href="mailto:concierge@ating.me">{ServiceInfoConstant.departments.concierge.email}</a>
                    </p>
                </div>
            </AbsoluteFullSizeBgBlurBox>
        )
    }
}
