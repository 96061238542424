

// Package Dir: model_base
// FileName: RPCModelBase.js
// RPC model: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase
// Super types: java.lang.Object

export class RPCModelBase {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;

    }
}