

// Package Dir: commerce/order/ship
// FileName: AdminAddNewDeliveryHandlerRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.ship.AdminAddNewDeliveryHandlerRPCRequest
// Super types: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderEntityIdRPCBody

export class AdminAddNewDeliveryHandlerRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_order_entity_id = null;

    }
}