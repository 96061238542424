import DateUTCModel from "../DateUTCModel";
import PersonContainer from "../../container/PersonContainer";
import {LanguageContainer} from "../../container/LanguageContainer";

export default class AccountSubscriberModel extends PersonContainer {

    entityId = null;

    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();

    accessKey = null;
    email = null;
    lastName = null;
    firstName = null;
    language = LanguageContainer.master.english;

    commerceCartId = null;

    /**
     *
     * @param data
     * @return {AccountSubscriberModel}
     */
    static deserialize(data){
        let newModel = new AccountSubscriberModel();

        newModel.entityId = data.entity_id;
        newModel.accessKey = data.access_key;

        newModel.dateCreated = ( data.date_created ? DateUTCModel.deserialize(data.date_created) : new DateUTCModel() );
        newModel.dateUpdated = ( data.date_updated ? DateUTCModel.deserialize(data.date_updated) : new DateUTCModel() );

        newModel.email = data.email;
        newModel.lastName = data.last_name;
        newModel.firstName = data.first_name;
        newModel.language = (data.language ? LanguageContainer.deserialize(data.language) : LanguageContainer.master.english);

        newModel.commerceCartId = data.commerce_cart_product_entity_id;

        return newModel;
    }

    serialize(){
        return {
            entity_id: this.entityId,
            access_key: this.accessKey,
            date_created: this.dateCreated,
            date_updated: this.dateUpdated,
            email: this.email,
            last_name: this.lastName,
            first_name: this.firstName,
            language: this.lang,
            commerce_cart_product_entity_id: this.commerceCartId,
        }
    }
}
