import * as React from "react";
import * as PropTypes from "prop-types";
import {ScrollableTable} from "../../../../ui_template/table/ScrollableTable";
import DateFormatter from "../../../../model/formatter/DateFormatter";
import {CompactReleaseItemInCollectionBrowserBox} from "./viewer/CompactReleaseItemInCollectionBrowserBox";
import {TableHeadCell} from "../../../../ui_template/table/cell/TabelHeadCell";
import CommerceProductModel from "../../../../model/entity/commerce/CommerceProductModel";
import ReleaseItemUpdateForm from "./viewer/ReleaseItemUpdateForm";
import ReleaseItemPreviewGenerateForm from "./viewer/ReleaseItemPreviewGenerateForm";

export default class ReleaseCollectionBrowserBox extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        storeId: PropTypes.string,
        releaseCollectionModel: PropTypes.object,
        releaseItemsOfCollection: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }


    constructor(props, context) {
        super(props, context);
        this.state = {
            commerceProductModel: new CommerceProductModel(),
            processIndex: 0,
        }

        this.REFS = {
            allPrice: React.createRef()
        }
    }

    render() {
        /**
         * @type {ReleaseCollectionModel}
         */
        const releaseCollectionModel = this.props.releaseCollectionModel
        const releaseItemsOfCollection = this.props.releaseItemsOfCollection

        const filteredReleaseITems = releaseItemsOfCollection.items.slice(0, 5)

        return (
            <div className="flexContainerColumn">
                <div className="flexItem00">
                    name: {releaseCollectionModel.collectionName}<br/>
                    state: {releaseCollectionModel.collectionState}<br/>
                    created date: {DateFormatter.getFullDate(releaseCollectionModel.dateCreated.date.toString())}<br/>
                    size: {releaseItemsOfCollection.items.length}
                </div>
                <div>
                    <div style={{padding: 5, margin: 10, border: '1px solid #ccc'}}>
                        <div className={'ui label'}>
                            Update product info at once
                        </div>
                        <ReleaseItemUpdateForm language={this.props.language}
                                               accessTokenModel={this.props.accessTokenModel}
                                               releaseCollectionModel={releaseCollectionModel}
                                               releaseItemsOfCollection={releaseItemsOfCollection}
                                               delay={30}
                        />
                    </div>
                </div>
                <div>
                    <div style={{padding: 5, margin: 10, border: '1px solid #ccc'}}>
                        <div className={'ui label'}>
                            Generate preview at once
                        </div>
                        <ReleaseItemPreviewGenerateForm language={this.props.language}
                                                        accessTokenModel={this.props.accessTokenModel}
                                                        releaseCollectionModel={releaseCollectionModel}
                        />
                    </div>
                </div>
                <div className="flexItem00">
                    <ScrollableTable className="ui unstackable small table"
                                     header={<TableHeadCell columns={["Preview", "Item Information", ""]}  />}
                                     rows={filteredReleaseITems.map((releaseItemModelId)=> {
                                         return <CompactReleaseItemInCollectionBrowserBox language={this.props.language}
                                                                                          accessTokenModel={this.props.accessTokenModel}
                                                                                          releaseItemId={releaseItemModelId}
                                                                                          releaseCollectionModel={releaseCollectionModel}
                                                                                          storeId={this.props.storeId}
                                         />
                                     })}
                    />
                    {(releaseItemsOfCollection.items.length < 1 ? (
                        <div className="ui message info">
                            you do not have any release item.
                        </div>
                    ) : null)}
                </div>
            </div>
        )
    }
}