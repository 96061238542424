import * as React from "react";
import * as PropTypes from "prop-types";
import CommerceProductModel from "../../../../../model/entity/commerce/CommerceProductModel";
import CurrencyTypeDropdown from "../../../../../ui_component/CurrencyTypeDropdown";
import StockKeepingUnitTypeDropdown from "../../../../../ui_component/StockKeepingUnitTypeDropdown";
import * as Loops4RPCAPI from "../../../../../api/loops4/Loops4RPCAPI";

export default class ReleaseItemUpdateForm extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        releaseCollectionModel: PropTypes.object,
        releaseItemsOfCollection: PropTypes.object,
        accessTokenModel: PropTypes.object,
        delay: PropTypes.number
    }


    constructor(props, context) {
        super(props, context);
        this.state = {
            commerceProductModel: new CommerceProductModel(),
            processIndex: 0,
        }

        this.defaultDelay = 10
    }



    waitFor = async (miliseconds) => {
        return new Promise((resolve) => {
            setTimeout(resolve, miliseconds)
        })
    };

    updatePriceAmount = (e) => {
        const nextValue = e.target.value;

        this.setState((prevS, props) => {
            prevS.commerceProductModel.price.amount = nextValue
            return {
                ...prevS
            }
        })
    }
    updatePriceCurrency = (value) => {
        this.setState((prevS, props) => {
            prevS.commerceProductModel.price.currency = value;
            return {
                ...prevS
            }
        })
    }
    updateProductQuantity = (e) => {
        const nextValue = e.target.value;

        this.setState((prevS, props) => {
            prevS.commerceProductModel.stock.quantity = nextValue
            return {
                ...prevS
            }
        })
    }

    updateSKUType = (value) => {
        this.setState((prevS, props) => {
            prevS.commerceProductModel.stock.skuType = value;
            return {
                ...prevS
            }
        })
    }

    requestSetAllPrice = (e) => {
        e.preventDefault();

        const priceAmount = this.state.commerceProductModel.price.amount
        const priceCurrency = this.state.commerceProductModel.price.currency.value
        const stockType = this.state.commerceProductModel.stock.skuType.value
        const stockQuantity = this.state.commerceProductModel.stock.quantity

        const isSet = window.confirm(`Set all price to amount: ${priceAmount}, currency: ${priceCurrency}, stockType: ${stockType}, stockQuantity: ${stockQuantity}`)

        if(isSet)
            this.requestToAllItemsForProduct(this.state.commerceProductModel)
    }

    requestToAllItemsForProduct = async (commerceProductModel) => {
        const priceAmount = commerceProductModel.price.amount
        const priceCurrency = commerceProductModel.price.currency.value
        const stockType = commerceProductModel.stock.skuType.value
        const stockQuantity = commerceProductModel.stock.quantity

        const productBody = {
            stockQuantity: stockQuantity,
            stockSKUType: stockType,
            priceAmount: priceAmount,
            priceCurrency: priceCurrency,
        }


        const idList = this.props.releaseItemsOfCollection.items
        let index = 0

        this.setState((prevs, props) => {
            return {
                ...prevs,
                processIndex: index
            }
        })

        for(const releaseItemModelId of idList) {
            const curIndex = ++index

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    processIndex: curIndex
                }
            })

            await this.waitFor(this.props.delay || this.defaultDelay);

            const rpcResult = await Loops4RPCAPI.getReleaseItem({
                accessTokenModel: this.props.accessTokenModel,
                releaseItemId: releaseItemModelId
            })

            if(rpcResult.isError()){
                // Pass temporary
            } else {
                const releaseItemModel = rpcResult.model
                const productId = (releaseItemModel.product ? releaseItemModel.product.entityId : undefined)

                let updateResult
                if(productId === undefined || productId === null){
                    updateResult = await Loops4RPCAPI.createCommerceSaleProductReleaseItem({
                        accessTokenModel: this.props.accessTokenModel,
                        releaseItemId: releaseItemModel.entityId,
                        ...productBody
                    })
                } else {
                    updateResult = await Loops4RPCAPI.updateCommerceSaleProductReleaseItem({
                        accessTokenModel: this.props.accessTokenModel,
                        productId: productId,
                        ...productBody
                    })
                }

                if(updateResult.isError()){

                } else {

                }
            }
        }
    }

    render() {
        /**
         * @type {ReleaseCollectionModel}
         */
        const releaseCollectionModel = this.props.releaseCollectionModel

        return (
            <form className="ui form" onSubmit={this.requestSetAllPrice} >
                <div className="two fields">
                    <div className="field">
                        <div className="ui message">
                            <label style={{marginRight: 10}}>
                                Product Information
                            </label>
                            <div className="ui button green mini"
                                 style={{verticalAlign: 'middle', margin: 0}}
                                 onClick={this.requestSetAllPrice}
                            >
                                Update
                            </div>
                            <div>
                                <b>PROGRESS</b>
                            </div>
                            <div>
                                {this.state.processIndex} / {this.props.releaseItemsOfCollection.items.length}
                            </div>
                            <div>
                                Delay: {this.props.delay || this.defaultDelay} ms
                            </div>
                        </div>

                        <div style={{padding: 4}}></div>

                        <div className="field">
                            <label>
                                Price Amount
                            </label>
                            <div className="ui left icon input">
                                <input type="text"
                                       value={this.state.commerceProductModel.price.amount}
                                       onChange={this.updatePriceAmount}
                                />
                            </div>
                        </div>

                        <div style={{padding: 4}}></div>

                        <div className="field">
                            <label>
                                Price Currency
                            </label>
                            <div className="ui left icon input">
                                <CurrencyTypeDropdown language={this.props.language}
                                                      currencyType={this.state.commerceProductModel.price.currency}
                                                      updateAction={this.updatePriceCurrency}
                                />
                            </div>
                        </div>


                        <div style={{padding: 4}}></div>

                        <div className="field">
                            <label>
                                Price Quantity
                            </label>
                            <div className="ui left icon input">
                                <input type="text"
                                       value={this.state.commerceProductModel.stock.quantity}
                                       onChange={this.updateProductQuantity}
                                />
                            </div>
                        </div>

                        <div style={{padding: 4}}></div>

                        <div className="field">
                            <label>
                                Price Stock Keeping Unit
                            </label>
                            <div className="ui left icon input">
                                <StockKeepingUnitTypeDropdown language={this.props.language}
                                                              stockKeepingUnitType={this.state.commerceProductModel.stock.skuType}
                                                              updateAction={this.updateSKUType}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}