import MasterContainer from "./MasterContainer";
export class LanguageContainer extends MasterContainer{

    /**
     * @param {string} value
     * @param {string} name
     */
    constructor(value, name, nameWithEnglish, flagCode) {
        super();
        this.value = value;
        this.name = name;
        this.nameWithEnglish = nameWithEnglish;
        this.flagCode = flagCode;
    }

    static master = {
        english: new LanguageContainer(
            "eng",
            "English",
            'English',
            'us'
        ),
        japanese: new LanguageContainer(
            "jpn",
            "日本語",
            '日本語 - Japanese',
            'jp'
        ),
        korean: new LanguageContainer(
            "kor",
            "Korean",
            '한국어 - Korean',
            'kr'
        )
    }

    /**
     *
     * @param data
     * @return {LanguageContainer}
     */
    static deserialize(data){
        switch (data){
            case LanguageContainer.master.english.value: return LanguageContainer.master.english;
            case LanguageContainer.master.japanese.value: return LanguageContainer.master.japanese;
            case LanguageContainer.master.korean.value: return LanguageContainer.master.korean;
            default: return LanguageContainer.master.english;
        }
    }
}