import * as React from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse} from "../../../../../../api/loops4/model/commerce/order/put_order_commerce_product_release_item_from_commerce_cart_product/PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse";
import {RouteUtility} from "../../../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../../../RouteDictionary";
import PublishReleaseCollection from "../../../PublishReleaseCollection";
import {Link} from "react-router-dom";
import DateFormatter from "../../../../../../model/formatter/DateFormatter";

const mapStateToProps = (state, props) => {
    return {
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.subscriber,
        routeState: state.routeState,
    };
};

class PaymentResultSuccessView extends React.Component {

    static propTypes = {
        languageMaster: PropTypes.object,
        accessTokenModel: PropTypes.object,
        paymentRequestResult: PropTypes.objectOf(PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse),
        releaseCollectionId: PropTypes.string,
    }

    style = {
        mainContainer: {
            marginTop: '10%',
        },
        segmentContainer: {
            // minWidth: 300,
        },
        messageBox: {
            minWidth: 300,
        },
        messageDivider: {
            height: 10
        },
        description: {
            marginLeft: 5
        },
        backButton: {
            marginTop: 20,
        }
    }

    render() {
        /**
         * @type {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse}
         */
        const result = this.props.paymentRequestResult

        return (
            <div className="flexContainerRow flexItemAlignCenterStart" style={this.style.mainContainer}>
                <div className="flexItem00">
                    <div className="ui placeholder segment" style={this.style.segmentContainer}>
                        <div className="ui icon header">
                            <div>
                                <i className="exclamation circle icon"></i>
                            </div>
                            <div>
                                注文が完了されました。
                            </div>
                        </div>

                        <div className={'ui section divider'} />

                        <div style={this.style.messageBox}>
                            <div>
                                <span className={'ui label'}>注文ID</span>
                                <span style={this.style.description}>{result.entity_id}</span>
                            </div>

                            <div style={this.style.messageDivider} />

                            <div>
                                <span className={'ui label'}>注文日</span>
                                <span style={this.style.description}>
                                    {DateFormatter.getDateFormatForLocaleByLanguageMaster(this.props.languageMaster, result.date_created.toString())}
                                </span>
                            </div>
                        </div>

                        <div className={'ui section divider'} />

                        <div style={this.style.messageBox}>
                            写真のダウンロードは注文詳細ページから出来ます。
                        </div>

                        <Link className={'flexContainerRow'}
                              to={RouteUtility.getPathname(RouteDictionary.orderInPublishReleaseCollection, {
                                  [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionId,
                                  [RoutePathParam.orderId]: result.entity_id,
                              }, {
                                  [RouteQueryParam.view_mode]: PublishReleaseCollection.viewMode.order_detail,
                                  [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
                                  [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
                              })}>
                            <div className="ui primary button" style={this.style.backButton}>注文詳細ページへ移動</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(PaymentResultSuccessView);