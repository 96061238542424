

// Package Dir: library/item
// FileName: GetLibraryItemRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.library.item.GetLibraryItemRPCRequest
// Super types: java.lang.Object

export class GetLibraryItemRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.entity_id = null;

    }
}