

// Package Dir: commerce/order/get_order_history
// FileName: GetOrderHistoryRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.get_order_history.GetOrderHistoryRPCResponse
// Super types: java.lang.Object

export class GetOrderHistoryRPCResponse {
    constructor() {

        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.order.get_order_history.compact.CompactOrderHistoryResponse>
         * @type {array<CompactOrderHistoryResponse>}
         */
        this.orders = [];

    }
}