import * as React from "react";
import * as PropTypes from "prop-types";
import {RouteUtility} from "../../../../RouteUtility";
import {RouteDictionary, RoutePathParam} from "../../../../RouteDictionary";
import {pushHistory} from "../../../../RouteHistory";
import * as Loops4BinaryAPI from "../../../../../api/loops4/Loops4BinaryAPI";
import StyleConsts from "../../../../../constant/StyleConstant";
import {connect} from "react-redux";
import DateFormatter from "../../../../../model/formatter/DateFormatter";
import {ImageSizeMasterDefinition} from "../../../../../model/formatter/ImageSizeFormatter";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        languageMaster: state.languageMaster,
    };
};

class PictureItemInCollectionViewerComponent extends React.Component {

    static propTypes = {
        id: PropTypes.string,
        key: PropTypes.string,
        accessTokenModel: PropTypes.object,
        lastSelectFlag: PropTypes.bool,
        selectFlag: PropTypes.bool,
        selectAction: PropTypes.func, //selectAction:(picturePK:PrimaryKeyModel, selectFlag:boolean, keyEvent) => any;
        itemModel: PropTypes.object,
        parentBoxId: PropTypes.string,
        parentCollectionId: PropTypes.string,
    }


    REFS_SET = {
        miniPictureController: "miniPictureController"
    };

    doubleClick() {
        const routePath = RouteUtility.getPathname(RouteDictionary.item, {
            [RoutePathParam.boxId]: this.props.parentBoxId,
            [RoutePathParam.collectionId]: this.props.parentCollectionId,
            [RoutePathParam.itemId]: this.props.itemModel.entity_id,
        })

        pushHistory(routePath)
    }

    click(e) {
        this.props.selectAction(this.props.itemModel, !this.props.selectFlag, e)
    }

    clickChecker(e) {
        // e.preventDefault();
        // e.stopPropagation();
        e.ctrlKey = true;
        this.click(e);
    }

    // drag(itemModelPK:ItemModel, e){
    drag(e){
        //If it is already selected element, don't need other action
        if(this.props.selectFlag === false){
            this.click(e)
        }

        // e.dataTransfer.setData("model", JSON.stringify(itemModelPK))
    }

    /**
     * @type {CSSProperties}
     */
    ICON_CONTAINER_STYLE = {
        backgroundColor: "rgb(104, 104, 104)",
        color: "#CCC",
        padding: 4,
        borderRadius: 5,
        fontSize: '0.7em',
        marginTop: -4
    }

    ICON = {
        RESOLUTION: (
            <span style={this.ICON_CONTAINER_STYLE}>RESOLUTION</span>
        ),
        ISO: (
            <span style={this.ICON_CONTAINER_STYLE}>ISO</span>
        ),
        TAKEN: (
            <span style={this.ICON_CONTAINER_STYLE}>TAKEN</span>
        )
    }

    render() {

        let selectedBoxStyle_Outer = {
            border:'1px solid #484848',
            backgroundColor: 'rgba(218, 218, 218, 0.27)',
            color:null,
            boxShadow:'transparent',
            padding: 10,
            display:'inline-block'
        };
        let selectedBoxStyle_Outer_class = "";
        let selectedBoxStyle_Inner = {
            backgroundColor:null, color:null, padding:5,
            textOverflow: 'ellipsis',
            overflow:'hidden',
            width:'100%'
        };

        if(this.props.selectFlag){
            selectedBoxStyle_Outer.border = "4px solid rgb(160, 212, 193)";
            selectedBoxStyle_Outer.padding = 7;
        }

        if(this.props.lastSelectFlag){
            selectedBoxStyle_Outer.border = "4px solid rgb(0, 178, 112)";
            selectedBoxStyle_Outer.padding = 7;
        }

        let pickFlagFlagIcon = null;
        // if(this.props.itemModel.globalAttribute.pickFlag === ItemModel.masters.pickFlag.pick){
        //     pickFlagFlagIcon = <i className="icon flag small checkered" style={{padding:0, margin:0}} />
        // }else if(this.props.itemModel.globalAttribute.pickFlag === ItemModel.masters.pickFlag.reject){
        //     pickFlagFlagIcon = <i className="icon flag small" style={{padding:0, margin:0}} />
        // }else if(this.props.itemModel.globalAttribute.pickFlag === ItemModel.masters.pickFlag.none){
        //     pickFlagFlagIcon = <i className="icon flag small outline" style={{padding:0, margin:0}} />
        // }

        let checkedFlag = false;

        if(this.props.selectFlag){
            // selectedBoxStyle_Inner.backgroundColor = "#4285f4";
            // selectedBoxStyle_Inner.color = "white";
            selectedBoxStyle_Outer_class = "custom imageCardShadow";
            checkedFlag = true;
        }

        const imageSrc = Loops4BinaryAPI.getLibraryItemPreviewURL({
            accessTokenModel: this.props.accessTokenModel,
            libraryItemId: this.props.itemModel.entity_id,
            longTermPixel: ImageSizeMasterDefinition.SIZE_300.longTermPixel,
        })

        /**
         *
         * @type {GetLibraryItemRPCResponse}
         */
        const libraryItem = this.props.itemModel

        const resolutionIcon = (libraryItem.file_width ? (<div>{this.ICON.RESOLUTION} {libraryItem.file_width} x {libraryItem.file_height}</div>) : null )
        const isoIcon = ( libraryItem.meta_exif_iso ? <div>{this.ICON.ISO} {libraryItem.meta_exif_iso}</div> : null)
        const takenIcon = ( libraryItem.meta_exif_create_date ? <div>{this.ICON.TAKEN} {DateFormatter.getDateFormatForLocaleByLanguageMaster(this.props.languageMaster, libraryItem.meta_exif_create_date)}</div> : null)

        return (
            <div className={selectedBoxStyle_Outer_class}
                 id={this.props.id}
                 key={this.props.key}
                 style={selectedBoxStyle_Outer}
                 onDragStart={this.drag.bind(this)}
                 draggable={true}
                 onDoubleClick={this.doubleClick.bind(this)}
                 onClick={this.click.bind(this)}
            >
                <div style={{marginBottom: 8}}>
                    <div className="flexContainerRow">
                        <div className="flexItem00">
                            <div className="squaredThree" onClick={this.clickChecker.bind(this)} >
                                <input type="checkbox" defaultChecked={checkedFlag} />
                                <label />
                            </div>
                        </div>
                        <div className="flexItem11 flexItemAlignEndCenter">
                            <div style={Object.assign({},{maxWidth:142,fontSize:12, display:'inline-flex'}, StyleConsts.preventFieldOverflow)}>
                                {this.props.itemModel.item_name}
                            </div>
                        </div>
                    </div>


                </div>

                <div className="ui fluid image">
                    <div style={{border:'3px solid #989898'}}>
                        <div className=""
                             style={{width:185, height:140,
                                 backgroundSize:'contain', backgroundRepeat:'no-repeat',
                                 backgroundColor:'#868686',backgroundPosition:'center',
                                 backgroundImage:`URL(${imageSrc})`}}>
                        </div>
                    </div>
                </div>
                <div style={selectedBoxStyle_Inner}>
                    <div className="flexContainerRow">
                        <div className="flexItem11 flexItemAlignStartCenter">
                            {pickFlagFlagIcon}
                        </div>
                        <div className="flexItem11 flexItemAlignEndCenter">
                            {/*{CollectionBrowserAction.convertToStar(this.props.itemModel.globalAttribute.rating, this.props.selectFlag)}*/}
                        </div>
                    </div>
                    <div className="flexContainerRow flexItemAlignStartCenter">
                        <div className="flexItem00" style={{fontSize: '0.85em'}}>
                            {resolutionIcon}
                            {isoIcon}
                            {takenIcon}
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}

export default connect(mapStateToProps)(PictureItemInCollectionViewerComponent);