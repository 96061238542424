import * as React from "react";
import * as PropTypes from "prop-types";
import * as Loops4RPCAPI from "../../../../../api/loops4/Loops4RPCAPI";
import ReleaseItemModel from "../../../../../model/entity/release/ReleaseItemModel";
import DimLoaderComponent from "../../../../../ui_template/DimLoaderComponent";
import DateFormatter from "../../../../../model/formatter/DateFormatter";
import * as Loops4BinaryAPI from "../../../../../api/loops4/Loops4BinaryAPI";
import LanguagePack from "../../../../../language/LanguagePack";
import {Link} from "react-router-dom";
import {RouteUtility} from "../../../../RouteUtility";
import {RouteDictionary, RoutePathParam} from "../../../../RouteDictionary";
import {TableBodyCell} from "../../../../../ui_template/table/cell/TableBodyCell";

export class CompactReleaseItemInCollectionBrowserBox extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        storeId: PropTypes.string,
        releaseItemId: PropTypes.string,
        accessTokenModel: PropTypes.object,
        releaseCollectionModel: PropTypes.object,
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context, REFS_SET) {
        super(props, context);
        this.state = {
            releaseItemModel: new ReleaseItemModel()
        }
    }

    componentDidMount() {
        this.updateByProps(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateByProps(nextProps)
    }

    updateByProps(nextProps) {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const rpcResult = await Loops4RPCAPI.getReleaseItem({
                accessTokenModel: nextProps.accessTokenModel,
                releaseItemId: nextProps.releaseItemId
            })

            let contentBox = null
            let foundReleaseItemModel = null
            if (rpcResult.isError()) {
                contentBox = (
                    <div>
                        Request got failed:: {rpcResult.getErrorMessage()}
                    </div>
                )
            } else {
                foundReleaseItemModel = rpcResult.model
            }

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: false,
                    contentBox: contentBox,
                    releaseItemModel: foundReleaseItemModel || prevs.releaseItemModel
                }
            })

        })
    }

    getThumbnail(releaseItemModel){
        if(releaseItemModel.entityId){
            const imageSrc = Loops4BinaryAPI.getReleaseItemPreviewURL({
                accessTokenModel: this.props.accessTokenModel,
                releaseItemId: releaseItemModel.entityId,
                longTermPixel: 100
            })
            return (
                <img src={imageSrc} style={{ maxWidth: 300, maxHeight: 100}}/>
            )
        } else {
            return (
                <div>
                    The item is not loaded yet
                </div>
            )
        }
    }


    genHeader(title) {
        return (
            <div>
                <span style={{marginLeft: 5}}>
                    {title}
                </span>
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.bright}/>
            )
        } else if(this.state.contentBox) {
            return this.state.contentBox
        } else {
            /**
             * @type {ReleaseItemModel}
             */
            const releaseItemModel = this.state.releaseItemModel

            return (
                <TableBodyCell columns={[
                    this.getThumbnail(releaseItemModel),
                    <div>
                        <div><i className="ui icon cubes"/>{releaseItemModel.entityId}</div>
                        <div>name: {releaseItemModel.itemName}</div>
                        <div>created at: {DateFormatter.getFullDate(releaseItemModel.dateCreated.date.toString())}</div>
                        {(releaseItemModel.product ? (
                            <div>
                                <div>price: {releaseItemModel.product.price.amount} {LanguagePack.extract(this.props.language, releaseItemModel.product.price.currency)}</div>
                                <div>quantity: {releaseItemModel.product.stock.quantity}</div>
                                <div>skuType: {releaseItemModel.product.stock.skuType.name}</div>
                            </div>
                        ) : <div>Product is not defined</div>)}
                    </div>,
                    <div>
                        <Link to={RouteUtility.getPathname(RouteDictionary.releaseItem, {
                            [RoutePathParam.storeId]: this.props.storeId,
                            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId,
                            [RoutePathParam.releaseItemId]: releaseItemModel.entityId,
                        })}>
                            <button className="ui button small basic compact">Detail</button>
                        </Link>
                    </div>,
                ]}/>
            )
        }
    }

}