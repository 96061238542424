import * as React from "react";
import * as PropTypes from "prop-types";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import {REFUND_COMMERCE_ORDER__V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {connect} from "react-redux";
import DimLoaderComponent from "../../../../../ui_template/DimLoaderComponent";
import {CurrencyMasterDefinition} from "../../../../../model/formatter/MoneyFormatter";
import {RefundCommerceOrderRPCRequest} from "../../../../../api/loops4/model/commerce/order/refund_commerce_order/RefundCommerceOrderRPCRequest";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        ...props,
        language: state.language,
        accessTokenModel: state.auth.user,

    };
};

class OrderRefundForm extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        accessTokenModel: PropTypes.object,
        orderId: PropTypes.string,
    }


    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false
        }
        this.REFS_SET = {
            refund_price_amount: React.createRef()
        }
    }

    requestRefund = async (e) => {
        const refundPrice = parseFloat(this.REFS_SET.refund_price_amount.current.value || 0)

        if(window.confirm(`Do you really want to refund ${refundPrice}?`)){
            this.setState((prevs, props) => {
                return {
                    ...props,
                    isLoading: true
                }
            }, async () => {

                const param = new RefundCommerceOrderRPCRequest()
                param.commerce_order_entity_id = this.props.orderId
                param.price_currency = CurrencyMasterDefinition.JPY.masterCode
                param.price_amount = refundPrice

                const rpcResult = await REFUND_COMMERCE_ORDER__V1_0({
                    headers: getAuthorizationHeader(this.props.accessTokenModel),
                    paramBody: param
                })

                if(rpcResult.isError()){
                    alert("Failed to request")
                } else {
                    alert("Succeed to request")
                }

                this.setState((prevs, props) => {
                    return {
                        ...props,
                        isLoading: false
                    }
                })
            })
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {
            return (
                <form className="ui form" onSubmit={this.requestSetAllPrice}>
                    <div className="two fields">
                        <div className="field">
                            <div className="ui message">
                                <label style={{marginRight: 10}}>
                                    Refund
                                </label>
                                <div className="ui input">
                                    <input type="text" placeholder="0" ref={this.REFS_SET.refund_price_amount}/>
                                </div>
                                <div className="ui button green mini"
                                     style={{verticalAlign: 'middle', margin: 0}}
                                     onClick={this.requestRefund}
                                >
                                    Request refund
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )
        }
    }
}

export default connect(mapStateToProps)(OrderRefundForm);
