import * as React from "react";
import * as PropTypes from "prop-types";
import {PaymentMethodContainer} from '../../../../../../model/container/PaymentMethodContainer'
import {PaymentCardContainer} from '../../../../../../model/container/PaymentCardContainer'
import {CountryContainer} from '../../../../../../model/container/CountryContainer'
import {ValidateSet, Validator} from "../../../../../../model/validator/Validator";
import PublishPaymentRequestPack from "../../../../../../model/validator/validation/PublishPaymentRequestPack";
import $ from '../../../../../../library/JQuery';
import LanguagePack from "../../../../../../language/LanguagePack";
import OrderRequestLangPack from "../../../../../../language/publish/order/OrderRequestLangPack";
import DateFormatter from "../../../../../../model/formatter/DateFormatter";
import OrderRequestVaidationGuideLangPack
    from "../../../../../../language/publish/order/OrderRequestVaidationGuideLangPack";
import {RouteUtility} from "../../../../../RouteUtility";
import {pushHistory} from "../../../../../RouteHistory";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../../../RouteDictionary";
import ServiceInfoConstant from "../../../../../../constant/ServiceInfoConstant";
import IntroActivityLangPack from "../../../../../../language/publish/subscriber/IntroActivityLangPack";
import DomIdConstant from "../../../../../../constant/DomIdConstant";
import {connect} from "react-redux";
import PublishReleaseCollection from "../../../PublishReleaseCollection";
import MiniLoaderComponent from "../../../../../../ui_template/MiniLoaderComponent";
import {PortalPathDictionary} from "../../../../../../share/dictionary/portal_path.dictionary";

const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        languageMaster: state.languageMaster,
        routeState: state.routeState,
        accessTokenModel: state.auth.subscriber,
    };
};

class PaymentRequestForm extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        languageModel: PropTypes.object,
        accessTokenModel: PropTypes.object,

        updateBill: PropTypes.func,
        billModel: PropTypes.object,
        releaseCollectionModel: PropTypes.object,
        orderingProducts: PropTypes.arrayOf(PropTypes.object),
        subscriberModel: PropTypes.object,
        doPay: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context);

        this.REFS = {
            uiContainer:{
                countryDropdown: React.createRef()
            },
            order:{
                items: React.createRef()
            },
            shipping:{
                firstName: React.createRef(),
                lastName: React.createRef(),
                address: React.createRef(),
                city: React.createRef(),
                postalCode: React.createRef(),
                country: React.createRef()
            },
            payment:{
                methods: {
                    card: React.createRef(),
                    transfer: React.createRef()
                },
                card: {
                    number: React.createRef(),
                    cvc: React.createRef(),
                    expiration:{
                        month: React.createRef(),
                        year: React.createRef()
                    },
                    holder: React.createRef()
                }
            },
            agreeCheckbox: React.createRef()
        }

        this.state = {
            isLoading: false,
            checkNoticeBox: {
                order: {
                    items: false
                },
                payment:{
                    card: {
                        number:false,
                        cvc:false,
                        expiration:{
                            month:false,
                            year:false
                        },
                        holder:false
                    }
                },
                shipping: {
                    firstName: false,
                    lastName: false,
                    address: false,
                    city: false,
                    postalCode: false,
                    country: false,
                },
                agreeCheckbox:false,
            }
        };
    }

    componentDidMount(){
        $(this.REFS.uiContainer.countryDropdown.current).dropdown({
            onChange: (value, text, $choice)=>{
                $(this.REFS.shipping.country.current).val(value)
                this.routeValidatingInput(this.REFS.shipping.country, false, {})
            }
            // action: (text, value)=>{
            //     console.log("text",text)
            //     console.log("value",value)
            // }
        });

        $(this.REFS.agreeCheckbox.current).parent().checkbox({
            onChange: ()=> {
                this.routeValidatingInput(this.REFS.agreeCheckbox, false, {})
            }
        })

        this.routeValidatingInput(this.REFS.order.items, false, {});
    }

    componentWillUnmount() {
    }

    verifyCardType(cardNumber){

        let cardRegs = [
            PaymentCardContainer.master.visa,
            PaymentCardContainer.master.mastercard,
            PaymentCardContainer.master.americanExpress,
            PaymentCardContainer.master.dinersClub,
            PaymentCardContainer.master.discover,
            PaymentCardContainer.master.jcb
        ];

        var targetCard = PaymentCardContainer.master.unknown.value;
        cardRegs.forEach( card => {
            if(card.regex.test(cardNumber)){
                targetCard = card.value
            }
        });

        return targetCard
    }

    /* =========================================================================================================================*/
    /* =================================================== CONTROLLING =========================================================*/
    /* =========================================================================================================================*/

    onSubmit = (e) => {
        // Stop submit
        e.preventDefault();
        e.stopPropagation();
    }

    doPay = (e) => {
        // console.log('doPay arguments', e)
        // Stop submit
        // e.preventDefault();
        // e.stopPropagation();

        const payAction = () => {
            let validationResult = [
                this.routeValidatingInput(this.REFS.order.items, false, {}),
                this.routeValidatingInput(this.REFS.shipping.firstName, false, {}),
                this.routeValidatingInput(this.REFS.shipping.lastName, false, {}),
                this.routeValidatingInput(this.REFS.shipping.address, false, {}),
                this.routeValidatingInput(this.REFS.shipping.city, false, {}),
                this.routeValidatingInput(this.REFS.shipping.postalCode, false, {}),
                this.routeValidatingInput(this.REFS.shipping.country, false, {}),
            ];

            if(this.props.billModel.paymentMethod === PaymentMethodContainer.master.card){
                validationResult.concat([
                    this.routeValidatingInput(this.REFS.payment.card.number, false, {}),
                    this.routeValidatingInput(this.REFS.payment.card.cvc, false, {}),
                    this.routeValidatingInput(this.REFS.payment.card.expiration.month, false, {}),
                    this.routeValidatingInput(this.REFS.payment.card.expiration.year, false, {}),
                    this.routeValidatingInput(this.REFS.payment.card.holder, false, {}),
                    this.routeValidatingInput(this.REFS.agreeCheckbox, false, {})
                ])
            }

            const invalidationSize = validationResult.filter( result => result === false).length
            console.log({invalidationSize, validationResult})

            if(invalidationSize > 0){
                // Do nothing
                this.setState((preState, props) => {
                    return {
                        ...preState,
                        isLoading: false
                    }
                })
            } else {
                this.props.doPay(this.props.billModel, this.props.orderingProducts, () => {
                    this.setState((preState, props) => {
                        return {
                            ...preState,
                            isLoading: false
                        }
                    })
                })
            }
        }


        this.setState((preState, props) => {
            return {
                ...preState,
                isLoading: true
            }
        }, payAction)

    }

    /**
     * @param {string} formTarget
     * @param {boolean} emptyAllow
     * @param {event} event
     * @returns {boolean}
     */
    routeValidatingInput(ref, emptyAllow = false, event){
        let foundValue = $(ref.current).val()

        let validationResult = false;

        let validator = new Validator();

        let updatedBill = Object.assign({}, this.props.billModel);

        switch (ref) {
            case this.REFS.shipping.city: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.shipping.city), emptyAllow)
                if (validationResult) {
                    updatedBill.buyerAddressCity = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.shipping.city = !validationResult;
                    return preState
                })
                break;
            }
            case this.REFS.shipping.country: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.shipping.country), emptyAllow)
                if (validationResult) {
                    updatedBill.buyerAddressCountryCode = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.shipping.country = !validationResult;
                    return preState
                });
                break;
            }
            case this.REFS.shipping.address: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.shipping.address), emptyAllow)
                if (validationResult) {
                    updatedBill.buyerAddressLine = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.shipping.address = !validationResult;
                    return preState
                });
                break;
            }
            case this.REFS.shipping.postalCode: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.shipping.postalCode), emptyAllow)
                if (validationResult) {
                    updatedBill.buyerAddressPostalCode = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.shipping.postalCode = !validationResult;
                    return preState
                });
                break;
            }
            case this.REFS.shipping.firstName: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.shipping.firstName), emptyAllow)
                if (validationResult) {
                    updatedBill.buyerFirstName = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.shipping.firstName = !validationResult;
                    return preState
                });
                break;
            }
            case this.REFS.shipping.lastName: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.shipping.lastName), emptyAllow)
                if (validationResult) {
                    updatedBill.buyerLastName = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.shipping.lastName = !validationResult;
                    return preState
                });
                break;
            }
            case this.REFS.payment.card.number: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.payment.card.number), emptyAllow)
                if (validationResult) {
                    updatedBill.cardNumber = foundValue;
                    let cartType = this.verifyCardType(foundValue)
                    updatedBill.cardType = PaymentCardContainer.deserialize(cartType);
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.payment.card.number = !validationResult;
                    return preState
                })
                break;
            }
            case this.REFS.payment.card.holder: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.payment.card.holder), emptyAllow)
                if (validationResult) {
                    updatedBill.cardHolder = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.payment.card.holder = !validationResult;
                    return preState
                })
                break;
            }
            case this.REFS.payment.card.expiration.month: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.payment.card.expiration.month), emptyAllow)
                if (validationResult) {
                    updatedBill.cardValidationExpiringMonth = parseInt(foundValue);
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.payment.card.expiration.month = !validationResult;
                    return preState
                })
                break;
            }
            case this.REFS.payment.card.expiration.year: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.payment.card.expiration.year), emptyAllow)
                if (validationResult) {
                    updatedBill.cardValidationExpiringYear = parseInt(foundValue);
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.payment.card.expiration.year = !validationResult;
                    return preState
                })
                break;
            }
            case this.REFS.payment.card.cvc: {
                validationResult = validator.validate(new ValidateSet(foundValue, PublishPaymentRequestPack.payment.card.cvc), emptyAllow)
                if (validationResult) {
                    updatedBill.cardValidationCode = foundValue;
                } else {
                    // Error callback
                }
                this.setState((preState, props) => {
                    preState.checkNoticeBox.payment.card.cvc = !validationResult;
                    return preState
                })
                break;
            }
            case this.REFS.agreeCheckbox: {
                validationResult = this.REFS.agreeCheckbox.current.checked;
                this.setState((preState, props) => {
                    preState.checkNoticeBox.agreeCheckbox = !validationResult;
                    return preState
                })
                break;
            }
            case this.REFS.order.items: {
                validationResult = this.props.orderingProducts.length > 0;
                this.setState((preState, props) => {
                    preState.checkNoticeBox.order.items = !validationResult;
                    return preState
                })
                break;
            }
            default: {
                const message = "Not found REF"
                console.error(message, ref)
                throw Error(message)
            }
        }



        this.props.updateBill(updatedBill);


        return validationResult;
    }

    /**
     * @param {PaymentMethodContainer} paymentMethod
     */
    changePaymentMethod(paymentMethod){
        //TODO Integrate with validation

        let updatedBill = Object.assign({}, this.props.billModel);
        updatedBill.paymentMethod = paymentMethod;

        this.props.updateBill(updatedBill);
    }

    /**
     *
     * @param {string} cardType
     * @returns {*}
     */
    genCardTypeIcon(cardType){
        let iconName = null
        switch (cardType) {
            case PaymentCardContainer.master.visa.value: iconName='visa'; break;
            case PaymentCardContainer.master.mastercard.value: iconName='mastercard'; break;
            case PaymentCardContainer.master.americanExpress.value: iconName='amex'; break;
            case PaymentCardContainer.master.dinersClub.value: iconName='diners club'; break;
            case PaymentCardContainer.master.discover.value: iconName='discover'; break;
            case PaymentCardContainer.master.jcb.value: iconName='japan credit burea'; break;
            default: iconName='alternative';
        }

        return (<i className={`large credit card ${iconName} icon big`} />)
    }

    /**
     *
     * @param {string} description
     * @returns {*}
     */
    genDescription(description) {
        return (
            <span style={{color:'grey', fontWeight:'normal', marginLeft:10}}>
                {description}
            </span>
        )
    }

    /**
     * @param {CountryContainer} country
     * @returns {*}
     */
    genAvailableCardTypes(country){
        switch (country){
            case CountryContainer.master.japan:
            case CountryContainer.master.southKorea:{
                return (
                    <div>
                        <label className="ui label">Visa</label>
                        <label className="ui label">MasterCard</label>
                        <label className="ui label">American Express</label>
                    </div>
                )
            }
            case CountryContainer.master.unitedStates:{
                return (
                    <div>
                        <label className="ui label">Visa</label>
                        <label className="ui label">MasterCard</label>
                        <label className="ui label">American Express</label>
                        <label className="ui label">JCB</label>
                        <label className="ui label">Discover</label>
                        <label className="ui label">Diners Club</label>
                    </div>
                )
            }
        }

    }

    goToShoppingCart = () => {
        const url = RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
            [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionModel.entityId,
        }, {
            [RouteQueryParam.view_mode]: PublishReleaseCollection.viewMode.selection,
            [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
            [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
        })

        pushHistory(url)
    }

    generateEmptyShoppingCartMessage = (langType) => {
        return (
            <div className="ui negative message compact">
                <div style={{marginBottom: 20}}>
                    {LanguagePack.extract(langType, OrderRequestLangPack.order.items.guideForMoreConsidering)}
                </div>
                <button className="ui button green" onClick={this.goToShoppingCart}>
                    {LanguagePack.extract(langType, OrderRequestLangPack.order.items.button)}
                </button>

            </div>
        )
    }

    /**
     *
     * @param {string} title
     * @param {string} description
     * @returns {*}
     */
    genTitleAndDescriptionForTR(title, description){
        return (
            <tr>
                <td style={{fontWeight: 1000}}>
                    {title}
                </td>
                <td style={{paddingLeft: 10}}>
                    {description}
                </td>
            </tr>
        )
    }

    /* =========================================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/
    /* =========================================================================================================================*/


    render() {
        let langType = this.props.language;
        let billModel = this.props.billModel;

        /**
         * @type {Array<number>}
         */
        let cardMonths = [];
        for (let month = 1; month <= 12; month++){
            cardMonths.push(month)
        }
        /**
         * @type {Array<number>}
         */
        let cardYearFor20Year = DateFormatter.getNextYears(20);

        /* ==================================================== RETURN RENDERING ==========================================================*/

        return (
                <form className="ui form" onSubmit={this.onSubmit}>
                    <div>
                        {this.state.checkNoticeBox.order.items ? (
                            <div>
                                {this.generateEmptyShoppingCartMessage(langType)}
                                <div className="ui section divider"></div>
                            </div>
                        ) : null}
                    </div>

                    <div className="ui grid">
                        <div className="four wide column">
                            <h3>
                                {LanguagePack.extract(langType, OrderRequestLangPack.shipping.title)}
                            </h3>
                            <p>{LanguagePack.extract(langType, OrderRequestLangPack.shipping.guide)}</p>
                        </div>
                        <div className="twelve wide column">
                            <div className="field">
                                <label>
                                    {LanguagePack.extract(langType, OrderRequestLangPack.shipping.email.title)}
                                    {this.genDescription(LanguagePack.extract(langType, OrderRequestLangPack.shipping.email.guide))}
                                </label>
                                <div className="field">
                                    <input type="text"
                                           id={DomIdConstant.DomIdPaymentRequestForm.email}
                                           value={this.props.subscriberModel.email}
                                           placeholder="Email"
                                           disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label>{LanguagePack.extract(langType, OrderRequestLangPack.shipping.name.title)}</label>
                                <div className="two fields">
                                    <div className="field">
                                        {this.genDescription(LanguagePack.extract(langType, OrderRequestLangPack.shipping.name.description.firstName))}
                                        {( this.state.checkNoticeBox.shipping.firstName ?
                                            <div className="ui negative message">
                                                {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.shipping.firstName)}
                                            </div>
                                        : null )}
                                        <input type="text"
                                               id={DomIdConstant.DomIdPaymentRequestForm.firstName}
                                               ref={this.REFS.shipping.firstName}
                                               value={billModel.buyerFirstName}
                                               onChange={this.routeValidatingInput.bind(this, this.REFS.shipping.firstName, true)}
                                        />
                                    </div>
                                    <div className="field">
                                        {this.genDescription(LanguagePack.extract(langType, OrderRequestLangPack.shipping.name.description.lastName))}
                                        <input type="text"
                                               id={DomIdConstant.DomIdPaymentRequestForm.lastName}
                                               ref={this.REFS.shipping.lastName}
                                               value={billModel.buyerLastName}
                                               onChange={this.routeValidatingInput.bind(this, this.REFS.shipping.lastName, true)}
                                        />
                                        {( this.state.checkNoticeBox.shipping.lastName ?
                                            <div className="ui negative message">
                                                {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.shipping.lastName)}
                                            </div>
                                            : null )}
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label>{LanguagePack.extract(langType, OrderRequestLangPack.shipping.address.title)}</label>
                                {this.genDescription(LanguagePack.extract(langType, OrderRequestLangPack.shipping.address.description))}
                                <input type="text"
                                       id={DomIdConstant.DomIdPaymentRequestForm.billingStreetAddress}
                                       ref={this.REFS.shipping.address}
                                       value={billModel.buyerAddressLine}
                                       onChange={this.routeValidatingInput.bind(this, this.REFS.shipping.address, true)}
                                />
                                {( this.state.checkNoticeBox.shipping.address ?
                                    <div className="ui negative message">
                                        {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.shipping.address)}
                                    </div>
                                    : null )}
                            </div>
                            <div className="field">
                                <label>{LanguagePack.extract(langType, OrderRequestLangPack.shipping.city.title)}</label>
                                {this.genDescription(LanguagePack.extract(langType, OrderRequestLangPack.shipping.city.description))}
                                <input type="text"
                                       id={DomIdConstant.DomIdPaymentRequestForm.billingCity}
                                       ref={this.REFS.shipping.city}
                                       value={billModel.buyerAddressCity}
                                       onChange={this.routeValidatingInput.bind(this, this.REFS.shipping.city, true)}
                                />
                                {( this.state.checkNoticeBox.shipping.city ?
                                    <div className="ui negative message">
                                        {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.shipping.city)}
                                    </div>
                                    : null )}
                            </div>
                            <div className="field">
                                <label>{LanguagePack.extract(langType, OrderRequestLangPack.shipping.postalCode.title)}</label>
                                {this.genDescription(LanguagePack.extract(langType, OrderRequestLangPack.shipping.postalCode.description))}
                                <input type="text"
                                       id={DomIdConstant.DomIdPaymentRequestForm.billingPostalCode}
                                       ref={this.REFS.shipping.postalCode}
                                       value={billModel.buyerAddressPostalCode}
                                       onChange={this.routeValidatingInput.bind(this, this.REFS.shipping.postalCode, true)}
                                />
                                {( this.state.checkNoticeBox.shipping.postalCode ?
                                    <div className="ui negative message">
                                        {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.shipping.postalCode)}
                                    </div>
                                    : null )}
                            </div>
                            <div className="field">
                                <label>{LanguagePack.extract(langType, OrderRequestLangPack.shipping.country)}</label>
                                <div id={DomIdConstant.DomIdPaymentRequestForm.billingCountryForm} className="ui fluid search selection dropdown" ref={this.REFS.uiContainer.countryDropdown}>
                                    <input type="hidden"
                                           ref={this.REFS.shipping.country}
                                           value={billModel.buyerAddressCountryCode}
                                           onChange={this.routeValidatingInput.bind(this, this.REFS.shipping.country, true)}
                                    />
                                    <i className="dropdown icon"></i>
                                    <div className="default text">
                                        {LanguagePack.extract(langType, OrderRequestLangPack.shipping.countryGuide)}
                                    </div>
                                    <div className="menu">
                                        <div id={DomIdConstant.DomIdPaymentRequestForm.countryForJapan}
                                            className="item" data-value={CountryContainer.master.japan.value}><i className="jp flag"></i>
                                            {LanguagePack.extract(langType, OrderRequestLangPack.shipping.countries.japan)}
                                        </div>
                                        <div className="item" data-value={CountryContainer.master.southKorea.value}><i className="kr flag"></i>
                                            {LanguagePack.extract(langType, OrderRequestLangPack.shipping.countries.southKorea)}
                                        </div>`
                                        <div className="item" data-value={CountryContainer.master.unitedStates.value}><i className="us flag"></i>
                                            {LanguagePack.extract(langType, OrderRequestLangPack.shipping.countries.unitedStates)}
                                        </div>
                                    </div>
                                </div>
                                {( this.state.checkNoticeBox.shipping.country ?
                                    <div className="ui negative message">
                                        {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.shipping.country)}
                                    </div>
                                    : null )}
                            </div>
                        </div>
                    </div>


                    <div className="ui section divider"></div>

                    <div>
                        <div className="ui grid">
                            <div className="four wide column">
                                <h3>{LanguagePack.extract(langType, OrderRequestLangPack.payment.title)}</h3>
                            </div>
                            <div className="twelve wide column">
                                <div style={{marginBottom: 20}}>
                                    <div>
                                        {LanguagePack.extract(langType, OrderRequestLangPack.payment.paymentMethodDescription)}
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div onClick={this.changePaymentMethod.bind(this, PaymentMethodContainer.master.card)}>
                                            <input type="radio"
                                                   id={DomIdConstant.DomIdPaymentRequestForm.paymentMethodForCreditCard}
                                                   ref={this.REFS.payment.methods.card}
                                                   defaultChecked={(this.props.billModel.paymentMethod === PaymentMethodContainer.master.card)}
                                            />
                                            <label>{LanguagePack.extract(langType, OrderRequestLangPack.payment.paymentMethods.card)}</label>
                                        </div>
                                        {/*<div onClick={this.changePaymentMethod.bind(this, PaymentMethodContainer.master.transfer)}>*/}
                                        {/*    <input type="radio"*/}
                                        {/*           id={DomIdConstant.DomIdPaymentRequestForm.paymentMethodForTransfer}*/}
                                        {/*           ref={this.REFS.payment.methods.transfer}*/}
                                        {/*           defaultChecked={(this.props.billModel.paymentMethod === PaymentMethodContainer.master.transfer)}*/}
                                        {/*    />*/}
                                        {/*    <label>{LanguagePack.extract(langType, OrderRequestLangPack.payment.paymentMethods.transfer)}</label>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                                {(this.props.billModel.paymentMethod === PaymentMethodContainer.master.transfer ? (
                                    <div>
                                        <div style={{marginBottom: 10}}>
                                            {LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.guide)}
                                        </div>
                                        <div style={{marginBottom: 10}}>
                                            <table className="ui table compact">
                                                <tbody>
                                                    {this.genTitleAndDescriptionForTR(
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.bank.title),
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.bank.description)
                                                    )}
                                                    {this.genTitleAndDescriptionForTR(
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.branch.title),
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.branch.description)
                                                    )}
                                                    {this.genTitleAndDescriptionForTR(
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.accountType.title),
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.accountType.description)
                                                    )}
                                                    {this.genTitleAndDescriptionForTR(
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.accountNumber.title),
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.accountNumber.description)
                                                    )}
                                                    {this.genTitleAndDescriptionForTR(
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.accountHolder.title),
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.accountHolder.description)
                                                    )}
                                                    {this.genTitleAndDescriptionForTR(
                                                        LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.transferInformation.depositor.title),
                                                        `${this.props.subscriberModel.lastName} ${this.props.subscriberModel.firstName}`
                                                    )}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div>
                                            {LanguagePack.extract(langType, OrderRequestLangPack.payment.transfer.whatHappensNext)}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{display:'inline-block'}}>
                                            <div style={{width: 300, borderRadius: 8, border:'1px solid #d8d8d8', backgroundColor:'#f5f5f5', padding:10, backgroundImage: 'url(/assets/img/payment/gray_jean.png)'}}>
                                                <div style={{textAlign:'right'}}>
                                                    {this.genCardTypeIcon(this.verifyCardType(this.props.billModel.cardNumber))}
                                                </div>
                                                <div style={{display:'relative', marginTop:6}}>
                                            <span style={{color:'black', fontSize: 14, textShadow: '#CCC 1px 0 10px'}}>
                                                {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.number)}
                                            </span>
                                                    <input type="text"
                                                           id={DomIdConstant.DomIdPaymentRequestForm.paymentCardNumber}
                                                           ref={this.REFS.payment.card.number}
                                                           maxLength={19}
                                                           minLength={12}
                                                           value={billModel.cardNumber}
                                                           style={{padding:12, border:'1px solid #CCC', fontSize: 16, marginTop: 7}}
                                                           onChange={this.routeValidatingInput.bind(this, this.REFS.payment.card.number, true)}
                                                    />
                                                    {( this.state.checkNoticeBox.payment.card.number ?
                                                        <div className="ui negative message">
                                                            {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.payment.card.number)}
                                                        </div>
                                                        : null )}
                                                </div>
                                                <div style={{display:'relative', marginTop:12}}>
                                                    <div style={{color:'black', fontSize: 12, minWidth: 100, textShadow: '#CCC 1px 0 10px', display:'inline-block', marginRight: 7, textAlign: 'right'}}>
                                                        {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.expiration.title)}
                                                    </div>
                                                    <div style={{width:"calc( 100% - 107px)", display: 'inline-block'}}>
                                                        <select
                                                            id={DomIdConstant.DomIdPaymentRequestForm.paymentCardMonth}
                                                            ref={this.REFS.payment.card.expiration.month}
                                                            value={billModel.cardValidationExpiringMonth.toString()}
                                                            style={{width: '40%', height: 25, display:'inline-block', margin: 0, padding: 3}}
                                                            onChange={this.routeValidatingInput.bind(this, this.REFS.payment.card.expiration.month, true)}
                                                        >
                                                            <option value="0" disabled={true}>
                                                                {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.expiration.month)}
                                                            </option>
                                                            {cardMonths.map( (month, index) => (<option value={month.toString()} key={index}>{(month < 10 ? "0"+month : month)}</option>))}
                                                        </select>

                                                        &nbsp;/&nbsp;

                                                        <select
                                                            id={DomIdConstant.DomIdPaymentRequestForm.paymentCardYear}
                                                            ref={this.REFS.payment.card.expiration.year}
                                                            value={billModel.cardValidationExpiringYear.toString()}
                                                            style={{width: '40%', height: 25, display:'inline-block', margin: 0, padding: 3}}
                                                            onChange={this.routeValidatingInput.bind(this, this.REFS.payment.card.expiration.year, true)}
                                                        >
                                                            <option value="0" disabled={true}>
                                                                {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.expiration.year)}
                                                            </option>
                                                            {cardYearFor20Year.map( (year, index) => (<option value={year.toString()} key={index}>{year}</option>))}
                                                        </select>
                                                    </div>
                                                    {( this.state.checkNoticeBox.payment.card.expiration.month && this.state.checkNoticeBox.payment.card.expiration.year  ?
                                                        <div className="ui negative message small">
                                                            {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.payment.card.expiration.month)}
                                                        </div>
                                                        : null )}
                                                </div>
                                                <div style={{display:'relative', marginTop:12, verticalAlign: 'middle'}}>
                                                    <div style={{color:'black', fontSize: 12, minWidth: 100, textShadow: '#CCC 1px 0 10px', display:'inline-block', marginRight: 7, textAlign: 'right'}}>
                                                        {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.holder)}
                                                    </div>
                                                    <div style={{width:"calc( 100% - 107px)", display: 'inline-block'}}>
                                                        <input type="text"
                                                               id={DomIdConstant.DomIdPaymentRequestForm.paymentCardHolder}
                                                               ref={this.REFS.payment.card.holder}
                                                               value={billModel.cardHolder}
                                                               style={{padding:8, border:'1px solid #CCC', fontSize: 12}}
                                                               onChange={this.routeValidatingInput.bind(this, this.REFS.payment.card.holder, true)}
                                                        />
                                                    </div>
                                                </div>
                                                {( this.state.checkNoticeBox.payment.card.holder ?
                                                    <div className="ui negative message">
                                                        {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.payment.card.holder)}
                                                    </div>
                                                    : null )}

                                            </div>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <div style={{display:'inline-block'}}>
                                            <div style={{width: 250, borderRadius: 8, border:'1px solid #d8d8d8', backgroundColor:'#f5f5f5', padding:10, marginTop:10, backgroundImage: 'url(/assets/img/payment/gray_jean.png)'}}>
                                                <div style={{margin: -10, marginTop: 5, marginBottom: 10, height: 38, backgroundColor:'#565656'}}></div>
                                                <div style={{marginTop:12, verticalAlign: 'middle', textAlign:'right'}}>
                                                    <table style={{width: "100%"}}><tbody><tr>
                                                        <td style={{color:'black', fontSize: 12, minWidth: 100, textShadow: '#CCC 1px 0 10px', paddingRight: 7, textAlign: 'right'}}>
                                                            {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.cvc.title)}
                                                        </td>
                                                        <td style={{width: 100}}>
                                                            <input type="text"
                                                                   id={DomIdConstant.DomIdPaymentRequestForm.paymentSecurityCode}
                                                                   ref={this.REFS.payment.card.cvc}
                                                                   maxLength={5}
                                                                   value={billModel.cardValidationCode}
                                                                   style={{padding:8, border:'1px solid #CCC', fontSize: 12}}
                                                                   onChange={this.routeValidatingInput.bind(this, this.REFS.payment.card.cvc, true)}
                                                            />
                                                        </td>
                                                    </tr></tbody></table>

                                                    {( this.state.checkNoticeBox.payment.card.cvc ?
                                                        <div className="ui negative message">
                                                            {LanguagePack.extract(langType, OrderRequestVaidationGuideLangPack.payment.card.cvc)}
                                                        </div>
                                                        : null )}

                                                    <div style={{minHeight:50, color:'grey',fontSize:12,fontWeight:200}}>
                                                        <div style={{marginTop: 20}}>
                                                            {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.cvc.guide.title)}
                                                        </div>
                                                        <div>
                                                            {LanguagePack.extract(langType, OrderRequestLangPack.payment.card.cvc.guide.description)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                </div>
                            </div>
                    </div>

                    <div className="ui section divider"></div>

                    <div className="ui grid">
                        <div className="four wide column">
                        </div>
                        <div className="twelve wide column">
                            <h3 style={{fontWeight:200}}>
                                <span>
                                    {LanguagePack.extract(langType, OrderRequestLangPack.order.total)}:&nbsp;
                                </span>
                                <span style={{marginLeft: 10}}>
                                    {this.props.billModel.billMoney.amount}
                                </span>
                                <span style={{marginLeft: 5}}>
                                    {LanguagePack.extract(langType, this.props.billModel.billMoney.currency.name)}
                                </span>
                            </h3>

                            {this.state.checkNoticeBox.order.items ? this.generateEmptyShoppingCartMessage(langType) : null}

                            <ul>
                                <li>
                                    {ServiceInfoConstant.serviceName}
                                    <a id={DomIdConstant.DomIdPaymentRequestForm.policyPageLinkButton} href={PortalPathDictionary.company.policy.path} target="_blank">
                                        {LanguagePack.extract(langType, IntroActivityLangPack.agreements.guideTxtTermsOfUse)}
                                    </a>
                                </li>
                            </ul>

                            <div className="ui checkbox">
                                <input type="checkbox" name="gift" className="hidden"
                                       ref={this.REFS.agreeCheckbox}/>
                                <label id={DomIdConstant.DomIdPaymentRequestForm.policyAgreementButton}>{LanguagePack.extract(langType, IntroActivityLangPack.agreements.guideTxtReadAndAgree)}</label>
                                {( this.state.checkNoticeBox.agreeCheckbox ?
                                    <div className="ui negative message compact" style={{marginTop:10}}>
                                        {LanguagePack.extract(langType, IntroActivityLangPack.agreements.checkAgreementGuide)}
                                    </div>
                                    : null )}
                            </div>
                            <br/><br/>
                            {this.state.isLoading ? (
                                <MiniLoaderComponent loaderFlag={true} themeStyle={MiniLoaderComponent.themeStyle.bright}/>
                            ) : (
                                <div id={DomIdConstant.DomIdPaymentRequestForm.submitButton} className="ui positive button" onClick={this.doPay}>
                                    {LanguagePack.extract(langType, OrderRequestLangPack.order.placeOrderButton)}
                                </div>
                            )}
                        </div>
                    </div>

                </form>
        );
    }
}

export default connect(mapStateToProps)(PaymentRequestForm);