import * as React from "react";
import {connect} from "react-redux";
import AbsoluteFullSizeBox from "../../ui_template/AbsoluteFullSizeBox";
import {RouteListenerComponent} from "../RouteListener";
import {RoutePathParam} from "../RouteDictionary";
import * as Loops4RPCAPI from "../../api/loops4/Loops4RPCAPI";
import DimLoaderComponent from "../../ui_template/DimLoaderComponent";
import MainAppLayout from "../../screen/layout/MainAppLayout";
import StoreBrowserBox from "./browser/StoreBrowserBox";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        accessTokenModel: state.auth.user,
        routeState: state.routeState,
    };
};

class Store extends RouteListenerComponent {

    REFS_SET = {};

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            contentBox: null
        }
    }

    async updateByProps() {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const storeId = this.props.routeState.pathParameter[RoutePathParam.storeId]
            const rpcResult = await Loops4RPCAPI.getReleaseStore({
                accessTokenModel: this.props.accessTokenModel,
                storeId: storeId
            })

            let contentBox = null
            if (rpcResult.isError()) {
                contentBox = (
                    <div>
                        Request got failed:: {rpcResult.getErrorMessage()}
                    </div>
                )
            } else {
                /**
                 * @type {StoreModel}
                 */
                const storeModel = rpcResult.model
                contentBox = (
                    <StoreBrowserBox accessTokenModel={this.props.accessTokenModel}
                                     storeModel={storeModel}
                    />
                )
            }

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: false,
                    contentBox: contentBox
                }
            })

        })
    }

    render() {
        let content;

        if (this.state.isLoading) {
            content = (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {
            content = this.state.contentBox
        }

        return (
            <MainAppLayout>
                <div className="flexContainerRow " style={{width: '100%'}}>
                    <div className="flexItem11  flexItemHorizontallyDynamic">
                        <AbsoluteFullSizeBox>
                            <div className="flexContainerColumn ">
                                <div className="flexItem11">
                                    <AbsoluteFullSizeBox>
                                        {content}
                                    </AbsoluteFullSizeBox>
                                </div>
                            </div>
                        </AbsoluteFullSizeBox>
                    </div>
                </div>
            </MainAppLayout>
        )
    }
}

export default connect(mapStateToProps)(Store);