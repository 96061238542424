

// Package Dir: general
// FileName: EmptyRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.general.EmptyRequest
// Super types: java.lang.Object

export class EmptyRequest {
    constructor() {



    }
}