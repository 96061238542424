import React from 'react';
import * as AuthAPI from "../../api/loops4/AuthAPI";
import {RouteUtility} from "../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../RouteDictionary";
import SigningValidator from "./SigningValidator";
import {renderRedirectPage} from "./UnSignedAccountRedirectView";

class SignedAccountSubscriberValidator extends SigningValidator{
    async validateTheAccount(reduxState, routeState) {
        const accessTokenModel =  reduxState.auth.subscriber;

        const isSignedIn = await AuthAPI.isValidAccessToken({
            accessTokenModel: accessTokenModel
        })

        return isSignedIn
    }

    renderRedirectPage(reduxState, routeState) {
        const redirectPage = RouteUtility.getPathname(RouteDictionary.subscriberSignIn, {
            [RoutePathParam.releaseCollectionId]: routeState.pathParameter[RoutePathParam.releaseCollectionId]
        }, {
            [RouteQueryParam.access_key]: routeState.queryParameter[RouteQueryParam.access_key]
        })

        return renderRedirectPage(redirectPage)
    }
}

export const signedAccountSubscriberValidator = new SignedAccountSubscriberValidator()