export default class DateUTCModel{

    date = new Date();

    constructor(date){
        this.date = date || new Date();
    }

    /**
     * @param data
     * @return {DateUTCModel}
     */
    static deserialize(data) {

        let date = new Date();
        if(data instanceof Date){
            date = data;
        } else {
            date = new Date(data)
        }

        let newModel = new DateUTCModel(date);
        return newModel;
    }

    toString() {
        return this.date.toISOString()
    }
    toISOString(){
        return this.date.toISOString()
    }
    toJSON(){
        return this.date.toISOString()
    }
    getTime(){
        return this.date.getTime()
    }


}
