

// Package Dir: commerce/order/common
// FileName: OrderPriceRPCBody.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderPriceRPCBody
// Super types: java.lang.Object

export class OrderPriceRPCBody {
    constructor() {

        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.price_amount = 0;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.price_currency = null;

    }
}