import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "../library/JQuery";


export default class HeaderToolbarLayouter extends React.Component {

    static propTypes = {
        addictionMenu: PropTypes.element,
    }


    REFS_SET={
        HeaderContainer:"HeaderContainer"
    };

  render() {

      let headerToolbarStyle = {
          fontSize:12,
          color:"grey",
          boxSizing: "content-box",
          transition:'all 0.5s ease-in',
          width:'100%'
      }

    return (
        <div style={headerToolbarStyle} ref={this.REFS_SET.HeaderContainer} >

            {this.props.addictionMenu}
            {
                /*
                 <div className="item right">
                 <div className="ui transparent icon input" style={{display:'inline-block'}}>
                 <input type="text" placeholder="Search Pictures..."/>
                 <i className="search icon"></i>
                 </div>
                 </div>
                 */
            }

        </div>
    );

  }
}
