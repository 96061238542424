import * as React from "react";
import {connect} from "react-redux";
import {RouteListenerComponent} from "../../RouteListener";
import DimLoaderComponent from "../../../ui_template/DimLoaderComponent";
import LanguagePack from "../../../language/LanguagePack";
import SignLangPack from "../../../language/SignLangPack";
import SignInLangPack from "../../../language/publish/subscriber/SignInLangPack";
import SubscriberSignConstraintPack from "../../../constraints/publish/subscriber/SubscriberSignConstraintPack";
import ServiceInfoConstant from "../../../constant/ServiceInfoConstant";
import LanguageDropdown from "../../../ui_component/LanguageDropdown";
import {RouteUtility} from "../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../RouteDictionary";
import * as Loops4RPCAPI from "../../../api/loops4/Loops4RPCAPI";
import * as AuthAPI from "../../../api/loops4/AuthAPI";
import {AuthUserType} from "../../../constant/APIConstant";
import {AccessTokenModel} from "../../../model/auth/AccessTokenModel";
import {clearAccessTokenForSubscriber, setAccessTokenForSubscriber} from "../../../state/redux/action/AuthAction";
import {clearSubscriberInfo, setSubscriberInfo} from "../../../state/redux/action/UserAction";
import {pushHistory} from "../../RouteHistory";
import {APPLICATION_VERSION} from "../../../constant/ApplicationVersionConstant";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        routeState: state.routeState,
    };
};

class PublishReleaseCollectionSignIn extends RouteListenerComponent {

    REFS_SET = {
        signForm_accountPassword: 'signForm_accountPassword'
    };

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            contentBox: null,
        }

        this.signIn = this.signIn.bind(this)
    }

    signIn(e) {
        e.preventDefault();

        const accountId = this.props.routeState.queryParameter[RouteQueryParam.access_key];
        const accountPassword = this.refs[this.REFS_SET.signForm_accountPassword]["value"];

        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            let accessToken;

            AuthAPI.issueAccessToken({
                accountId,
                accountPassword,
                AuthUserType: AuthUserType.subscriber,
            }).then(async (response) => {
                accessToken = new AccessTokenModel({
                    accessToken: response['access_token'],
                    lifeSeconds: response['life_seconds'],
                    refreshToken: response['refresh_token'],
                    createdAt: response['created_at'],
                    scope: response['scope'],
                    tokenType: response['token_type']
                })

                const subscriberInfoResult = await Loops4RPCAPI.getAccountInformationByAuthSession_WithAccountSubscriberModel({
                    accessTokenModel: accessToken,
                    accountId: accountId,
                })

                if (subscriberInfoResult.isError()) {
                    throw Error(`failed to get user information, Please try it again or inquiry to administrator, Error Code: ${subscriberInfoResult.getErrorMessage()}`)
                } else {
                    return subscriberInfoResult.model
                }
            }).then((subscriberInfoModel) => {
                setAccessTokenForSubscriber(accessToken)
                setSubscriberInfo(subscriberInfoModel)

                pushHistory(RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
                    [RoutePathParam.releaseCollectionId]: this.props.routeState.pathParameter[RoutePathParam.releaseCollectionId]
                }, {
                    [RouteQueryParam.access_key]: accountId,
                    [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
                }))
            })
                .catch(e => {
                    clearAccessTokenForSubscriber()
                    clearSubscriberInfo()
                    alert(`failed to sign in, please check your account e-mail and secretKey, Error:: ${e.message}`)
                })
                .finally(_ => {
                    this.setState((prevs, props) => {
                        return {
                            ...prevs,
                            isLoading: false
                        }
                    })
                })
        })
    }

    isValidAccessKey = () => {
        const accessKey = this.props.routeState.queryParameter[RouteQueryParam.access_key]
        return (
            accessKey !== 'undefined' &&
            accessKey !== undefined &&
            accessKey !== null
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {

            const language = this.props.language
            const isValidAccessKey = this.isValidAccessKey()

            return (
                <div style={{width:'100%', height:'100%', backgroundColor: '#EFEFEF',
                    backgroundImage: `url('${process.env.PUBLIC_URL}/assets/img/backgrounds/trimed2_1080.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 100%',
                    backgroundPosition: '70% 0px'
                }} >
                    <div className="ui container"
                         style={{position: 'relative', zIndex: 100, paddingTop: '10%', paddingBottom: '20%'}}>
                        <div style={{textAlign: 'right'}}>
                            <LanguageDropdown
                                updateAction={(lang) => RouteUtility.changeAndPushQueryParameter(RouteQueryParam.language, lang.value)}
                                defaultLanguage={language}
                            />
                        </div>

                        <div>
                            <h2>
                                {ServiceInfoConstant.serviceName}<br/>
                                {LanguagePack.extract(language, SignInLangPack.title)}
                            </h2>
                            <h4 style={{marginTop:0, color: '#CCC'}}>
                                v{APPLICATION_VERSION}
                            </h4>
                        </div>

                        {/*<div className="ui message grey">*/}
                        {/*<h2 style={{color:'red'}}>*/}
                        {/*現在システム点検中です。<br/>*/}
                        {/*</h2>*/}
                        {/*<div>*/}
                        {/*現在システム点検中です。<br/>*/}
                        {/*<br/>*/}
                        {/*<b>点検時間：2017年1月29日08：00AM　〜　2017年1月29日　12：00PM<br/></b>*/}
                        {/*点検時間以来、再接続して下さい。<br/>*/}
                        {/*<br/>*/}
                        {/*※現在もご利用は可能ですが、一部の機能が動作しない可能性がございます。<br/>*/}
                        {/*</div>*/}
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {/*ーーーーーーーーーーーーーーーーーーーーーーーーーーー<br/>*/}
                        {/*<h2 style={{color:'red'}}>*/}
                        {/*We are in maintenance.<br/>*/}
                        {/*</h2>*/}
                        {/*<div>*/}
                        {/*<br/>*/}
                        {/*We are in the maintenance.<br/>*/}
                        {/*<br/>*/}
                        {/*<b>Maintenance time: 29, Jan, 2017 08:00AM ~ 29, Jan, 2017 12:00PM<br/></b>*/}
                        {/*Please access again since maintenance.<br/>*/}
                        {/*<br/>*/}
                        {/*This site still available but it might have problem during maintenance time.*/}
                        {/*</div>*/}
                        {/*</div>*/}

                        <div className="ui hidden divider"></div>

                        <div style={{maxWidth: 390}}>
                            {isValidAccessKey ? (
                              <React.Fragment>
                                  <h4>
                                      {LanguagePack.extract(language, SignInLangPack.secretKey)}
                                  </h4>
                                  <form className="ui form compact" onSubmit={this.signIn}>
                                      <div className="field">
                                          <div className="ui left icon input compact">
                                              <input type="password"
                                                     ref={this.REFS_SET.signForm_accountPassword}
                                                     minLength={SubscriberSignConstraintPack.password.minLength}
                                                     maxLength={SubscriberSignConstraintPack.password.maxLength}
                                                     placeholder={LanguagePack.extract(language, SignInLangPack.secretKey)}
                                              />
                                              <i className="lock icon"/>
                                          </div>
                                      </div>
                                      <div className="field">
                                          <input className="ui blue submit green button"
                                                 type="submit"
                                                 value={LanguagePack.extract(language, SignLangPack.buttons.signIn)}
                                          />
                                      </div>
                                      <div className="field">
                                    <span style={{
                                        color: 'black',
                                        fontWeight: 100
                                    }}>{LanguagePack.extract(language, SignInLangPack.secretKeyGuideWhere)}</span>
                                      </div>
                                  </form>
                              </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <h4>
                                        {LanguagePack.extract(language, SignInLangPack.invalidAccessKey)}
                                    </h4>
                                    <p style={{
                                        color: 'black',
                                        fontWeight: 100
                                    }}>
                                        {LanguagePack.extract(language, SignInLangPack.invalidAccessKeyGuide)}
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(PublishReleaseCollectionSignIn);