import {Loops4RequestMessage} from "../message/Loops4RequestMessage";
import {requestToRPC} from "../loops4RPCExecutor";

import {GetReleaseCollectionRPCResponse} from "../model/release/collection/GetReleaseCollectionRPCResponse";
import {PutAccountReleaseSubscriberRPCResponse} from "../model/account/release_subscriber/PutAccountReleaseSubscriberRPCResponse";
import {GetReleaseCategoryRPCResponse} from "../model/release/category/GetReleaseCategoryRPCResponse";
import {NotifyOnlyOkRPCResponse} from "../model/general/NotifyOnlyOkRPCResponse";
import {GetAccountReleaseSubscriberRPCResponse} from "../model/account/release_subscriber/GetAccountReleaseSubscriberRPCResponse";
import {RefundCommerceOrderRPCResponse} from "../model/commerce/order/refund_commerce_order/RefundCommerceOrderRPCResponse";
import {GetAccountReleaseSubscriberInReleaseCollectionRPCResponse} from "../model/account/release_subscriber/GetAccountReleaseSubscriberInReleaseCollectionRPCResponse";
import {GetLibraryLabelRPCResponse} from "../model/library/label/GetLibraryLabelRPCResponse";
import {GetLibraryCollectionRPCResponse} from "../model/library/collection/GetLibraryCollectionRPCResponse";
import {GetOrderCommerceProductReleaseItemRPCResponse} from "../model/commerce/order/get_order_commerce_product_release_item/GetOrderCommerceProductReleaseItemRPCResponse";
import {GetLibraryItemRPCResponse} from "../model/library/item/GetLibraryItemRPCResponse";
import {GetOrderHistoryRPCResponse} from "../model/commerce/order/get_order_history/GetOrderHistoryRPCResponse";
import {CancelCommerceOrderRPCResponse} from "../model/commerce/order/cancel_commerce_order/CancelCommerceOrderRPCResponse";
import {GetCommerceSaleProductReleaseCollectionRPCResponse} from "../model/commerce/sale/product/release/collection/GetCommerceSaleProductReleaseCollectionRPCResponse";
import {GetReleaseStoreRPCResponse} from "../model/release/store/GetReleaseStoreRPCResponse";
import {GetCommerceCartProductRPCResponse} from "../model/commerce/cart/product/GetCommerceCartProductRPCResponse";
import {GetLibraryBoxRPCResponse} from "../model/library/box/GetLibraryBoxRPCResponse";
import {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse} from "../model/commerce/order/put_order_commerce_product_release_item_from_commerce_cart_product/PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse";
import {GetAccountUserRPCResponse} from "../model/account/user/GetAccountUserRPCResponse";
import {GetReleaseItemsOfCollectionRPCResponse} from "../model/release/item/GetReleaseItemsOfCollectionRPCResponse";
import {GetReleaseItemRPCResponse} from "../model/release/item/GetReleaseItemRPCResponse";
import {RPCModelBase} from "../model/model_base/RPCModelBase";
import {GetCommerceSaleProductReleaseItemRPCResponse} from "../model/commerce/sale/product/release/item/GetCommerceSaleProductReleaseItemRPCResponse";

/**
 * @param {object} headers
 * @param {GetReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseCollectionRPCResponse}>}
 */
export const GET_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutAccountReleaseSubscriberRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: PutAccountReleaseSubscriberRPCResponse}>}
 */
export const PUT_ACCOUNT_RELEASE_SUBSCRIBER__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutAccountReleaseSubscriber"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new PutAccountReleaseSubscriberRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetReleaseCategoryRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseCategoryRPCResponse}>}
 */
export const GET_RELEASE_CATEGORY__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetReleaseCategory"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseCategoryRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {RemoveReleaseStoreIntoReleaseStoreRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const REMOVE_RELEASE_COLLECTION_INTO_RELEASE_STORE__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "RemoveReleaseCollectionIntoReleaseStore"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetAccountReleaseSubscriberRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetAccountReleaseSubscriberRPCResponse}>}
 */
export const GET_ACCOUNT_RELEASE_SUBSCRIBER__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetAccountReleaseSubscriber"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetAccountReleaseSubscriberRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {RemoveLibraryItemIntoLibraryCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const REMOVE_LIBRARY_ITEM_INTO_LIBRARY_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "RemoveLibraryItemIntoLibraryCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutReleaseCollectionPreviewRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const PUT_RELEASE_COLLECTION_PREVIEW__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutReleaseCollectionPreview"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {RefundCommerceOrderRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: RefundCommerceOrderRPCResponse}>}
 */
export const REFUND_COMMERCE_ORDER__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "RefundCommerceOrder"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new RefundCommerceOrderRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetAccountReleaseSubscriberInReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetAccountReleaseSubscriberInReleaseCollectionRPCResponse}>}
 */
export const GET_ACCOUNT_RELEASE_SUBSCRIBER_IN_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetAccountReleaseSubscriberInReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetAccountReleaseSubscriberInReleaseCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AddLibraryCollectionIntoLibraryBoxRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADD_LIBRARY_COLLECTION_INTO_LIBRARY_BOX__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AddLibraryCollectionIntoLibraryBox"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AdminCancelDeliveryHandlerRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADMIN_CANCEL_DELIVERY___V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AdminCancelDelivery"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AdminAddNewDeliveryHandlerRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADMIN_ADD_NEW_DELIVERY___V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AdminAddNewDelivery"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutLibraryLabelRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryLabelRPCResponse}>}
 */
export const PUT_LIBRARY_LABEL__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutLibraryLabel"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryLabelRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetLibraryCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryCollectionRPCResponse}>}
 */
export const GET_LIBRARY_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetLibraryCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetOrderCommerceProductReleaseItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetOrderCommerceProductReleaseItemRPCResponse}>}
 */
export const GET_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM__v1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetOrderCommerceProductReleaseItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetOrderCommerceProductReleaseItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetLibraryItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryItemRPCResponse}>}
 */
export const GET_LIBRARY_ITEM__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetLibraryItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetOrderHistoryRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetOrderHistoryRPCResponse}>}
 */
export const GET_ORDER_HISTORY__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetOrderHistory"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetOrderHistoryRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {CancelCommerceOrderRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: CancelCommerceOrderRPCResponse}>}
 */
export const CANCEL_COMMERCE_ORDER__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "CancelCommerceOrder"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new CancelCommerceOrderRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetCommerceSaleProductReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetCommerceSaleProductReleaseCollectionRPCResponse}>}
 */
export const GET_COMMERCE_SALE_PRODUCT_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetCommerceSaleProductReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetCommerceSaleProductReleaseCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutLibraryItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryItemRPCResponse}>}
 */
export const PUT_LIBRARY_ITEM__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutLibraryItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {ClearCommerceProductReleaseItemIntoCommerceCartProductRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const CLEAR_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "ClearCommerceProductReleaseItemIntoCommerceCartProduct"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {RemoveCommerceProductReleaseItemIntoCommerceCartProductRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const REMOVE_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "RemoveCommerceProductReleaseItemIntoCommerceCartProduct"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AddReleaseCollectionIntoReleaseStoreRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADD_RELEASE_COLLECTION_INTO_RELEASE_STORE__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AddReleaseCollectionIntoReleaseStore"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {RemoveLibraryBoxIntoLibraryBoxRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const REMOVE_LIBRARY_COLLECTION_INTO_LIBRARY_BOX__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "RemoveLibraryCollectionIntoLibraryBox"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutReleaseCategoryRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseCategoryRPCResponse}>}
 */
export const PUT_RELEASE_CATEGORY__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutReleaseCategory"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseCategoryRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutCommerceSaleProductReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetCommerceSaleProductReleaseCollectionRPCResponse}>}
 */
export const PUT_COMMERCE_SALE_PRODUCT_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutCommerceSaleProductReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetCommerceSaleProductReleaseCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetReleaseStoreRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseStoreRPCResponse}>}
 */
export const GET_RELEASE_STORE__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetReleaseStore"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseStoreRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetCommerceCartProductRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetCommerceCartProductRPCResponse}>}
 */
export const GET_COMMERCE_CART_PRODUCT__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetCommerceCartProduct"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetCommerceCartProductRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {EmptyRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetOrderHistoryRPCResponse}>}
 */
export const ADMIN_GET_ORDER_HISTORY__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AdminGetOrderHistory"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetOrderHistoryRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseCollectionRPCResponse}>}
 */
export const PUT_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutLibraryBoxRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryBoxRPCResponse}>}
 */
export const PUT_LIBRARY_BOX__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutLibraryBox"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryBoxRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse}>}
 */
export const PUT_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM_FROM_COMMERCE_CART_PRODUCT__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutOrderCommerceProductReleaseItemFromCommerceCartProduct"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetLibraryBoxRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryBoxRPCResponse}>}
 */
export const GET_LIBRARY_BOX__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetLibraryBox"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryBoxRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetLibraryLabelRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryLabelRPCResponse}>}
 */
export const GET_LIBRARY_LABEL__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetLibraryLabel"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryLabelRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AddReleaseItemIntoReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADD_RELEASE_ITEM_INTO_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AddReleaseItemIntoReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetAccountUserRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetAccountUserRPCResponse}>}
 */
export const GET_ACCOUNT_USER__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetAccountUser"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetAccountUserRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutReleaseStoreRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseStoreRPCResponse}>}
 */
export const PUT_RELEASE_STORE__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutReleaseStore"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseStoreRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AddLibraryItemIntoReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADD_LIBRARY_ITEM_INTO_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AddLibraryItemIntoReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseItemsOfCollectionRPCResponse}>}
 */
export const GET_RELEASE_ITEMS_OF_COLLECTION_V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetReleaseItemsOfCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseItemsOfCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetReleaseItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseItemRPCResponse}>}
 */
export const GET_RELEASE_ITEM__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetReleaseItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetAccountInformationByAuthSessionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: RPCModelBase}>}
 */
export const GET_ACCOUNT_INFORMATION_BY_AUTH_SESSION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetAccountInformationByAuthSession"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new RPCModelBase(), data) 
        })
}

/**
 * @param {object} headers
 * @param {RemoveReleaseCollectionIntoReleaseCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const REMOVE_RELEASE_ITEM_INTO_RELEASE_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "RemoveReleaseItemIntoReleaseCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutLibraryCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetLibraryCollectionRPCResponse}>}
 */
export const PUT_LIBRARY_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutLibraryCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetLibraryCollectionRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutAccountUserRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetAccountUserRPCResponse}>}
 */
export const PUT_ACCOUNT_USER__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutAccountUser"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetAccountUserRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutReleaseItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetReleaseItemRPCResponse}>}
 */
export const PUT_RELEASE_ITEM__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutReleaseItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetReleaseItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AddCommerceProductReleaseItemIntoCommerceCartProductRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADD_COMMERCE_PRODUCT_RELEASE_ITEM_INTO_COMMERCE_CART_PRODUCT__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AddCommerceProductReleaseItemIntoCommerceCartProduct"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {GetCommerceSaleProductReleaseItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetCommerceSaleProductReleaseItemRPCResponse}>}
 */
export const GET_COMMERCE_SALE_PRODUCT_RELEASE_ITEM__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "GetCommerceSaleProductReleaseItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetCommerceSaleProductReleaseItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {ModifyCommerceSaleProductReleaseItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetCommerceSaleProductReleaseItemRPCResponse}>}
 */
export const MODIFY_COMMERCE_SALE_PRODUCT_RELEASE_ITEM__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "ModifyCommerceSaleProductReleaseItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetCommerceSaleProductReleaseItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {PutCommerceSaleProductReleaseItemRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: GetCommerceSaleProductReleaseItemRPCResponse}>}
 */
export const PUT_COMMERCE_SALE_PRODUCT_RELEASE_ITEM__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "PutCommerceSaleProductReleaseItem"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new GetCommerceSaleProductReleaseItemRPCResponse(), data) 
        })
}

/**
 * @param {object} headers
 * @param {AddLibraryItemIntoLibraryCollectionRPCRequest} paramBody
 * @return {Promise<Loops4ResponseMessage | {model: NotifyOnlyOkRPCResponse}>}
 */
export const ADD_LIBRARY_ITEM_INTO_LIBRARY_COLLECTION__V1_0 = async ({headers = {}, paramBody}) => {

    const message = new Loops4RequestMessage()
    message.method = "AddLibraryItemIntoLibraryCollection"
    message.version = "1.0"
    message.parameter = paramBody
    
    return requestToRPC({
            headers,
            body: message,
            deserializer: (data) => Object.assign(new NotifyOnlyOkRPCResponse(), data) 
        })
}