

// Package Dir: commerce/cart/product
// FileName: GetCommerceCartProductRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.cart.product.GetCommerceCartProductRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.commerce.cart.product.CommerceCartProductRPCBody, com.loops4.controller.loops4rpc.model.commerce.cart.product.CommerceCartProductRPCRelation, java.lang.Object

export class GetCommerceCartProductRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.commerce.cart.product.CommerceOrderProductWithOrderAttributeRPCResponse>
         * @type {array<CommerceOrderProductWithOrderAttributeRPCResponse>}
         */
        this.products = [];

    }
}