/**
 * Created by greg on 7/21/16.
 */

export const RegExpDictionary = {
    wordOnlyWithWhiteSpace: /^[\w\s]*$/,
    wordWithDigit: /^[\w]*$/,
    wordOnly: /^[\w]*$/,
    digitOnly: /^[\d]*$/,
    digitWithHyphen: /^[\d]*\|-\[\d]*$/,
    everything: /^.*$/,
};


class ValidatingRule {

    /**
     *
     * @param {any} targetValue
     * @param {boolean} emptyAllowed
     * @return {boolean}
     */
    run(targetValue, emptyAllowed){

    };

    /**
     *
     * @param {function} customRule
     */
    // constructor(customRule: (targetValue: any, emptyAllowed:boolean) => boolean){
    constructor(customRule= (targetValue, emptyAllowed) => {}){
        this.run = customRule
    }
}
export class ValidatingRuleNumberTemplate extends ValidatingRule{
    minNum = ValidatingRuleNumberTemplate.MIN_NUM;
    maxNum = ValidatingRuleNumberTemplate.MAX_NUM;

    static MAX_NUM = Number.MAX_VALUE;
    static MIN_NUM = Number.MIN_VALUE;

    /**
     * @param {number} minNum
     * @param {number} maxNum
     * @param {boolean} emptyAllowed
     */
    constructor(minNum, maxNum, emptyAllowed = false){
        super(
            /**
             * @param {any} targetValue
             * @param {boolean} emptyAllowed
             * @return {boolean}
             */
            (targetValue, emptyAllowed = false)=>{
                return (
                    ( this.maxNum > 0 ? targetValue <= this.maxNum : true) &&
                    ( emptyAllowed ?
                            true :
                            ( this.minNum > 0 ? targetValue >= this.minNum : true)
                    )
                );
            }
        );

        this.minNum = minNum;
        this.maxNum = maxNum;
    }
}
export class ValidatingRuleBooleanTemplate extends ValidatingRule{
    targetBoolean = false;

    /**
     * @param {boolean} targetBoolean
     */
    constructor(targetBoolean){
        super(
            /**
             * @param {any} targetValue
             * @param {boolean} emptyAllowed
             * @return {boolean}
             */
            (targetValue, emptyAllowed = false)=>{
                return (targetValue === this.targetBoolean)
            }
        );

        this.targetBoolean = targetBoolean;
    }
}
export class ValidatingRuleTemplate extends ValidatingRule{
    minLength;
    maxLength;
    regExp;

    /**
     * @param {number} minLength
     * @param {number} maxLength
     * @param {RegExp} regExp
     */
    constructor(minLength, maxLength, regExp){
        super(
            /**
             * @param {any} targetValue
             * @param {boolean} emptyAllowed
             * @return {boolean}
             */
            (targetValue, emptyAllowed = false)=>{
                return (
                    ( this.maxLength > 0 ? targetValue.length <= this.maxLength : true) &&
                    ( emptyAllowed ?
                            true :
                            ( this.minLength > 0 ? targetValue.length >= this.minLength : true)
                    )
                    &&
                    ( this.regExp ? this.regExp.exec(targetValue) === targetValue : true)
                )
            }
        );

        this.minLength = minLength;
        this.maxLength = maxLength;
        this.regExp = regExp;

    }
}

export class ValidateSet{

    /**
     *
     * @param {any} targetValue
     * @param {ValidatingRule} rule
     */
    constructor(targetValue, rule){
        this.targetValue = targetValue;
        this.rule = rule;
    }
}

export class Validator{

    /**
     * @type {Array<ValidateSet>}
     */
    validateSets = [];

    constructor(){
    }

    /**
     * @param {ValidateSet} ValidateSet
     * @param {boolean} emptyAllowed
     * @return {boolean}
     */
    validate(ValidateSet, emptyAllowed = false){
        return ValidateSet.rule.run(ValidateSet.targetValue, emptyAllowed);
    }

    /**
     * @param {boolean} emptyAllowed
     * @return {boolean}
     */
    validateTheSets(emptyAllowed = false){
        return this.validateSets.filter( validateSet => this.validate(validateSet, emptyAllowed) === false).length === 0
    }
}