import DateUTCModel from "../DateUTCModel";
import ReleaseCollectionModel from "./ReleaseCollectionModel";

export default class StoreModel{

    entityId;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();

    storeName;
    storeType;
    storeState;

    childReleaseCollectionList = [];

    /**
     *
     * @param data
     * @return {StoreModel}
     */
    static deserialize(data){
        let newModel = new StoreModel();

        if(data){
            newModel.entityId = data.entity_id;
            newModel.storeName = data.store_name;
            newModel.storeType = data.store_type;
            newModel.storeState = data.store_state;
            newModel.dateCreated = DateUTCModel.deserialize(data.date_created);
            newModel.dateUpdated = DateUTCModel.deserialize(data.date_updated);

            if(data.collections && data.collections.length){
                newModel.childReleaseCollectionList = data.collections.map( collection => ReleaseCollectionModel.deserialize(collection));
            }
        }

        return newModel;
    }

}