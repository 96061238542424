

// Package Dir: account/release_subscriber
// FileName: GetAccountReleaseSubscriberInReleaseCollectionRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.account.release_subscriber.GetAccountReleaseSubscriberInReleaseCollectionRPCRequest
// Super types: java.lang.Object

export class GetAccountReleaseSubscriberInReleaseCollectionRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.release_collection_entity_id = null;

    }
}