import React from 'react';

import {Route} from "react-router-dom";
import * as PropTypes from "prop-types";
import SigningValidator from "./SigningValidator";
import {ReduxMainStore} from "../../state/redux/store/ReduxMainStore";
import {RouteUtility} from "../RouteUtility";


export default class SignedAccountRouter extends React.Component {

    static propTypes = {
        component: PropTypes.any,
        signingValidator: PropTypes.objectOf(SigningValidator).isRequired,
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: true,
            isSignedIn: undefined,
        }
        RouteUtility.updateRouteState(props)
    }

    componentDidMount() {

        const state = ReduxMainStore.getState();
        const routeState = state.routeState;

        /**
         * @type {SigningValidator}
         */
        const signingValidator = this.props.signingValidator
        signingValidator
            .validateTheAccount(state, routeState)
            .then(result => {
                this.setState((prevs, props) => {
                    return {
                        ...prevs,
                        isLoading: false,
                        isSignedIn: result
                    }
                })
            })
    }

    render() {
        const { component: Component, signingValidator: signingValidator, ...restProps} = this.props;

        let loader = (props) => {}

        if(this.state.isLoading || this.state.isSignedIn == undefined){
            loader = (props) => {
                return (
                    <div>
                        Checking signing ...
                    </div>
                )
            }
        } else if(this.state.isSignedIn === false){
            loader = (props) => {
                const state = ReduxMainStore.getState();
                const routeState = state.routeState;

                return signingValidator.renderRedirectPage(state, routeState)
            }
        } else {
            loader = (props) => {
                return (
                    <Component {...props} />
                )
            }
        }

        return (
            <Route {...restProps} render={(props) =>
                loader(props)
            }/>
        )
    }
}