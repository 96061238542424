import DateUTCModel from "../DateUTCModel";
import PersonContainer from "../../container/PersonContainer";
import {LanguageContainer} from "../../container/LanguageContainer";
import {AuthUserType} from "../../../constant/APIConstant";

export default class AccountUserModel extends PersonContainer {

    entityId = null;
    accountId = null;
    dateCreated = new DateUTCModel();
    dateUpdated = new DateUTCModel();
    firstName = null;
    lastName = null;
    language = null;

    rootLibraryBoxId = null;
    rootStoreId = null;
    commerceCartId = null;

    /**
     *
     * @param data
     * @return {AccountUserModel}
     */
    static deserialize(data){
        let newModel = new AccountUserModel();

        newModel.entityId = data.entity_id;
        newModel.accountId = data.account_id;
        newModel.lastName = data.last_name;
        newModel.firstName = data.first_name;
        newModel.dateCreated = ( data.date_created ? DateUTCModel.deserialize(data.date_created) : new DateUTCModel() );
        newModel.dateUpdated = ( data.date_updated ? DateUTCModel.deserialize(data.date_updated) : new DateUTCModel() );

        newModel.rootLibraryBoxId = data.library_box_entity_id;
        newModel.rootStoreId = data.release_store_entity_id;
        newModel.commerceCartId = data.commerce_cart_product_entity_id;

        newModel.language = (data.language ? LanguageContainer.deserialize(data.language) : LanguageContainer.master.english);

        return newModel;
    }

    /**
     *
     * @param data
     * @return {String}
     */
    serialize(){
        return {
            entity_id: this.entityId,
            account_id: this.accountId,
            last_name: this.lastName,
            first_name: this.firstName,
            date_created: this.dateCreated,
            date_updated: this.dateUpdated,
            library_box_entity_id: this.rootLibraryBoxId,
            release_store_entity_id: this.rootStoreId,
            commerce_cart_product_entity_id: this.commerceCartId,
            language: this.language,
        }
    }

    /**
     * @param data
     * @return {AccountUserModel}
     */
    static getPartial(data) {
        let newModel = new AccountUserModel();

        let getOrElse = (data, elseData = null)=>{
            return (data ? data : elseData)
        }

        if(data){
            newModel.entityId = getOrElse(data.entity_id);
            newModel.accountId = getOrElse(data.accountId);
            newModel.accountPassword = getOrElse(data.accountPassword);
            newModel.lastName = getOrElse(data.lastName);
            newModel.firstName = getOrElse(data.firstName);
            newModel.lastName = getOrElse(data.lastName);
            newModel.language = getOrElse(data.language);
            newModel.dateCreated = getOrElse(data.dateCreated);
            newModel.libraryQuotes = getOrElse(data.libraryQuotes);
            newModel.archive_drawerEntity_id = 0;
            newModel.stream_drawerEntity_id = 0;
        }

        return newModel;
    }


    /**
     * @return {string}
     */
    getAuthPrimaryKey() {
        return this.accountId;
    }


    /**
     * @return {string}
     */
    getAuthShowingName() {
        return this.accountId;
    }

    /**
     * @return {string}
     */
    getAuthNamespace() {
        return AuthUserType.user;
    }
}
