import * as React from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse} from "../../../../../../api/loops4/model/commerce/order/put_order_commerce_product_release_item_from_commerce_cart_product/PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse";
import {RouteUtility} from "../../../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../../../RouteDictionary";
import PublishReleaseCollection from "../../../PublishReleaseCollection";
import {Link} from "react-router-dom";
import DateFormatter from "../../../../../../model/formatter/DateFormatter";

const mapStateToProps = (state, props) => {
    return {
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.subscriber,
        routeState: state.routeState,
    };
};

class PaymentResultFailView extends React.Component {

    static propTypes = {
        languageMaster: PropTypes.object,
        accessTokenModel: PropTypes.object,
        paymentRequestResult: PropTypes.any,
        releaseCollectionId: PropTypes.string,
        backToInputFormView: PropTypes.func,
    }


    style = {
        mainContainer: {
            marginTop: '10%',
        },
        segmentContainer: {
            // minWidth: 300,
        },
        messageBox: {
            minWidth: 300,
        },
        messageDivider: {
            height: 10
        },
        description: {
            marginLeft: 5
        },
        backButton: {
            marginTop: 20,
        }
    }

    /**
     * @param {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse} result
     */
    convertBillingError(result){
        return (
            <React.Fragment>
                <div>
                    <span className={'ui label'}>エラーコード</span>
                    <div style={{...this.style.description, marginTop: 5}}>
                        {result.billing_error.error_code} <br/>
                        ({result.billing_error.error_description})
                    </div>
                </div>

                <div style={this.style.messageDivider} />

                <div>
                    <span className={'ui label red'}>エラーメッセージ</span>
                    <div style={{...this.style.description, marginTop: 5}}>{result.billing_error.error_message}</div>
                </div>

                <div className={'ui section divider'} />

                <div>
                    <span className={'ui label'}>注文ID</span>
                    <span style={this.style.description}>{result.entity_id}</span>
                </div>

                <div style={this.style.messageDivider} />

                <div>
                    <span className={'ui label'}>注文日</span>
                    <span style={this.style.description}>
                        {DateFormatter.getDateFormatForLocaleByLanguageMaster(this.props.languageMaster, result.date_created.toString())}
                    </span>
                </div>
            </React.Fragment>
        )
    }
    /**
     * @param {string} result
     */
    convertStringError(result){
        return (
            <React.Fragment>
                <div>
                    <span className={'ui label red'}>エラーメッセージ</span>
                    <div style={{...this.style.description, marginTop: 5}}>{result}</div>
                </div>
            </React.Fragment>
        )
    }

    render() {

        /**
         * type {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse}
         */
        const result = this.props.paymentRequestResult
        let errorOutput = null
        if(typeof result === "object"){
            errorOutput = this.convertBillingError(result)
        } else {
            errorOutput = this.convertStringError(result)
        }

        return (
            <div className="flexContainerRow flexItemAlignCenterStart" style={this.style.mainContainer}>
                <div className="flexItem00">
                    <div className="ui placeholder segment" style={this.style.segmentContainer}>
                        <div className="ui icon header">
                            <i className="exclamation circle icon"></i>
                            注文が失敗しました。
                        </div>

                        <div className={'ui section divider'} />

                        <div style={this.style.messageBox}>
                            {errorOutput}
                        </div>

                        <div className="ui primary button"
                             style={this.style.backButton}
                             onClick={this.props.backToInputFormView}
                        >
                            決済フォームに戻る
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps)(PaymentResultFailView);