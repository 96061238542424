import * as React from "react";

import * as PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import * as Loops4BinaryAPI from "../../../../../api/loops4/Loops4BinaryAPI";
import ProductShoppingController from "./viewer/product_shopping/controller/ProductShoppingController";
import ProductShoppingPresentationInThumbnail
    from "./viewer/product_shopping/presentaion/ProductShoppingPresentationInThumbnail";
import ProductShoppingPresentationInViewer
    from "./viewer/product_shopping/presentaion/ProductShoppingPresentationInViewer";
import {LanguageMaster} from "../../../../../model/formatter/LanguageFormatter";
import ScrollReachLoader from "../../../../../ui_component/ScrollReachLoader";
import ImageWithLoader from "./ImageWithLoader";
import {ReduxMainStore} from "../../../../../state/redux/store/ReduxMainStore";
import {connect, Provider} from "react-redux";
import ImgsViewer from 'react-images-viewer'
import {ImageSizeMasterDefinition} from "../../../../../model/formatter/ImageSizeFormatter";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import {GET_RELEASE_ITEMS_OF_COLLECTION_V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {COMMERCE_RELEASE_COLLECTION_ITEMS_OF_COLLECTION} from "../../../../../state/redux/constant/ActionType";
import {GetReleaseCollectionRPCRequest} from "../../../../../api/loops4/model/release/collection/GetReleaseCollectionRPCRequest";
import DimLoaderComponent from "../../../../../ui_template/DimLoaderComponent";


const selectedImgStyle = {
    transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};
const cont = {
    backgroundColor: "#eee",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative"
};


/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        ...props,
        releaseCollectionItemsOfCollection: state.releaseCollectionItemsOfCollection
    };
};

class PublishReleaseCollectionBrowserBox extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        languageMaster: PropTypes.instanceOf(LanguageMaster).isRequired,
        releaseCollectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
    }

    imgStyle = {
        width: 320,
        height: 'auto',
        minWidth: 300,
        minHeight: 200
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            currentImageIndex: 0,
            isLightBoxOpen: false,
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                isLoading: true
            }
        }, async () => {
            const param = new GetReleaseCollectionRPCRequest()
            param.entity_id = this.props.releaseCollectionModel.entityId

            const rpcResult = await GET_RELEASE_ITEMS_OF_COLLECTION_V1_0({
                headers: getAuthorizationHeader(this.props.accessTokenModel),
                paramBody: param
            })

            if(rpcResult.isError()){
                console.error({rpcResult})
            } else {
                ReduxMainStore.dispatch({
                    type: COMMERCE_RELEASE_COLLECTION_ITEMS_OF_COLLECTION,
                    payload: rpcResult.model
                })
            }

            this.setState((prevs, props) => {
                return {
                    ...prevs,
                    isLoading: false
                }
            })
        })
    }

    openLightbox = (event, obj) => {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                currentImageIndex: obj.index || 0,
                isLightBoxOpen: true,
            }
        })
    }
    closeLightbox = () => {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                currentImageIndex: 0,
                isLightBoxOpen: false,
            }
        })
    }
    gotoPrevious = () => {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                currentImageIndex: prevs.currentImageIndex - 1,
            }
        })
    }
    gotoNext = () => {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                currentImageIndex: prevs.currentImageIndex + 1,
            }
        })
    }

    /**
     * @param {GetReleaseItemsOfCollectionRPCResponse} releaseCollectionItemsOfCollection
     * @return {Array<PhotoProps>}
     */
    generatePreviewForThumbnailViewer(releaseCollectionItemsOfCollection){
        return releaseCollectionItemsOfCollection
            .items
            .map((releaseItemId, index) => {
                const thumbnailURL =  Loops4BinaryAPI.getReleaseItemPreviewURL({
                    accessTokenModel: this.props.accessTokenModel,
                    releaseItemId: releaseItemId,
                    longTermPixel: ImageSizeMasterDefinition.SIZE_300.longTermPixel,
                })

                return {
                    indexInEntirePage: index,
                    src: thumbnailURL,
                    width: 1,
                    height: 1
                }
            })
    }

    /**
     *
     * @param {GetReleaseItemsOfCollectionRPCResponse} releaseCollectionItemsOfCollection
     * @returns Array<ViewType>
     */
    generatePreviewForSingleViewer(releaseCollectionItemsOfCollection){
        return releaseCollectionItemsOfCollection
            .items
            .map(releaseItemId => {
                const fullScreenImageURL = {
                    size: ImageSizeMasterDefinition.SIZE_1920.longTermPixel,
                    url: Loops4BinaryAPI.getReleaseItemPreviewURL({
                        accessTokenModel: this.props.accessTokenModel,
                        releaseItemId: releaseItemId,
                        longTermPixel: ImageSizeMasterDefinition.SIZE_1920.longTermPixel,
                    })
                }
                const regularImageURL = {
                    size: ImageSizeMasterDefinition.SIZE_800.longTermPixel,
                    url: Loops4BinaryAPI.getReleaseItemPreviewURL({
                        accessTokenModel: this.props.accessTokenModel,
                        releaseItemId: releaseItemId,
                        longTermPixel: ImageSizeMasterDefinition.SIZE_800.longTermPixel,
                    })
                }
                const smallImageURL = {
                    size: ImageSizeMasterDefinition.SIZE_300.longTermPixel,
                    url: Loops4BinaryAPI.getReleaseItemPreviewURL({
                        accessTokenModel: this.props.accessTokenModel,
                        releaseItemId: releaseItemId,
                        longTermPixel: ImageSizeMasterDefinition.SIZE_300.longTermPixel,
                    })
                }
                const thumbnailURL = {
                    size: ImageSizeMasterDefinition.SIZE_100.longTermPixel,
                    url: Loops4BinaryAPI.getReleaseItemPreviewURL({
                        accessTokenModel: this.props.accessTokenModel,
                        releaseItemId: releaseItemId,
                        longTermPixel: ImageSizeMasterDefinition.SIZE_100.longTermPixel,
                    })
                }

                /**
                 * @type ViewType
                 */
                const model = {
                    src: fullScreenImageURL.url,
                    thumbnail: thumbnailURL.url,
                    // srcBySize: [
                    //     fullScreenImageURL,
                    //     regularImageURL,
                    //     smallImageURL,
                    //     thumbnailURL
                    // ].map(urlSet => `${urlSet.url} ${urlSet.size}w`),
                    caption: (
                        <div className={'PublishReleaseCollectionBrowserBoxTheme'}>
                            <div className={'selectionThumbnail caption view'}>
                                <Provider store={ReduxMainStore}>
                                    <ProductShoppingController releaseItemId={releaseItemId}
                                                               presentationalComponent={ProductShoppingPresentationInViewer}
                                    />
                                </Provider>
                            </div>
                        </div>
                    )
                }

                return model
            })
    }

    render() {
        const releaseCollectionItemsOfCollection = this.props.releaseCollectionItemsOfCollection;
        let photosForThumbnail = this.generatePreviewForThumbnailViewer(releaseCollectionItemsOfCollection);
        let photosForViewer = this.generatePreviewForSingleViewer(releaseCollectionItemsOfCollection);

        const imageRenderer = ({
                                   // Gallery props
                                   index, left, top, key, containerHeight, margin, direction, onClick, photo,
                               }) => {
            if (direction === "column") {
                cont.position = "absolute";
                cont.left = left;
                cont.top = top;
            }

            const indexInEntirePage = photo.indexInEntirePage
            const releaseItemId = releaseCollectionItemsOfCollection.items[indexInEntirePage]

            return (
                <div
                    key={index}
                    style={{ margin, ...cont }}
                >
                    <div className="flexContainerColumn flexItemAlignCenterCenter">
                        <div className="flexItem11" />
                        <div className="flexItem00">
                            <ImageWithLoader
                                alt={photo.title}
                                style={{...this.imgStyle}}
                                loaderstyle={{
                                    color: 'black',
                                    backgroundColor: 'rgba(0,0,0,1)'
                                }}
                                onClick={(event) => onClick(event, {
                                    index: indexInEntirePage, left, top, key, containerHeight, margin, direction, onClick, photo
                                })}
                                src={photo.src}
                            />
                        </div>
                        <div className="flexItem11" />
                        <div className="flexItem00 PublishReleaseCollectionBrowserBoxTheme" >
                            <div
                                className={'selectionThumbnail caption thumbnail'}
                                style={{
                                    padding: 10,
                                }}>
                                {releaseItemId
                                    ?
                                    <ProductShoppingController releaseItemId={releaseItemId}
                                                               presentationalComponent={ProductShoppingPresentationInThumbnail}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        };


        // 330 => side menu
        // const calculatedItemsPerPage = ((window.innerWidth - 330) / this.thumbnailWidth)
        // const itemPerPage = (calculatedItemsPerPage > 0 ? parseInt(calculatedItemsPerPage) : 1)
        const itemPerPage = 30
        const nextPage = (pageNumber) => {
            const itemsInPage = photosForThumbnail.slice(itemPerPage * (pageNumber - 1), itemPerPage * pageNumber)

            if(itemsInPage.length > 0){
                return itemsInPage
            } else {
                return []
            }
        }

        if (this.state.isLoading) {
            return (
                <DimLoaderComponent loaderFlag={true} themeStyle={DimLoaderComponent.themeStyle.dark}/>
            )
        } else {
            return (
                <div style={{width: '100%'}}>
                    <ImgsViewer
                        imgs={photosForViewer}
                        currImg={this.state.currentImageIndex}
                        isOpen={this.state.isLightBoxOpen}
                        onClickPrev={this.gotoPrevious}
                        onClickNext={this.gotoNext}
                        onClose={this.closeLightbox}
                        showThumbnails={true}
                        theme={{
                            footer: {
                                height: 120
                            }
                        }}
                    />
                    <ScrollReachLoader className={''}
                                       style={{height: '98vh'}}
                                       renderNextPage={nextPage}
                                       renderContainer={(items) => <Gallery photos={[...items]}
                                                                            onClick={this.openLightbox}
                                                                            margin={3}
                                                                            renderImage={imageRenderer}
                                       />}
                    />
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(PublishReleaseCollectionBrowserBox);