

// Package Dir: release/collection
// FileName: PutReleaseCollectionRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.release.collection.PutReleaseCollectionRPCRequest
// Super types: com.loops4.controller.loops4rpc.model.release.collection.ReleaseCollectionRPCBody, java.lang.Object

export class PutReleaseCollectionRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_state = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.collection_type = null;
        /**
         *
         * JVM type: kotlin.collections.List<kotlin.String>
         * @type {array<string>}
         */
        this.library_item_entity_ids = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.release_store_entity_id = null;

    }
}