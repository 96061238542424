import {endpointLoops4} from "../../constant/APIConstant";
import {getAuthorizationHeader} from "./Loops4RPCAPI";
import {AccessTokenModel} from "../../model/auth/AccessTokenModel";

export const issueAccessToken = async ({accountId, accountPassword, AuthUserType}) => {
    return await fetch(`${endpointLoops4}/api/oauth2/access_token`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            client_id: accountId,
            client_password: accountPassword,
            scope: [AuthUserType]
        }),
    })
}

export const refreshAccessToken = async ({accountId, accountPassword}) => {
    return await fetch(`${endpointLoops4}/api/oauth2/access_token`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            refresh_token: accountId,
        }),
    }).then(res => res.json())
      .then(json => AccessTokenModel.deserialize(json))
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @return {Promise<void>}
 */
export const deleteAccessToken = async ({accessTokenModel}) => {
    return await fetch(`${endpointLoops4}/api/oauth2/access_token`, {
        method: 'DELETE',
        headers: Object.assign(getAuthorizationHeader(accessTokenModel), {'Content-Type': 'application/json'})
    })
}

/**
 * @param {AccessTokenModel} accessTokenModel
 * @return {Promise<void>}
 */
export const isValidAccessToken = async ({accessTokenModel}) => {
    if(accessTokenModel.accessToken){
        // const createdDate = moment(accessTokenModel.createdAt)
        // const expiringDate = createdDate.add(accessTokenModel.lifeSeconds, 'seconds')
        // const isDateValidated = createdDate.isBefore(expiringDate)
        // console.log('isValidAccessToken', {
        //     createdDate,
        //     expiringDate,
        //     lifeSeconds: accessTokenModel.lifeSeconds,
        //     isDateValidated
        // })
        //
        // return isDateValidated
        return true
    } else {
        return false
    }
    // return await Request({
    //     uri: `${endpointLoops4}/api/oauth2/access_token`,
    //     method: 'POST',
    //     json: true,
    //     headers: getAuthorizationHeader(accessTokenModel),
    //     body: accessTokenModel
    // })
}
