import * as React from "react";
import * as PropTypes from "prop-types";
import ButtonContainerAddictionMenu from "./addiction_menu/ButtonContainerAddictionMenu";
import {RouteUtility} from "../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../RouteDictionary";
import {Link} from "react-router-dom";
import {clearFullSizeDIM, setFullSizeDIM} from "../../../state/redux/action/UIControlAction";
import AddPhotoComponent from "./adder/AddPhotoComponent";
import DomIdConstant from "../../../constant/DomIdConstant.json";

class LibraryBrowserAddictionMenuState {
    addPopup = null;
}


export default class LibraryBrowserAddictionMenu extends React.Component {


    static propTypes = {
        collectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
        rootStoreId: PropTypes.string,
    }



    /* =========================================================================================================================*/
    /* ============================================== COMPONENT BASE ===========================================================*/
    /* =========================================================================================================================*/


    REFS_SET = {};

    constructor(props, context) {
        super(props, context);
        this.state = new LibraryBrowserAddictionMenuState();

    }

    itemBaseStyle = {
        height: 59,
        padding: 10,
        textAlign: "center",
        boxSizing:'border-box'
    };


    itemRow = {
        display: 'block'
    };


    // removeCollection(collectionPK: PrimaryKeyModel, parentDrawerPK: PrimaryKeyModel){
    //     CollectionAction.deleteCollection(collectionPK, parentDrawerPK, ()=> SelectionAction.unSelect())
    // }
    //
    // removeCollectionPermanently(collectionPK: PrimaryKeyModel){
    //     let decisionResult = confirm("Are you sure remove it permanently?\n\nYou will lost this data and never get recovery after it.");
    //
    //     if(decisionResult){
    //         CollectionAction.deleteCollectionPermanently(collectionPK, ()=> SelectionAction.unSelect())
    //     }
    // }

    // copy() {
    //     this.props.selectionStore.getAllItems().forEach(
    //         item => {
    //             CollectionAction.attachItem(this.props.collectionModel.getPrimaryKey(), item,
    //                 (collectionModel) => console.log("LibraryBrowserAddictionMenu >> copy item to collection", collectionModel)
    //             )
    //         }
    //     )
    //
    // }
    //
    // remove() {
    //     this.props.selectionStore.getAllItems().forEach(
    //         item => {
    //             CollectionAction.attachItem(this.props.collectionModel.getPrimaryKey(), item,
    //                 (collectionModel) => console.log("LibraryBrowserAddictionMenu >> copy item to collection", collectionModel)
    //             )
    //         }
    //     )
    //
    // }

    // downloadSelectedItems() {
    //     console.debug("downloadSelectedItems", this.props.selectionStore.getAllItems());
    //     // CollectionAction.downloadCollectionByItems(this.props.collectionModel.getPrimaryKey(), this.props.selectionStore.getAllIPickUptems())
    //     let fileName = `${this.props.collectionModel.collectionName}-${this.props.selectionStore.getAllItems().length}`
    //     CollectionAction.downloadAnArchiveForItems(AuthModel.authNamespace.user, this.props.selectionStore.getAllItems(), fileName)
    // }


    openAddPopup() {
        setFullSizeDIM(
            <div>
                <div className="flexContainerRow flexItemAlignEndCenter">
                    <div className="flexItem00">
                        <div className={'ui button'}
                             id={DomIdConstant.DomLibrayBrowserAddictionMenu.closePhotoPopupButton}
                             onClick={() => clearFullSizeDIM()}
                        >
                            <i className={'icon window close'}/>
                            <span>CLOSE</span>
                        </div>
                    </div>
                </div>
                <div className="ui segment" >
                    <h4 style={{textAlign:'left'}}>
                        Add photo to
                        &nbsp;&nbsp;<label className="ui label"> {this.props.collectionModel.collection_name}</label>&nbsp;&nbsp;collection
                    </h4>
                    <div className="ui divider">
                    </div>
                    {<AddPhotoComponent collectionModel={this.props.collectionModel}
                                        accessTokenModel={this.props.accessTokenModel}
                    />}
                </div>
            </div>
        )
    }

    // openEditPopup(event) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //
    //     AppContextAction.openDim(
    //             <div className="ui segment" >
    //                 <div className="ui form" style={{textAlign:'left', fontSize:12}}>
    //                     <div className="two fields">
    //                     <div className="field">
    //                         <h4 style={{textAlign:'left'}}>
    //                          Change Name
    //                         </h4>
    //                         <div className="ui divider"></div>
    //                         <CollectionNameChangerComponent modelPK={this.props.collectionModel.getPrimaryKey()}
    //                                                         modelTitle={this.props.collectionModel.collectionName}/>
    //                     </div>
    //
    //                      <div className="field">
    //                          <h4 style={{textAlign:'left'}}>
    //                           Remove Collection
    //                         </h4>
    //                         <div className="ui divider"></div>
    //                         <div style={{textAlign:'center'}}>
    //                             <div className="two fields">
    //                                 <div className="field">
    //                                     <button className="ui button blue small"
    //                                             onClick={this.removeCollection.bind(this, this.props.collectionModel.getPrimaryKey(), this.props.parentDrawerModelPK)}>
    //                                         A - Remove
    //                                     </button>
    //                                 </div>
    //                                 <div className="field">
    //                                     <button style={{marginLeft:15}} className="ui basic black button small"
    //                                             onClick={this.removeCollectionPermanently.bind(this, this.props.collectionModel.getPrimaryKey())}>
    //                                         B - Remove permanently <span style={{color:'red'}}>*caution</span>
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                      </div>
    //                     </div>
    //                 </div>
    //
    //
    //
    //           <table style={{marginTop:10, textAlign:'left', fontSize:12}}>
    //               <tbody>
    //               <tr>
    //                   <td><i className="icon info circle"/></td>
    //                   <td>How different Collection and Drawer?<br/></td>
    //               </tr>
    //               <tr>
    //                   <td></td>
    //                   <td>
    //                       <p>
    //                           Collection<br/>
    //                           &nbsp;&nbsp;It is a set of photos. It is same concept with Photo Book in your book shelf<br/>
    //                           The different point is It is virtual space for the picture. You can put same photo for several collections. then, if you change the photo, you can see all photo data is changed at the same time.<br/>
    //                           It will leads you flexible photo management.
    //                       </p>
    //                       <p>
    //                             Drawer<br/>
    //                             &nbsp;&nbsp;It is a set of collections.  It is same concept with Book Sehlf in your room<br/>
    //                             The different point is It is virtual space for the collections. You can put same collection for several drawers. then, you can have collections more flexible and categorized.<br/>
    //                             It will leads you flexible photo management.
    //                       </p>
    //                       ...  <a onClick={(e)=>{e.preventDefault(); alert("The page is on preparing. We will update soon.")}}>learn more</a></td>
    //               </tr>
    //               </tbody>
    //           </table>
    //             </div>
    //     )
    // }

    // removeItemFromCollection(collectionPK: PrimaryKeyModel, items:Array<PrimaryKeyModel>){
    //     items.map( item => CollectionAction.detachItem(collectionPK, item,
    //         ()=> {
    //             SelectionAction.unSelect();
    //             AppContextAction.closeDim();
    //         }
    //         )
    //     )
    // }

    // removeItemPermanently(items:Array<PrimaryKeyModel>){
    //     let decisionResult = confirm("Are you sure remove it permanently?\n\nYou will lost this data and never get recovery after it.");
    //
    //     if(decisionResult){
    //         items.map( item => CollectionAction.detachItemPermanently(AuthModel.authNamespace.user, item,
    //             ()=> {
    //                 SelectionAction.unSelect();
    //                 AppContextAction.closeDim();
    //             }
    //             )
    //         )
    //     }
    //
    // }

    // openRemovePopup() {
    //     let items = this.props.selectionStore.getAllItems();
    //     let itemsAvailable = items.length > 0;
    //
    //     AppContextAction.openDim(
    //             <div className="ui segment" style={{color:'black', textAlign:'left'}}>
    //                 <h4 style={{textAlign:'left'}}>
    //                 REMOVE
    //                 </h4>
    //
    //                 <div className="ui divider"></div>
    //
    //                 <div style={{overflow:'scroll', height:'50%', minHeight:100}}>
    //                     {
    //                         (itemsAvailable ?
    //                             <ul>
    //                                 {items.map( (item: ItemModel, index) => {
    //                                     return (
    //                                         <li key={index} > {item._id} / {item.itemName}</li>
    //                                     )
    //                                 })}
    //                             </ul>
    //                              :
    //                             <div className="ui message negative">
    //                                 You did not select any item to remove.
    //                             </div>
    //                         )
    //                     }
    //
    //                 </div>
    //                 <div className="ui divider">
    //                   </div>
    //                 <div style={{textAlign:'center'}}>
    //                     <button className="ui button blue"
    //                             onClick={this.removeItemFromCollection.bind(this, this.props.collectionModel.getPrimaryKey(), this.props.selectionStore.getAllItems())}
    //                             disabled={!itemsAvailable}
    //                     >
    //                         A - Remove in collection
    //                     </button>
    //                     <button style={{marginLeft:15}}
    //                             className="ui basic black button"
    //                             onClick={this.removeItemPermanently.bind(this, this.props.selectionStore.getAllItems())}
    //                             disabled={!itemsAvailable}
    //                     >
    //                         B - Remove permanently <span style={{color:'red'}}>*caution</span>
    //                     </button>
    //                 </div>
    //
    //           <table style={{marginTop:10}}>
    //               <tbody>
    //               <tr>
    //                   <td><i className="icon info circle"/></td>
    //                   <td>How different removing type A and B?<br/></td>
    //               </tr>
    //               <tr>
    //                   <td></td>
    //                   <td>
    //                       <p>
    //                           A - Remove in collection<br/>
    //                           &nbsp;&nbsp;It will be removed from this collection but it still remains in other collections if it belongs to them
    //                       </p>
    //                       <p>
    //                             B - Remove permanently <span style={{color:'red'}}>*caution</span><br/>
    //                             &nbsp;&nbsp;It will be removed in every collections of your archive. The result of it, you can not use it anymore
    //                       </p>
    //                       ...  <a onClick={(e)=>{e.preventDefault(); alert("The page is on preparing. We will update soon.")}}>learn more</a></td>
    //               </tr>
    //               </tbody>
    //           </table>
    //             </div>
    //     )
    // }

    // shareCollection(){
    //     let creatingShareModel = new ShareModel();
    //
    //     creatingShareModel.sharingCollection = this.props.collectionModel;
    //
    //     SharePublisherAction.createShare(
    //         creatingShareModel,
    //         (createdShareModel) => {
    //             let url = SharePublisherAction.genShareSubscriberUrl(createdShareModel)
    //             AppContextAction.openDim(
    //                 <div className="ui segment" >
    //                     <h4 style={{textAlign:'left'}}>
    //                         Shared collection
    //                         &nbsp;&nbsp;<label className="ui label"> {this.props.collectionModel.collectionName}</label>&nbsp;&nbsp;collection
    //                     </h4>
    //                     <div className="ui divider">
    //                     </div>
    //
    //                     <div>
    //                         <label className="ui label">URL</label>
    //                         <a href={url}>
    //                             {url}
    //                         </a>
    //                     </div>
    //                 </div>
    //             )
    //         },
    //         ()=>{
    //             alert("failed to create share")
    //         }
    //     )
    //
    // }



    /* =========================================================================================================================*/
    /* ============================================== GENERATE ELEMENT==========================================================*/
    /* =========================================================================================================================*/


    genSelectButton() {
        // if (this.props.selectionStore.getAllItems().length > 0) {
        //     return (<ButtonContainerAddictionMenu icon={<i className="icon large minus square outline"/>}
        //                                           label={`Unselect`}
        //                                           clickEvent={SelectionAction.unSelect.bind(this)}
        //     />)
        // } else {
            return (<ButtonContainerAddictionMenu icon={<i className="icon large checkmark box"/>}
                                                  label={`Select All`}
                                                  // clickEvent={SelectionAction.selectAll.bind(this, this.props.collectionModel.items)}
            />)
        // }
    }

    genDownloadButton() {
        // let ConfirmIfDownloadingEntireCollection = ()=> {
        //     if (confirm("Do you download all pictures of this collection?\nPlease selection photos if you want few of them.")) {
        //         CollectionAction.downloadAnArchiveForItems(AuthModel.authNamespace.user, this.props.collectionModel.items, this.props.collectionModel.collectionName)
        //     }
        // };
        //
        // if (this.props.selectionStore.getAllItems().length > 0) {
        //     return (<ButtonContainerAddictionMenu icon={<i className="icon large download"/>}
        //                                           label={`Download (${this.props.selectionStore.getAllItems().length})`}
        //                                           clickEvent={this.downloadSelectedItems.bind(this)}
        //     />)
        // } else {
            return (<ButtonContainerAddictionMenu icon={<i className="icon large download"/>}
                                                  label={`Download`}
                                                  // clickEvent={ConfirmIfDownloadingEntireCollection.bind(this)}
            />)
        // }

    }



    /* =========================================================================================================================*/
    /* ==================================================== RENDERING ==========================================================*/
    /* =========================================================================================================================*/


    render() {


        return (
            <div className="flexContainerRow">
                <div className="flexItem00">
                    <ButtonContainerAddictionMenu icon={<i className="icon large edit"/>}
                                                  label="Edit"
                                                  // clickEvent={this.openEditPopup.bind(this)}
                    />
                </div>
                <div className="flexItem00">
                    <ButtonContainerAddictionMenu icon={<i className="icon large photo"/>}
                                                  id={DomIdConstant.DomLibrayBrowserAddictionMenu.addPhotoPopupButton}
                                                  label="Add Photo"
                                                  clickEvent={this.openAddPopup.bind(this)}
                    />
                </div>
                <div className="flexItem00">
                    {this.genSelectButton()}
                </div>
                <div className="flexItem00">
                    {this.genDownloadButton()}
                </div>
                <div className="flexItem00">
                    <ButtonContainerAddictionMenu icon={<i className="icon large trash"/>}
                                                  label="Remove"
                                                  // clickEvent={this.openRemovePopup.bind(this)}
                    />
                </div>
                <div className="flexItem00">
                    <ButtonContainerAddictionMenu icon={null}
                                                  label={
                                                      <div className="ui button green mini" style={{verticalAlign: 'middle', margin: 0}}>
                                                            Share
                                                      </div>
                                                  }
                                                  // clickEvent={this.shareCollection.bind(this)}
                    />
                </div>
                <div className="flexItem00">
                    <ButtonContainerAddictionMenu icon={null}
                                                  id={DomIdConstant.DomLibrayBrowserAddictionMenu.storeReleaseFormByLibraryCollectionButton}
                                                  label={
                                                      <Link
                                                          to={RouteUtility.getPathname(RouteDictionary.storeReleaseFormByLibraryCollection, {
                                                              // TODO set store id of the user
                                                              [RoutePathParam.storeId]: this.props.rootStoreId,
                                                          },{
                                                              [RouteQueryParam.sourceCollectionId]: this.props.collectionModel.entity_id,
                                                          })}
                                                      >
                                                          <div className="ui button green mini" style={{verticalAlign: 'middle', margin: 0}}>
                                                              ReleaseCollection
                                                          </div>
                                                      </Link>
                                                  }
                    />
                </div>

                {
                    this.state.addPopup
                }
            </div>
        );
    }
}
