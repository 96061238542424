

// Package Dir: library/item
// FileName: GetLibraryItemRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.library.item.GetLibraryItemRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.library.item.LibraryItemRPCBody, java.lang.Object

export class GetLibraryItemRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.attribute_pin_flag = null;
        /**
         *
         * JVM type: kotlin.Int
         * @type {number}
         */
        this.attribute_rating = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.file_entity_id = null;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.file_height = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.file_width = 0;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.item_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.item_type = null;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.meta_exif_aperture = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_brightness = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_camera_model_name = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_camera_orientation = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_color_space = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_create_date = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_exposure_mode = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_exposure_program = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_file_type = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_flash = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_focal_length = null;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.meta_exif_image_Height = 0;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.meta_exif_image_width = 0;
        /**
         *
         * JVM type: kotlin.Int?
         * @type {number}
         */
        this.meta_exif_iso = 0;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.meta_exif_latitude = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_lens_model = null;
        /**
         *
         * JVM type: kotlin.Double?
         * @type {number}
         */
        this.meta_exif_longitude = 0;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_make = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_metering_mode = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_modify_date = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_shutter_speed = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.meta_exif_software = null;

    }
}