const portalEnvPrefix = 'NEXT_PUBLIC_'
const dashboardEnvPrefix = 'REACT_APP__'

const getEnv = (envName: string) => {
    const portalEnv = process.env[portalEnvPrefix + envName]
    const dashboardEnv = process.env[dashboardEnvPrefix + envName]

    if(portalEnv !== undefined && portalEnv.length > 0){
        return portalEnv
    }

    if(dashboardEnv !== undefined && dashboardEnv.length > 0){
        return dashboardEnv
    }

    return Error(`Undefined the env ${envName}`)
}
export const ServiceDomainDictionary = {
    portal: getEnv('SERVICE_DOMAIN_PORTAL'),
    dashboard: getEnv('SERVICE_DOMAIN_DASHBOARD'),
    rest_api: getEnv('SERVICE_DOMAIN_REST_API'),
    grpc_api: getEnv('SERVICE_DOMAIN_GRPC_API')
}