import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {getShippingArchiveFileURL} from "../../../../../../api/loops4/Loops4BinaryAPI";
import {LanguageMaster} from "../../../../../../model/formatter/LanguageFormatter";
import {CompactShippingHistoryInCompactOrderHistoryResponse} from "../../../../../../api/loops4/model/commerce/order/get_order_history/compact/CompactShippingHistoryInCompactOrderHistoryResponse";
import {SHIPPING_STATE_MASTER} from "../../../../../../api/loops4/master/shipping/shipping_state_master";
import {SHIPPING_METHOD_MASTER} from "../../../../../../api/loops4/master/shipping/shipping_method_master";
import {ADMIN_CANCEL_DELIVERY___V1_0} from "../../../../../../api/loops4/caller/loops4RpcCaller";
import {getAuthorizationHeader} from "../../../../../../api/loops4/Loops4RPCAPI";
import {AdminCancelDeliveryHandlerRPCRequest} from "../../../../../../api/loops4/model/commerce/order/ship/AdminCancelDeliveryHandlerRPCRequest";
import {
    getShippingMethodAsLocal,
    getShippingStateAsLocal, isInactiveState,
    renderDigitalFileDownloader
} from "../../../../../../model/formatter/ShippingFormatter";

const mapStateToProps = (state, props) => {
    return {
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.admin,
        routeState: state.routeState,
    };
};
class ShippingDetailInAdminCompactOrderHistoryRow extends React.Component {

    static propTypes = {
        languageMaster: PropTypes.objectOf(LanguageMaster),
        accessTokenModel: PropTypes.object.isRequired,

        shippingIndex: PropTypes.number.isRequired,
        shipping: PropTypes.objectOf(CompactShippingHistoryInCompactOrderHistoryResponse).isRequired,
    }

    style = {
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context, REFS_SET) {
        super(props, context);
    }

    cancelShipping = async (shippingId) => {
        const param = new AdminCancelDeliveryHandlerRPCRequest()
        param.commerce_shipping_entity_id = shippingId

        await ADMIN_CANCEL_DELIVERY___V1_0({
            headers: getAuthorizationHeader(this.props.accessTokenModel),
            paramBody: param
        })
    }

    renderShippingCancelButton(shippingId, shippingState, shippingMethod){
        if(
            shippingMethod === SHIPPING_METHOD_MASTER.DIGITAL_CONTENT_FILE_DOWNLOAD &&
            (
                shippingState === SHIPPING_STATE_MASTER.DELIVERING_SHIPPING ||
                shippingState === SHIPPING_STATE_MASTER.PREPARING_SHIPPING ||
                shippingState === SHIPPING_STATE_MASTER.PENDING_SHIPPING
            )
        ){
            return (
                <div className={'ui button black'}
                     onClick={this.cancelShipping.bind(this, shippingId)}
                >
                    配送キャンセル
                </div>
            )
        }
    }

    render() {
        const language = this.props.language
        const languageMaster = this.props.languageMaster

        const shippingIndex = this.props.shippingIndex
        /**
         * @type CompactShippingHistoryInCompactOrderHistoryResponse
         */
        const shipping = this.props.shipping

        return (
            <div>
                {shippingIndex > 0 ? (<div className="ui hidden divider" />) : null}
                <div className={'ui segment ' + (isInactiveState(shipping.shipping_state) ? '  grey' : ' blue')}>
                    <div>
                        <h4 className={"ui header"}>
                            <span className="ui grey circular label">{shippingIndex + 1}</span> 配送
                            <div className="sub header" style={{marginLeft: 35}}>{shipping.entity_id}</div>
                        </h4>
                    </div>
                    <ul>
                        <li style={{fontWeight: 'bold'}}>{getShippingStateAsLocal(shipping.shipping_state)}</li>
                        <li>{getShippingMethodAsLocal(shipping.shipping_method)}</li>
                    </ul>
                    <div>
                        {shipping.actual_shipping.map(
                            /**
                             * @param {OrderActualShippingEntityRPCBody} actualShipping
                             */
                            (actualShipping, index) => {
                                console.log({actualShipping})
                                return renderDigitalFileDownloader(
                                    this.props.accessTokenModel,
                                    actualShipping.entity_id,
                                    actualShipping.shipping_state,
                                    actualShipping.shipping_method,
                                    index + 1,
                                    shipping.actual_shipping.length
                                )
                            }
                        )}

                        {this.renderShippingCancelButton(shipping.entity_id, shipping.shipping_state, shipping.shipping_method)}
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps)(ShippingDetailInAdminCompactOrderHistoryRow);