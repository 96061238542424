

// Package Dir: 
// FileName: Loops4ResponseMessage.js
// RPC model: com.loops4.controller.loops4rpc.abstraction.message.Loops4ResponseMessage
// Super types: java.lang.Object

export class Loops4ResponseMessage {
    constructor() {

        /**
         *
         * JVM type: Data?
         * @type {Data}
         */
        this.data = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.abstraction.exception.Loops4RPCException>
         * @type {array<com.loops4.controller.loops4rpc.abstraction.exception.Loops4RPCException>}
         */
        this.errors = [];
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.method = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.version = null;

    }
}