import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {RouteUtility} from "../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../RouteDictionary";
import PublishReleaseCollection from "../PublishReleaseCollection"

export const ClassNameConst ={
    active: "active"
};

const mapStateToProps = (state, props) => {
    return {
        language: state.language,
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.subscriber,
        commerceCartModel: state.subscriberCommerceCartModel,
        routeState: state.routeState,
    };
};

class ContentNavigationMenu extends React.Component{
    static propTypes = {
        language: PropTypes.object,
        commerceCartModel: PropTypes.object,

        releaseCollectionId: PropTypes.string,
        totalItems: PropTypes.number,
        viewMode: PropTypes.string
    }

    style = {
        mainContainer: {
            padding: 10
        },
        menu: {
            minWidth: 300
        },
        mainText: {
            color: 'black'
        },
        subText: {
            color: 'rgba(155, 155, 155, 1)',
            paddingLeft: 30,
            marginTop: 5,
            fontSize: '0.8em',
        },
        statusBox: {
            textAlign: 'center'
        },
        statusIcon: {
            minWidth: 60
        },
        status_label: {
            fontSize: '0.8em',
            color: 'rgba(155, 155, 155, 1)',
        }
    }
    
    render() {

        let naviCounter = 0
        let selectionClassName = ''
        let shoppingCartClassName = ''
        let paymentClassName = ''
        let orderHistoryClassName = ''

        switch (this.props.viewMode) {
            case PublishReleaseCollection.viewMode.selection: {
                selectionClassName = ' active '
                break;
            }
            case PublishReleaseCollection.viewMode.shoppingCart: {
                shoppingCartClassName = ' active '
                break;
            }
            case PublishReleaseCollection.viewMode.payment: {
                paymentClassName = ' active '
                break;
            }
            case PublishReleaseCollection.viewMode.order_history: {
                orderHistoryClassName = ' active '
                break;
            }
            case PublishReleaseCollection.viewMode.order_detail: {
                orderHistoryClassName = ' active '
                break;
            }
        }

        return (
            <div style={this.style.mainContainer}>
                <div className="ui vertical menu " style={this.style.menu}>
                    <div className={`item ${selectionClassName}`}>
                        <Link className={'flexContainerRow'}
                              to={RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
                                  [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionId,
                              }, {
                                  [RouteQueryParam.view_mode]: PublishReleaseCollection.viewMode.selection,
                                  [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
                                  [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
                              })}>
                            <div className={'flexItem11'} style={this.style.mainText}>
                                <i className="icon circular">{++naviCounter}</i>
                                <div className={'flexContainerColumn'} style={{height: 'initial'}}>
                                    <div className={'flexItem11'} />
                                    <div className={'flexItem00'}>
                                        写真を選択する
                                    </div>
                                    <div className={'flexItem11'} />
                                </div>
                            </div>
                            <div className={'flexItem00'} style={this.style.statusBox} >
                                <div style={this.style.status_label}>TOTAL</div>
                                <span className="ui basic grey small label" style={this.style.statusIcon}>{this.props.totalItems}</span>
                            </div>
                        </Link>
                    </div>

                    <div className={`item ${shoppingCartClassName}`}>
                        <Link className={'flexContainerRow'}
                              to={RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
                                  [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionId,
                              }, {
                                  [RouteQueryParam.view_mode]: PublishReleaseCollection.viewMode.shoppingCart,
                                  [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
                                  [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
                              })}>
                            <div className={'flexItem11'} style={this.style.mainText}>
                                <i className="icon circular">{++naviCounter}</i>
                                <div className={'flexContainerColumn'} style={{height: 'initial'}}>
                                    <div className={'flexItem11'} />
                                    <div className={'flexItem00'}>
                                        ショッピングカート
                                    </div>
                                    <div className={'flexItem11'} />
                                </div>
                            </div>
                            <div className={'flexItem00'} style={this.style.statusBox}>
                                <div style={this.style.status_label}>IN</div>
                                <span className="ui green small label" style={this.style.statusIcon}>{this.props.commerceCartModel.products.length}</span>
                            </div>

                        </Link>
                    </div>

                    <div className={`item ${paymentClassName}`}>
                        <Link className={'flexContainerRow'}
                              to={RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
                                  [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionId,
                              }, {
                                  [RouteQueryParam.view_mode]: PublishReleaseCollection.viewMode.payment,
                                  [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
                                  [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
                              })}>

                            <div className={'flexItem11'} style={this.style.mainText}>
                                <i className="icon circular">{++naviCounter}</i>
                                <div className={'flexContainerColumn'} style={{height: 'initial'}}>
                                    <div className={'flexItem11'} />
                                    <div className={'flexItem00'}>
                                        決済
                                    </div>
                                    <div className={'flexItem11'} />
                                </div>
                            </div>
                            <div className={'flexItem00'} style={this.style.statusBox}>
                                {/*<span className="ui basic grey small label" style={this.style.statusIcon}>___ YEN</span>*/}
                            </div>
                        </Link>
                    </div>

                    <div className={`item ${orderHistoryClassName}`}>
                        <Link
                              to={RouteUtility.getPathname(RouteDictionary.publishReleaseCollection, {
                                  [RoutePathParam.releaseCollectionId]: this.props.releaseCollectionId,
                              }, {
                                  [RouteQueryParam.view_mode]: PublishReleaseCollection.viewMode.order_history,
                                  [RouteQueryParam.access_key]: this.props.routeState.queryParameter[RouteQueryParam.access_key],
                                  [RouteQueryParam.language]: this.props.routeState.queryParameter[RouteQueryParam.language],
                              })}>

                            <div className={'flexContainerRow'}>

                                <div className={'flexItem11'} style={this.style.mainText}>
                                    <i className="icon circular">{++naviCounter}</i>
                                    <div className={'flexContainerColumn'} style={{height: 'initial'}}>
                                        <div className={'flexItem11'} />
                                        <div className={'flexItem00'}>
                                            注文履歴
                                        </div>
                                        <div className={'flexItem11'} />
                                    </div>
                                </div>
                                <div className={'flexItem00'} style={this.style.statusBox}>
                                </div>
                            </div>

                            <div style={this.style.subText}>
                                注文した写真はこちらからダウンロード出来ます
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )

    }
}

export default connect(mapStateToProps)(ContentNavigationMenu);