import * as React from "react";
import {connect} from "react-redux";
import AbsoluteFullSizeBgBlurBox from "../../ui_template/AbsoluteFullSizeBgBlurBox";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state, props) => {
    return {
        fullScreenDim: state.fullScreenDim,
    };
};

class DimExposure extends React.Component {

    render() {
        if(this.props.fullScreenDim){
            return (
                <AbsoluteFullSizeBgBlurBox>
                    {this.props.fullScreenDim}
                </AbsoluteFullSizeBgBlurBox>
            )
        } else {
            return null
        }
    }
}

export default connect(mapStateToProps)(DimExposure);