import {BillErrorRPCBody} from "../../../commerce/order/common/BillErrorRPCBody";

// Package Dir: commerce/order/put_order_commerce_product_release_item_from_commerce_cart_product
// FileName: PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.put_order_commerce_product_release_item_from_commerce_cart_product.PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, java.lang.Object

export class PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse {
    constructor() {

        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.BillErrorRPCBody?
         * @type {BillErrorRPCBody}
         */
        this.billing_error = new BillErrorRPCBody();
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;

    }
}