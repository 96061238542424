import * as React from "react";
import DateFormatter from "../../../../../../../../model/formatter/DateFormatter";

/**
 *
 * @param {GetReleaseItemRPCResponse} releaseItemModel
 * @param {LanguageMaster} languageMaster
 * @returns {JSX.Element}
 */
const calculate = (releaseItemModel, languageMaster) => {
    const megaPixels = Math.round((releaseItemModel.exif.width * releaseItemModel.exif.height) / 1000000)
    const takenAtSource = releaseItemModel.exif.date_original || releaseItemModel.exif.create_date
    const takenAt = (takenAtSource ? DateFormatter.getDateFormatForLocaleByLanguageMaster(languageMaster, takenAtSource.toString()) : null)

    return {
        megaPixels,
        takenAt
    }
}

const detailTitleStyle = {
    border: '1px solid black',
    fontWeight: 'bold'
}

const dividerStyle = {
    fontSize: '0.9em',
    color: '#AAA'
}
const detailSubStyle = {
    fontSize: '0.9em',
    color: '#AAA'
}

/**
 *
 * @param {GetReleaseItemRPCResponse} releaseItemModel
 * @param {LanguageMaster} languageMaster
 * @returns {JSX.Element}
 */
const generateMetadataForViewer = (releaseItemModel, languageMaster) => {
    const {
        megaPixels,
        takenAt
    } = calculate(releaseItemModel, languageMaster)

    return (
        <div>
            <span>{releaseItemModel.item_name}</span>
            {takenAt ? (<span><span style={dividerStyle}> / </span><span style={{detailTitleStyle}}>@</span>{takenAt}</span>) : null}
            {megaPixels ? (<span><span style={dividerStyle}> / </span>{megaPixels} <span style={{detailTitleStyle}}>MP</span><span style={detailSubStyle}>({releaseItemModel.exif.width} x {releaseItemModel.exif.height})</span></span>) : null}
        </div>
    )
}

/**
 *
 * @param {GetReleaseItemRPCResponse} releaseItemModel
 * @param {LanguageMaster} languageMaster
 * @returns {JSX.Element}
 */
const generateMetadataForThumbnail = (releaseItemModel, languageMaster) => {
    const {
        megaPixels,
        takenAt
    } = calculate(releaseItemModel, languageMaster)

    return (
        <div>
            <span>{releaseItemModel.item_name}</span>
            {takenAt ? (<span> / <span style={{detailTitleStyle}}>@</span>{takenAt}</span>) : null}
            {megaPixels ? (<span> / {megaPixels} <span style={{detailTitleStyle}}>MP</span></span>) : null}
        </div>
    )
}

export default {
    generateMetadataForViewer,
    generateMetadataForThumbnail
}