import * as React from 'react';
import LanguagePack from "../../LanguagePack";

const IntroActivityLangPack = {
    welcome: {
        welcomeHeader: new LanguagePack(
            "Welcome,",
            "ようこそ、",
            "안녕하세요,"
        ),
        welcomeHeaderSub: new LanguagePack(
            "Enjoy your photos!",
            "写真を楽しんで下さい！",
            "사진을 즐겨주세요"
        ),
        messageFromHost: new LanguagePack(
            "Message from host",
            "ホストからのメッセージ",
            "호스트로부터의 메세지"
        ),
        hostOpenedOfferForYou: new LanguagePack(
            "opened this offer for you",
            "がこのオファーを開きました。",
            "가(이) 이 오퍼를 열었습니다."
        )
    },
    agreements:{
        titleAgreements: new LanguagePack(
            "Agreements",
            "利用同意",
            "이용동의"
        ),
        guideTxtAgreements: new LanguagePack(
            "You must agree to this before using this service",
            "ご利用のためにはこの内容に同意が必要です。",
            "이용하기 위해서는 내용에 동의하실 필요가 있습니다."
        ),
        guideTxtUntilAvailable: new LanguagePack(
            "This offer is available until",
            "このオファーは下記までご利用出来る。",
            "이 오퍼는 하기까지 이용할 수 있다."
        ),
        guideTxtTermsOfUse: new LanguagePack(
            "Terms of use",
            "使用約款",
            "이용정책"
        ),
        guideTxtRightBeforePurchasing: new LanguagePack(
            "I do not have any rights for photos until purchasing",
            "購入する前まではこれらの写真に対してどの権利も持っていない。",
            "구매전까지는 해당 사진들에 대해 어떠한 권리도 갖지 않는다."
        ),
        guideTxtReadAndAgree: new LanguagePack(
            "I have read and I agree to it",
            "私はこの利用同意を読み、同意します。",
            "나는 이용약관을 읽었으며 이에 동의 합니다."
        ),
        checkAgreementGuide: new LanguagePack(
            "Please read agreement and check the checkbox.",
            "同意内容を確認し、チェックボックスをチェックして下さい。",
            "동의내용을 확인하고 체크박스를 체크해주세요."
        ),
        buttonConfirmAndNext: new LanguagePack(
            "Confirm and next",
            "確認そして次へ",
            "동의 후 다음으로"
        ),
    }
}

export default IntroActivityLangPack