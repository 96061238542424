import * as React from "react";
import * as PropTypes from "prop-types";

export class Table extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        header: PropTypes.object,
        headerStyle: PropTypes.object,
        rows: PropTypes.arrayOf(PropTypes.object),
        rowStyle: PropTypes.object,
    }

    render() {
        return (
            <table className={this.props.className} style={this.props.style}>
                <thead >
                    {this.props.header}
                </thead>
                <tbody className="verticalScroll" >
                    {this.props.rows}
                </tbody>
            </table>
        )
    }
}
