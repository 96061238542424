

// Package Dir: release/store
// FileName: GetReleaseStoreRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.release.store.GetReleaseStoreRPCRequest
// Super types: java.lang.Object

export class GetReleaseStoreRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.entity_id = null;

    }
}