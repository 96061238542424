

// Package Dir: commerce/order/get_order_commerce_product_release_item
// FileName: GetOrderCommerceProductReleaseItemRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.order.get_order_commerce_product_release_item.GetOrderCommerceProductReleaseItemRPCRequest
// Super types: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderEntityIdRPCBody

export class GetOrderCommerceProductReleaseItemRPCRequest {
    constructor() {

        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_order_entity_id = null;

    }
}