import {createSelector} from "reselect"

const passThroughCombiner = (value) => value

export const selectLanguageMaster = createSelector(
    [
        /**
         * @param {ReduxStoreState} state
         * @param props
         * @returns {string}
         */
            (state, props) => state.languageMaster
    ],
    passThroughCombiner
)

export const selectLanguage = createSelector(
    [
        /**
         * @param {ReduxStoreState} state
         * @param props
         * @returns {string}
         */
            (state, props) => state.language
    ],
    passThroughCombiner
)

export const selectAccessTokenModelForSubscriber = createSelector(
    [
        /**
         * @param {ReduxStoreState} state
         * @param props
         * @returns {string}
         */
            (state, props) => state.auth.subscriber
    ],
    passThroughCombiner
)

export const selectSubscriberCartId = createSelector(
    [
        /**
         * @param {ReduxStoreState} state
         * @param props
         * @returns {string}
         */
            (state, props) => state.subscriberCommerceCartModel.entity_id
    ],
    passThroughCombiner
)