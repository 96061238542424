import React from 'react';
import '../../App.css';
import {connect} from "react-redux";
import {RouteListenerComponent} from "../RouteListener";
import ServiceInfoConstant from "../../constant/ServiceInfoConstant";
import NotFoundLangPack from "../../language/publish/subscriber/NotFoundLangPack";
import LanguagePack from "../../language/LanguagePack";

/**
 * @param {ReduxStoreState} state
 */
const mapStateToProps = (state) => {
    return { language: state.language };
};

class Index extends RouteListenerComponent {

    getPageTitle() {
        return 'INDEX'
    }

    render() {
    return (
        <div className="ui container" style={{position:'relative', zIndex:100, paddingTop:'20%', paddingBottom:'20%'}}>
            <div>
                <h2>
                    {ServiceInfoConstant.serviceName}<br/>
                    {LanguagePack.extract(this.props.language, NotFoundLangPack.title)}
                </h2>
                <p style={{color:'grey'}}>
                    {LanguagePack.extract(this.props.language, NotFoundLangPack.addressGuide)} <br/>
                    {window.location.href}
                </p>
                <br/>
                <p>
                    {LanguagePack.extract(this.props.language, NotFoundLangPack.description)}
                </p>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(Index);
