

// Package Dir: library/box
// FileName: GetLibraryBoxRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.library.box.GetLibraryBoxRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.library.box.LibraryBoxRPCBody, com.loops4.controller.loops4rpc.model.library.box.LibraryBoxRPCRelation, java.lang.Object

export class GetLibraryBoxRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.box_name = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.box_type = null;
        /**
         *
         * JVM type: kotlin.collections.List<com.loops4.controller.loops4rpc.model.library.collection.GetLibraryCollectionRPCResponse>
         * @type {array<GetLibraryCollectionRPCResponse>}
         */
        this.collections = [];

    }
}