import * as React from "react";
import * as PropTypes from "prop-types";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import * as CommerceAction from "../../../../../state/redux/action/CommerceAction";
import LanguagePack from "../../../../../language/LanguagePack";
import OrderRequestLangPack from "../../../../../language/publish/order/OrderRequestLangPack";
import PaymentRequestForm from "./viewer/PaymentRequestForm";
import BillModel from "../../../../../model/entity/BillModel";
import {connect} from "react-redux";
import {PUT_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM_FROM_COMMERCE_CART_PRODUCT__V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCRequest} from "../../../../../api/loops4/model/commerce/order/put_order_commerce_product_release_item_from_commerce_cart_product/PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCRequest";
import {OrderCommerceProductReleaseItemEntityRPCBody} from "../../../../../api/loops4/model/commerce/order/common/OrderCommerceProductReleaseItemEntityRPCBody";
import {OrderShippingRPCBody} from "../../../../../api/loops4/model/commerce/order/common/OrderShippingRPCBody";
import {OrderShippingProductWithAttributeRPCBody} from "../../../../../api/loops4/model/commerce/order/common/OrderShippingProductWithAttributeRPCBody";
import LoadingComponent from "../../../../../ui_template/LoadingComponent";
import PaymentResultSuccessView from "./viewer/PaymentResultSuccessView";
import PaymentResultFailView from "./viewer/PaymentResultFailView";
import {CountryContainer} from "../../../../../model/container/CountryContainer";

const mapStateToProps = (state, props) => {
    return {
        commerceCartModel: state.subscriberCommerceCartModel,
        cartId: state.subscriberInfo.commerceCartId,
    };
};

class PublishReleaseCollectionPaymentBox extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        releaseCollectionModel: PropTypes.object,
        accessTokenModel: PropTypes.object,
        commerceCartModel: PropTypes.object,
        cartId: PropTypes.string,
        subscriber: PropTypes.object
    }

    static viewMode = {
        inputForm: 'inputForm',
        result_success: 'result_success',
        result_fail: 'result_fail',
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context, REFS_SET) {
        super(props, context);

        const billModel = new BillModel()
        const products = this.props.commerceCartModel.products

        billModel.buyerAddressCountryCode = CountryContainer.master.japan.value

        billModel.billMoney.amount = products
            .map(product => product.price_amount)
            .reduce((acc, cur) => acc + cur, 0)

        // const dummySuccess = new PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse()
        // dummySuccess.date_created = "2020-04-11T01:42:07.000Z"
        // dummySuccess.date_updated = "2020-04-11T01:42:07.000Z"
        // dummySuccess.entity_id = "SUCCESS_ID"
        //
        // // const dummyFailure = "You have problem"
        // const dummyFailure = new PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCResponse()
        // dummyFailure.date_created = "2020-04-11T01:42:07.000Z"
        // dummyFailure.date_updated = "2020-04-11T01:42:07.000Z"
        // dummyFailure.entity_id = "FAILED_ID"
        //
        // dummyFailure.billing_error.error_message = 'Error message~~~'
        // dummyFailure.billing_error.error_code = 'ERROR_CODE'
        // dummyFailure.billing_error.error_description = 'ERROR_DESCRIPTION'

        this.state = {
            currentImage: 0,
            lightboxIsOpen: false,
            billModel: billModel,
            viewMode: PublishReleaseCollectionPaymentBox.viewMode.inputForm,
            paymentRequestResult: null,
            // viewMode: PublishReleaseCollectionPaymentBox.viewMode.result_success,
            // paymentRequestResult: dummySuccess,
            // viewMode: PublishReleaseCollectionPaymentBox.viewMode.result_fail,
            // paymentRequestResult: dummyFailure,
        }
    }

    submitPayment = async (billModel, orderingProducts, callback) => {
        console.log({billModel, orderingProducts})

        const param = new PutOrderCommerceProductReleaseItemFromCommerceCartProductRPCRequest();

        param.commerce_cart_product_entity_id = this.props.cartId;

        param.bill.payment_sender_address.address_city = billModel.buyerAddressCity;
        param.bill.payment_sender_address.address_country = billModel.buyerAddressCountryCode;
        param.bill.payment_sender_address.address_line = billModel.buyerAddressLine;
        param.bill.payment_sender_address.address_postal_code = billModel.buyerAddressPostalCode;
        param.bill.payment_sender_address.first_name = billModel.buyerFirstName;
        param.bill.payment_sender_address.last_name = billModel.buyerLastName;

        param.bill.payment_transaction.payment_country = billModel.buyerAddressCountryCode;
        param.bill.payment_transaction.payment_method = billModel.paymentMethod;

        param.bill.payment_transaction.payment_detail.card_number = billModel.cardNumber;
        param.bill.payment_transaction.payment_detail.expiration_month = billModel.cardValidationExpiringMonth;
        param.bill.payment_transaction.payment_detail.expiration_year = billModel.cardValidationExpiringYear;
        param.bill.payment_transaction.payment_detail.holder_name = billModel.cardHolder;
        param.bill.payment_transaction.payment_detail.cvc_code = billModel.cardValidationCode;

        param.bill.price.price_currency = billModel.billMoney.currency;
        param.bill.price.product_price_amount = billModel.billMoney.amount;
        param.bill.price.refund_price_amount = 0;
        param.bill.price.shipping_price_amount = 0;
        param.bill.price.tax_price_amount = 0;
        param.bill.price.total_price_amount = billModel.billMoney.amount;

        const postingProducts = orderingProducts.map(product => {
            const p = new OrderCommerceProductReleaseItemEntityRPCBody();
            p.entity_id = product.entity_id;
            p.quantity = 1;

            p.bill_price.price_amount = product.price_amount;
            p.bill_price.price_currency = product.price_currency;
            p.release_price.price_amount = product.price_amount;
            p.release_price.price_currency = product.price_currency;

            return p;
        })
        param.commerce_sale_product_release_item_list.push(...postingProducts);

        const shippingA = new OrderShippingRPCBody();
        shippingA.price.price_currency = billModel.billMoney.currency;
        shippingA.price.price_amount = billModel.billMoney.amount;

        shippingA.address.first_name = billModel.buyerFirstName;
        shippingA.address.last_name = billModel.buyerLastName;
        shippingA.address.address_line = billModel.buyerAddressLine;
        shippingA.address.address_city = billModel.buyerAddressCity;
        shippingA.address.address_postal_code = billModel.buyerAddressPostalCode;
        shippingA.address.address_country = billModel.buyerAddressCountryCode;

        const shippingProducts = orderingProducts.map(product => {
            const s = new OrderShippingProductWithAttributeRPCBody();
            s.entity_id = product.entity_id;
            s.quantity = 1;

            return s;
        });

        shippingA.products.push(...shippingProducts)
        param.shipping.push(shippingA)

        const rpcResult = await PUT_ORDER_COMMERCE_PRODUCT_RELEASE_ITEM_FROM_COMMERCE_CART_PRODUCT__V1_0({
            headers: getAuthorizationHeader(this.props.accessTokenModel),
            paramBody: param
        })

        let nextViewMode = null;
        let paymentRequestResult = null

        if(rpcResult.isError()) {
            nextViewMode = PublishReleaseCollectionPaymentBox.viewMode.result_fail
            paymentRequestResult = rpcResult.getErrorMessage()
        } else if(rpcResult.model.billing_error){
            nextViewMode = PublishReleaseCollectionPaymentBox.viewMode.result_fail
            paymentRequestResult = rpcResult.model
        } else {
            nextViewMode = PublishReleaseCollectionPaymentBox.viewMode.result_success
            paymentRequestResult = rpcResult.model
        }

        CommerceAction.refreshSubscriberCommerceCart()
        this.setState((prevs, props)=> {
            return {
                ...prevs,
                isLoading: false,
                viewMode: nextViewMode,
                paymentRequestResult: paymentRequestResult
            }
        }, () => {
            if(callback){
                callback()
            }
        })
    }

    updateBill = (nextBillModel) => {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                billModel: nextBillModel
            }
        })
    }

    backToInputFormView = () => {
        this.setState((prevs, props) => {
            return {
                ...prevs,
                viewMode: PublishReleaseCollectionPaymentBox.viewMode.inputForm
            }
        })
    }

    render() {
        const products = this.props.commerceCartModel.products

        let viewElement = null

        switch (this.state.viewMode) {
            case PublishReleaseCollectionPaymentBox.viewMode.inputForm:{
                viewElement = (
                    <div>
                        <h1>
                            <i className="ui icon payment" /> {LanguagePack.extract(this.props.language, OrderRequestLangPack.order.checkout.title)}
                        </h1>
                        <div>
                            {LanguagePack.extract(this.props.language, OrderRequestLangPack.order.checkout.guide)}
                        </div>

                        <div className="ui section divider" />

                        <PaymentRequestForm billModel={this.state.billModel}
                                            doPay={this.submitPayment}
                                            orderingProducts={products}
                                            releaseCollectionModel={this.props.releaseCollectionModel}
                                            subscriberModel={this.props.subscriber}
                                            updateBill={this.updateBill}
                        />
                    </div>
                )
                break;
            }
            case PublishReleaseCollectionPaymentBox.viewMode.result_success:{
                viewElement = (
                    <PaymentResultSuccessView paymentRequestResult={this.state.paymentRequestResult}
                                              releaseCollectionId={this.props.releaseCollectionModel.entityId}
                    />
                )
                break;
            }
            case PublishReleaseCollectionPaymentBox.viewMode.result_fail:{
                viewElement = (
                    <PaymentResultFailView paymentRequestResult={this.state.paymentRequestResult}
                                           releaseCollectionId={this.props.releaseCollectionModel.entityId}
                                           backToInputFormView={this.backToInputFormView}
                    />
                )
                break;
            }
        }

        if(this.state.isLoading){
            return <LoadingComponent loaderFlag={this.state.isLoading} style={{color: 'white'}}/>

        } else {
            return (
                <div className={'contentBox'}>
                    {viewElement}
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(PublishReleaseCollectionPaymentBox);