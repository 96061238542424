import * as React from "react";
import LanguagePack from "../../LanguagePack";

const SelectionLangPack = {
    selection: {
        headers: {
            all: new LanguagePack(
                "All",
                "全体",
                "전체"
            ),
            notDecided: new LanguagePack(
                "Not Decided",
                "未決定",
                "미결정"
            ),
            notDecidedNone: new LanguagePack(
                "NONE",
                "無し",
                "없음"
            ),
            picked: new LanguagePack(
                "Shopping Cart",
                "ショッピングカート",
                "쇼핑카트"
            ),
            rejected: new LanguagePack(
                "Excluded",
                "除外",
                "제외"
            ),
            alreadyPurchasedGuide: (itemCounts) => new LanguagePack(
                `The photos(${itemCounts} items) you have already purchased photos are excluded`,
                `既に購入された写真(${itemCounts}個)はリストから排除されました。`,
                `이미 구입된 사진(${itemCounts} 개)은 목록에서 제외 되었습니다.`
            ),
            nonContents: new LanguagePack(
                "You don't have photos in this tab.",
                "このタブには該当写真がございません。",
                "이 탭에는 해당하는 사진이 존재하지 않습니다."
            )

        },
        thumbnailHoverTxt: new LanguagePack(
            "See details",
            "詳しく見る",
            "자세히 보기"
        ),
        stepLoader:  new LanguagePack(
            "Loading ・・・",
            "ローディング　・・・",
            "로딩 중 ・・・"
        ),
        stepLoaderMessage: {
            forHighResolution:  new LanguagePack(
                "Steps for loading high-definition data",
                "段階的に高精細のデータを呼び出し中",
                "단계적으로 고화소 데이터를 불러오는 중"
            )
        },
        buttons:{
            pick: new LanguagePack(
                "Add To Cart",
                "カートに追加",
                "카트에 추가"
            ),
            picked: new LanguagePack(
                "Added To Cart",
                "カートに追加済み",
                "카트에 추가됨"
            ),
            reject: new LanguagePack(
                "Exclude",
                "除外",
                "제외"
            ),
            rejected: new LanguagePack(
                "Excluded",
                "除外済み",
                "제외됨"
            ),
            divider: new LanguagePack(
                "or",
                "又は",
                "또는"
            ),
            doneAndNext: new LanguagePack(
                "Done & Next",
                "完了 & 次へ",
                "완료 & 다음으로"
            )
        },
        bottomGuides:{
            pleaseGoNext: new LanguagePack(
                "If you're done to select photos, Please proceed next",
                "写真の選択が終わりましたら、次の段階へ進めて下さい。",
                "사진 선택이 끝나셨다면 다음 단계로 진행해주세요."
            )
        }
    }
}

export default SelectionLangPack