import {PutCommerceStockKeepingUnitRPCRequest} from "../../../../../commerce/sale/product/release/item/PutCommerceStockKeepingUnitRPCRequest";

// Package Dir: commerce/sale/product/release/item
// FileName: GetCommerceSaleProductReleaseItemRPCResponse.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.sale.product.release.item.GetCommerceSaleProductReleaseItemRPCResponse
// Super types: com.loops4.controller.loops4rpc.model.model_base.RPCModelBase, com.loops4.controller.loops4rpc.model.commerce.sale.product.release.item.CommerceSaleProductReleaseItemRPCBody, java.lang.Object

export class GetCommerceSaleProductReleaseItemRPCResponse {
    constructor() {

        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_created = null;
        /**
         *
         * JVM type: java.time.ZonedDateTime
         * @type {java.time.ZonedDateTime}
         */
        this.date_updated = null;
        /**
         *
         * JVM type: kotlin.String?
         * @type {string}
         */
        this.entity_id = null;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.release_item_entity_id = null;
        /**
         *
         * JVM type: kotlin.Double
         * @type {number}
         */
        this.price_amount = 0;
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.price_currency = null;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.sale.product.release.item.PutCommerceStockKeepingUnitRPCRequest
         * @type {PutCommerceStockKeepingUnitRPCRequest}
         */
        this.stock = new PutCommerceStockKeepingUnitRPCRequest();

    }
}