import React from 'react';

export default class SigningValidator {
    /**
     * @returns {Promise<Boolean>}
     */
    async validateTheAccount(reduxState, routeState){
        throw Error("Override it");
    }

    renderRedirectPage(reduxState, routeState) {
        return <div>
            Please override it
        </div>
    }
}