import MasterContainer from "./MasterContainer";

export class CommerceStockKeepingUnitTypeContainer extends MasterContainer {

    /**
     * @param {string} value
     * @param {string} name
     */
    constructor(value, name) {
        super();
        this.value = value;
        this.name = name;
    }

    static master = {
        UnlimitedSKU: new CommerceStockKeepingUnitTypeContainer(
            "UNLIMITED_SKU",
            "Unlimited Stock Keeping Unit"
        ),
        CountableSKU: new CommerceStockKeepingUnitTypeContainer(
            "COUNTABLE_SKU",
            "Countable Stock Keeping Unit"
        )
    };

    /**
     * @param data
     * @return {CommerceStockKeepingUnitTypeContainer}
     */
    static deserialize(data){
        switch (data){
            case CommerceStockKeepingUnitTypeContainer.master.UnlimitedSKU.value: return CommerceStockKeepingUnitTypeContainer.master.UnlimitedSKU;
            case CommerceStockKeepingUnitTypeContainer.master.CountableSKU.value: return CommerceStockKeepingUnitTypeContainer.master.CountableSKU;
        }
    }


}