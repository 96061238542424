import * as React from "react";
import * as PropTypes from "prop-types";
import {CurrencyContainer} from "../../../../../../model/container/money/CurrencyContainer";
import * as Loops4BinaryAPI from "../../../../../../api/loops4/Loops4BinaryAPI";
import CommerceMoneyContainer from "../../../../../../model/container/money/CommerceMoneyContainer";
import PageSelectorComponent from "../../../../../../ui_template/PageSelectorComponent";

export default class PublishItemListComponent extends React.Component {

    static propTypes = {
        language: PropTypes.object,
        accessTokenModel: PropTypes.object,
        items: PropTypes.arrayOf(PropTypes.object),
    }


    REFS_SET = {
        dimmerContainer:"dimmerContainer"
    };

    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageDividerCount: 10,
        };
    }

    const = {
        defaultValue: {
            productPrice: 10,
            productCurrency: CurrencyContainer.master.USD,
        },
        fixed: 2,
        minimumPrice:0
    }

    thStyle = {
        verticalAlign:'top',
        backgroundColor:'#F0FFF2'
    };


    /**
     * @param {number} pageNum
     */
    changePage(pageNum){
        this.setState((prevs, props) => {
            prevs.pageNum = pageNum;
            return prevs;
        });
    }

    /**
     * @param {number} pageDivierCounter
     */
    changePageDividerCount(pageDivierCounter){
        this.setState((prevs, props) => {
            prevs.pageDividerCount = pageDivierCounter;
            return prevs;
        });
    }


    render() {
        let currentPageStartNum = (this.state.pageNum - 1) * this.state.pageDividerCount
        let currentPageEndNum = this.state.pageNum * this.state.pageDividerCount
        let currentPageProducts = this.props.items.slice(currentPageStartNum, currentPageEndNum)
        let allPages = parseInt((this.props.items.length / this.state.pageDividerCount).toFixed(0))

        let pageButtons = []
        for(let pageNum = 1; pageNum <= allPages; pageNum++){
            pageButtons.push(
                <div className={"ui button compact mini " + (this.state.pageNum === pageNum ? "green" : null)} onClick={this.changePage.bind(this, pageNum)}>
                    {pageNum}
                </div>
            )
        }

       return(
           <div className="ui" >

               <table className="ui striped unstackable small table">
                   <thead>
                       <tr>
                           <th>Preview</th>
                           <th className="eight wide">Name</th>
                           <th className="two wide">Price</th>
                           <th></th>
                       </tr>
                       {this.props.thead}
                       <tr>
                           <th colSpan={4}>
                               <PageSelectorComponent currentPageNumber={this.state.pageNum}
                                                      totalPageNumber={allPages}
                                                      updatePageNumber={this.changePage.bind(this)}
                               />
                           </th>
                       </tr>
                   </thead>

                   <tbody>
                   {currentPageProducts.map( (product, entryNum) => {

                       const imageSrc = Loops4BinaryAPI.getLibraryItemPreviewURL({
                           accessTokenModel: this.props.accessTokenModel,
                           libraryItemId: product.entityId,
                           longTermPixel: 100
                       })

                       return (
                           <tr key={entryNum}>
                               <td>
                                   <img src={imageSrc} style={{width: '100%'}} />
                               </td>
                               <td>
                                   <div className="ui input fluid">
                                       {(this.props.updateName ? (
                                           <input type="text" value={product.productName} onChange={this.props.updateName.bind(this, product)} />
                                       ) : product.itemName )}
                                   </div>
                               </td>
                               <td>
                                   <div className="ui input">
                                   </div>
                               </td>
                               <td>
                                   {this.props.actionBar}
                               </td>
                           </tr>
                       )
                   })}
                   </tbody>
                   <tfoot>
                   <tr>
                       <th>Total {this.props.items.length} items </th>
                       <th> </th>
                       <th> </th>
                       <th> </th>
                   </tr>
                   </tfoot>
               </table>

           </div>
       )
    }

}