import {OrderPriceRPCBody} from "../../../../commerce/order/common/OrderPriceRPCBody";

// Package Dir: commerce/cart/product/relation_product
// FileName: CommerceOrderProductWithOrderAttributeRPCRequest.js
// RPC model: com.loops4.controller.loops4rpc.model.commerce.cart.product.relation_product.CommerceOrderProductWithOrderAttributeRPCRequest
// Super types: java.lang.Object

export class CommerceOrderProductWithOrderAttributeRPCRequest {
    constructor() {

        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderPriceRPCBody
         * @type {OrderPriceRPCBody}
         */
        this.bill_price = new OrderPriceRPCBody();
        /**
         *
         * JVM type: kotlin.String
         * @type {string}
         */
        this.commerce_sale_product_release_item_entity_id = null;
        /**
         *
         * JVM type: kotlin.Int
         * @type {number}
         */
        this.quantity = 0;
        /**
         *
         * JVM type: com.loops4.controller.loops4rpc.model.commerce.order.common.OrderPriceRPCBody
         * @type {OrderPriceRPCBody}
         */
        this.release_price = new OrderPriceRPCBody();

    }
}