export const INCREMENT = 'INCREMENT'
export const UI_CONTROL_MUTATE_FULL_SIZE_DIM = 'UI_CONTROL_MUTATE_FULL_SIZE_DIM'

export const AUTH_SET_ACCESS_TOKEN_FOR_USER = 'AUTH_SET_ACCESS_TOKEN_FOR_USER'
export const AUTH_SET_ACCESS_TOKEN_FOR_ADMIN = 'AUTH_SET_ACCESS_TOKEN_FOR_ADMIN'
export const AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER = 'AUTH_SET_ACCESS_TOKEN_FOR_SUBSCRIBER'

export const ROUTE_SET_STATE = 'ROUTE_SET_STATE'

export const SET_ROUTER_INJECTOR = 'SET_ROUTER_INJECTOR'

export const USER_SET_ACCOUNT_USER_INFO = 'USER_SET_ACCOUNT_USER_INFO'
export const USER_SET_ACCOUNT_ADMIN_INFO = 'USER_SET_ACCOUNT_ADMIN_INFO'
export const USER_SET_SUBSCRIBER_INFO = 'USER_SET_SUBSCRIBER_INFO'


export const COMMERCE_CART_SET_SUBSCRIBER = 'COMMERCE_CART_SET_SUBSCRIBER'

export const COMMERCE_RELEASE_COLLECTION_ITEMS_OF_COLLECTION = 'COMMERCE_RELEASE_COLLECTION_ITEMS_OF_COLLECTION'

export const COMMERCE_ORDER_HISTORY_SET_SUBSCRIBER = 'COMMERCE_ORDER_HISTORY_SET_SUBSCRIBER'
export const COMMERCE_ORDER_DETAIL_SET_SUBSCRIBER = 'COMMERCE_ORDER_DETAIL_SET_SUBSCRIBER'

export const COMMERCE_ORDER_HISTORY_SET_ADMIN = 'COMMERCE_ORDER_HISTORY_SET_ADMIN'

export const SELECTION_BUCKET_SET = 'SELECTION_BUCKET_SET'


