import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {MoneyFormatter} from "../../../../../model/formatter/MoneyFormatter";
import DateFormatter from "../../../../../model/formatter/DateFormatter";
import {LanguageMaster} from "../../../../../model/formatter/LanguageFormatter";
import CommerceOrderHistoryModel from "../../../../../model/entity/commerce/CommerceOrderHistoryModel";
import {CompactOrderHistoryResponse} from "../../../../../api/loops4/model/commerce/order/get_order_history/compact/CompactOrderHistoryResponse";
import ShippingDetailInAdminCompactOrderHistoryRow from "./row_item/ShippingDetailInAdminCompactOrderHistoryRow";
import {ADMIN_ADD_NEW_DELIVERY___V1_0} from "../../../../../api/loops4/caller/loops4RpcCaller";
import {getAuthorizationHeader} from "../../../../../api/loops4/Loops4RPCAPI";
import {AdminAddNewDeliveryHandlerRPCRequest} from "../../../../../api/loops4/model/commerce/order/ship/AdminAddNewDeliveryHandlerRPCRequest";
import {getOrderStateAsLocal} from "../../../../../model/formatter/OrderFormatter";
import {Link} from "react-router-dom";
import {RouteUtility} from "../../../../RouteUtility";
import {RouteDictionary, RoutePathParam, RouteQueryParam} from "../../../../RouteDictionary";
import AdminPortal from "../../AdminPortal";

const mapStateToProps = (state, props) => {
    return {
        languageMaster: state.languageMaster,
        accessTokenModel: state.auth.admin,
        routeState: state.routeState,
    };
};
class AdminCompactOrderHistoryRow extends React.Component {

    static propTypes = {
        languageMaster: PropTypes.objectOf(LanguageMaster),
        accessTokenModel: PropTypes.object.isRequired,
        compactOrder: PropTypes.objectOf(CommerceOrderHistoryModel).isRequired,
    }

    style = {
        summary_column: {
            marginBottom: 5
        },
        detail_title: {
            fontWeight: 'bold'
        }
    }

    REFS_SET = {
        itemsContainer: "itemsContainer"
    };


    constructor(props, context, REFS_SET) {
        super(props, context);
    }

    genLink(orderId, element){
        return <Link to={RouteUtility.getPathname(RouteDictionary.adminPortal_OrderDetail, {
            [RoutePathParam.orderId]: orderId,
        },{
            [RouteQueryParam.view_mode]: AdminPortal.viewMode.order_detail
        })}>
            {element}
        </Link>
    }

    addNewShipping = async (orderId) => {
        const param = new AdminAddNewDeliveryHandlerRPCRequest()
        param.commerce_order_entity_id = orderId

        await ADMIN_ADD_NEW_DELIVERY___V1_0({
            headers: getAuthorizationHeader(this.props.accessTokenModel),
            paramBody: param
        })
    }

    renderNewDeliveryButton(orderId){
        if(
            true
        ){
            return (
                <div className={'ui button grey'}
                     onClick={this.addNewShipping.bind(this, orderId)}
                >
                    新しい配送実施
                </div>
            )
        }
    }

    render() {
        const language = this.props.language
        const languageMaster = this.props.languageMaster

        /**
         * @type {CompactOrderHistoryResponse}
         */
        const order = this.props.compactOrder

        const currencyMaster  = MoneyFormatter.getMaster(order.bill.price.price_currency)

        return (
            <div className={'ui padded segment'}>
                <div className="flexContainerRow">
                    <div className="flexItem11">
                        <div style={{display: 'block', width: '100%'}}>
                            <div className="ui header medium">
                                <i className="box icon"></i>
                                注文の詳細
                            </div>

                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文ID
                                </div>
                                <div>
                                    {this.genLink(order.entity_id,
                                        <span>{order.entity_id}</span>
                                    )}
                                </div>
                            </div>

                            <div style={this.style.summary_column}>
                                <div>
                                    {getOrderStateAsLocal(order.order_state)}
                                </div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文日
                                </div>
                                <div>{DateFormatter.getDateFormatForLocaleByLanguageMaster(languageMaster, order.date_created)}</div>
                            </div>
                            <div style={this.style.summary_column}>
                                <div style={this.style.detail_title}>
                                    注文総額
                                </div>
                                <div>
                                    {MoneyFormatter.getLocalizedAmountWithCurrency(languageMaster, order.bill.price.total_price_amount, currencyMaster)}
                                </div>
                            </div>

                            <div className="ui section divider"></div>
                            <div style={this.style.summary_column}>
                                {this.renderNewDeliveryButton(order.entity_id)}
                            </div>
                            <div className="ui section divider"></div>

                            <div style={this.style.summary_column}>
                                <div className="ui header medium">
                                    <i className="shipping fast icon"></i>
                                    配送
                                </div>
                                {order.shipping.map(
                                    /**
                                     * @param {CompactShippingHistoryInCompactOrderHistoryResponse} shipping
                                     * @returns {*}
                                     */
                                    (shipping, index) => {
                                        return (
                                            <ShippingDetailInAdminCompactOrderHistoryRow shipping={shipping}
                                                                                         shippingIndex={index}
                                            />
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flexItem00">
                        <div style={{width: 120}}>
                            {this.genLink(order.entity_id,
                                <div className={'ui button grey'}>注文の詳細</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps)(AdminCompactOrderHistoryRow);